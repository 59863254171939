import React, { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import Alert from "@mui/material/Alert";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSGrid from "../custom/containers/ERPSGrid";
import DeliveryMethodComponent from "./DeliveryMethod";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ErrorMessage from "../custom/containers/ErrorMessage";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import {
  lpad,
  getDateFromString,
  formatDate,
  checkFieldsChange,
  getBillerGroupErrorMessage,
  statisticsFormatDate,
} from "../../utils/helper";
import { EditorState, convertFromHTML, ContentState } from "draft-js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { htmlToText } from "html-to-text";

import { getUpdateBillerFinancialInstitutionHeadings } from "../../constants/FinancialInstitutionConstant";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../actions";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
  DYNAMIC,
  STATIC,
  instructTypeList,
  getInstructionText,
} from "./helpers/instructText";
import {
  getWeighingValue,
  getNumericValue,
  checkNonZeroValue,
} from "./../../helpers/biller";

import TextEditor from "./SlateEditor/Editor";
import { useEditorState } from "./SlateEditor/useEditorState";

function BillerProfileUser(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billerId, componentAccess, displayBillerId } = props;
  const billerProfileAccess = componentAccess?.find(
    ({ componentType }) => componentType === "BPTAB"
  );
  const [hasCellError, setCellError] = useState([]);

  const [count, setCount] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);
  //const [htmlContent, setHtmlContent] = useState({});
  const [reqValidationForDelivery, setValidationForDelivery] = useState([]);
  const [showApiValidationErrorMessage, setShowApiValidationErrorMessage] =
    useState(false);
  const [errorMessageList, setErrorMessage] = useState([]);
  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});
  const [billerProfile, setBillerProfile] = useState({});
  const [oldBillerProfile, setOldBillerProfile] = useState({});
  const [provinceList, setProvinceList] = useState([]);
  const [deliveryMethodLists, setDeliveryMethodLists] = useState([]);
  const [formatedMethodMapList, setFormatedMethodMapList] = useState([]);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const fiReducerObj = useSelector((state) => state.fiReducer);
  const selectedFi = fiReducerObj.selectedFi;
  const financialInstitutionList = fiReducerObj.financialInstitutionList;
  const [disabledApplieToField, setDisabledApplieToField] = useState(false);
  const masterfinancialInstitutionList = fiReducerObj.masterFiDescriptions;

  const fiNumber = selectedFi || loginUser.fiNumber;
  const roleType = loginUser?.role?.role_type;
  const [billerFinancialInstitutionList, setBillerFinancialInstitutionList] =
    useState([]);
  const [initialDeliveryMethods, setInitialDeliveryMethods] = useState({});
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const successRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);
  const [masterRule, setMasterRule] = useState({});
  const [lineOfbusinessData, setLineOfbusinessData] = useState({});
  const [budgetCategoryCodes, setBudgetCategoryCodes] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [hasShowWarningMessage, setShowWarningMessage] = useState(false);
  const [preview, setPreview] = useState({
    englishPreview: "",
    frenchPreview: "",
  });
  const [imageError, setImageError] = useState({});
  const [imageLoading, setImageLoading] = useState(null);
  const budgetCategory = useSelector((state) => state.budgetCategoryReducer);
  const customRoutineListData = useSelector(
    (state) => state.billerProfileReducer?.customRoutineListData
  );
  const formattedMethodReducerValue = useSelector(
    (state) => state.formatMethodReducer
  );
  const provinceEntities = useSelector((state) => state.provinceReducer);

  const successMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileAssociatedUpdateData
  );

  const apiExceptionMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileAssociatedUpdateError
  );

  const errorRef = useRef();
  const apiExceptionRef = useRef();
  const warningRef = useRef();

  useEffect(() => {
    if (!selectedFile?.English?.file) {
      setPreview((preState) => ({
        ...preState,
        englishPreview: "",
      }));
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile?.English?.file);
    setPreview((preState) => ({
      ...preState,
      englishPreview: objectUrl,
    }));

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile?.English?.file]);

  useEffect(() => {
    if (!selectedFile?.French?.file) {
      setPreview((preState) => ({
        ...preState,
        frenchPreview: "",
      }));
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile?.French?.file);
    setPreview((preState) => ({
      ...preState,
      frenchPreview: objectUrl,
    }));
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile?.French?.file]);

  useEffect(() => {
    const updatedBillerId = billerId?.split("-")[1] || billerId;
    dispatch(
      actions.billerProfileActions.getAllDeliveryMethod(t, updatedBillerId)
    );
  }, []);

  useEffect(() => {
    dispatch(actions.billerProfileActions.getBudgetCategory(t));
  }, []);

  useEffect(() => {
    if (budgetCategory) {
      if (budgetCategory?.budgetCategoryFiAssociated) {
        const data = budgetCategory.budgetCategoryFiAssociated.map(
          (budgetCode) => ({
            value: budgetCode.budgetCategory,
            label: `${budgetCode.budgetCategory}-${budgetCode.budgetDescription}`,
          })
        );
        data.unshift({
          value: "",
          label: "",
        });
        setBudgetCategoryCodes(data);
      }
    }
    if (budgetCategory?.budgetCategoryFiAssociatedError) {
      setErrorMessage((preState) => [...preState, provinceEntities.error]);
    }
  }, [budgetCategory]);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && successMessage !== "ERPS0055" && showSuccessMessage]);

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [apiExceptionMessage && showApiExceptionMessage]);

  useEffect(() => {
    if (warningRef.current) {
      warningRef.current.focus();
    }
  }, [hasShowWarningMessage]);

  useEffect(() => {
    if (billerProfile?.validationDigitChanged == true) {
      setBillerProfile((prevBillerProfile) => ({
        ...prevBillerProfile,
        weighing: getWeighingValue(
          parseInt(prevBillerProfile?.validationToDigit),
          parseInt(prevBillerProfile?.validationFromDigit)
        ),
      }));
    }
  }, [billerProfile.validationToDigit, billerProfile.validationFromDigit]);

  const deliveryMethodReducerValue = useSelector(
    (state) => state.deliveryMethodReducer
  );

  const billerProfileReducerValue = useSelector(
    (state) => state.billerProfileReducer
  );
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [selectedRow, setSelectedRow] = useState([]);

  const [customRoutines, setCustomRoutines] = useState([]);

  const getLastDeliveryMethod = (oldDeliveryMethodData) => {
    let deliveryMethodLength;
    let restDeliveryMethods;

    if (oldDeliveryMethodData && oldDeliveryMethodData?.length) {
      deliveryMethodLength = oldDeliveryMethodData?.length - 1;
      if (deliveryMethodLength >= 0) {
        restDeliveryMethods = oldDeliveryMethodData[deliveryMethodLength];
      }
    } else if (deliveryMethods && deliveryMethods?.length) {
      deliveryMethodLength = deliveryMethods?.length - 1;
      if (deliveryMethodLength >= 0) {
        restDeliveryMethods = deliveryMethods[deliveryMethodLength];
      }
    } else if (
      Object.keys(initialDeliveryMethods) &&
      Object.keys(initialDeliveryMethods)?.length
    ) {
      restDeliveryMethods = initialDeliveryMethods;
    }
    return restDeliveryMethods;
  };

  const getSettlementTypeValue = (oldDeliveryMethodData) => {
    const lastDeliveryMethod = getLastDeliveryMethod(oldDeliveryMethodData);

    if (
      lastDeliveryMethod &&
      Object.keys(lastDeliveryMethod) &&
      Object.keys(lastDeliveryMethod)?.length
    ) {
      const hasEDISettlementType =
        lastDeliveryMethod?.deliveryMethod === "EDI" &&
        (lastDeliveryMethod?.distributionFormat === "H6 3030" ||
          lastDeliveryMethod?.distributionFormat === "CBSA 3030");

      return hasEDISettlementType ? "EDI" : "EFT";
    }
  };
  const getValue = (billerLink, billerLinkGroupDtos) => {
    const billerLinkObj = billerLinkGroupDtos?.find(
      ({ billerId }) => billerId === billerLink
    );
    return billerLinkObj || billerLink;
  };
  const hasEditorValues = () => {
    const billerData = billerProfileReducerValue.billerProfileAssociatedData;
    return {
      hasEnglishInstructionTextValue: billerData?.instructTextEnglish
        ? true
        : false,
      hasFrenchInstructionTextValue: billerData?.instructTextFrench
        ? true
        : false,
    };
  };
  const {
    htmlContent,
    englishValue,
    frenchValue,
    handleEditorChange,
    handleDeserialiseInitialValue,
    setEnglishValue,
    setFrenchValue,
  } = useEditorState(hasEditorValues());

  useEffect(() => {
    if (customRoutineListData) {
      const data = customRoutineListData.map(({ routineName }) => ({
        value: routineName,
        label: routineName,
      }));
      data.unshift({
        value: "",
        label: "",
      });
      setCustomRoutines(data);
    }
  }, [customRoutineListData]);

  useEffect(() => {
    if (billerProfileReducerValue) {
      if (billerProfileReducerValue.billerProfileAssociatedData) {
        let setBillerData =
          billerProfileReducerValue.billerProfileAssociatedData;
        if (
          fiNumber == "50" &&
          setBillerData?.billerProfileExtensions?.[0]?.instructType != STATIC
        ) {
          setBillerData = {
            ...setBillerData,
            instructTextEnglish: getInstructionText(setBillerData, "en"),
          };
          setBillerData = {
            ...setBillerData,
            instructTextFrench: getInstructionText(setBillerData, "fr"),
          };
        }
        if (fiNumber == "10") {
          setBillerProfile({
            ...setBillerData,
            billerLastActivateDate:
              setBillerData?.billerProfileExtensions?.[0]
                ?.billerLastActivateDate,
            billerLastDeactivateDate:
              setBillerData?.billerProfileExtensions?.[0]
                ?.billerLastDeactivateDate,
            bmoBillerId: billerId?.split("-")[0],
            subtract: setBillerData?.subtract,
            billerLink: getValue(
              setBillerData?.billerLink,
              setBillerData?.billerLinkGroupDtos
            )?.billerFiNumber,
            instructTextFrench: setBillerData?.instructTextFrench?.replace(
              /\0/g,
              ""
            ),
            instructTextEnglish: setBillerData?.instructTextEnglish?.replace(
              /\0/g,
              ""
            ),
          });
          setPreview({
            englishPreview: setBillerData?.imageENBase64Content,
            frenchPreview: setBillerData?.imageFRBase64Content,
          });
        } else {
          setBillerProfile({
            ...setBillerData,
            bmoBillerId:
              billerId?.split("-")?.length > 1
                ? billerId?.split("-")[0]
                : setBillerData?.bmoBillerId,
            subtract: setBillerData?.subtract,
            ...(fiNumber == "50" && {
              instructType:
                setBillerData?.billerProfileExtensions?.[0]?.instructType ||
                DYNAMIC,
            }),
            instructTextFrench: setBillerData?.instructTextFrench?.replace(
              /\0/g,
              ""
            ),
            instructTextEnglish: setBillerData?.instructTextEnglish?.replace(
              /\0/g,
              ""
            ),
            billerCustomerNumberMinimum:
              setBillerData?.billerCustomerNumberMinimum === 0
                ? undefined
                : setBillerData?.billerCustomerNumberMinimum,
            billerCustomerNumberMaximum:
              setBillerData?.billerCustomerNumberMaximum === 0
                ? undefined
                : setBillerData?.billerCustomerNumberMaximum,
          });
        }

        handleDeserialiseInitialValue("en", setBillerData?.instructTextEnglish);

        handleDeserialiseInitialValue("fr", setBillerData?.instructTextFrench);

        const updatedBillerProfileValues =
          billerProfileReducerValue.billerProfileAssociatedData;

        setOldBillerProfile({
          ...billerProfileReducerValue.billerProfileAssociatedData,
          instructTextFrench:
            updatedBillerProfileValues?.instructTextFrench?.replace(/\0/g, ""),
          instructTextEnglish:
            updatedBillerProfileValues?.instructTextEnglish?.replace(/\0/g, ""),
          billerSettlementType: getSettlementTypeValue(
            billerProfileReducerValue.billerProfileAssociatedData
              ?.distributionList
          ),
        });

        if (
          billerProfileReducerValue.billerProfileAssociatedData
            ?.billerMasterRules?.masterRules
        ) {
          setMasterRule(
            billerProfileReducerValue.billerProfileAssociatedData
              ?.billerMasterRules?.masterRules
          );
        }

        if (
          billerProfileReducerValue.billerProfileAssociatedData
            ?.lineOfbusinessData
        ) {
          setLineOfbusinessData(
            billerProfileReducerValue.billerProfileAssociatedData
              .lineOfbusinessData
          );
        }

        if (
          billerProfileReducerValue.billerProfileAssociatedData
            ?.distributionList
        ) {
          const [initialDeliveryMethodResponse, ...restDeliveryMethods] =
            billerProfileReducerValue.billerProfileAssociatedData
              ?.distributionList;

          const hasMember = getBillerLinkGroupDetail(
            billerProfileReducerValue.billerProfileAssociatedData
          )?.member;
          if (hasMember) {
            setInitialDeliveryMethods({
              ...initialDeliveryMethodResponse,
              distributionGroupRcpIndex: "C",
              deliveryMethod:
                initialDeliveryMethodResponse?.deliveryMethod?.toUpperCase(),
            });
            const updatedDeliveryMethods = restDeliveryMethods?.map(
              ({ distributionGroupRcpIndex, ...rest }) => ({
                ...rest,
                distributionGroupRcpIndex: "C",
              })
            );
            setDeliveryMethods(updatedDeliveryMethods);
            setOldBillerProfile((preState) => ({
              ...preState,
              distributionList: [
                ...updatedDeliveryMethods,
                {
                  ...initialDeliveryMethodResponse,
                  distributionGroupRcpIndex: "C",
                  deliveryMethod:
                    initialDeliveryMethodResponse?.deliveryMethod?.toUpperCase(),
                },
              ],
            }));
            setDisabledApplieToField(true);
          } else {
            setInitialDeliveryMethods({
              ...initialDeliveryMethodResponse,
              deliveryMethod:
                initialDeliveryMethodResponse?.deliveryMethod?.toUpperCase(),
              ...(!initialDeliveryMethodResponse?.length && {
                distributionGroupRcpIndex: "G",
              }),
            });
            setDeliveryMethods(restDeliveryMethods);
          }
        }
      }
    }
    if (billerProfileReducerValue?.billerProfileAssociatedError) {
      setErrorMessage([billerProfileReducerValue.billerProfileAssociatedError]);
    }
  }, [
    billerProfileReducerValue?.billerProfileAssociatedData,
    billerProfileReducerValue?.billerProfileAssociatedError,
  ]);

  useEffect(() => {
    if (props?.handleTabLoading) {
      props?.handleTabLoading(true);
    }
    dispatch(
      actions.billerProfileActions.getBillerProfileWithAssociatedObjects(
        billerId,
        t,
        props?.handleTabLoading
      )
    );
  }, []);

  useEffect(() => {
    if (masterfinancialInstitutionList) {
      const data = masterfinancialInstitutionList
        .map(({ fiNumber, fiName }) => ({
          value: fiNumber,
          label: lpad(fiNumber, 4) + "-" + fiName,
        }))
        .filter(({ value }) => value != 0);
      data.unshift({
        value: "",
        label: "",
      });
      setBillerFinancialInstitutionList(data);
    }
  }, [financialInstitutionList, masterfinancialInstitutionList]);

  useEffect(() => {
    if (provinceEntities) {
      if (provinceEntities.provinceEntitiesList) {
        const peData = Object.keys(provinceEntities.provinceEntitiesList).map(
          (key) => ({
            value: key,
            label: provinceEntities.provinceEntitiesList[key],
          })
        );
        setProvinceList(peData);
      }
    }
    if (provinceEntities?.error) {
      setErrorMessage([provinceEntities.error]);
    }
  }, [provinceEntities]);

  useEffect(() => {
    dispatch(actions.billerProfileActions.getAllProvince(t));
    dispatch(actions.fiActions.getMasterFiDescriptionList(t));
    dispatch(actions.billerProfileActions.getCustomRoutineList(props));
  }, []);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [
    errorMessageList.length ||
      validationErrors.length ||
      Object.values(imageError).length ||
      showApiValidationErrorMessage,
    ,
  ]);

  const additionalValidationLists = [
    { label: "", value: "" },
    { label: t("erps.billermanagement.label.noneValidation"), value: "NONE" },
    { label: t("erps.billermanagement.label.uniqueRoutine"), value: "URN" },
    {
      label: t("erps.billermanagement.label.checkDigitValidation"),
      value: "CDV",
    },
    {
      label: "Both",
      value: "CDV_URN",
    }
  ];

  const statusList = [
    { label: t("erps.billermanagement.label.inactive"), value: "I" },
    { label: t("erps.billermanagement.label.active"), value: "A" },
  ];

  const checkBoxKeys = [
    "alphaAllowed",
    "prefixCheck",
    "suffixCheck",
    "rule_fold",
    "rule_subtract",
  ];

  const languageItems = [
    {
      name: t("erps.userprofile.language.english.text", "English"),
      value: "E",
    },
    { name: t("erps.userprofile.language.french.text", "French"), value: "F" },
  ];

  const productType = [
    { label: "APA", value: "APA" },
    { label: "MEBP", value: "MEBP" },
  ];
  const appliesToItems = [
    {
      name: t("erps.billermanagement.label.appliesToItems.link"),
      value: "G",
      disabled: disabledApplieToField,
    },
    {
      name: t("erps.billermanagement.label.appliesToItems.biller"),
      value: "C",
    },
  ];

  const nilReports = [
    { name: t("erps.billermanagement.label.nilReports.yes"), value: "Y" },
    { name: t("erps.billermanagement.label.nilReports.no"), value: "N" },
  ];

  useEffect(() => {
    let deliveryMethodLists;

    const distributionLists =
      billerProfileReducerValue.billerProfileAssociatedData?.distributionList;

    const hasXMLDeliveryMethodExist = distributionLists?.some(
      ({ deliveryMethod }) => deliveryMethod === "XML"
    );

    const deliveryMethodList = deliveryMethodReducerValue?.deliveryMethodList;

    if (distributionLists) {
      if (fiNumber == 10 && deliveryMethodList && !hasXMLDeliveryMethodExist) {
        const { XML, ...rest } = deliveryMethodList;
        deliveryMethodLists = rest;
      } else {
        deliveryMethodLists = deliveryMethodList;
      }
    }

    if (deliveryMethodLists) {
      const data = Object.keys(deliveryMethodLists).map((key) => ({
        value: key,
        label: deliveryMethodLists[key],
      }));

      setDeliveryMethodLists(data);
    }

    if (deliveryMethodReducerValue?.error) {
      setErrorMessage([deliveryMethodReducerValue.error]);
    }
  }, [
    billerProfileReducerValue.billerProfileAssociatedData?.distributionList,
    deliveryMethodReducerValue?.deliveryMethodList,
  ]);

  useEffect(() => {
    if (formattedMethodReducerValue?.formatMethodEntitiesList) {
      setFormatedMethodMapList(
        formattedMethodReducerValue?.formatMethodEntitiesList
      );
    }
    if (formattedMethodReducerValue?.error) {
      setErrorMessage([formattedMethodReducerValue.error]);
    }
  }, [formattedMethodReducerValue]);

  useEffect(() => {
    const updatedBillerId = billerId?.split("-")[1] || billerId;

    dispatch(actions.billerProfileActions.getFormatMethod(t, updatedBillerId));
  }, []);

  useEffect(() => {
    if (billerProfile?.billerProfileExtensions) {
      const data = billerProfile?.billerProfileExtensions
        .map(({ billerStatus, id }) => {
          if (billerStatus === "A") {
            return id;
          }
          return "";
        })
        .filter((e) => Boolean(e));
      setSelectedRow(data);
    }
  }, [billerProfile?.billerProfileExtensions]);

  const getFormatListItem = (deliveryMethod) => {
    if (formatedMethodMapList && deliveryMethod) {
      if (deliveryMethod) {
        let data = [];
        const formtedMap = formatedMethodMapList?.[deliveryMethod];
        if (
          formtedMap &&
          (deliveryMethod === "EDI" || deliveryMethod === "FAX")
        ) {
          const filteredObj =
            deliveryMethod === "EDI"
              ? { value: "H6 3030", label: formtedMap?.["H6 3030"] }
              : deliveryMethod === "FAX"
              ? { value: "DTL FAX EN", label: formtedMap["DTL FAX EN"] }
              : "";
          data = Object.keys(formtedMap)
            .filter((formatKey) => formatKey && formatKey !== filteredObj.value)
            .map((key) => {
              return {
                value: key,
                label: formtedMap[key],
              };
            });
          data.unshift(filteredObj);
          data.unshift({
            value: "",
            label: "",
          });
        } else if (formtedMap) {
          data = Object.keys(formtedMap).map((key) => {
            return {
              value: key,
              label: formtedMap[key],
            };
          });
          data.unshift({
            value: "",
            label: "",
          });
        }
        data.shift();
        return data;
      }
    }
  };

  const getBillerLinkGroupDetail = (data) => {
    const billerExternalId = displayBillerId;
    const billerGroupDetail = data?.billerLinkGroupDtos?.find(
      ({ billerFiNumber }) => billerFiNumber == billerExternalId
    );
    return billerGroupDetail;
  };

  const handleLineOfBusiness = (key) => (e) => {
    setLineOfbusinessData((prevState) => ({
      ...prevState,
      [key]: e.target.checked ? "Y" : "N",
    }));
  };

  const handleClickDeliveryMethod = () => {
    setCount(count + 1);

    setDeliveryMethods([
      ...deliveryMethods,
      {
        deliveryMethod: "",
        distributionFormat: "",
        distributionEDIMailbox: "",
        distributionListCode: "",
        destinationAttention: "",
        distributionPhoneNumber: "",
        distributionExtensionNumber: "",
        distributionGroupRcpIndex: "C",
      },
    ]);
  };
  const getEdiMailBoxValue = (val) => {
    if (val === "AMEX") {
      return "AMEX";
    } else if (val === "CBSA 3030") {
      return "CBSA";
    } else if (val === "REV CAN") {
      return fiNumber == "99" || fiNumber == "50" ? "CCRAADRCPR" : "CIBC";
    } else if (val === "QUEBEC HYD") {
      return fiNumber == "99" || fiNumber == "50" ? "HYDROQUEBEC" : "CIBC";
    } else if (val) {
      return fiNumber == "99" || fiNumber == "50"
        ? "BMO"
        : fiNumber == "10"
        ? "CIBC"
        : "";
    }
    return "";
  };

  const handleChangeInitialDelivery = (key, value) => {
    if (
      key === "distributionFormat" &&
      initialDeliveryMethods?.deliveryMethod === "EDI"
    ) {
      const ediMailBoxValue = getEdiMailBoxValue(value);
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else if (key === "deliveryMethod") {
      let ediMailBoxValue = "";
      const distributionFormatValue = value
        ? getFormatListItem(value)[0]?.value
        : "";
      if (value === "EDI") {
        ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
      }
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionFormat: distributionFormatValue,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else {
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
      });
    }
  };

  const handleChangeDeliveryMethods = (key, i) => (e) => {
    const value = e.target.value;
    const updatedDeliveryMethods = deliveryMethods.map((item, id) => {
      if (id === i) {
        if (key === "distributionFormat" && item.deliveryMethod === "EDI") {
          const ediMailBoxValue = getEdiMailBoxValue(value);
          return {
            ...item,
            [key]: value,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else if (key === "deliveryMethod") {
          let ediMailBoxValue = "";
          const distributionFormatValue = value
            ? getFormatListItem(value)[0]?.value
            : "";
          if (value === "EDI") {
            ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
          }
          return {
            ...item,
            [key]: value,
            distributionFormat: distributionFormatValue,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else {
          return {
            ...item,
            [key]: value,
          };
        }
      }
      return item;
    });

    setDeliveryMethods(updatedDeliveryMethods);
  };

  const handleRemoveDeliveryMethod = (index) => (e) => {
    setCount(count - 1);
    const slicedArr = deliveryMethods.filter((item, i) => index !== i);
    setDeliveryMethods(slicedArr);
    const slicedValidationForDelivery = reqValidationForDelivery.filter(
      (item, i) => index !== i
    );
    setValidationForDelivery(slicedValidationForDelivery);
  };

  const handleGetCellClassName = (params) => {
    if (params.field === "billPaymentLimit") {
      const checkError = hasCellError.find((val, idx) => idx + 1 == params.id);
      if (checkError?.hasError) {
        return "billPaymentLimit_error_highlight";
      }
    }
  };
  const formatCurrentDate = () => {
    const currentDate = new Date();
    const year = currentDate.getFullYear();
    const month = (currentDate.getMonth() + 1).toString().padStart(2, "0");
    const day = currentDate.getDate().toString().padStart(2, "0");

    const formattedDate = `${year}${month}${day}`;
    return +formattedDate;
  };
  const handleBillerProfile = (key) => (e) => {
    if (key === "recipientCcin") {
      setBillerProfile((preBillerInfo) => ({
        ...preBillerInfo,
        [key]: parseInt(e.target.value),
      }));
    } else if (key === "accountValidationValue") {
      if (e.target.value.includes("CDV")) {
        setBillerProfile((prevBillerProfile) => ({
          ...prevBillerProfile,
          [key]: e.target.value,
          subtract: "Y",
          ...(fiNumber == "50" && { instructType: DYNAMIC }),
        }));
      } else {
        setBillerProfile((prevBillerProfile) => ({
          ...prevBillerProfile,
          [key]: key === "billerLiveDate" ? e : e.target.value,
          modChecker: prevBillerProfile?.modChecker,
          checkDigitLocation: prevBillerProfile?.validationToDigit,
          validationFromDigit: prevBillerProfile?.validationToDigit,
          validationToDigit: prevBillerProfile?.validationToDigit,
          fold: prevBillerProfile?.fold || "N",
          subtract: prevBillerProfile?.subtract || "N",
          weighing: prevBillerProfile?.weighing || "",
          ...(fiNumber == "50" &&
            !e.target.value.includes("URN") && { instructType: DYNAMIC }),
        }));
      }
    } else if (fiNumber == 10 && key === "billerStatus") {
      const value = e.target.value;
      const currentDate = formatCurrentDate();
      const { billerLastDeactivateDate, billerLastActivateDate } =
        billerProfileReducerValue.billerProfileAssociatedData
          ?.billerProfileExtensions?.[0];
      const dateValues =
        value === "I"
          ? { billerLastDeactivateDate: currentDate, billerLastActivateDate }
          : { billerLastActivateDate: currentDate, billerLastDeactivateDate };
      setBillerProfile((prevBillerProfile) => ({
        ...prevBillerProfile,
        ...dateValues,
        [key]: value,
      }));
    } else {
      setBillerProfile((prevBillerProfile) => ({
        ...prevBillerProfile,
        [key]: checkBoxKeys.includes(key)
          ? e.target.checked
            ? "Y"
            : "N"
          : key === "billerLiveDate" ||
            key === "billerLastActivateDate" ||
            key === "billerLastDeactivateDate"
          ? e
          : e.target.value,
        ...((key == "validationToDigit" || key == "validationFromDigit") && {
          validationDigitChanged: true,
        }),
      }));
      if (key === "instructType") {
        if (e.target.value === DYNAMIC) {
          setEnglishValue(
            handleDeserialiseInitialValue(
              "en",
              billerProfileReducerValue.billerProfileAssociatedData
                ?.instructTextEnglish
            )
          );
          setFrenchValue(
            handleDeserialiseInitialValue(
              "fr",
              billerProfileReducerValue.billerProfileAssociatedData
                ?.instructTextFrench
            )
          );
        } else if (e.target.value === STATIC) {
          const initialData = [
            {
              type: "paragaph",
              children: [{ text: "" }],
            },
          ];
          setEnglishValue(initialData);
          setFrenchValue(initialData);
        }
      }
    }
  };

  const handleBlur = (key) => (e) => {
    const value = e.target.value;
    setBillerProfile((preBillerData) => ({
      ...preBillerData,
      [key]: value && typeof value === "string" ? value?.toUpperCase() : value,
    }));
  };

  const handleBillerProfileCheckbox = (key) => (e) => {
    setBillerProfile((prevBillerProfile) => ({
      ...prevBillerProfile,
      [key]: e.target.checked ? "Y" : "N",
    }));
  };

  const handleMasterRule = (key) => (e) => {
    setMasterRule((prevMasterRule) => ({
      ...prevMasterRule,
      [key]: checkBoxKeys.includes(key)
        ? e.target.checked
          ? "Y"
          : "N"
        : e.target.value,
    }));
  };

  const getFiName = (fiNumber) => {
    const fiNameWithNumber = billerFinancialInstitutionList?.find(
      ({ value }) => value == fiNumber
    )?.label;
    return fiNameWithNumber ? fiNameWithNumber.split("-")[1] : "";
  };

  const checkRequiredRecipientCcin = () => {
    const hasCcinRequired =
      (initialDeliveryMethods?.deliveryMethod === "EDI" &&
        initialDeliveryMethods?.distributionFormat === "H6 3030") ||
      deliveryMethods.some(
        (method) =>
          method?.deliveryMethod === "EDI" &&
          method?.distributionFormat === "H6 3030"
      );
    return hasCcinRequired;
  };

  function hasEvenNumberOfDoubleQuotes(str) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '"') {
        count++;
      }
    }
    return count % 2 === 0;
  }

  function countCharacters(str) {
    let countDoubleQuotes = 0;
    let countSpaces = 0;
    let countQuestionMarks = 0;
    for (let i = 0; i < str.length; i++) {
      const char = str[i];
      if (char === '"' || char === "”") {
        countDoubleQuotes++;
      } else if (char === " ") {
        countSpaces++;
      } else if (char === "?") {
        countQuestionMarks++;
      }
    }
    return {
      doubleQuotes: countDoubleQuotes,
      spaces: countSpaces,
      questionMarks: countQuestionMarks,
    };
  }

  const isValidUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const hasUrlValidProtocol =
        urlObj.protocol === "http:" || urlObj.protocol === "https:";
      if (
        hasUrlValidProtocol &&
        (url.includes("http://") || url.includes("https://"))
      ) {
        const urlHostPathName = urlObj?.hostname + urlObj?.pathname;
        const res = urlHostPathName.match(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,126}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res !== null;
      }
    } catch (e) {
      return false;
    }
  };

  const checkPhoneNumberValidationForDeliveryMethod = () => {
    const numericRegEx10 = /^[0-9]{10}$/;

    const hasValidLengthForInitialMethod =
      initialDeliveryMethods?.distributionPhoneNumber !== 0
        ? initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim() &&
          !numericRegEx10.test(
            initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim()
          )
        : "";

    const hasValidLengthForOtherMethods =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber !== 0 &&
          distributionPhoneNumber?.toString()?.trim() &&
          !numericRegEx10.test(distributionPhoneNumber?.toString()?.trim())
      );
    return { hasValidLengthForInitialMethod, hasValidLengthForOtherMethods };
  };

  function validateText(
    text,
    min,
    max,
    alphanumericCommaRegEx,
    errorMessages,
    billerMaxKey,
    billerMinKey,
    highlightErrorKey,
    t
  ) {
    const { alphanumericWithComma, minLengthMustNotZero, totalLengthExceedsMax } =
      errorMessages;

    let errors = [];
    let highlightErrorV1 = {};

    if (!alphanumericCommaRegEx.test(text)) {
      errors.push(t(alphanumericWithComma));
      highlightErrorV1 = {
        ...highlightErrorV1,
        [highlightErrorKey]: true,
      };
    } else {
      if (!min) {
        errors.push(t(minLengthMustNotZero));
        highlightErrorV1 = {
          ...highlightErrorV1,
          [highlightErrorKey]: true,
          [billerMinKey]: true,
        };
      }
      if (!max) {
        errors.push(t(totalLengthExceedsMax));

        highlightErrorV1 = {
          ...highlightErrorV1,
          [highlightErrorKey]: true,
          [billerMaxKey]: true,
        };
      }

      if (min && max) {
        const splittedValue = text?.split(",");
        const invalidLength = splittedValue.find(
          (val) => !(val?.length <= max)
        );

        if (invalidLength) {
          let objMessage = {};

          if (invalidLength?.length > max) {
            objMessage.totalLengthExceedsMax = totalLengthExceedsMax;
          }

          if (Object.keys(objMessage).length) {
            errors.push(...Object.values(objMessage).map((msg) => t(msg)));
            highlightErrorV1[highlightErrorKey] = true;
          }
        }
      }
    }
    return { highlightErrorV1, errors };
  }

  function validateTextForCibc(
    text,
    regexForCibcSuffixPrefix,
    errorMessages,
    highlightErrorKey,
    t
  ) {
    const { totalLengthExceedsMax } = errorMessages;

    let errors = [];
    let highlightErrorV1 = {};

    if (!regexForCibcSuffixPrefix.test(text)) {
      errors.push(t(totalLengthExceedsMax));
      highlightErrorV1 = {
        ...highlightErrorV1,
        [highlightErrorKey]: true,
      };
    } else {
      if (text) {
        const splittedValue = text?.split(",");
        const invalidLength = splittedValue.find((val) => !(val?.length <= 15));

        if (invalidLength) {
          if (invalidLength?.length > 15) {
            errors.push(t(totalLengthExceedsMax));
            highlightErrorV1[highlightErrorKey] = true;
          }
        }
      }
    }
    return { highlightErrorV1, errors };
  }

  const validateBillerProfileUser = (billerProfile) => {
    const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
    const alphaNumericRegExWithSomeSpecialChars =
      /^[0-9A-Za-z\&\(\)_\-:;"',\.\/ ]+$/;

    const alphanumericCommaRegEx = /^[0-9A-Za-z]+(,[0-9A-Za-z]+)*$/;
    const alphaNumericWithoutSpace = /^[0-9A-Za-z_]+$/;
    const numericRegEx10 = /^[0-9]{10}$/;
    const invalidContactPhone = "0000000000";
    const validContactNumberRegEx = /^([1-9]){1}[0-9]{9}$/;
    const alphaNumericWithSpecialCharRegExV1 =
      /^[0-9A-Za-z\&\#\(\)_\-:;"',\.\/ ]+$/;
    const alphaNumericWithSpecialCharRegEx =
      /^[éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\&\#\(\)_\-:;"',\.\/ ]+$/;
    const billerNameRegex =
      /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;
    const regexForBillerName =
      fiNumber == 10 ? billerNameRegex : alphaNumericRegExWithSomeSpecialChars;

    const numericRegEx = /^[0-9]+$/;
    const modCheckBmo = ["10", "11", "7", "07"];
    const modCheckCibc = ["07", "10", "11", "7"];
    let errors = [];
    let highlightErrorV1 = {};
    const regexForSpecialCharacter = /^[À-Ÿ!~@$%^*+=[]{}\|<>?]+$/;
    const regexForSpecialCharacterWithoutFrenchChar = /^[!~@$%^*+=[]{}\|<>?]+$/;

    const alphnumericWithHypen = /^[0-9A-Za-z\-]+$/;
    const billerCustomerNumberFormatRegEx = /^[B9A\-]+$/;
    const regexForCibcSuffixPrefix = /^[^'><$]+$/;
    const MAX_LENGTH = fiNumber == 50 || fiNumber == 99 ? 1200 : 1600;

    const englishContentLength = htmlToText(htmlContent["en"])?.length;

    const frenchContentLength = htmlToText(htmlContent["fr"])?.length;

    if (!billerProfile?.billerName) {
      errors.push(
        t("erps.billermanagement.error.cannotblank.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (billerProfile?.billerName && billerProfile?.billerName.length > 45) {
      errors.push(
        t("erps.billermanagement.error.nameNotGreaterThan45.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (!regexForBillerName.test(billerProfile?.billerName)) {
      errors.push(
        t("erps.billermanagement.error.nameAlphaNumeric.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (fiNumber == "10" && !billerProfile?.billerLink) {
      errors.push(t("erps.billermanagement.error.linkNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerLink: true };
    }
    if (
      billerProfile?.billerShortName &&
      billerProfile?.billerShortName.length > 20
    ) {
      errors.push(
        t(
          "erps.billermanagement.error.nameNotGreaterThan20.billerShortNameEnglish"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!regexForBillerName.test(billerProfile?.billerShortName)) {
      errors.push(
        t("erps.billermanagement.error.englishshortNameAlphaNumberic")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!billerProfile?.billerCity) {
      errors.push(t("erps.billermanagement.error.cityNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (billerProfile?.billerCity && billerProfile?.billerCity.length > 25) {
      errors.push(t("erps.billermanagement.error.cityNotGreaterThan25"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (!billerProfile?.billerProvince) {
      errors.push(t("erps.billermanagement.error.provinceNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerProvince: true };
    }

    if (
      billerProfile?.billerPostalCode &&
      !alphaNumericRegEx.test(billerProfile?.billerPostalCode)
    ) {
      errors.push(t("erps.billermanagement.error.postalCodeAlphaNumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerPostalCode: true };
    }

    if (fiNumber == 10 && !billerProfile?.billerBudgetCategory) {
      errors.push(
        t("erps.billermanagement.billerBudgetCategory.error.cannotblank")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerBudgetCategory: true };
    }
    if (fiNumber == 10 && !billerProfile?.billerCounty) {
      errors.push(t("erps.billermanagement.billerCounty.error.cannotblank"));
      highlightErrorV1 = { ...highlightErrorV1, billerCounty: true };
    }
    if (
      fiNumber == 10 &&
      billerProfile?.billerCounty &&
      !alphaNumericWithoutSpace.test(billerProfile?.billerCounty)
    ) {
      errors.push(t("erps.billermanagement.billerCounty.error.alphanumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerCounty: true };
    }
    if (
      billerProfile?.billerCounty &&
      billerProfile?.billerCounty.length != 3
    ) {
      errors.push(t("erps.billermanagement.billerCounty.error.equalto3chars"));
      highlightErrorV1 = { ...highlightErrorV1, billerCounty: true };
    }
    if (fiNumber == 10 && !billerProfile?.billerShortName) {
      errors.push(
        t("erps.billermanagement.billershortnameenglish.error.cannotblank")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }

    if (!billerProfile?.billerLanguageCode) {
      errors.push(t("erps.billermanagement.error.languageNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerLanguageCode: true };
    }
    if (!billerProfile?.primaryContactName) {
      errors.push(t("erps.billermanagement.error.primaryContactNameNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactName &&
      billerProfile?.primaryContactName.length > 35
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameNotGreaterThan35")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(billerProfile?.primaryContactName)
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (!billerProfile?.primaryContactPhone) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNumberNotBlank")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    }
    if (
      billerProfile?.primaryContactPhone !== undefined &&
      billerProfile?.primaryContactPhone?.toString()?.length !== 10
    ) {
      errors.push(
        t("erps.billermanagement.error.telephoneNumberNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    } else if (
      billerProfile?.primaryContactPhone !== undefined &&
      (billerProfile?.primaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.primaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidprimaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    }

    if (
      billerProfile?.secondaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(
        billerProfile?.secondaryContactName
      )
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactName: true };
    }

    if (
      billerProfile?.secondaryContactPhone &&
      billerProfile?.secondaryContactPhone?.toString()?.length !== 10
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryTelephoneNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    } else if (
      billerProfile?.secondaryContactPhone &&
      (billerProfile?.secondaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.secondaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidsecondaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    }

    if (
      !billerProfile?.creditorFiNumber &&
      billerProfile?.creditorFiNumber !== 0
    ) {
      if (fiNumber == 10) {
        errors.push(
          t("erps.billermanagement.error.billerFiNotBlank.creditorfi")
        );
      } else {
        errors.push(t("erps.billermanagement.error.billerFiNotBlank"));
      }
      highlightErrorV1 = {
        ...highlightErrorV1,
        creditorFiNumber: true,
      };

      if (
        billerProfile?.debitTransitNumber &&
        !numericRegEx.test(billerProfile?.debitTransitNumber)
      ) {
        errors.push(
          t("erps.billermanagement.error.debitTransitNumberNumbericValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }

      if (
        numericRegEx.test(billerProfile?.debitTransitNumber) &&
        billerProfile?.debitTransitNumber &&
        billerProfile?.debitTransitNumber.length > 5
      ) {
        errors.push(
          t(
            "erps.billermanagement.error.debitTransitNumberNotGreaterThan5Digit"
          )
        );
        highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }
      if (!alphnumericWithHypen.test(billerProfile?.debitAccountNumber)) {
        errors.push(
          t("erps.billermanagement.error.debitAccountNumberInvalidValue")
        );
        highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
      }
    }

    if (!billerProfile?.creditorTransitNumber) {
      errors.push(t("erps.billermanagement.error.creditTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      numericRegEx.test(billerProfile?.creditorTransitNumber) &&
      billerProfile?.creditorTransitNumber.length > 5
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNotGreaterThan5Digit")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      !numericRegEx.test(billerProfile?.creditorTransitNumber)
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNumbericValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }

    if (!billerProfile?.creditorAccountNumber) {
      errors.push(t("erps.billermanagement.error.creditAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (!alphnumericWithHypen.test(billerProfile?.creditorAccountNumber)) {
      errors.push(
        t("erps.billermanagement.error.creditAccountNumberInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (
      fiNumber == 10 &&
      billerProfile?.creditorAccountNumber &&
      billerProfile?.creditorAccountNumber.length > 12
    ) {
      errors.push(
        t("erps.billermanagement.error.creditAccountNotGreaterThan12")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (fiNumber == 10 && !billerProfile?.debitTransitNumber) {
      errors.push(t("erps.billermanagement.error.debitTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
    }
    if (fiNumber == 10 && !billerProfile?.debitAccountNumber) {
      errors.push(t("erps.billermanagement.error.debitAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
    }
    if (
      fiNumber == 10 &&
      billerProfile?.debitAccountNumber &&
      billerProfile?.debitAccountNumber.length > 12
    ) {
      errors.push(
        t("erps.billermanagement.error.debitAccountNotGreaterThan12")
      );
      highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
    }
    if (fiNumber == 10 && !billerProfile?.debitFiNumber) {
      errors.push(t("erps.billermanagement.error.debitFiNumberNotBlank.v1"));
      highlightErrorV1 = { ...highlightErrorV1, debitFiNumber: true };
    }

    if (checkValidBillPaymentValue()) {
      errors.push(
        t("erps.billermanagement.error.paymentLengthNotGreaterThan10Digits")
      );
    }
    if (
      !initialDeliveryMethods?.deliveryMethod ||
      (deliveryMethods.length &&
        deliveryMethods.some(({ deliveryMethod }) => !deliveryMethod))
    ) {
      errors.push(t("erps.billermanagement.error.deliveryMethodNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, deliveryMethod: true };
    }
    if (
      !initialDeliveryMethods?.distributionListCode?.trim() ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode }) => !distributionListCode?.trim()
        ))
    ) {
      if (!initialDeliveryMethods?.distributionListCode?.trim()) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.destinationNotBlank"));
    }

    if (
      (initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)) ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode, deliveryMethod }) =>
            deliveryMethod === "FAX" &&
            distributionListCode &&
            !numericRegEx10.test(distributionListCode)
        ))
    ) {
      if (
        initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.invaliddestinationBlank"));
    }

    if (
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForInitialMethod ||
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForOtherMethods
    ) {
      if (
        checkPhoneNumberValidationForDeliveryMethod()
          ?.hasValidLengthForInitialMethod
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      errors.push(
        t(
          "erps.billermanagement.error.distributionTelephoneNumberShouldBe10Digit"
        )
      );
    }
    if (
      checkDistributionLengthValid()?.hasValidDistributionNumber ||
      checkDistributionLengthValid()?.hasValidOtherDistributionNumber
    ) {
      if (checkDistributionLengthValid()?.hasValidDistributionNumber) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      // if (
      //   checkDistributionLengthValid()?.hasValidDistributionNumber ||
      //   checkDistributionLengthValid()?.hasValidOtherDistributionNumber
      // ) {
      //   errors.push(
      //     t("erps.billermanagement.error.distributionTelephoneNumberInvalid")
      //   );
      // }
    }

    if (getRequiredValidationForDeliveryMethod().length) {
      const validationForDeliveryMethod =
        getRequiredValidationForDeliveryMethod();
      setValidationForDelivery(validationForDeliveryMethod);
    }
    if (checkRequiredRecipientCcin()) {
      if (
        !billerProfile?.recipientCcin ||
        Number.isNaN(billerProfile?.recipientCcin)
      ) {
        errors.push(t("erps.billermanagement.error.cooperateCreditorNotBlank"));
        highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
      }
      if (
        billerProfile?.recipientCcin >= 0 &&
        billerProfile?.recipientCcin.toString().length != 8
      ) {
        errors.push(
          t("erps.billermanagement.error.cooperateNotGreaterThan8Digits")
        );
        highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
      }
    }
    if (fiNumber == 10) {
      const minVal = billerProfile?.billerCustomerNumberMinimum;
      const maxVal = billerProfile?.billerCustomerNumberMaximum;
      const accontMask = billerProfile?.billerAccountMask;
      const invalidAccountMaskRegex = /^[^'><$]*$/;
      let minLen = 0;
      let maxLen = 0;
      if (accontMask) {
        const { doubleQuotes, spaces, questionMarks } =
          countCharacters(accontMask);
        const accountMaskLen = accontMask.length;
        minLen = accountMaskLen - doubleQuotes - spaces;
        maxLen = accountMaskLen - doubleQuotes - spaces;

        if (accontMask && accontMask?.includes("?")) {
          minLen = minLen - questionMarks;
        }
      }

      if (!accontMask) {
        errors.push(t("erps.billermanagement.error.accountMaskRequired"));
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerAccountMask: true,
        };
      } else if (
        !invalidAccountMaskRegex.test(accontMask) ||
        !hasEvenNumberOfDoubleQuotes(accontMask)
      ) {
        errors.push(t("erps.billermanagement.error.accountMaskInvalidValue"));
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerAccountMask: true,
        };
      }
      if (minVal === undefined) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMinValueRequired")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMinimum: true,
        };
      } else if (minVal != minLen) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMinValueMatchMask")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMinimum: true,
        };
      }
      if (maxVal === undefined) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMaxValueRequired")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMaximum: true,
        };
      } else if (maxVal != maxLen) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMaxValueMatchMask")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMaximum: true,
        };
      }
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMinimum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMinimum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMinValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMaximum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMaximum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMaxValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMaximum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      parseInt(billerProfile?.billerCustomerNumberMinimum) >
        parseInt(billerProfile?.billerCustomerNumberMaximum)
    ) {
      errors.push(
        t("erps.billermanagement.error.billingAccountMinLessThanMax")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
        billerCustomerNumberMaximum: true,
      };
    }

    if (fiNumber != 10) {
      const billerMinField = billerProfile?.billerCustomerNumberMinimum;
      const billerMaxField = billerProfile?.billerCustomerNumberMaximum;
      const min = parseInt(billerMinField);
      const max = parseInt(billerMaxField);
      if ((isNaN(min) && !isNaN(max)) || (!isNaN(min) && isNaN(max))) {
        const hasMinEmpty = isNaN(min) && !isNaN(max);
        const hasMaxEmpty = !isNaN(min) && isNaN(max);

        if (hasMinEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMinValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMinimum: true,
          };
        } else if (hasMaxEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMaxValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMaximum: true,
          };
        }
      }
    }

    if (
      billerProfile?.accountValidationValue &&
      billerProfile?.accountValidationValue !== "NONE"
    ) {
      if (
        billerProfile?.accountValidationValue &&
        billerProfile?.accountValidationValue.includes("CDV")
      ) {
        if (!billerProfile?.modChecker) {
          errors.push(t("erps.billermanagement.error.modCheckRequired"));
          highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
        }
        if (billerProfile?.modChecker) {
          if (
            (fiNumber == "99" || fiNumber == "50") &&
            !modCheckBmo.includes(billerProfile?.modChecker)
          ) {
            errors.push(t("erps.billermanagement.error.invalidModCheckBmo"));
            highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
          }
          if (
            fiNumber == "10" &&
            !modCheckCibc.includes(billerProfile?.modChecker)
          ) {
            errors.push(t("erps.billermanagement.error.invalidModCheckCibc"));
            highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
          }
        }

        if (billerProfile?.checkDigitLocation === undefined) {
          errors.push(t("erps.billermanagement.error.digitLocaionRequired"));
          highlightErrorV1 = { ...highlightErrorV1, checkDigitLocation: true };
        }
        if (billerProfile?.validationFromDigit === undefined) {
          errors.push(
            t("erps.billermanagement.error.validatonFromDigitValueRequired")
          );
          highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
        }
        if (billerProfile?.validationToDigit === undefined) {
          errors.push(
            t("erps.billermanagement.error.validatonToDigitValueRequired")
          );
          highlightErrorV1 = { ...highlightErrorV1, validationToDigit: true };
        }
        if (parseInt(billerProfile?.checkDigitLocation) <= 0) {
          errors.push(t("erps.billermanagement.error.digitLocationNonZero"));
          highlightErrorV1 = { ...highlightErrorV1, checkDigitLocation: true };
        }
        if (parseInt(billerProfile?.validationFromDigit) <= 0) {
          errors.push(t("erps.billermanagement.error.validationFromNonZero"));
          highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
        }
        if (parseInt(billerProfile?.validationToDigit) <= 0) {
          errors.push(t("erps.billermanagement.error.validationToNonZero"));
          highlightErrorV1 = { ...highlightErrorV1, validationToDigit: true };
        }
        if (billerProfile?.weighing === undefined) {
          errors.push(t("erps.billermanagement.error.weightingRequired"));
          highlightErrorV1 = { ...highlightErrorV1, weighing: true };
        }
        if (
          billerProfile?.weighing !== undefined &&
          billerProfile?.weighing.length !=
            getWeighingValue(
              parseInt(billerProfile?.validationToDigit),
              parseInt(billerProfile?.validationFromDigit)
            ).length
        ) {
          errors.push(t("erps.billermanagement.error.weighingInvalid"));
          highlightErrorV1 = { ...highlightErrorV1, weighing: true };
        }
      }
      if (billerProfile?.accountValidationValue?.includes("URN")) {
        if (!billerProfile?.uniqueCheckDigitRoutine) {
          errors.push(
            t("erps.billermanagement.error.uniqueCheckDigitRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            uniqueCheckDigitRoutine: true,
          };
        }
        if (
          fiNumber == "10" &&
          billerProfile?.modChecker &&
          !modCheckCibc.includes(billerProfile?.modChecker)
        ) {
          errors.push(t("erps.billermanagement.error.invalidModCheckCibc"));
          highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
        } else if (
          (fiNumber == 99 || fiNumber == 50) &&
          billerProfile?.modChecker &&
          !modCheckBmo.includes(billerProfile?.modChecker)
        ) {
          errors.push(t("erps.billermanagement.error.invalidModCheckBmo"));
          highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
        }
      }
    } else {
      highlightErrorV1 = {
        ...highlightErrorV1,
        uniqueCheckDigitRoutine: false,
      };
    }
    // if (
    //   fiNumber != "10" &&
    //   (billerProfile?.billerCustomerNumberMaximum ||
    //     billerProfile?.billerCustomerNumberMinimum) &&
    //   !billerProfile?.billerCustomerNumberFormat
    // ) {
    //   errors.push(t("erps.billermanagement.error.accountformatRequired"));
    //   highlightErrorV1 = {
    //     ...highlightErrorV1,
    //     billerCustomerNumberFormat: true,
    //   };
    // }

    if (
      billerProfile?.billerCustomerNumberFormat &&
      !billerCustomerNumberFormatRegEx.test(
        billerProfile?.billerCustomerNumberFormat
      )
    ) {
      errors.push(t("erps.billermanagement.error.accountFormatInvalidValue"));
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberFormat: true,
      };
    }

    if (
      parseInt(billerProfile?.checkDigitLocation) > 0 &&
      billerProfile?.accountValidationValue.includes("CDV") &&
      parseInt(billerProfile?.checkDigitLocation) >
        parseInt(billerProfile?.billerCustomerNumberMinimum)
    ) {
      errors.push(
        t(
          "erps.billermanagement.error.digitaLocationNotGreaterThanAccountAndNonZero"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, checkDigitLocation: true };
    }
    if (
      parseInt(billerProfile?.validationFromDigit) > 0 &&
      parseInt(billerProfile?.validationFromDigit) >
        parseInt(billerProfile?.validationToDigit)
    ) {
      errors.push(t("erps.billermanagement.error.validatonToGreaterThanFrom"));
      highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
    }
    if (
      parseInt(billerProfile?.validationFromDigit) > 0 &&
      parseInt(billerProfile?.validationFromDigit) >
        parseInt(billerProfile?.checkDigitLocation)
    ) {
      errors.push(
        t("erps.billermanagement.error.validationFromDigitGreaterThanLocation")
      );
      highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
    }

    if (
      parseInt(billerProfile?.validationToDigit) > 0 &&
      parseInt(billerProfile?.checkDigitLocation) > 0 &&
      parseInt(billerProfile?.validationToDigit) >
        parseInt(billerProfile?.checkDigitLocation)
    ) {
      errors.push(
        t("erps.billermanagement.error.validationToDigitNotGreaterThanLocation")
      );
      highlightErrorV1 = { ...highlightErrorV1, validationToDigit: true };
    }

    if (
      billerProfile?.uniqueCheckDigitRoutine &&
      !alphaNumericWithSpecialCharRegExV1.test(
        billerProfile?.uniqueCheckDigitRoutine
      )
    ) {
      errors.push(t("erps.billermanagement.error.uniqueRoutineInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, uniqueCheckDigitRoutine: true };
    }

    if (fiNumber != 10 && billerProfile?.prefixText) {
      const prefixText = billerProfile.prefixText;
      const min =
        billerProfile.billerCustomerNumberMinimum !== undefined
          ? +billerProfile.billerCustomerNumberMinimum
          : 0;
      const max =
        billerProfile.billerCustomerNumberMaximum !== undefined
          ? +billerProfile.billerCustomerNumberMaximum
          : 0;
      const prefixErrorMessages = {
        alphanumericWithComma:
          "erps.billermanagement.error.prefixTextAlphanumericWithComma",
        invalidLength: "erps.billermanagement.error.prefixTextinvalid",
        minLengthMustNotZero:
          "erps.billermanagement.error.billerCustomerMinValueMustNot0",
        totalLengthExceedsMax:
          "erps.billermanagement.error.billerCustomerMinMaxOverPrefix",
      };
      const resValues = validateText(
        prefixText,
        min,
        max,
        alphanumericCommaRegEx,
        prefixErrorMessages,
        "billerCustomerNumberMaximum",
        "billerCustomerNumberMinimum",
        "prefixText",
        t
      );
      errors = [...errors, ...resValues?.errors];
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }

    if (fiNumber != 10 && billerProfile?.suffixText) {
      const suffixText = billerProfile.suffixText;
      const min =
        billerProfile.billerCustomerNumberMinimum !== undefined
          ? +billerProfile.billerCustomerNumberMinimum
          : 0;
      const max =
        billerProfile.billerCustomerNumberMaximum !== undefined
          ? +billerProfile.billerCustomerNumberMaximum
          : 0;
      const suffixErrorMessages = {
        alphanumericWithComma:
          "erps.billermanagement.error.suffixTextAlphanumericWithComma",
        invalidLength: "erps.billermanagement.error.suffixTextinvalid",
        minLengthMustNotZero:
          "erps.billermanagement.error.billerCustomerMinValueMustNot0",
        totalLengthExceedsMax:
          "erps.billermanagement.error.billerCustomerMinMaxOverSuffix",
      };
      const resValues = validateText(
        suffixText,
        min,
        max,
        alphanumericCommaRegEx,
        suffixErrorMessages,
        "billerCustomerNumberMaximum",
        "billerCustomerNumberMinimum",
        "suffixText",
        t,
        errors,
        highlightErrorV1
      );
      errors = resValues?.errors ? [...errors, ...resValues?.errors] : errors;
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }

    if (fiNumber == 10 && billerProfile?.suffixText) {
      const suffixText = billerProfile.suffixText;
      const suffixErrorMessages = {
        totalLengthExceedsMax:
          "erps.billermanagement.error.suffixTextNumericWithCommaForCibc",
      };
      const resValues = validateTextForCibc(
        suffixText,
        regexForCibcSuffixPrefix,
        suffixErrorMessages,
        "suffixText",
        t
      );
      errors = resValues?.errors ? [...errors, ...resValues?.errors] : errors;
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }
    if (fiNumber == 10 && billerProfile?.prefixText) {
      const prefixText = billerProfile.prefixText;
      const prefixErrorMessages = {
        totalLengthExceedsMax:
          "erps.billermanagement.error.prefixTextNumericWithCommaForCibc",
      };
      const resValues = validateTextForCibc(
        prefixText,
        regexForCibcSuffixPrefix,
        prefixErrorMessages,
        "prefixText",
        t
      );
      errors = resValues?.errors ? [...errors, ...resValues?.errors] : errors;
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }

    if (englishContentLength && englishContentLength > MAX_LENGTH) {
      errors.push(
        t("erps.billermanagement.error.englishTextEditor10Character")
      );
      highlightErrorV1 = { ...highlightErrorV1, htmlContentEn: true };
    }

    if (frenchContentLength && frenchContentLength > MAX_LENGTH) {
      errors.push(t("erps.billermanagement.error.frenchTextEditor10Character"));
      highlightErrorV1 = { ...highlightErrorV1, htmlContentFr: true };
    }
    if (
      fiNumber != 10 &&
      billerProfile?.englishImageUrl &&
      !isValidUrl(billerProfile?.englishImageUrl)
    ) {
      errors.push(t("erps.billermanagement.error.englishImageUrlInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, englishImageUrl: true };
    }
    if (
      billerProfile?.billerComment &&
      billerProfile?.billerComment.length > 160
    ) {
      errors.push(t("erps.billermanagement.error.commentLengthInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, billerComment: true };
    }
    return { errors, highlightErrorV1 };
  };
  const sanitizeBillerData = (jsonObj) => {
    Object.keys(jsonObj).forEach((key) => {
      if (
        jsonObj[key] === undefined ||
        ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
      ) {
        delete jsonObj[key];
      } else if (
        typeof jsonObj[key] === "string" &&
        jsonObj[key].includes("\u0000")
      ) {
        jsonObj[key] = jsonObj[key].replaceAll("\u0000", "");
      }
    });

    return jsonObj;
  };
  const getUpdateForApproval = (updatedData, oldData) => {
    const {
      billerProfileExtensions = [],
      creditorAccountNumber = "",
      creditorFiNumber = "",
      creditorTransitNumber = "",
      englishImageUrl = "",
    } = updatedData;

    const {
      billerProfileExtensions: prevBillerProfileExtensions = [],
      creditorAccountNumber: prevCreditorAccountNumber = "",
      creditorFiNumber: prevCreditorFiNumber = "",
      creditorTransitNumber: prevCreditorTransitNumber = "",
      englishImageUrl: prevEnglishImageUrl = "",
    } = oldData;
    let hasValueChanged;

    if (
      prevEnglishImageUrl !== englishImageUrl ||
      prevCreditorTransitNumber !== creditorTransitNumber ||
      prevCreditorFiNumber !== creditorFiNumber ||
      prevCreditorAccountNumber !== creditorAccountNumber
    ) {
      hasValueChanged = true;
    } else {
      hasValueChanged = billerProfileExtensions?.some(
        ({ billerStatus, fiNumber }) => {
          return (
            prevBillerProfileExtensions.find(
              ({ fiNumber: prevFiNumber }) => prevFiNumber === fiNumber
            )?.billerStatus !== billerStatus
          );
        }
      );
    }
    return hasValueChanged;
  };

  const updateBillerProfile = () => {
    setSaveLoading(true);
    setShowApiExceptionMessage(false);
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (showApiValidationErrorMessage) {
      setShowApiValidationErrorMessage(false);
    }
    const tempBillerProfile = { ...billerProfile };
    const billerProfileSanitizedData = sanitizeBillerData(billerProfile);

    const { errors, highlightErrorV1 } = validateBillerProfileUser(
      billerProfileSanitizedData
    );
    if (errors.length) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      setSaveLoading(false);
      if (errorRef.current) {
        errorRef.current.focus();
      }
    } else if (!(imageError?.English || imageError?.French)) {
      setValidationErrors(errors);
      setHighlightError({});
      const updateBillerMasterRule = {
        ...billerProfile.billerMasterRules,
        masterRules: masterRule,
      };
      const nilReportValue = !billerProfile?.nilReportIndex
        ? "N"
        : billerProfile?.nilReportIndex;
      const updatedTempBillerProfile = {
        ...tempBillerProfile,
        billerFiNumber: billerProfile?.billerNonVolatileDto?.biller_fi_number,
        billerCountry: "CA",
        userId: loginUser?.userId,
        distributionList: [initialDeliveryMethods, ...deliveryMethods],
        instructTextEnglish:
          fiNumber == "50" && billerProfile?.instructType != STATIC
            ? getInstructionText(billerProfile, "en")
            : htmlContent["en"] || billerProfile?.instructTextEnglish,
        instructTextFrench:
          fiNumber == "50" && billerProfile?.instructType != STATIC
            ? getInstructionText(billerProfile, "fr")
            : htmlContent["fr"] || billerProfile?.instructTextFrench,
        billerMasterRules: updateBillerMasterRule,
        lineOfbusinessData: lineOfbusinessData,
        ...(selectedFile?.English && {
          imageENBase64Content: selectedFile?.English?.fileBase64Content,
        }),
        ...(selectedFile?.French && {
          imageFRBase64Content: selectedFile?.French?.fileBase64Content,
        }),
        ...(nilReportValue && { nilReportIndex: nilReportValue }),
        ...(fiNumber == "50" && {
          instructType: billerProfile?.instructType,
        }),
        ...((fiNumber == 50 || fiNumber == 99) && {
          approvalRequired: getUpdateForApproval(
            billerProfile,
            billerProfileReducerValue?.billerProfileAssociatedData
          ),
        }),
        billerLiveDate: formatDate(billerProfile?.billerLiveDate),
        billerSettlementType: getSettlementTypeValue(),
      };
      const updatedBillerProfile = {
        ...billerProfile,
        billerFiNumber: billerProfile?.billerNonVolatileDto?.biller_fi_number,
        billerCountry: "CA",
        userId: loginUser?.userId,
        distributionList: [initialDeliveryMethods, ...deliveryMethods],
        instructTextEnglish:
          fiNumber == "50" && billerProfile?.instructType != STATIC
            ? getInstructionText(billerProfile, "en")
            : htmlContent["en"],
        instructTextFrench:
          fiNumber == "50" && billerProfile?.instructType != STATIC
            ? getInstructionText(billerProfile, "fr")
            : htmlContent["fr"],
        billerMasterRules: updateBillerMasterRule,
        lineOfbusinessData: lineOfbusinessData,
        ...(selectedFile?.English && {
          imageENBase64Content: selectedFile?.English?.fileBase64Content,
        }),
        ...(selectedFile?.French && {
          imageFRBase64Content: selectedFile?.French?.fileBase64Content,
        }),
        ...(nilReportValue && { nilReportIndex: nilReportValue }),
        ...(fiNumber == "50" && {
          instructType: billerProfile?.instructType,
        }),
        ...((fiNumber == 50 || fiNumber == 99) && {
          approvalRequired: getUpdateForApproval(
            billerProfile,
            billerProfileReducerValue?.billerProfileAssociatedData
          ),
        }),
        billerLiveDate: formatDate(billerProfile?.billerLiveDate),
        billerSettlementType: getSettlementTypeValue(),
      };
      let hasFieldsChanged;
      let setBillerData = billerProfileReducerValue.billerProfileAssociatedData;
      if (updatedBillerProfile?.instructType === "D") {
        const {
          billerLastActivateDate,
          billerLastDeactivateDate,
          bmoBillerId,
          userId,
          instructTextEnglish,
          instructTextFrench,
          ...rest
        } = updatedTempBillerProfile;
        hasFieldsChanged = checkFieldsChange(
          {
            ...oldBillerProfile,
            billerLiveDate: formatDate(oldBillerProfile?.billerLiveDate),
            billerLink: getValue(
              setBillerData?.billerLink,
              setBillerData?.billerLinkGroupDtos
            )?.billerFiNumber,
          },
          rest
        );
      } else {
        const {
          billerLastActivateDate,
          billerLastDeactivateDate,
          bmoBillerId,
          userId,
          ...rest
        } = updatedTempBillerProfile;
        hasFieldsChanged = checkFieldsChange(
          {
            ...oldBillerProfile,
            billerLiveDate: formatDate(oldBillerProfile?.billerLiveDate),
            billerLink: getValue(
              setBillerData?.billerLink,
              setBillerData?.billerLinkGroupDtos
            )?.billerFiNumber,
          },
          rest
        );
      }

      if (hasFieldsChanged) {
        if (hasShowWarningMessage) {
          setShowWarningMessage(false);
        }
        dispatch(
          actions.billerProfileActions.updateBillerProfileWithAssociatesChildObjects(
            updatedBillerProfile,
            oldBillerProfile,
            {
              setSaveLoading,
              setShowSuccessMessage,
              setValidationErrors,
              setShowApiExceptionMessage,
              t,
              setShowApiValidationErrorMessage,
              handleTabLoading: props?.handleTabLoading,
            }
          )
        );
      } else {
        setSaveLoading(false);
        setShowWarningMessage(true);
      }
    }
  };

  const checkValidBillPaymentValue = () => {
    let cellErrorVal = [];
    billerProfile.billerProfileExtensions.forEach((row) => {
      if (
        row.billPaymentLimit < 0 ||
        row.billPaymentLimit?.toString().length > 10
      ) {
        cellErrorVal.push({
          hasError: true,
          value: row.billPaymentLimit?.toString(),
        });
      } else {
        cellErrorVal.push({
          hasError: false,
          value: row.billPaymentLimit?.toString(),
        });
      }
    });
    setCellError(cellErrorVal);
    return billerProfile.billerProfileExtensions?.some(
      (row) =>
        row.billPaymentLimit?.toString().length > 10 || row.billPaymentLimit < 0
    );
  };

  const handleCellEditCommit = ({ id, field, value }) => {
    let updatedBillerProfileExtensionData = [];
    if (field === "billPaymentLimit") {
      const billerProfileExtensions = billerProfile.billerProfileExtensions;
      if (billerProfile && billerProfileExtensions) {
        billerProfileExtensions.forEach((extension) => {
          if (extension.id === id) {
            updatedBillerProfileExtensionData.push({
              ...extension,
              billPaymentLimit: value,
            });
          } else {
            updatedBillerProfileExtensionData.push({ ...extension });
          }
        });
      }
      billerProfile["billerProfileExtensions"] =
        updatedBillerProfileExtensionData;
    }
  };

  const handleSelectionModelChange = (id, value) => {
    let updatedBillerProfileExtensionData = [];
    const billerProfileExtensions = billerProfile.billerProfileExtensions;
    if (billerProfile && billerProfileExtensions) {
      updatedBillerProfileExtensionData = billerProfileExtensions.map(
        (extension) => {
          if (id == extension.id) {
            return {
              ...extension,
              billerStatus: value ? "A" : "I",
            };
          } else {
            return extension;
          }
        }
      );
    }
    const data1 = updatedBillerProfileExtensionData
      .map(({ billerStatus, id }) => {
        if (billerStatus === "A") {
          return id;
        }
        return "";
      })
      .filter((e) => Boolean(e));
    setSelectedRow(data1);
    setBillerProfile((prevState) => ({
      ...prevState,
      billerProfileExtensions: updatedBillerProfileExtensionData,
    }));
  };

  const getRequiredValidationForDeliveryMethod = () => {
    const deliveryValueValidation = [];
    const numericRegEx10 = /^[0-9]{10}$/;
    const invalidContactNumber = "0000000000";
    const validContactNumberRegex = /^([1-9]){1}[0-9]{9}$/;

    deliveryMethods.forEach(
      ({ deliveryMethod, distributionListCode, distributionPhoneNumber }) => {
        let obj = {};
        if (!deliveryMethod) {
          obj = { ...obj, deliveryMethod: true };
        }
        if (!distributionListCode?.trim()) {
          obj = { ...obj, distributionListCode: true };
        }
        if (deliveryMethod === "FAX") {
          if (
            distributionListCode?.trim() &&
            !numericRegEx10.test(distributionListCode?.trim())
          ) {
            obj = { ...obj, distributionListCode: true };
          }
        }
        if (
          distributionPhoneNumber &&
          (!validContactNumberRegex.test(
            distributionPhoneNumber?.toString()?.trim()
          ) ||
            distributionPhoneNumber?.toString()?.trim() == invalidContactNumber)
        ) {
          obj = { ...obj, distributionPhoneNumber: true };
        }
        deliveryValueValidation.push(obj);
      }
    );
    return deliveryValueValidation;
  };

  const checkDistributionLengthValid = () => {
    const numericRegEx10 = /^([1-9]){1}[0-9]{9}$/;
    const invalidContactPhone = "0000000000";

    const initialDistributionNumber =
      initialDeliveryMethods?.distributionPhoneNumber !== 0
        ? initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim()
        : "";
    const hasValidDistributionNumber =
      initialDistributionNumber &&
      (!numericRegEx10.test(initialDistributionNumber) ||
        initialDistributionNumber == invalidContactPhone);
    const hasValidOtherDistributionNumber =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber !== 0 &&
          distributionPhoneNumber?.toString()?.trim() &&
          (!numericRegEx10.test(distributionPhoneNumber?.toString()?.trim()) ||
            distributionPhoneNumber?.toString()?.trim() == invalidContactPhone)
      );
    return { hasValidDistributionNumber, hasValidOtherDistributionNumber };
  };

  const hasAccess = (key) => {
    if (key === "faxHeader") {
      return (
        (initialDeliveryMethods?.deliveryMethod === "FAX" &&
          initialDeliveryMethods?.deliveryMethod === "FAX") ||
        deliveryMethods.some((method) => method?.deliveryMethod === "FAX")
      );
    }
    if (key === "recipientCcin") {
      return (
        initialDeliveryMethods?.deliveryMethod ||
        deliveryMethods.some((method) => method?.deliveryMethod)
      );
    }
  };

  const handleRedirect = () => {
    props.history.push("/manage-billers");
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const checkValidImage = (image, key) => {
    let MAX_IMAGE_SIZE = 100 * 1024;
    if (!image) {
      return `${key} image is required`;
    } else if (!image.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      return `${key} image has not an accepted image type`;
    } else if (image.size > MAX_IMAGE_SIZE) {
      return `The ${key} size is too big`;
    }
  };

  const handleFileInput = (e, key) => {
    e.preventDefault();
    let image = e.target.files[0];
    let error = checkValidImage(image, key);
    if (error) {
      setImageError((pre) => ({
        ...pre,
        [key]: error,
      }));
    } else {
      setImageError({});
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        const imageInfo = {
          file: image,
          fileName: image.name,
          fileBase64Content: reader.result,
          languageCode: key === "English" ? "en" : "fr",
        };

        setSelectedFile((prev) => ({
          ...prev,
          [key]: imageInfo,
        }));
        setBillerProfile((prev) => ({
          ...prev,
          ...(key === "English" && { englishImageUrl: imageInfo?.fileName }),
          ...(key === "French" && { frenchImageUrl: imageInfo?.fileName }),
        }));
      };
    }
  };

  const handleRemoveImage = (lang) => {
    if (selectedFile) {
      if (lang === "English") {
        const { French } = selectedFile;
        const { frenchPreview } = preview;
        setSelectedFile({ French });
        setBillerProfile((prev) => ({
          ...prev,
          englishImageUrl: "",
        }));

        setPreview({ frenchPreview });
      } else if (lang === "French") {
        const { English } = selectedFile;
        const { englishPreview } = preview;
        setSelectedFile({ English });
        setBillerProfile((prev) => ({
          ...prev,
          frenchImageUrl: "",
        }));
        setPreview({ englishPreview });
      }
    } else {
      if (lang === "English") {
        setBillerProfile((prev) => ({
          ...prev,
          englishImageUrl: "",
        }));
        const { frenchPreview } = preview;
        setPreview({ frenchPreview });
      } else if (lang === "French") {
        setBillerProfile((prev) => ({
          ...prev,
          frenchImageUrl: "",
        }));
        const { englishPreview } = preview;
        setPreview({ englishPreview });
      }
    }
  };

  const getBillerGroupLists = () => {
    const listData = billerProfile?.billerLinkGroupDtos?.map((link) => {
      return {
        label: link?.billerLinkGroupDetail,
        value: link?.billerId,
      };
    });
    listData?.unshift({
      label: "",
      value: "",
    });
    return listData;
  };

  return (
    <Box>
      {successMessage &&
        successMessage !== "ERPS0055" &&
        showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage}
            setShowMessage={setShowSuccessMessage}
            inputRef={successRef}
          />
        )}
      {hasShowWarningMessage && (
        <Stack sx={{ width: "100%" }} spacing={2} tabIndex={0} ref={warningRef}>
          <Alert severity="warning">
            {t("erps.billermanagement.warning.nothinghaschanged")}
          </Alert>
        </Stack>
      )}
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.page.formtitle", "Biller Profile")}
        </Typography>
        {props?.isLoading && (
          <Box ml={40}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
      {errorMessageList.length ||
      validationErrors.length ||
      showApiValidationErrorMessage ||
      Object.values(imageError).length ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={
            errorMessageList.length ||
            validationErrors.length ||
            showApiValidationErrorMessage ||
            Object.values(imageError).length
              ? 2
              : 0
          }
        >
          {errorMessageList.length ||
          validationErrors.length ||
          showApiValidationErrorMessage ||
          Object.values(imageError).length ? (
            <ErrorMessage
              error={
                showApiValidationErrorMessage
                  ? [
                      ...errorMessageList,
                      ...validationErrors,
                      ...Object.values(imageError),
                      ...getBillerGroupErrorMessage(successMessage),
                    ]
                  : [
                      ...errorMessageList,
                      ...validationErrors,
                      ...Object.values(imageError),
                    ]
              }
              errorRef={errorRef}
            />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      {apiExceptionMessage && showApiExceptionMessage && (
        <Box className="manageUsers__errorMsg__container" role="alert" mt="2">
          <ErrorMessage
            error={apiExceptionMessage}
            errorRef={apiExceptionRef}
          />
        </Box>
      )}
      <Box mt={3} sx={{ display: "flex" }}>
        {(roleType === "PA" || roleType === "CE") && (
          <Typography variant="inherit">
            {t(
              "erps.billermanagement.label.internalBillerId",
              "Internal biller ID"
            )}
            <Typography variant="body2">
              {fiNumber == 99 ? billerId : billerId?.split("-")[1] || billerId}
            </Typography>
          </Typography>
        )}
        {roleType !== "PA" && roleType !== "CE" && (
          <Typography variant="inherit">
            {t("erps.billermanagement.label.billerId", "Biller ID")}
            <Typography variant="body2">
              {displayBillerId || billerId}
            </Typography>
          </Typography>
        )}
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseTextField
          id="billerName"
          type="text"
          label={t("erps.billermanagement.label.billerName")}
          className="common__textField__width45"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("billerName")}
          onBlur={handleBlur("billerName")}
          value={billerProfile?.billerName}
          required="true"
          error={highlightError?.billerName}
          inputProps={{ maxLength: 45 }}
        />
      </Stack>
      <Stack direction="row" spacing={2}></Stack>
      <Stack direction="row" spacing={2}>
        <ERPSBaseTextField
          id="billerShortName"
          type="text"
          label={t("erps.billermanagement.label.billerShortName")}
          className="common__textField__width"
          size="small"
          onChange={handleBillerProfile("billerShortName")}
          value={billerProfile?.billerShortName || ""}
          error={highlightError?.billerShortName}
          inputProps={{ maxLength: 20 }}
          required={fiNumber == 10 ? "true" : ""}
          onBlur={handleBlur("billerShortName")}
        />
        {fiNumber != 10 && (
          <ERPSBaseTextField
            id="billerSettlementType"
            type="text"
            label={t("erps.billermanagement.label.settlementType")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerSettlementType")}
            value={getSettlementTypeValue()}
            error={highlightError?.billerSettlementType}
            isDisabled={true}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          />
        )}
        {fiNumber == "10" && (
          <>
            <ERPSBaseTextField
              id="billerLink"
              type="number"
              label={t("erps.billermanagement.label.billerLink")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("billerLink")}
              value={billerProfile?.billerLink}
              error={highlightError?.billerLink}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 9 }}
            />
          </>
        )}
      </Stack>
      <Stack direction="row" spacing={2}>
        {fiNumber != "10" ? (
          <ERPSBaseTextField
            id="billerDepartment"
            type="text"
            label={t("erps.billermanagement.label.attention", "Attention")}
            className="common__textField__width"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={handleBillerProfile("billerDepartment")}
            value={billerProfile?.billerDepartment}
            inputProps={{ maxLength: 35 }}
          />
        ) : (
          <ERPSBaseTextField
            id="billerLinkGroup"
            value={
              getValue(
                billerProfileReducerValue.billerProfileAssociatedData
                  ?.billerLink,
                billerProfile?.billerLinkGroupDtos
              )?.billerId
            }
            type="text"
            label={t("erps.billermanagement.label.billerLinkGroup")}
            className="common__textField__width"
            size="small"
            select={true}
            items={getBillerGroupLists()}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            listDisabled={true}
          />
        )}
        <ERPSBaseTextField
          id="address"
          type="text"
          label={t("erps.billermanagement.label.address", "Address")}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("billerAddress")}
          value={billerProfile?.billerAddress}
          inputProps={{ maxLength: 40 }}
        />
        <ERPSBaseTextField
          id="billerCity"
          type="text"
          label={t("erps.billermanagement.label.city", "City")}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("billerCity")}
          value={billerProfile?.billerCity}
          required={"true"}
          error={highlightError?.billerCity}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        {(fiNumber != "10" || selectedFi != "10") && (
          <ERPSBaseTextField
            id="county"
            type="text"
            label={t("erps.billermanagement.label.country", "Country")}
            className="common__textField__width"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={handleBillerProfile("billerCountry")}
            value={"CA"}
            inputProps={{ maxLength: 2 }}
            isDisabled={true}
          />
        )}
        <ERPSBaseTextField
          id="billerProvince"
          type="text"
          label={t("erps.billermanagement.label.province", "Province")}
          className="common__textField__width"
          size="small"
          onChange={handleBillerProfile("billerProvince")}
          value={billerProfile?.billerProvince}
          select={true}
          InputLabelProps={{ shrink: true }}
          items={provinceList}
          SelectProps={{ native: true }}
          error={highlightError?.billerProvince}
          required="true"
        />
        <ERPSBaseTextField
          id="postalCode"
          type="text"
          label={t("erps.billermanagement.label.postalcode", "Postal code")}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("billerPostalCode")}
          value={billerProfile?.billerPostalCode}
          inputProps={{ maxLength: 7 }}
          error={highlightError?.billerPostalCode}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ERPSBaseRadioGroup
          name="billerLanguageCode"
          marginTop=".4rem"
          InputLabelProps={{ shrink: true }}
          value={billerProfile?.billerLanguageCode || "E"}
          label={t("erps.userprofile.language.text", "Language")}
          onChange={handleBillerProfile("billerLanguageCode")}
          items={languageItems}
          required={"true"}
          error={highlightError?.billerLanguageCode}
        />
        <ERPSBaseTextField
          id="groupPriority"
          type="number"
          label={t("erps.billermanagement.label.priority", "Group priority")}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("groupPriority")}
          value={checkNonZeroValue(billerProfile?.groupPriority)}
          inputProps={{ maxLength: 2 }}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 2);
          }}
        />
        {fiNumber != "10" && (
          <ERPSBaseTextField
            id="bmoBillerId"
            type="number"
            label={t(
              "erps.billermanagement.label.bmoBillerId",
              "BMO biller ID"
            )}
            className="common__textField__width"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={handleBillerProfile("bmoBillerId")}
            value={billerProfile?.bmoBillerId}
            inputProps={{ maxLength: 8 }}
            isDisabled={true}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 8);
            }}
          />
        )}
      </Stack>
      {fiNumber == "10" ? (
        <>
          <Stack direction="row" mb={2} spacing={2}>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ marginTop: "15px" }}
            >
              <ERPSBaseDatePicker
                label={t("erps.billermanagement.label.billerLastActivateDate")}
                value={
                  getDateFromString(billerProfile?.billerLastActivateDate) ||
                  null
                }
                id={"biller_last_activate_date"}
                InputLabelProps={{ shrink: false }}
                className="common__textField__width"
                onChange={handleBillerProfile("billerLastActivateDate")}
                error={highlightError?.billerLastActivateDate}
                hasRequired={false}
                hasMinMaxNotCheck={true}
                datePickerStyle={"quick-reg-datepicker-v1"}
                isDisabled={true}
                maxDate={new Date("2100-01-01")}
                minDate={new Date("1900-01-01")}
              />
              <Box ml={2}>
                <ERPSBaseDatePicker
                  label={t(
                    "erps.billermanagement.label.billerLastDeactivateDate"
                  )}
                  value={
                    getDateFromString(
                      billerProfile?.billerLastDeactivateDate
                    ) || null
                  }
                  id={"biller_last_deactivate_date"}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  className="common__textField__width"
                  onChange={handleBillerProfile("billerLastDeactivateDate")}
                  error={highlightError?.billerLastDeactivateDate}
                  hasRequired={false}
                  hasMinMaxNotCheck={true}
                  datePickerStyle={"quick-reg-datepicker-v1"}
                  isDisabled={true}
                  maxDate={new Date("2100-01-01")}
                  minDate={new Date("1900-01-01")}
                />
              </Box>
            </Box>
            <Box>
              <ERPSBaseTextField
                id="billerSettlementType"
                type="text"
                label={t("erps.billermanagement.label.settlementType")}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("billerSettlementType")}
                value={getSettlementTypeValue()}
                error={highlightError?.billerSettlementType}
                isDisabled={true}
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="billerStatus"
              type="text"
              label={t("erps.billermanagement.label.status", "Status")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("billerStatus")}
              value={billerProfile?.billerStatus}
              error={highlightError?.billerStatus}
              select={true}
              items={statusList}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            />
            <Typography
              style={{ marginTop: "30px", marginLeft: "50px", width: "120px" }}
            >
              {t("erps.billermanagement.label.liveDate", "Live date")}
              <Typography variant="body2">
                {billerProfile?.billerStatus === "A"
                  ? statisticsFormatDate(billerProfile?.billerLiveDate)
                  : 0}
              </Typography>
            </Typography>
            <Box ml={40}>
              <ERPSBaseTextField
                id="remitType"
                type="text"
                label={t(
                  "erps.billermanagement.label.remittanceType",
                  "Remittance type"
                )}
                className="common__textField__width_v6"
                size="small"
                onChange={handleBillerProfile("remitType")}
                value={billerProfile?.remitType}
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 3 }}
              />
            </Box>
          </Stack>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="productType"
              type="text"
              label={t(
                "erps.billermanagement.label.productType",
                "Product type"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("productType")}
              value={billerProfile?.productType || "APA"}
              error={highlightError?.productType}
              InputLabelProps={{
                shrink: true,
              }}
              select={true}
              items={productType}
              inputProps={{ maxLength: 4 }}
              required={"true"}
              SelectProps={{ native: true }}
            />
            <ERPSBaseTextField
              id="budgetCategory"
              type="text"
              label={t(
                "erps.billermanagement.label.budgetCategory",
                "Budget category"
              )}
              className="common__textField__width"
              InputLabelProps={{
                shrink: billerProfile?.billerBudgetCategory ? true : false,
              }}
              size="small"
              onChange={handleBillerProfile("billerBudgetCategory")}
              value={billerProfile?.billerBudgetCategory}
              error={highlightError?.billerBudgetCategory}
              select={true}
              required={"true"}
              items={budgetCategoryCodes}
              SelectProps={{ native: true }}
              inputProps={{ maxLength: 5 }}
            />
            <ERPSBaseTextField
              id="billerCounty"
              type="text"
              label={t("erps.billermanagement.label.county")}
              className="common__textField__width"
              size="small"
              value={billerProfile.billerCounty}
              onChange={handleBillerProfile("billerCounty")}
              inputProps={{ maxLength: 3 }}
              error={highlightError?.billerCounty}
              required={"true"}
              InputLabelProps={{ shrink: true }}
            />
          </Stack>
        </>
      ) : (
        <Stack direction="row">
          <ERPSBaseDatePicker
            label={t("erps.billermanagement.label.liveDate", "Live date")}
            value={billerProfile?.billerLiveDate}
            InputLabelProps={{ shrink: true }}
            className="common__textField__width"
            onChange={handleBillerProfile("billerLiveDate")}
            required="true"
            error={highlightError?.liveDate}
            hasMinMaxNotCheck={true}
            maxDate={new Date("2100-01-01")}
            minDate={new Date("1900-01-01")}
            id={"biller_live_date"}
          />
        </Stack>
      )}
      <Box mt={4} mb={-2}>
        <Typography variant="h3">
          {t("erps.billermanagement.button.contactInfo", "Contact information")}
        </Typography>
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseTextField
          id="primaryContactName"
          type="text"
          label={t(
            "erps.billermanagement.label.primarycontact",
            "Primary contact"
          )}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("primaryContactName")}
          value={billerProfile?.primaryContactName}
          required={"true"}
          error={highlightError?.primaryContactName}
          inputProps={{ maxLength: 35 }}
        />
        <Stack direction="row" spacing={0}>
          <ERPSBaseTextField
            id="primaryTelephoneNumber"
            type="number"
            label={t("erps.billermanagement.label.telNum", "Telephone number")}
            className="common__textField__width_v1"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={handleBillerProfile("primaryContactPhone")}
            value={billerProfile?.primaryContactPhone}
            required={"true"}
            error={highlightError?.primaryContactPhone}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 10);
            }}
          />
          <ERPSBaseTextField
            id="primaryExt"
            type="number"
            label={t("erps.billermanagement.label.ext", "Ext.")}
            className="common__textField__width_ext"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={handleBillerProfile("primaryContactExtension")}
            value={checkNonZeroValue(billerProfile?.primaryContactExtension)}
            inputProps={{ maxLength: 5 }}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 5);
            }}
          />
        </Stack>
        <ERPSBaseTextField
          id="primaryFaxNumber"
          type="number"
          label={t("erps.billermanagement.label.faxNumber", "Fax number")}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("primaryContactFax")}
          value={checkNonZeroValue(billerProfile?.primaryContactFax)}
          inputProps={{ maxLength: 10 }}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 10);
          }}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ERPSBaseTextField
          id="secondaryContact"
          type="text"
          label={t(
            "erps.billermanagement.label.secondarycontact",
            "Secondary contact"
          )}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={handleBillerProfile("secondaryContactName")}
          value={billerProfile?.secondaryContactName}
          error={highlightError?.secondaryContactName}
          inputProps={{ maxLength: 35 }}
        />
        <Stack direction="row" spacing={0}>
          <ERPSBaseTextField
            id="secondaryTelephoneNumber"
            type="number"
            label={t("erps.billermanagement.label.telNum", "Telephone number")}
            className="common__textField__width_v1"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={handleBillerProfile("secondaryContactPhone")}
            value={checkNonZeroValue(billerProfile?.secondaryContactPhone)}
            error={highlightError?.secondaryContactPhone}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 10);
            }}
          />
          <ERPSBaseTextField
            id="secondaryExt"
            type="number"
            label={t("erps.billermanagement.label.ext", "Ext.")}
            InputLabelProps={{ shrink: true }}
            className="common__textField__width_ext"
            size="small"
            onChange={handleBillerProfile("secondaryContactExtension")}
            value={checkNonZeroValue(billerProfile?.secondaryContactExtension)}
            inputProps={{ maxLength: 5 }}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 5);
            }}
          />
        </Stack>
        <ERPSBaseTextField
          id="secondaryFaxNumber"
          type="number"
          label={t("erps.billermanagement.label.faxNumber", "Fax number")}
          className="common__textField__width"
          InputLabelProps={{ shrink: true }}
          size="small"
          onChange={handleBillerProfile("secondaryContactFax")}
          value={checkNonZeroValue(billerProfile?.secondaryContactFax)}
          inputProps={{ maxLength: 10 }}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 10);
          }}
        />
      </Stack>
      {fiNumber == "10" && (
        <>
          {" "}
          <Box mt={4} mb={-2}>
            {
              <>
                {" "}
                <Box mt={4} mb={-2}>
                  <Typography variant="h3">
                    {t("erps.billermanagement.label.debit", "Debit")}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                  <ERPSBaseTextField
                    id="debitFiNumber"
                    items={billerFinancialInstitutionList}
                    SelectProps={{ native: true }}
                    type="text"
                    select={true}
                    label={t(
                      "erps.billermanagement.label.billerFinancialInstitution",
                      "Biller financial institution"
                    )}
                    className="common__textField__width addNewBiller_label_fontSize"
                    size="small"
                    onChange={handleBillerProfile("debitFiNumber")}
                    value={billerProfile?.debitFiNumber || ""}
                    InputLabelProps={{
                      shrink: Boolean(billerProfile?.debitFiNumber),
                    }}
                    required={"true"}
                    error={highlightError?.debitFiNumber}
                  />
                  <ERPSBaseTextField
                    id="debitTransitNumber"
                    type="number"
                    label={t(
                      "erps.billermanagement.label.transitNumber",
                      "Transit number"
                    )}
                    className="common__textField__width"
                    size="small"
                    onChange={handleBillerProfile("debitTransitNumber")}
                    value={billerProfile?.debitTransitNumber}
                    InputLabelProps={{ shrink: true }}
                    required="true"
                    error={highlightError?.debitTransitNumber}
                    inputProps={{ maxLength: 5 }}
                  />
                  <ERPSBaseTextField
                    id="debitAccountNumber"
                    type="text"
                    label={t(
                      "erps.billermanagement.label.accountNumber",
                      "Account number"
                    )}
                    className="common__textField__width"
                    size="small"
                    onChange={handleBillerProfile("debitAccountNumber")}
                    value={billerProfile?.debitAccountNumber}
                    InputLabelProps={{ shrink: true }}
                    required="true"
                    error={highlightError?.debitAccountNumber}
                  />
                </Stack>
                <Box mt={4} mb={-2}>
                  <Typography variant="h3">
                    {t("erps.billermanagement.label.credit", "Credit")}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                  <ERPSBaseTextField
                    id="creditorFiNumber"
                    items={billerFinancialInstitutionList}
                    SelectProps={{ native: true }}
                    type="text"
                    select={true}
                    label={t(
                      "erps.billermanagement.label.billerFinancialInstitution",
                      "Biller financial institution"
                    )}
                    className="common__textField__width addNewBiller_label_fontSize"
                    size="small"
                    onChange={handleBillerProfile("creditorFiNumber")}
                    value={billerProfile?.creditorFiNumber || ""}
                    InputLabelProps={{
                      shrink: Boolean(billerProfile?.creditorFiNumber),
                    }}
                    required={"true"}
                    error={highlightError?.creditorFiNumber}
                  />
                  <ERPSBaseTextField
                    id="creditorTransitNumber"
                    type="text"
                    label={t(
                      "erps.billermanagement.label.transitNumber",
                      "Transit number"
                    )}
                    className="common__textField__width"
                    size="small"
                    onChange={handleBillerProfile("creditorTransitNumber")}
                    value={billerProfile?.creditorTransitNumber}
                    InputLabelProps={{ shrink: true }}
                    required={"true"}
                    error={highlightError?.creditorTransitNumber}
                    inputProps={{ maxLength: 5 }}
                  />
                  <ERPSBaseTextField
                    id="creditorAccountNumber"
                    type="text"
                    label={t(
                      "erps.billermanagement.label.accountNumber",
                      "Account number"
                    )}
                    className="common__textField__width"
                    size="small"
                    onChange={handleBillerProfile("creditorAccountNumber")}
                    value={billerProfile?.creditorAccountNumber}
                    InputLabelProps={{ shrink: true }}
                    required="true"
                    error={highlightError?.creditorAccountNumber}
                  />
                </Stack>
                <Box mt={1} className="manageUsers__page__title">
                  <Typography variant="h3">
                    {t("erps.billermanagement.label.vendorAlias")}
                  </Typography>
                </Box>
                <Box mt={2} style={{ paddingBottom: "25px" }}>
                  <TextareaAutosize
                    aria-label={t("erps.billermanagement.label.vendorAlias")}
                    placeholder={t("erps.billermanagement.label.vendorAlias")}
                    className={"common__textField__prefix_text_vendorAlias"}
                    id="billerAlias"
                    onChange={handleBillerProfile("billerAlias")}
                    value={billerProfile?.billerAlias}
                    error={highlightError?.billerAlias}
                    maxLength={700}
                  />
                </Box>
              </>
            }
          </Box>
        </>
      )}
      {fiNumber != 10 && (
        <>
          {" "}
          <Box mt={4} mb={-2}>
            {
              <>
                <Box mt={4} mb={-2}>
                  <Typography variant="h3">
                    {t(
                      "erps.billermanagement.label.bankAccount",
                      "Bank account"
                    )}
                  </Typography>
                </Box>
                <Stack direction="row" spacing={2}>
                  <ERPSBaseTextField
                    id="creditorFiNumber"
                    items={billerFinancialInstitutionList}
                    SelectProps={{ native: true }}
                    InputLabelProps={{
                      shrink:
                        !billerProfile?.creditorFiNumber &&
                        billerProfile?.creditorFiNumber !== 0
                          ? false
                          : true,
                    }}
                    type="text"
                    select={true}
                    label={t(
                      "erps.billermanagement.label.billerFinancialInstitution",
                      "Biller financial institution"
                    )}
                    className="common__textField__width"
                    size="small"
                    onChange={handleBillerProfile("creditorFiNumber")}
                    value={billerProfile?.creditorFiNumber}
                    error={highlightError?.creditorFiNumber}
                    required={"true"}
                  />
                  <ERPSBaseTextField
                    id="creditorTransitNumber"
                    type="text"
                    label={t(
                      "erps.billermanagement.label.transitNumber",
                      "Transit number"
                    )}
                    className="common__textField__width"
                    size="small"
                    InputLabelProps={{ shrink: true }}
                    onChange={handleBillerProfile("creditorTransitNumber")}
                    value={billerProfile?.creditorTransitNumber}
                    required={"true"}
                    error={highlightError?.creditorTransitNumber}
                    inputProps={{ maxLength: 5 }}
                  />
                  <ERPSBaseTextField
                    id="creditorAccountNumber"
                    type="text"
                    label={t(
                      "erps.billermanagement.label.accountNumber",
                      "Account number"
                    )}
                    InputLabelProps={{ shrink: true }}
                    className="common__textField__width"
                    size="small"
                    onChange={handleBillerProfile("creditorAccountNumber")}
                    value={billerProfile?.creditorAccountNumber}
                    required={"true"}
                    error={highlightError?.creditorAccountNumber}
                  />
                </Stack>

                <Box className="manageUsers__page__title">
                  <Typography variant="h2">
                    {t(
                      "erps.billermanagement.label.financialInstitution",
                      "Financial institutions"
                    )}
                  </Typography>
                </Box>
                <ERPSGrid
                  data={{
                    rows:
                      billerProfile && billerProfile?.billerProfileExtensions
                        ? billerProfile?.billerProfileExtensions
                        : [],
                    //count: billerProfile ? billerProfile.billerProfileExtensionDtos.length : "0",
                    count: "20",
                    columns: getUpdateBillerFinancialInstitutionHeadings(
                      true,
                      handleSelectionModelChange
                    ),
                  }}
                  handleCellEditCommit={handleCellEditCommit}
                  dataGridWidth="dataGrid__box__container__updateBiller__financial_institution_table"
                  hideFooterPagination={true}
                  handleSelectionModelChange={handleSelectionModelChange}
                  selectedRows={selectedRow}
                  getCellClassName={handleGetCellClassName}
                  tableLabelText={t(
                    "erps.billermanagement.label.financialInstitution",
                    "Financial institutions"
                  )}
                />
              </>
            }
          </Box>
        </>
      )}
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t(
            "erps.billermanagement.label.paymentAdviceFileDetails",
            "Payment advice file details"
          )}
        </Typography>
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseTextField
          id="deliveryMethod"
          type="text"
          label={t(
            "erps.billermanagement.label.deilveryMethod",
            "Delivery method"
          )}
          className="common__textField__width"
          size="small"
          onChange={(e) =>
            handleChangeInitialDelivery("deliveryMethod", e.target.value)
          }
          select={true}
          items={deliveryMethodLists}
          value={initialDeliveryMethods?.deliveryMethod}
          SelectProps={{ native: true }}
          InputLabelProps={{
            shrink: initialDeliveryMethods?.deliveryMethod ? true : false,
          }}
        />
        <ERPSBaseTextField
          id="distributionFormat"
          type="text"
          label={t("erps.billermanagement.label.format", "Format")}
          className="common__textField__width"
          size="small"
          onChange={(e) =>
            handleChangeInitialDelivery("distributionFormat", e.target.value)
          }
          select={true}
          value={initialDeliveryMethods?.distributionFormat}
          items={getFormatListItem(initialDeliveryMethods?.deliveryMethod)}
          SelectProps={{ native: true }}
          InputLabelProps={{
            shrink: initialDeliveryMethods?.deliveryMethod ? true : false,
          }}
        />
        <ERPSBaseTextField
          id="distributionListCode"
          type="text"
          label={t("erps.billermanagement.label.destination", "Destination")}
          InputLabelProps={{ shrink: true }}
          className="common__textField__width"
          size="small"
          onChange={(e) =>
            handleChangeInitialDelivery("distributionListCode", e.target.value)
          }
          value={initialDeliveryMethods?.distributionListCode}
          required={"true"}
          error={highlightError?.initialDistributionListCode}
        />
      </Stack>
      <Stack direction="row" spacing={2}>
        <ERPSBaseTextField
          id="destinationAttention"
          type="text"
          label={t("erps.billermanagement.label.attention", "Attention")}
          className="common__textField__width"
          size="small"
          onChange={(e) =>
            handleChangeInitialDelivery("destinationAttention", e.target.value)
          }
          value={initialDeliveryMethods?.destinationAttention}
          InputLabelProps={{ shrink: true }}
          inputProps={{ maxLength: 30 }}
        />
        <Stack direction="row" spacing={0}>
          <ERPSBaseTextField
            id="distributionPhoneNumber"
            type="number"
            label={t("erps.billermanagement.label.telNum", "Telephone number")}
            className="common__textField__width_v1"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionPhoneNumber",
                e.target.value
              )
            }
            value={checkNonZeroValue(
              initialDeliveryMethods?.distributionPhoneNumber
            )}
            InputLabelProps={{ shrink: true }}
            inputProps={{ maxLength: 10 }}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 10);
            }}
            error={highlightError?.initialDistributionPhoneNumber}
          />
          <ERPSBaseTextField
            id="distributionExtensionNumber"
            type="number"
            label={t("erps.billermanagement.label.ext", "Ext.")}
            className="common__textField__width_ext"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionExtensionNumber",
                e.target.value
              )
            }
            InputLabelProps={{ shrink: true }}
            value={checkNonZeroValue(
              initialDeliveryMethods?.distributionExtensionNumber
            )}
            inputProps={{ maxLength: 5 }}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 5);
            }}
          />
        </Stack>
      </Stack>
      {initialDeliveryMethods?.deliveryMethod === "EDI" && (
        <Stack direction="row" spacing={0}>
          <ERPSBaseTextField
            id="distributionEDIMailbox"
            type="text"
            label={t("erps.billermanagement.label.ediMailBox", "EDI mailbox")}
            className="common__textField__width"
            size="small"
            InputLabelProps={{ shrink: true }}
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionEDIMailbox",
                e.target.value
              )
            }
            value={initialDeliveryMethods?.distributionEDIMailbox}
            inputProps={{ maxLength: 45 }}
            isDisabled={true}
          />
        </Stack>
      )}
      {fiNumber == "10" && (
        <Stack direction="row" spacing={0}>
          <ERPSBaseRadioGroup
            name="distributionGroupRcpIndex"
            value={initialDeliveryMethods?.distributionGroupRcpIndex || "C"}
            InputLabelProps={{ shrink: true }}
            label={t(
              "erps.billermanagement.distributionGroupRcpIndex.text",
              "Applies to"
            )}
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionGroupRcpIndex",
                e.target.value
              )
            }
            items={appliesToItems}
            error={highlightError?.distributionGroupRcpIndex}
          />
        </Stack>
      )}
      {deliveryMethods && deliveryMethods.length ? (
        <DeliveryMethodComponent
          deliveryMethods={deliveryMethods}
          handleChangeDeliveryMethods={handleChangeDeliveryMethods}
          getFormatListItem={getFormatListItem}
          deliveryMethodLists={deliveryMethodLists}
          handleRemoveDeliveryMethod={handleRemoveDeliveryMethod}
          handleClickDeliveryMethod={handleClickDeliveryMethod}
          count={count}
          error={reqValidationForDelivery}
          appliesToItems={appliesToItems}
          fiNumber={fiNumber}
        />
      ) : (
        ""
      )}

      <Box mt={!(deliveryMethods && deliveryMethods.length) ? 3 : 0}>
        {!deliveryMethods.length && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseLink
              className="link__blue"
              label={t(
                "erps.billermanagement.label.addDeilveryMethod",
                "Add a delivery method"
              )}
              target={""}
              onClick={() => handleClickDeliveryMethod()}
            />
          </Stack>
        )}
        {hasAccess("recipientCcin") && (
          <Stack direction={"row"} spacing={2}>
            <ERPSBaseTextField
              id="recipientCcin"
              type="number"
              label={t(
                "erps.billermanagement.label.ccin",
                "Cooperate creditor id number"
              )}
              className="common__textField__width_v4"
              size="small"
              onChange={handleBillerProfile("recipientCcin")}
              value={billerProfile?.recipientCcin}
              error={highlightError?.recipientCcin}
              required={checkRequiredRecipientCcin() ? "true" : ""}
              InputLabelProps={{ shrink: true }}
              inputProps={{ maxLength: 8 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 8);
              }}
            />
            {fiNumber == 10 && (
              <ERPSBaseTextField
                id="nextAuditNumber"
                type="number"
                label={t("erps.billermanagement.label.nextAuditNumber")}
                className="common__textField__width_v4"
                size="small"
                value={
                  billerProfile?.billerProfileExtensions[0]?.nextFileNumber
                }
                InputLabelProps={{ shrink: true }}
                isDisabled={true}
              />
            )}
          </Stack>
        )}
      </Box>

      <Box mt={4}>
        <Typography variant="h3">
          {t("erps.billermanagement.label.deliveryDetails", "Delivery details")}
        </Typography>
      </Box>
      <ERPSBaseRadioGroup
        marginTop=".4rem"
        value={billerProfile?.nilReportIndex || "N"}
        label={t("erps.billermanagement.label.nilReports", "Nil reports")}
        id="nilReportIndex"
        onChange={handleBillerProfile("nilReportIndex")}
        items={nilReports}
        required={"true"}
        error={highlightError?.nilReportIndex}
      />

      {fiNumber != 10 && (
        <>
          <Box mt={-2}>
            <ERPSBaseTextField
              id="faxHeader1"
              type="text"
              label={t(
                "erps.billermanagement.label.faxHeaderLine1",
                "Fax header line 1"
              )}
              className="common__textField__width_v5"
              size="small"
              InputLabelProps={{ shrink: true }}
              onChange={handleBillerProfile("faxHeaderLine1")}
              value={billerProfile?.faxHeaderLine1}
              inputProps={{ maxLength: 40 }}
            />
          </Box>
          <Box mt={-2}>
            <ERPSBaseTextField
              id="faxHeader"
              type="text"
              label={t(
                "erps.billermanagement.label.faxHeaderLine2",
                "Fax header line 2"
              )}
              className="common__textField__width_v5"
              size="small"
              InputLabelProps={{ shrink: true }}
              onChange={handleBillerProfile("faxHeaderLine2")}
              value={billerProfile?.faxHeaderLine2}
              inputProps={{ maxLength: 40 }}
            />
          </Box>
        </>
      )}
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t(
            "erps.billermanagement.label.billingAccountValidation",
            "Billing account validations"
          )}
        </Typography>
      </Box>
      <Stack direction="row" spacing={2}>
        {fiNumber == "10" ? (
          <ERPSBaseTextField
            id="billerAccountMask"
            type="text"
            label={t("erps.billermanagement.label.accountMask", "Account mask")}
            className="common__textField__width"
            size="small"
            required={"true"}
            onChange={handleBillerProfile("billerAccountMask")}
            value={billerProfile?.billerAccountMask || ""}
            error={highlightError?.billerAccountMask}
          />
        ) : (
          <ERPSBaseTextField
            id="billerCustomerNumberFormat"
            type="text"
            label={t(
              "erps.billermanagement.label.accountFormat",
              "Account format"
            )}
            className="common__textField__width"
            InputLabelProps={{ shrink: true }}
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberFormat")}
            value={billerProfile?.billerCustomerNumberFormat}
            inputProps={{ maxLength: 70 }}
            required={fiNumber == "10" ? "true" : ""}
            error={highlightError?.billerCustomerNumberFormat}
          />
        )}
        <ERPSBaseTextField
          id="billerCustomerNumberMinimum"
          type="number"
          label={t(
            "erps.billermanagement.label.minimumLength",
            "Minimum length"
          )}
          className="common__textField__width"
          InputLabelProps={{ shrink: true }}
          size="small"
          onChange={handleBillerProfile("billerCustomerNumberMinimum")}
          value={checkNonZeroValue(billerProfile?.billerCustomerNumberMinimum)}
          inputProps={{ maxLength: 2 }}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 2);
          }}
          error={highlightError?.billerCustomerNumberMinimum}
        />
        <ERPSBaseTextField
          id="billerCustomerNumberMaximum"
          type="number"
          label={t(
            "erps.billermanagement.label.maximumLength",
            "Maximum length"
          )}
          className="common__textField__width"
          InputLabelProps={{ shrink: true }}
          size="small"
          onChange={handleBillerProfile("billerCustomerNumberMaximum")}
          value={checkNonZeroValue(billerProfile?.billerCustomerNumberMaximum)}
          inputProps={{ maxLength: 2 }}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 2);
          }}
          error={highlightError?.billerCustomerNumberMaximum}
        />
      </Stack>
      {fiNumber != 10 && (
        <Stack direction="row" spacing={16} mt={2}>
          <Stack direction="column" mt={2}>
            <ERPSBaseCheckBoxGrp
              label={t(
                "erps.billermanagement.label.alphaAllowed",
                "Alpha allowed"
              )}
              id="alphaAllowed"
              value={billerProfile?.alphaAllowed === "Y" ? true : false}
              onChange={handleBillerProfile("alphaAllowed")}
            />{" "}
          </Stack>
        </Stack>
      )}
      <Stack direction="row" spacing={2} mt={-2}>
        <ERPSBaseTextField
          id="additionalValidation"
          type="text"
          label={t(
            "erps.billermanagement.label.additionalValidation",
            "Additional validation"
          )}
          className="common__textField__width"
          size="small"
          onChange={handleBillerProfile("accountValidationValue")}
          select={true}
          value={billerProfile?.accountValidationValue}
          items={additionalValidationLists}
          SelectProps={{ native: true }}
          InputLabelProps={{
            shrink: billerProfile?.accountValidationValue ? true : false,
          }}
        />
        {billerProfile?.accountValidationValue?.includes("URN") && (
          <ERPSBaseTextField
            id="uniqueCheckDigitRoutine"
            type="text"
            label={t(
              "erps.billermanagement.label.uniqueRoutineName",
              "Unique routine name"
            )}
            className="common__textField__width"
            size="small"
            items={customRoutines}
            onChange={handleBillerProfile("uniqueCheckDigitRoutine")}
            value={billerProfile?.uniqueCheckDigitRoutine}
            SelectProps={{ native: true }}
            select={true}
            required={"true"}
            error={highlightError?.uniqueCheckDigitRoutine}
            InputLabelProps={{
              shrink: true,
            }}
          />
        )}
      </Stack>
      {billerProfile?.accountValidationValue &&
        billerProfile?.accountValidationValue !== "NONE" && (
          <>
            <Stack direction="row" spacing={2} mt={-2}>
              <ERPSBaseTextField
                id="modChecker"
                type="text"
                label={t("erps.billermanagement.label.modCheck", "Mod check")}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("modChecker")}
                value={billerProfile?.modChecker || ""}
                required={
                  billerProfile?.accountValidationValue.includes("CDV") ? "true" : ""
                }
                error={highlightError?.modChecker}
              />

              <ERPSBaseTextField
                id="checkDigitLocation"
                type="number"
                label={t(
                  "erps.billermanagement.label.digitLocation",
                  "Digit location"
                )}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("checkDigitLocation")}
                value={billerProfile?.checkDigitLocation || ""}
                required={
                  billerProfile?.accountValidationValue.includes("CDV") ? "true" : ""
                }
                error={highlightError?.checkDigitLocation}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <ERPSBaseTextField
                id="validationFromDigit"
                type="number"
                label={t(
                  "erps.billermanagement.label.validationFromDigit",
                  "Validation from digit"
                )}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("validationFromDigit")}
                value={billerProfile?.validationFromDigit || ""}
                required={
                  billerProfile?.accountValidationValue.includes("CDV") ? "true" : ""
                }
                error={highlightError?.validationFromDigit}
              />
              <ERPSBaseTextField
                id="validationToDigit"
                type="number"
                label={t(
                  "erps.billermanagement.label.validationToDigit",
                  "Validation to digit"
                )}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("validationToDigit")}
                value={billerProfile?.validationToDigit || ""}
                required={
                  billerProfile?.accountValidationValue.includes("CDV") ? "true" : ""
                }
                error={highlightError?.validationToDigit}
              />
            </Stack>

            <Stack direction="row" spacing={16} mt={2}>
              <Stack direction="column" mt={2}>
                <ERPSBaseCheckBoxGrp
                  label={t("erps.billermanagement.label.fold", "Fold")}
                  id="fold"
                  value={billerProfile?.fold === "Y" ? true : false}
                  onChange={handleBillerProfileCheckbox("fold")}
                />
                <ERPSBaseCheckBoxGrp
                  label={t("erps.billermanagement.label.subtract", "Subtract")}
                  id="subtract"
                  value={billerProfile?.subtract === "Y" ? true : false}
                  onChange={handleBillerProfileCheckbox("subtract")}
                  error={highlightError?.subtract}
                />
              </Stack>
              <ERPSBaseTextField
                id="weighing"
                type="text"
                label={t("erps.billermanagement.label.weighting", "Weighting")}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("weighing")}
                value={billerProfile?.weighing}
                required={
                  billerProfile?.accountValidationValue.includes("CDV") ? "true" : ""
                }
                error={highlightError?.weighing}
              />
            </Stack>
          </>
        )}
      {/* Prefix */}
      <Box className="manageUsers__page__title">
        <ERPSBaseCheckBoxGrp
          label={t(
            "erps.updatebillerapproval.checkbox.label.prefixCheck",
            "Exclude prefix"
          )}
          value={billerProfile?.prefixCheck === "Y" ? true : false}
          onChange={handleBillerProfileCheckbox("prefixCheck")}
        />{" "}
        <Box mt={1}>
          <TextareaAutosize
            aria-label="Prefix text"
            placeholder={t(
              "erps.updatebillerapproval.label.prefixtext",
              "Prefix text"
            )}
            id="prefixText"
            className={
              highlightError?.prefixText
                ? "common__textField__prefix_text textarea_error_highlight"
                : "common__textField__prefix_text"
            }
            onChange={handleBillerProfile("prefixText")}
            value={billerProfile?.prefixText}
            inputProps={{ maxLength: 100 }}
          />
          <Typography
            variant={"label"}
            sx={{ display: "block", marginLeft: "4px" }}
          >
            {fiNumber == "50"
              ? t("erps.billermanagement.textArea.dndNote.text")
              : t("erps.billermanagement.textArea.note.text")}
          </Typography>
        </Box>
      </Box>
      {/* Suffix */}
      <Box className="manageUsers__page__title">
        <ERPSBaseCheckBoxGrp
          label={t(
            "erps.updatebillerapproval.checkbox.label.suffixCheck",
            "Exclude suffix"
          )}
          value={billerProfile?.suffixCheck === "Y" ? true : false}
          id="suffixCheck"
          onChange={handleBillerProfileCheckbox("suffixCheck")}
        />{" "}
        <Box mt={1}>
          <TextareaAutosize
            aria-label="Suffix text"
            placeholder={t(
              "erps.updatebillerapproval.label.suffixtext",
              "Suffix text"
            )}
            className={
              highlightError?.suffixText
                ? "common__textField__prefix_text textarea_error_highlight"
                : "common__textField__prefix_text"
            }
            id="suffixText"
            onChange={handleBillerProfile("suffixText")}
            value={billerProfile?.suffixText}
            inputProps={{ maxLength: 100 }}
          />
          <Typography
            variant={"label"}
            sx={{ display: "block", marginLeft: "4px" }}
          >
            {fiNumber == "50"
              ? t("erps.billermanagement.textArea.dndNote.text")
              : t("erps.billermanagement.textArea.note.text")}
          </Typography>
        </Box>
      </Box>
      {/* Image and instruction text */}
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t(
            "erps.updatebillerapproval.heading.imageinstructiontext",
            "Image and instruction text"
          )}
        </Typography>
      </Box>
      {fiNumber == "50" && (
        <Box>
          <ERPSBaseTextField
            id="instructType"
            type="text"
            label={t("erps.billermanagement.label.instructTextType")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("instructType")}
            select={true}
            value={billerProfile?.instructType || DYNAMIC}
            items={instructTypeList}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: true,
            }}
            isDisabled={!billerProfile?.accountValidationValue?.includes("URN")}
          />
        </Box>
      )}
      {/* English */}
      <Box>
        {fiNumber != 10 ? (
          <ERPSBaseTextField
            id="englishImageUrl"
            type="text"
            label={t(
              "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl",
              "English image url"
            )}
            className="common__textField__image_url"
            size="small"
            onChange={handleBillerProfile("englishImageUrl")}
            value={billerProfile?.englishImageUrl || ""}
            error={highlightError?.englishImageUrl}
            inputProps={{ maxLength: 100 }}
          />
        ) : (
          <>
            {(selectedFile?.English || preview?.englishPreview) && (
              <Box ml={3} mt={2}>
                {" "}
                <img
                  src={preview?.englishPreview}
                  height="240"
                  width="550"
                  alt={t(
                    "erps.updatebillerapproval.imageinstructiontext.label.englishimage"
                  )}
                />
              </Box>
            )}
            <Box display="flex">
              <div>
                <ERPSBaseTextField
                  id="englishImage"
                  type="text"
                  label={t(
                    "erps.updatebillerapproval.imageinstructiontext.label.englishimage"
                  )}
                  className="common__textField__image"
                  size="small"
                  onChange={handleBillerProfile("englishImage")}
                  value={billerProfile?.englishImageUrl}
                  error={highlightError?.englishImage || imageError?.English}
                  inputProps={{ maxLength: 100 }}
                  InputLabelProps={{
                    shrink:
                      billerProfile?.englishImageUrl ||
                      selectedFile?.English?.fileName
                        ? true
                        : false,
                  }}
                  isDisabled={true}
                />
              </div>
              <Box display={"flex"} mt={-2}>
                <Box>
                  <label htmlFor="icon-button-photo">
                    <ERPSBaseButton
                      variant="primary"
                      className="button_with_margin48"
                      label={"Upload..."}
                      loading={imageLoading?.English}
                      component={"span"}
                      onClick={(ev) => ev.stopPropagation()}
                    >
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="icon-button-photo"
                        onChange={(e) => handleFileInput(e, "English")}
                        type="file"
                        key={selectedFile?.English?.fileName || ""}
                      />
                    </ERPSBaseButton>
                  </label>
                </Box>
                <Box ml={3}>
                  <ERPSBaseButton
                    variant="primary"
                    className="button_with_margin48"
                    label={"Remove"}
                    //loading={loginPending}
                    component={"span"}
                    onClick={() => handleRemoveImage("English")}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box mb={-4} mt={4}>
        <Typography variant="label" id={"english_text_editor"}>
          {t(
            "erps.updatebillerapproval.imageinstructiontext.englishinstructiontext",
            "English instruction text"
          )}
        </Typography>
      </Box>
      {englishValue && (
        <Box>
          <TextEditor
            value={englishValue}
            handleEditorChange={handleEditorChange}
            readOnly={
              fiNumber == "50" && billerProfile?.instructType === DYNAMIC
            }
            langType={"en"}
            id={"english_text_editor"}
          />
        </Box>
      )}
      {/* French */}
      <Box>
        {fiNumber == 10 && (
          <>
            {(selectedFile?.French || preview?.frenchPreview) && (
              <Box ml={3} mt={2}>
                {" "}
                <img
                  src={preview?.frenchPreview}
                  height="240"
                  width="550"
                  alt={t(
                    "erps.updatebillerapproval.imageinstructiontext.label.frenchimage"
                  )}
                />
              </Box>
            )}
            <Box display="flex">
              <div>
                <ERPSBaseTextField
                  id="frenchImage"
                  type="text"
                  label={t(
                    "erps.updatebillerapproval.imageinstructiontext.label.frenchimage"
                  )}
                  className="common__textField__image"
                  size="small"
                  onChange={handleBillerProfile("frenchImage")}
                  value={billerProfile?.frenchImageUrl}
                  error={highlightError?.frenchImage || imageError?.French}
                  inputProps={{ maxLength: 100 }}
                  InputLabelProps={{
                    shrink:
                      billerProfile?.frenchImageUrl ||
                      selectedFile?.French?.fileName
                        ? true
                        : false,
                  }}
                  isDisabled={true}
                />
              </div>
              <Box display={"flex"} mt={-2}>
                <Box>
                  <label htmlFor="icon-button-photo2">
                    <ERPSBaseButton
                      variant="primary"
                      className="button_with_margin48"
                      label={"Upload..."}
                      loading={imageLoading?.French}
                      component={"span"}
                      onClick={(ev) => ev.stopPropagation()}
                    >
                      <input
                        accept="image/*"
                        style={{
                          display: "none",
                        }}
                        id="icon-button-photo2"
                        onChange={(e) => handleFileInput(e, "French")}
                        type="file"
                        key={selectedFile?.French?.fileName || ""}
                      />
                    </ERPSBaseButton>
                  </label>
                </Box>
                <Box ml={3}>
                  <ERPSBaseButton
                    variant="primary"
                    className="button_with_margin48"
                    label={"Remove"}
                    //loading={loginPending}
                    component={"span"}
                    onClick={() => handleRemoveImage("French")}
                  />
                </Box>
              </Box>
            </Box>
          </>
        )}
      </Box>
      <Box mb={-4} mt={4}>
        <Typography variant="label" id={"french_text_editor"}>
          {t(
            "erps.updatebillerapproval.imageinstructiontext.frenchinstructiontext",
            "French instruction text"
          )}
        </Typography>
      </Box>
      {frenchValue && (
        <Box>
          <TextEditor
            value={frenchValue}
            handleEditorChange={handleEditorChange}
            readOnly={
              fiNumber == "50" && billerProfile?.instructType === DYNAMIC
            }
            langType={"fr"}
            id={"french_text_editor"}
          />
        </Box>
      )}
      {/* Line of business settings for CIBC */}
      {fiNumber == "10" && (
        <>
          {" "}
          <Box className="manageUsers__page__title">
            {
              <>
                {" "}
                <Box className="manageUsers__page__title">
                  <Typography variant="h2">
                    {t(
                      "erps.billermanagement.label.lineofbusiness",
                      "Line of business settings"
                    )}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="h4">
                    {t("erps.billermanagement.label.image", "Image")}
                  </Typography>
                </Box>
                <Box style={{ width: "500px" }}>
                  <Box
                    mt={3}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography>
                        {t("erps.billermanagement.label.cibc", "CIBC:")}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="imageCibcTelephoneBankingIVR"
                            value={
                              lineOfbusinessData?.isImgCIBCTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcBranchBanking",
                              "Branch banking"
                            )}
                            id="imageCibcBranchBanking"
                            value={
                              lineOfbusinessData?.isImgCIBCBranchBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCBranchBankingChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="imageCibcTelephoneBankingDesktop"
                            value={
                              lineOfbusinessData?.isImgCIBCTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="imageCibcOnlineBanking"
                            value={
                              lineOfbusinessData?.isImgCIBCOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography>
                        {t(
                          "erps.billermanagement.label.pcfinancial",
                          "PC Financial:"
                        )}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="imagePcFinanceTelephoneBankingIVR"
                            value={
                              lineOfbusinessData?.isImgPCFinancialTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgPCFinancialTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>

                        <Box mt={3}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="imagePcFinanceTelephoneBankingDesktop"
                            value={
                              lineOfbusinessData?.isImgPCFinancialTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgPCFinancialTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="imagePcFinanceOnlineBanking"
                            value={
                              lineOfbusinessData?.isImgPCFinancialOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgPCFinancialOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography variant="h4">
                    {t(
                      "erps.billermanagement.label.instructional",
                      "Instructional"
                    )}
                  </Typography>
                </Box>
                <Box style={{ width: "500px" }}>
                  <Box
                    mt={3}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography>
                        {t("erps.billermanagement.label.cibc", "CIBC:")}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="instructionalCibcTelephoneBankingIVR"
                            value={
                              lineOfbusinessData?.isInstCIBCTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcBranchBanking",
                              "Branch banking"
                            )}
                            id="instructionalCibcBranchBanking"
                            value={
                              lineOfbusinessData?.isInstCIBCBranchBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCBranchBankingChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="instructionalCibcTelephoneBankingDesktop"
                            value={
                              lineOfbusinessData?.isInstCIBCTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="instructionalCibcOnlineBanking"
                            value={
                              lineOfbusinessData?.isInstCIBCOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography>
                        {t(
                          "erps.billermanagement.label.pcfinancial",
                          "PC Financial:"
                        )}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="instructionalPcFinanceTelephoneBankingIVR"
                            value={
                              lineOfbusinessData?.isInstPCFinancialTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstPCFinancialTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>

                        <Box mt={3}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="instructionalPcFinanceTelephoneBankingDesktop"
                            value={
                              lineOfbusinessData?.isInstPCFinancialTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstPCFinancialTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="instructionalPcFinanceOnlineBanking"
                            value={
                              lineOfbusinessData?.isInstPCFinancialOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstPCFinancialOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            }
          </Box>
        </>
      )}
      {/* Comments */}
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.comment", "Comments")}
        </Typography>
      </Box>
      <Box mt={1}>
        <TextareaAutosize
          aria-label="Comments"
          placeholder={t("erps.billermanagement.label.comment", "Comments")}
          className={"common__textField__prefix_text"}
          id="billerComment"
          onChange={handleBillerProfile("billerComment")}
          value={billerProfile?.billerComment}
          error={highlightError?.billerComment}
          inputProps={{ maxLength: 160 }}
        />
      </Box>
      {/* Action Buttons */}
      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.common.button.cancel", "Cancel")}
          onClick={handleOpenCancelDialog}
          componentAccess={billerProfileAccess?.childComponents}
          actionType="hasCancelUpdateBillerAccess"
        />
        <ERPSBaseButton
          variant="primary"
          onClick={updateBillerProfile}
          className="button_with_margin48"
          label={t("erps.newuser.button.save", "Save")}
          loading={saveLoading}
          componentAccess={billerProfileAccess?.childComponents}
          actionType="hasUpdateBillerAccess"
        />
      </Stack>
      {/* Dialog */}
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </Box>
  );
}

export default BillerProfileUser;