import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { upcomingRecallsRejectsHeading } from "../../constants/UpcomingRecallsRejectsConstants";
import { getComponentAccess } from "../../utils/helper";
import { transactionTypes, RoleType }  from "../../constants/sharedConstants.js";


const UpcomingRecallsRejects = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { state },
  } = props;

  const [errorMessage, setErrorMessage] = useState(null);
  const user = useSelector((state) => state.loginUserReducer.user);

  const componentAccess = state?.componentAccess
          || getComponentAccess( { function_type_code: "URR", label_type_code: "TE" }, user );

  const urrResponse = useSelector(
    (state) => state.transactionEnquiryReducer
  );

  // Setting these two values from urrResponse if we're coming from the Detail Page (state?.fromDetailPag == true)
  const [searchType, setSearchType] = useState( state?.fromDetailPage && urrResponse?.upcomingRecallsRejectsDetails?.transactionType || transactionTypes[0].value);
  const [transactionData, setTransactionData] = useState( state?.fromDetailPage && urrResponse?.upcomingRecallsRejectsData || null);

  const [loading, setLoading] = useState(false);
  const [loadingMsg, setLoadingMsg] = useState("");
  const [pageNumber, setPageNumber] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const [transactionTypeDisplay, setTransactionTypeDisplay] = useState([]);

  const [searchValues, setSearchValues] = useState({
    transactionType: searchType,
    offset: 0,
    limit: rowPerPage,
  });
  
  const [fiMap, setFiMap] = useState(null);
  const financialInstitutionResponse = useSelector( (state) => state.fiReducer?.financialInstitutionList );

  useEffect(() => {
    if (!financialInstitutionResponse || financialInstitutionResponse.length == 0)
      dispatch(actions.fiActions.getFinancialInstitutionList(t));
    setTransactionTypeDisplay( transactionTypes.map(tt => ({value: tt.value, name: t(tt.name, tt.name)}) ) );

    // if coming from the detail page, restore search values 
    if (state?.fromDetailPage && urrResponse?.upcomingRecallsRejectsDetails) { // if back from the Details page
      // set searchValues' attriutes from urrResponse.upcomingRecallsRejectsDetails
      const { transactionStatus, ...searchFields } = urrResponse.upcomingRecallsRejectsDetails;
      //setSearchValues({ ...searchFields });  -- This DOES NOT work: the next useEffect does not yet 'see' the properties set this way.
      Object.keys(searchFields).forEach(key => searchValues[key] = urrResponse.upcomingRecallsRejectsDetails[key]);

      setRowPerPage(searchValues.limit);
      setPageNumber(searchValues.offset / searchValues.limit);
      state.fromDetailPage = null;  // resetting the coming-from-detail-page flag
    } else {
      searchValues.currentFiNumber = user?.fiNumber;
      let roleType = user.role.role_type;
      if (roleType === RoleType.BILLER_ADMIN || roleType === RoleType.BILLER_USER)
        searchValues.billerId = user.billerNonVolatileDto.biller_fi_number;  // sends external biller id
    }
  }, []);

  useEffect(() => {
    if (financialInstitutionResponse && financialInstitutionResponse.length)
      setFiMap(new Map(financialInstitutionResponse.map( ({ fi_number, fi_name }) => [fi_number, fi_name])));
  }, [financialInstitutionResponse]);

  const errorRef = useRef();
  useEffect(() => {
    if (errorRef.current)
      errorRef.current.focus();
  }, [errorMessage]);

  const searchTypeChanged = (searchTypeValue) => {
    if (loading) {
      // display msg
      setLoadingMsg("The page is loading, please wait");
    } else {
      // resetting back to the first page
      searchValues.offset = 0;
      setSearchType(searchTypeValue);
      setPageNumber(0);
    }
  };

  useEffect(() => {
    if (typeof actions.transactionEnquiryActions === 'undefined') {  // IS THERE A NEED TO CHECK ??
      console.log(ts() + " useEffect:  actions.transactionEnquiryActions is undefined");
    } else {
      searchValues.transactionType = searchType;
      loadResults("from useEffect[searchType]");
    }
  }, [searchType]);

  // Handling Response
  useEffect(() => {     //  (*.., [urrResponse])  -- called ONLY after a call to the service.  Actually, NOT SO!
    if (urrResponse) {
      const responseData = urrResponse.upcomingRecallsRejectsData?.data;
      if (responseData && responseData?.length) {
        // .seqNo will be the sequence number of the record in the full result list, i.e. result list for all pages
        for(let i=0; i < responseData.length; i++)
          responseData[i].seqNo = i + 1 + searchValues.offset;
      }
      setTransactionData(responseData);        
    }
  }, [urrResponse]);

  const paginateURR = (values) => {  // 'values' has fields startOffset and endOffset (for 1st Page: 0,25;  2nd Page: 25,50;  3rd: 50,75...)
    searchValues.offset = values.startOffset;
    if (searchValues.limit !== (values.endOffset - values.startOffset))
      searchValues.limit = values.endOffset - values.startOffset;

    loadResults();
  };

  let loadResults = (tempText = "") => {
    const actualCriteria = { ...searchValues, transactionStatus: (searchType === transactionTypes[1].value) ? "Q" : "N" };
   
    if (errorMessage)
      setErrorMessage(null);

    if (loading) {
      setLoading(false);
      setLoadingMsg(null);
    }
    dispatch(
      actions.transactionEnquiryActions.searchUpcomingRejectsRecalls(
        actualCriteria,
        { ...props, t, history, setLoading, setErrorMessage }
      )
    );
  };

  return (
    <div role="main">
      <Box sx={{ ml: 3 }}>
        {!errorMessage ? ("") :
          (<Box className="manageUsers__errorMsg__container" role="alert" mt={errorMessage ? 2 : 0} >
            {<ErrorMessage error={errorMessage} errorRef={errorRef} />}
          </Box>)
        }

        <Box className="manageUsers__page__title">
          {/* For Title update */}
          <CommonContainerBox title={t("erps.upcomingrecallsrejects.page.title")} />
          <Typography variant="h1">{t("erps.upcomingrecallsrejects.page.title")}</Typography>
        </Box>

        <Stack sx={{ mt: 2, spacing: 2 }} direction="row">
          <Box>
            <ERPSBaseRadioGroup
              name="searchBy"
              marginTop={"true"}
              //InputLabelProps={{ shrink: true }}
              value={searchType}
              onChange={(e) => searchTypeChanged(e.target.value)}
              items={transactionTypeDisplay}
              label=""   // seems like it's a mandatory prop
              //className={"transactionEnquiry__radioButton_direction"}
              //actionType={"hasURRSearchAccess"}   // DO I DEFINE A PERMISSION FOR THIS RADIO BTN ??
            />
          </Box>
        </Stack>

        {transactionData !== null && transactionData !== undefined && transactionData.length > 0 ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: transactionData,
                  columns: upcomingRecallsRejectsHeading(searchType === "RJ" ? fiMap : null),
                  count: urrResponse?.upcomingRecallsRejectsData?.count || 0,
                }}
                ///initialLoading={initialLoading}
                sumOfColumnWidth="true"
                onAnyPaginationAction={paginateURR}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
                tableLabelText={"_Page Title_"}
                //tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={false}
                pageName={"transaction-enquiry-urr"}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: transactionData,
                  columns: upcomingRecallsRejectsHeading(searchType === "RJ" ? fiMap : null),
                  count: urrResponse?.upcomingRecallsRejectsData?.count || 0,
                }}
                //onAnyPaginationAction={paginateTransactionEnquiry}
                hideFooterPagination={false}
                pageName={"upcoming-recalls-rejects"}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
              />
            </Box>
          </>
        )
        : (
            <Box mt={1}>
                <Typography variant="body1">
                    { (transactionData?.length === 0)
                        ? (`0 ${t("erps.transactionEnquiry.page.resultfound")}`)
                        : (t("erps.availabledocuments.searchuser.notstarted", "Search not started"))
                    }
                </Typography>
            </Box>
          )
        }
      </Box>
    </div>
  );
};

export default withSidebar(UpcomingRecallsRejects);

function ts() {  return new Date().toISOString().substring(11, 23);  } // 2020-05-12T23:50:21.817Z
