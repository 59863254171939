import axios from "axios";
import transactionEnquiryTypes from "../actions/types/transactionEnquiry.action.type";
import store from "../store/index";
import { getExceptionMessage, API_BASE_URL, formatDate } from "../utils/helper";
import moment from "moment";
import axiosRetry from 'axios-retry';
import { onSuccess, onError } from "./serviceUtil";

export const transactionEnquiryService = {
  getTransactionEnquiry,
  udpateRecallAndRetrace,
  getTransactionsForReject,
  rejectTransaction,
  searchRecallPendingApprovals,
  processPendingApproval,
  recallTransaction,
  searchRecallRejectHistory
};

function getTransactionEnquiry(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const { startOffset, endOffset, paymentFromDate, paymentToDate, ...rest } =
      transactionData;
    const updatedPaymentFromDate = moment(paymentFromDate).format();
    const updatedPaymentToDate = moment(paymentToDate).format();
    const token = store.getState()?.loginUserReducer.token;

    axiosRetry(axios, {
      retries: 5,
      retryDelay: (retryCount) => {
        if (retryCount < 5) {
          if (props?.setErrorMessage && props?.t) {
            props?.setErrorMessage(
              props?.t("erps.transactionEnquiry.page.pending")
            );
          }
        }

        return retryCount * (retryCount < 2 ? 20000 : 30000); // time interval between retries
      },
      retryCondition: (error) => {
        return error.response.status === 504;
      },
      onRetry: (retryCount, error, requestConfig) => {
        const requestId = error?.response?.data?.message?.split("::")[0]?.trim();
        requestConfig.data = requestConfig.data?.replace('"requestId":null', `"requestId":"${requestId}"`);
      }
    });

    axios
      .post(
        `${API_BASE_URL()}/searchTransaction`,
        {
          ...rest,
          paymentFromDate: formatDate(updatedPaymentFromDate),
          paymentToDate: formatDate(updatedPaymentToDate),
          fiNumber: props?.fiNumberValue,
          selectedFiNumber: fiDetail?.selectedFi,
          fiCompanyNumber: transactionData?.subscribeEntityId
            ? transactionData.subscribeEntityId
            : user.fiCompanyNumber,
          offset: startOffset,
          limit: 100,
          userId: user.userId,
          languageCode: user.languageCode,
          roleType: user?.role?.role_type,
          requestId: null,
          requestFrom:"reviewTransactions"
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const response = {
          data: res.data,
          transactionEnquiryDetails: {
            ...transactionData,
            paymentFromDate,
            paymentToDate,
          },
        };
        dispatch( onSuccess(response, transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_SUCCESS, props) );
      })
      .catch((error) => {
        let errorMessage = getExceptionMessage(error, props?.t);
        dispatch( onError(errorMessage, transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_ERROR, props) );
      });
  };
}

function getTransactionsForReject(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;

    axiosRetry(axios, {
      retries: 5,
      retryDelay: (retryCount) => {
        if (retryCount < 5) {
          if (props?.setErrorMessage && props?.t) {
            props?.setErrorMessage(
                props?.t("erps.transactionEnquiry.page.pending")
            );
          }
        }

        return retryCount * (retryCount < 2 ? 20000 : 30000); // time interval between retries
      },
      retryCondition: (error) => {
        return error.response.status === 504;
      },
      onRetry: (retryCount, error, requestConfig) => {
        const requestId = error?.response?.data?.message?.split("::")[0]?.trim();
        requestConfig.data = requestConfig.data?.replace('"requestId":null', `"requestId":"${requestId}"`);
      }
    });
    axios
      .post(
        `${API_BASE_URL()}/search-transactions-for-reject`,
        {
          userId: user.userId,
          roleType: user?.role?.role_type,
          languageCode: user.languageCode,
          //requestId: null,     //MB:  What is it for ??
          requestFrom:"rejectPaymentForMerchants",
          ...transactionData
        },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        const response = {
          data: res.data,
          transactionForRejectDetails: { ...transactionData },
        };
        dispatch( onSuccess(response, transactionEnquiryTypes.GET_TRANSACTION_FOR_REJECT_SUCCESS, props) );
      })
      .catch((error) => {
        let errorMessage = getExceptionMessage(error, props?.t, {searchByBillerName: transactionData.billerName ? true : false});
        dispatch( onError(errorMessage, transactionEnquiryTypes.GET_TRANSACTION_FOR_REJECT_FAILURE, props) );
      });
  };
}

function udpateRecallAndRetrace(data, props) {
  return (dispatch) => {
    const { reqBodyForSendEmail, reqBodyForAddRecallAndRetrace } = data;
    const { fiNumber, clientName, userId, reqType, screenshot, language } =
      reqBodyForSendEmail;
    const formData = new FormData();
    formData.append("screenshot", screenshot);
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
        `${API_BASE_URL()}/add-recalltrace-audit`,
        { ...reqBodyForAddRecallAndRetrace },
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((re) => {
        axios
          .post(
            `${API_BASE_URL()}/upload-screenshot?fiNumber=${fiNumber}&clientName=${clientName}&userId=${userId}&reqType=${reqType}&language=${language}`,
            formData,
            {
              headers: {
                "Content-Type": "multipart/form-data",
                Authorization: "Bearer " + token,
              },
            }
          )
          .then((res) => {
            dispatch( onSuccess(res.data, transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_SUCCESS, props) );
          })
          .catch((error) => {
            let errorMessage = getExceptionMessage(error, props?.t);
            dispatch( onError(errorMessage, transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_FAILURE, props) );
          });
      })
      .catch((error) => {
        let errorMessage = getExceptionMessage(error, props?.t);
        dispatch( onError(errorMessage, transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_FAILURE, props) );
      });
  };
}

function searchRecallPendingApprovals(transactionData, props) {
  return (dispatch) => {
      const user = store.getState()?.loginUserReducer.user;
      //const fiDetail = store.getState()?.fiReducer;
      const token = store.getState()?.loginUserReducer.token;
      axios
        .post(
          `${API_BASE_URL()}/searchRecallPendingApprovals`,
          {   userId: user.userId,
              languageCode: user.languageCode,
              roleType: user?.role?.role_type,
              ...transactionData
          },
          { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, } }
        )
        .then((res) => {
          const response = {
              data: res.data,
              recallPendingApprovalsDetails: { ...transactionData },
          };
          dispatch( onSuccess(response, "GET_RECALL_PENDING_APPROVALS_SUCCESS", props) );
        })
        .catch((error) => {
          let errorMessage = getExceptionMessage(error, props?.t);
          dispatch( onError(errorMessage, "GET_RECALL_PENDING_APPROVALS_ERROR", props) );
        });
  };
}

function processPendingApproval(data, props) {

  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    let url = null;
    if (data.action === "C")
      url = `${API_BASE_URL()}/cancel-transaction`;
    else if (data.action === "A")
      url = `${API_BASE_URL()}/approvePendingRecall`;
    else if (data.action === "R")
      url = `${API_BASE_URL()}/rejectPendingRecall`;
    else
      console.error("Unrecognized value of data.action: " + data.action);

    axios
      .post(
          url,
          { ...data, language_code: user.languageCode, recalled_rejected_by: user.userId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
      ).then((res) => {
        dispatch( onSuccess(res.data, transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_SUCCESS, props) );
      }).catch((error) => {
        let errorMsg = getExceptionMessage(error, props?.t);
        dispatch( onError(errorMsg, transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_FAILURE, props) );
      });
  };
}

function rejectTransaction(data, props) {

  return (dispatch) => {
    const { reqBodyForReject } = data;
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
        .post(
            `${API_BASE_URL()}/reject-transaction`,
            { ...reqBodyForReject },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token,
              },
            }
        ).then((res) => {
          dispatch( onSuccess(res.data, transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_REJECT_SUCCESS, props) );
        }).catch((error) => {
          let errorMessage = getExceptionMessage(error, props?.t);
          dispatch( onError(errorMessage, transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_REJECT_FAILURE, props) );
        });
  };
}

function recallTransaction(data, props) {

  return (dispatch) => {
    const { reqBodyForRecall } = data;
    const user = store.getState()?.loginUserReducer.user;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .post(
          `${API_BASE_URL()}/recall-transaction`,
          { ...reqBodyForRecall },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
          }
      ).then((res) => {
        dispatch( onSuccess(res.data, transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_RECALL_SUCCESS, props) );
      }).catch((error) => {
        let errorMessage = getExceptionMessage(error, props?.t);
        dispatch( onError(errorMessage, transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_RECALL_FAILURE, props) );
      });
  };
}

function searchRecallRejectHistory(transactionData, props) {
  return (dispatch) => {
    const user = store.getState()?.loginUserReducer.user;
    const fiDetail = store.getState()?.fiReducer;
    const token = store.getState()?.loginUserReducer.token;

    axios
      .post(
        `${API_BASE_URL()}/searchRecallRejectHistory`,
        { userId: user.userId,
          languageCode: user.languageCode,
          roleType: user?.role?.role_type,
          ...transactionData
        },
        { headers: { "Content-Type": "application/json", Authorization: "Bearer " + token, } }
      )
      .then((res) => {
        const response = {
          data: res.data,
          recallRejectHistoryDetails: { ...transactionData },
        };
        dispatch( onSuccess(response, "GET_RECALL_REJECT_HISTORY_SUCCESS", props) );
      })
      .catch((error) => {
        let errorMessage = getExceptionMessage(error, props?.t);
        dispatch( onError(errorMessage, "GET_RECALL_REJECT_HISTORY_ERROR", props) );
      });
  };
}
