import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { convertDateIntoERPSDateFormat, formatAmount } from "../utils/helper";

export const recallPaymentCustomerHeading = () => {
    return [
        {
          field: "seqNo",
          headerName: t("erps.common.label.number.short"),
          width: 50,
          sortable: false,
        },
        {
          field: "transaction_amount",
          headerName: t("erps.common.label.paymentAmount"),
          width: 160,
          sortable: false,
          renderCell: (params) => { if (params.value)  return formatAmount(params.value); },
        },
        { field: "effective_date",
          headerName: t("erps.common.label.effectiveDate"),
          width: 110,
          sortable: false,
          renderCell: (params) => convertDateIntoERPSDateFormat(params.value),
        },
        {
          field: "verification_number",
          headerName: t("erps.common.label.confirmationNumber.short"),
          width: 184,
          sortable: false,
        },
        {
          field: "fi_biller_id",
          headerName: t("erps.common.label.billerID"),
          width: 140,
          sortable: false,
          renderCell: (params) => params?.row?.display_biller_id,
        },
        {
          field: "biller_customer_number",
          headerName: t("erps.common.label.customerAccountNumber.short"),
          width: 220,
          sortable: false,
        },
        {
          field: "fi_customer_name",
          headerName: t("erps.common.label.customerName"),
          width: 220,
          sortable: false,
        },
        {
          field: "recall",
          headerName: t("erps.recallpaymentcustomers.page.recall"),
          width: 80,
          sortable: false,
          renderCell: (params) => (
            <ERPSBaseLink
              to={{
                pathname: `/recall-payments-customers/transaction-details`,
                state: {id: params.row?.payment_id},
              }}
              className="link__blue"
              label={t("erps.recallpaymentcustomers.page.recall")}
            />
          ),
        },
      ]
   
};