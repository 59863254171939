import React, { useState, useEffect, useRef } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ErrorMessage from "../custom/containers/ErrorMessage";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useSelector, useDispatch } from "react-redux";
import actions from "../../actions";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import CircularProgress from "@material-ui/core/CircularProgress";
import Alert from "@mui/material/Alert";
import {
  getServiceAgreement,
  requiredFormatDate,
  zeroPad,
  lpad,
  checkFieldsChange,
} from "../../utils/helper";
import { getNumericValue, checkNonZeroValue } from "./../../helpers/biller";

function EditBillerProfileDetail(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { billerId, displayBillerId } = props;
  const [saveLoading, setSaveLoading] = useState(false);

  const [errorMessageList, setErrorMessage] = useState([]);
  const [billerProfile, setBillerProfile] = useState({});
  const [oldBillerValues, setOldBillerValues] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});

  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const masterfinancialInstitutionList = useSelector(
    (state) => state.fiReducer?.masterFiDescriptions
  );

  const fiNumber = selectedFi || loginUser.fiNumber;
  const roleType = loginUser?.role?.role_type;

  const successRef = useRef(null);
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const successMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileAssociatedUpdateData
  );
  const errorRef = useRef();
  const warningRef = useRef();

  const documentFormatItems = [
    { name: t("erps.userprofile.language.pdf.text", "PDF"), value: "N" },
    {
      name: t("erps.userprofile.language.pdfandcsv.text", "PDF and CSV"),
      value: "Y",
    },
  ];

  const getMonthlyServiceFee = (pricePlanId, documentFormatVal) => {
    if (pricePlanData) {
      const pricePlan = pricePlanData?.find(
        ({ value }) => value?.toString() === pricePlanId?.toString()
      );
      if (
        documentFormatVal === "Y" &&
        pricePlan?.baseFee !== undefined &&
        pricePlan?.csvFee !== undefined
      ) {
        const updatedValue = pricePlan?.baseFee + pricePlan?.csvFee;
        return `$ ${updatedValue?.toFixed(2)}`;
      }
      return pricePlan?.baseFee !== undefined
        ? `$ ${pricePlan?.baseFee?.toFixed(2)}`
        : "";
    }
    return "";
  };

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  const pricePlaneResult = useSelector(
    (state) => state?.pricePlanReducer?.pricePlanList
  );

  const billerProfileReducerValue = useSelector(
    (state) => state.billerProfileReducer
  );
  const [billerUpdateValue, setBillerUpdateValue] = useState({
    csv_advice_ok:
      billerProfileReducerValue.billerProfileAssociatedData
        ?.billerNonVolatileDto?.csv_advice_ok,
  });

  const [openCancelDialog, setOpenCancelDialog] = useState(false);
  const [pricePlanData, setPricePlaneData] = useState(null);
  const [hasShowWarningMessage, setShowWarningMessage] = useState(false);

  useEffect(() => {
    if (warningRef.current) {
      warningRef.current.focus();
    }
  }, [hasShowWarningMessage]);
  const [baseFee, setBaseFee] = useState("");

  const updatedComponentAccess = props?.componentAccess?.find(
    ({ componentType }) => componentType === "WPBPV"
  );

  const updatedChildComponentAccess = updatedComponentAccess?.childComponents;
  useEffect(() => {
    if (billerProfileReducerValue) {
      if (billerProfileReducerValue.billerProfileAssociatedData) {
        const billerProfile =
          billerProfileReducerValue.billerProfileAssociatedData;
        setBillerProfile({
          ...billerProfile,
          bmoBillerId:
            billerId?.split("-")?.length > 1
              ? billerId?.split("-")[0]
              : billerProfile?.bmoBillerId,
        });
        const oldBillerValues = {
          debit_fi_number: zeroPad(
            billerProfile?.billerNonVolatileDto?.debit_fi_number,
            3
          ),
          debit_transit_number:
            billerProfile?.billerNonVolatileDto?.debit_transit_number,
          debit_account_number:
            billerProfile?.billerNonVolatileDto?.debit_account_number,
          amount_owing: billerProfile?.billerNonVolatileDto?.amount_owing,
          setup_fee: billerProfile?.billerNonVolatileDto?.setup_fee,
          email_notification_ok:
            billerProfile?.billerNonVolatileDto?.email_notification_ok,
          csv_advice_ok: billerProfile?.billerNonVolatileDto?.csv_advice_ok,
          billing_model_number:
            billerProfile?.billerNonVolatileDto?.billing_model_number,
        };
        setBillerUpdateValue(oldBillerValues);
        setOldBillerValues(oldBillerValues);
        const baseFee = getMonthlyServiceFee(
          billerProfile?.billerNonVolatileDto?.billing_model_number,
          billerProfile?.billerNonVolatileDto?.csv_advice_ok
        );
        setBaseFee(baseFee);
      }
    }
    if (billerProfileReducerValue?.billerProfileAssociatedError) {
      setErrorMessage([billerProfileReducerValue.billerProfileAssociatedError]);
    }
  }, [
    billerProfileReducerValue?.billerProfileAssociatedData,
    billerProfileReducerValue?.billerProfileAssociatedError,
  ]);

  useEffect(() => {
    if (props?.handleTabLoading) {
      props?.handleTabLoading(true);
    }
    dispatch(
      actions.billerProfileActions.getBillerProfileWithAssociatedObjects(
        billerId,
        t,
        props?.handleTabLoading
      )
    );
    dispatch(actions.pricePlanActions.getPricePlanList(t));
  }, []);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessageList.length, validationErrors?.length]);

  useEffect(() => {
    if (pricePlaneResult) {
      const updatedData = pricePlaneResult.data.map(
        ({ billingModelDescription, billingModelNumber, ...rest }) => ({
          ...rest,
          label: billingModelDescription,
          value: billingModelNumber,
        })
      );
      updatedData.unshift({
        lable: "",
        value: "",
      });
      setPricePlaneData(updatedData);
    }
  }, [pricePlaneResult]);

  useEffect(() => {
    if (
      masterfinancialInstitutionList &&
      masterfinancialInstitutionList?.length
    ) {
      const data = masterfinancialInstitutionList
        .map(({ fiNumber, fiName }) => ({
          value: lpad(fiNumber, 4).toString(),
          label: lpad(fiNumber, 4) + "-" + fiName,
        }))
        ?.filter(({ value }) => value != 0);
      data.unshift({
        value: "",
        label: "",
      });
      setBillerFinancialInstitutionList(data);
    }
  }, [masterfinancialInstitutionList]);
  useEffect(() => {
    dispatch(actions.fiActions.getMasterFiDescriptionList(t));
  }, []);

  const [billerFinancialInstitutionList, setBillerFinancialInstitutionList] =
    useState([]);
  const updatedDate = (date) =>
    date?.toString()?.replace(/(\d{4})(\d{2})(\d{2})/g, "$1/$2/$3");

  const handleBillerProfile = (e, key) => {
    if (key !== "email_notification_ok") {
      if (key === "billing_model_number") {
        const baseFee = getMonthlyServiceFee(
          e.target.value,
          billerUpdateValue?.csv_advice_ok
        );
        setBaseFee(baseFee);
      }
      if (key === "csv_advice_ok") {
        const baseFee = getMonthlyServiceFee(
          billerUpdateValue?.billing_model_number,
          e.target.value
        );
        setBaseFee(baseFee);
      }
      setBillerUpdateValue((prevState) => ({
        ...prevState,
        [key]: e.target.value,
      }));
    } else {
      setBillerUpdateValue((prevState) => ({
        ...prevState,
        [key]: e.target.checked ? "Y" : "N",
      }));
    }
  };
  const checkNumericValue = (val) => {
    const decimalRegEx = /^\d{0,8}(\.\d{0,2})*$/;
    return !decimalRegEx.test(val);
  };

  const validateBillerProfileUser = (billerProfile) => {
    const alphnumericWithHypen = /^[0-9A-Za-z\-]+$/;
    const errors = [];
    let highlightErrorV1 = {};
    if (!billerProfile?.debit_account_number) {
      errors.push(t("erps.billermanagement.error.debitAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, debit_account_number: true };
    }
    if (!billerProfile?.debit_transit_number) {
      errors.push(t("erps.billermanagement.error.debitTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, debit_transit_number: true };
    }
    if (
      billerProfile?.debit_account_number &&
      billerProfile?.debit_account_number.length > 12
    ) {
      errors.push(
        t("erps.billermanagement.error.debitAccountNotGreaterThan12")
      );
      highlightErrorV1 = { ...highlightErrorV1, debit_account_number: true };
    }
    if (
      billerProfile?.debit_account_number &&
      !alphnumericWithHypen.test(billerProfile?.debit_account_number)
    ) {
      errors.push(
        t("erps.billermanagement.error.debitAccountNumberInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, debit_account_number: true };
    }
    if (checkNumericValue(billerProfile?.setup_fee)) {
      errors.push(
        `Setup fee - ${t("erps.managePricePlan.error.maxsize10plus2")}`
      );
      highlightErrorV1 = { ...highlightErrorV1, setup_fee: true };
    }
    if (checkNumericValue(billerProfile?.amount_owing)) {
      errors.push(
        `Amount owing - ${t("erps.managePricePlan.error.maxsize10plus2")}`
      );
      highlightErrorV1 = { ...highlightErrorV1, amount_owing: true };
    }
    return { errors, highlightErrorV1 };
  };

  const updateBillerProfile = () => {
    setSaveLoading(true);
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    const { errors, highlightErrorV1 } =
      validateBillerProfileUser(billerUpdateValue);
    if (errors.length) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      setSaveLoading(false);
      if (errorRef.current) {
        errorRef.current.focus();
      }
    } else {
      setValidationErrors([]);
      setHighlightError({});

      const hasFieldsChanged = checkFieldsChange(
        oldBillerValues,
        billerUpdateValue
      );
      if (hasFieldsChanged) {
        if (hasShowWarningMessage) {
          setShowWarningMessage(false);
        }
        dispatch(
          actions.billerProfileActions.updateBillerProfileForWebPortalUser(
            {
              ...billerUpdateValue,
              languageCode: billerProfile?.billerLanguageCode,
              fi_number: billerProfile?.billerNonVolatileDto?.fi_number,
              biller_id: billerProfile?.billerNonVolatileDto?.biller_id,
              biller_fi_number: billerId?.split("-")[0],
              setup_fee: billerUpdateValue?.setup_fee || 0,
              amount_owing: billerUpdateValue?.amount_owing || 0,
            },
            {
              setSaveLoading,
              setShowSuccessMessage,
              setValidationErrors,
              t,
              handleTabLoading: props?.handleTabLoading,
            }
          )
        );
      } else {
        setSaveLoading(false);
        setShowWarningMessage(true);
        console.log("Inside ELSE OUTSIDE API Call----", hasFieldsChanged);
      }
    }
  };

  const handleRedirect = () => {
    props.history.push("/manage-billers");
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const serviceAgreement = getServiceAgreement({
    billerNonVolatileDto: billerProfile?.billerNonVolatileDto,
    fiNumber: billerProfile?.billerNonVolatileDto?.fi_number,
    creditorFiNumber: billerProfile?.creditorFiNumber,
  });
  const pdfFile = serviceAgreement
    ? loginUser?.languageCode === "F"
      ? serviceAgreement.french
      : serviceAgreement.english
    : "";
  const slicedAccountNumberLength =
    billerProfile?.creditorAccountNumber?.toString()?.length - 3;
  return (
    <Box>
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      {errorMessageList.length || validationErrors?.length ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={errorMessageList.length || validationErrors?.length ? 2 : 0}
        >
          {errorMessageList.length || validationErrors?.length ? (
            <ErrorMessage
              error={[...errorMessageList, ...validationErrors]}
              errorRef={errorRef}
            />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      {hasShowWarningMessage && (
        <Stack sx={{ width: "100%" }} spacing={2} tabIndex={0} ref={warningRef}>
          <Alert severity="warning">
            {t("erps.billermanagement.warning.nothinghaschanged")}
          </Alert>
        </Stack>
      )}

      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.page.formtitle", "Biller Profile")}
        </Typography>
        {props?.isLoading && (
          <Box ml={40}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
      <Box mt={3} display={"flex"}>
        {(roleType === "PA" || roleType === "CE") && (
          <Typography variant="inherit">
            {t(
              "erps.billermanagement.label.internalBillerId",
              "Internal biller ID"
            )}
            <Typography variant="body2">
              {billerProfile?.billerNonVolatileDto?.biller_id}
            </Typography>
          </Typography>
        )}
        {roleType !== "PA" && roleType !== "CE" && (
          <Typography variant="inherit">
            {t("erps.billermanagement.label.billerId", "Biller ID")}
            <Typography variant="body2">
              {displayBillerId || billerId}
            </Typography>
          </Typography>
        )}
      </Box>
      <Box mt={4}>
        <Stack direction="row" spacing={20}>
          <Box>
            <Typography variant="inherit">
              {t("erps.billermanagement.label.billerName")}
              <Typography variant="body2">
                {billerProfile?.billerName}
              </Typography>
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box mt={4}>
        <Stack direction="row" spacing={30}>
          <Box>
            <Typography variant="inherit">
              {t("erps.billermanagement.label.billerProvince")}
              <Typography variant="body2">
                {billerProfile?.billerProvince}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography variant="inherit">
              {t("erps.billermanagement.label.language")}
              <Typography variant="body2">
                {billerProfile?.billerLanguageCode === "E"
                  ? "English"
                  : "French"}
              </Typography>
            </Typography>
          </Box>
        </Stack>
      </Box>
      <Box mt={4}>
        <Typography variant="h3">
          {t("erps.billermanagement.button.contactInfo", "Contact information")}
        </Typography>
      </Box>
      <Box mt={4} display={"flex"}>
        <Stack direction="row" spacing={13}>
          <Box>
            <Typography variant="inherit">
              {t("erps.billermanagement.label.primarycontact")}
              <Typography variant="body2">
                {billerProfile?.primaryContactName}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Stack direction="row" spacing={2}>
              <Typography variant="inherit">
                {t("erps.billermanagement.label.telNum")}
                <Typography variant="body2">
                  {billerProfile?.primaryContactPhone}
                </Typography>
              </Typography>
              <Typography variant="inherit">
                {t("erps.billermanagement.label.extension")}
                <Typography variant="body2">
                  {billerProfile?.primaryContactExtension || ""}
                </Typography>
              </Typography>
            </Stack>
          </Box>
        </Stack>
        <Box ml={10}>
          <Typography variant="inherit">
            {t("erps.billermanagement.label.faxNumber")}
            <Typography variant="body2">
              {billerProfile?.primaryContactFax}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.creditaccount")}
        </Typography>
      </Box>
      <Box mt={4} display={"flex"}>
        <Stack direction="row" spacing={18}>
          <Box>
            <Typography variant="inherit">
              {t("erps.billermanagement.label.billerFinancialInstitution")}
              <Typography variant="body2">
                {zeroPad(billerProfile?.creditorFiNumber, 3)}
              </Typography>
            </Typography>
          </Box>
          <Box>
            <Typography variant="inherit">
              {t("erps.billermanagement.label.transitNumber")}
              <Typography variant="body2">
                {`*${billerProfile?.creditorTransitNumber
                  ?.toString()
                  ?.slice(1, 4)}*`}
              </Typography>
            </Typography>
          </Box>
        </Stack>
        <Box ml={23}>
          <Typography variant="inherit">
            {t("erps.billermanagement.label.account")}
            <Typography variant="body2">
              {`****${billerProfile?.creditorAccountNumber?.slice(
                slicedAccountNumberLength
              )}`}
            </Typography>
          </Typography>
        </Box>
      </Box>
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.debitaccount")}
        </Typography>
      </Box>
      <Stack direction="row" spacing={8}>
        <ERPSBaseTextField
          id="debitFiNumber"
          items={billerFinancialInstitutionList}
          SelectProps={{ native: true }}
          InputLabelProps={{
            shrink: billerUpdateValue?.debit_fi_number ? true : false,
          }}
          type="text"
          select={true}
          label={t(
            "erps.billermanagement.label.billerFinancialInstitution",
            "Biller financial institution"
          )}
          className="common__textField__width addNewBiller_label_fontSize"
          size="small"
          onChange={(e) => handleBillerProfile(e, "debit_fi_number")}
          value={billerUpdateValue?.debit_fi_number || ""}
        />
        <ERPSBaseTextField
          id="debitTransitNumber"
          type="number"
          label={t(
            "erps.billermanagement.label.transitNumber",
            "Transit number"
          )}
          className="common__textField__width"
          size="small"
          InputLabelProps={{ shrink: true }}
          onChange={(e) => handleBillerProfile(e, "debit_transit_number")}
          value={billerUpdateValue?.debit_transit_number}
          onInput={(e) => {
            e.target.value = getNumericValue(e.target.value, 0, 5);
          }}
          required={"true"}
        />
        <ERPSBaseTextField
          id="debitAccountNumber"
          type="text"
          label={t(
            "erps.billermanagement.label.accountNumber",
            "Account number"
          )}
          InputLabelProps={{ shrink: true }}
          className="common__textField__width"
          size="small"
          onChange={(e) => handleBillerProfile(e, "debit_account_number")}
          value={billerUpdateValue?.debit_account_number}
          error={highlightError?.debit_account_number}
          required={"true"}
        />
      </Stack>
      {((fiNumber == "10" &&
        billerProfile?.billerNonVolatileDto?.agreement_accepted === "A") ||
        fiNumber != 10) && (
        <Box className="manageUsers__page__title">
          <Typography variant="h2">
            {t("erps.billermanagement.label.billerdetails")}
          </Typography>
        </Box>
      )}
      <Box mt={2}>
        <Box display={"flex"}>
          <Stack direction="row" spacing={25}>
            <Box>
              <Typography variant="inherit">
                {t("erps.billermanagement.label.advicetype")}
                <Typography variant="body2">
                  {billerProfile?.billerNonVolatileDto?.advice_type || 0}
                </Typography>
              </Typography>
            </Box>

            {fiNumber != "10" && (
              <ERPSBaseTextField
                id="pricePlaneName"
                InputLabelProps={{
                  shrink:
                    billerUpdateValue?.billing_model_number === undefined ||
                    billerUpdateValue?.billing_model_number === ""
                      ? false
                      : true,
                }}
                type="text"
                label={t("erps.billermanagement.label.priceplanname")}
                className="common__textField__width"
                size="small"
                onChange={(e) => handleBillerProfile(e, "billing_model_number")}
                value={billerUpdateValue?.billing_model_number}
                hasMargin={true}
                select={true}
                items={pricePlanData}
                SelectProps={{ native: true }}
              />
            )}
          </Stack>
          {fiNumber != 10 && (
            <Box ml={12}>
              <Typography variant="inherit">
                {t("erps.billermanagement.label.monthlyservicefee")}
                <Typography variant="body2">
                  {baseFee ||
                    getMonthlyServiceFee(
                      billerUpdateValue?.billing_model_number,
                      billerUpdateValue?.csv_advice_ok
                    ) ||
                    ""}
                </Typography>
              </Typography>
            </Box>
          )}
        </Box>
        {fiNumber != "10" && (
          <Stack direction="row" spacing={8}>
            <ERPSBaseTextField
              id="amountOwing"
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              label={t("erps.billermanagement.label.amountowing")}
              className="common__textField__width"
              size="small"
              onChange={(e) => handleBillerProfile(e, "amount_owing")}
              value={billerUpdateValue?.amount_owing}
              error={highlightError?.amount_owing}
            />
            <ERPSBaseTextField
              id="setUpFee"
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              type="text"
              label={t("erps.billermanagement.label.setupfee")}
              className="common__textField__width"
              size="small"
              onChange={(e) => handleBillerProfile(e, "setup_fee")}
              value={billerUpdateValue?.setup_fee}
              error={highlightError?.setup_fee}
            />
          </Stack>
        )}
      </Box>
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.billerstatus")}
        </Typography>
      </Box>
      <Stack direction="row">
        <Box mt={3}>
          <Typography variant="inherit">
            {t("erps.billermanagement.label.billerstatus")}
            <Typography variant="body2">
              {billerProfile?.billerStatus === "A"
                ? t("erps.billermanagement.label.active")
                : t("erps.billermanagement.label.inactive")}
            </Typography>
          </Typography>
        </Box>
        <Box ml={25}>
          <ERPSBaseRadioGroup
            name="csvAdviceOk"
            marginTop="1rem"
            value={billerUpdateValue?.csv_advice_ok || "N"}
            label={t("erps.billerregistration.label.documentFormat")}
            onChange={(e) => handleBillerProfile(e, "csv_advice_ok")}
            items={documentFormatItems}
          />
        </Box>
      </Stack>
      <Stack mt={2}>
        <ERPSBaseCheckBoxGrp
          name="email_notification"
          value={
            billerUpdateValue?.email_notification_ok === "Y" ? true : false
          }
          label={t(
            "erps.billerregistration.label.sendNotification",
            "Send email notification"
          )}
          onChange={(e) => handleBillerProfile(e, "email_notification_ok")}
        />
      </Stack>
      <Box mt={2}>
        <Typography variant="body1">
          {t(
            "erps.billerregistration.label.dateAgreementAccepted",
            "Date agreement accepted"
          )}{" "}
          :<br />
        </Typography>
        {billerProfile?.billerNonVolatileDto?.agreement_accepted_date ? (
          <>
            <label className="test-label">
              {updatedDate(
                billerProfile?.billerNonVolatileDto?.agreement_accepted_date
              )}
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <ERPSBaseLink
              className="link__blue"
              target="_blank"
              label={t("erps.billerregistration.label.viewAgreement")}
              to={pdfFile}
            />{" "}
          </>
        ) : (
          <Typography variant="body1">
            {t("erps.billerregistration.label.datenotAgreementAccepted")}
          </Typography>
        )}
      </Box>

      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.common.button.cancel", "Cancel")}
          onClick={handleOpenCancelDialog}
          componentAccess={updatedChildComponentAccess}
          actionType={"hasCancelUpdateWPBillerAccess"}
        />
        <ERPSBaseButton
          variant="primary"
          onClick={updateBillerProfile}
          className="button_with_margin48"
          label={t("erps.newuser.button.save", "Save")}
          loading={saveLoading}
          componentAccess={updatedChildComponentAccess}
          actionType={"hasWPUpdateBillerAccess"}
        />
      </Stack>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </Box>
  );
}

export default EditBillerProfileDetail;
