import React, { useEffect, useState, useRef } from "react";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { Box, Icon } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import useForm from "../custom/useForm";
import warningIcon from "../../assets/images/warning-icon.png";
import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import passwordActionTypes from "../../actions/types/password.action.type";
import ErrorMessage from "../custom/containers/ErrorMessage";
import Tooltip from "@mui/material/Tooltip";
import doneIcon from "../../assets/images/doneIcon.png";
import closeIcon from "../../assets/images/closeIcon.png";

const ChangePassword = (props) => {
  const { t } = useTranslation();
  const errorRef = useRef();

  const { componentAccess, parentComponentAccess } = props;
  const userFromUpdatePassword = useSelector(
    (state) => state.userPasswordReducer?.user
  );
  const userFromManageUser = useSelector(
    (state) => state.changePasswordReducer?.user
  );

  const errorFromUpdatePassword = useSelector(
    (state) => state.userPasswordReducer?.error
  );

  const [customError, setCustomError] = useState({});

  const dispatch = useDispatch();
  let errors;
  let message;
  if (
    props.pageName === "update-password" ||
    props?.pageName === "manage-biller"
  ) {
    errors = userFromUpdatePassword && userFromUpdatePassword.errors;
  } else {
    if (!userFromManageUser?.isSaved && userFromManageUser?.message) {
      errors = userFromManageUser && userFromManageUser.message;
      message = userFromManageUser && userFromManageUser.message;
    } else {
      message = userFromManageUser && userFromManageUser.message;
      errors = userFromManageUser && userFromManageUser.errors;
    }
  }

  useEffect(() => {
    if (props.pageName === "update-password" && errors) {
      dispatch({
        type: passwordActionTypes.USER_PWD_UPDATE_ERROR,
        error: null,
      });
    }
  }, []);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [message, errors, customError, errorFromUpdatePassword]);

  const {
    values,
    showPwdIconPWD,
    showPwdIconNEWPWD,
    showPwdIconConfirmPWD,
    handleChange,
    handleSubmit,
    navigationToMangerUserPage,
    handleClickShowPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmPassword,
    handleMouseDownPassword,
    loginPending,
  } = useForm(changePassword, {
    ...props,
    errors,
    componentAccess,
    parentComponentAccess,
    t,
    setCustomError,
  });

  const changePasswordAccess = componentAccess.find(
    ({ componentType }) => componentType === "CPTP"
  );

  function changePassword() {
    console.log(" Change Password Form successfully submit");
  }
  const getDialogContent = () => {
    return (
      <Typography gutterBottom aria-describedby="dialog-message">
        {t(
          "erps.popup.body.content",
          "You have unsaved changes. If you continue without saving, your changes will be lost."
        )}
      </Typography>
    );
  };

  let errorLists = [
    {
      errorType: "newpwdMinLengthValidation",
      errorMessage: "erps.userdetails.password.form.instruction.msg1",
    },
    {
      errorType: "newpwdSpCharValidation",
      errorMessage: "erps.userdetails.password.form.instruction.msg2",
    },
    {
      errorType: "newpwdUpCaseValidation",
      errorMessage: "erps.userdetails.password.form.instruction.msg3",
    },
    {
      errorType: "newpwdLoCaseValidation",
      errorMessage: "erps.userdetails.password.form.instruction.msg4",
    },
    {
      errorType: "newpwdDigitValidation",
      errorMessage: "erps.userdetails.password.form.instruction.msg5",
    },
    {
      errorType: "notMatchPrevious12Months",
      errorMessage: "erps.userdetails.password.form.instruction.msg6",
    },
  ];

  return (
    <Box>
      <Box>
        {props.subTitle && (
          <Typography variant="h2">
            {t("erps.userdetails.tab.changepassword.title", "Change password")}
          </Typography>
        )}
        {message && !userFromManageUser?.isSaved ? (
          <Box
            className="manageUsers__errorMsg__container"
            role="alert"
            mt={message ? 2 : 0}
          >
            {message && <ErrorMessage error={message} errorRef={errorRef} />}
          </Box>
        ) : (
          ""
        )}

        {errorFromUpdatePassword &&
        typeof errorFromUpdatePassword === "string" ? (
          <Box
            className="manageUsers__errorMsg__container"
            role="alert"
            mt={errorFromUpdatePassword ? 2 : 0}
          >
            {errorFromUpdatePassword && (
              <ErrorMessage
                error={errorFromUpdatePassword}
                errorRef={errorRef}
              />
            )}
          </Box>
        ) : (
          ""
        )}
        <Box>
          <p>
            {t(
              "erps.userdetails.tab.changepassword.subtitle",
              "Complete the fields below to update your password. All fields are required."
            )}
          </p>
        </Box>
      </Box>

      <Box component="form" autoComplete="off">
        <Stack direction="column">
          {props.pageName == "update-password" && (
            <ERPSBaseTextField
              id="currentpwd"
              type={showPwdIconPWD ? "text" : "password"}
              className="three_column__textField"
              label={t(
                "erps.userdetails.password.form.currentpwd",
                "Current Password"
              )}
              required="true"
              error={
                errors
                  ? errors.isCurrentPasswordError
                  : customError?.isCurrentPasswordError || false
              }
              value={values.currentpwd || ""}
              onChange={handleChange}
              size="small"
              actionType="hasCurrentPasswordTextField"
              componentAccess={changePasswordAccess?.childComponents}
              inputProps={{
                endAdornment: (
                  <Tooltip
                    title={
                      showPwdIconPWD
                        ? t("erps.common.label.showPassword")
                        : t("erps.common.label.hidePassword")
                    }
                  >
                    <InputAdornment position="end">
                      <IconButton
                        aria-label={
                          showPwdIconPWD
                            ? t("erps.common.label.showPassword")
                            : t("erps.common.label.hidePassword")
                        }
                        aria-pressed={showPwdIconPWD ? "true" : "false"}
                        onClick={handleClickShowPassword}
                        onMouseDown={handleMouseDownPassword}
                        edge="end"
                      >
                        {showPwdIconPWD ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  </Tooltip>
                ),
              }}
            />
          )}

          {props.pageName == "update-password" &&
            (errors?.isCurrentPasswordError ||
              customError?.isCurrentPasswordError) && (
              <Box
                className="manageUsers__errorMsg__container"
                role="alert"
                mt={
                  props.pageName == "update-password" &&
                  (errors?.isCurrentPasswordError ||
                    customError?.isCurrentPasswordError)
                    ? 2
                    : 0
                }
              >
                {props.pageName == "update-password" &&
                  (errors?.isCurrentPasswordError ||
                    customError?.isCurrentPasswordError) && (
                    <ErrorMessage
                      error={
                        customError?.isCurrentPasswordError
                          ? t(
                              "erps.userdetails.currentpassword.notblank.error.msg"
                            )
                          : t(
                              "erps.userdetails.currentpassword.error.msg",
                              "Current password does not match"
                            )
                      }
                      errorRef={errorRef}
                    />
                  )}
              </Box>
            )}

          <ERPSBaseTextField
            id="newpwd"
            type={showPwdIconNEWPWD ? "text" : "password"}
            label={t("erps.userdetails.password.form.newpwd", "New password")}
            className={"three_column__textField"}
            error={
              errors
                ? errors.isNewPasswordError
                : customError?.isNewPasswordError || false
            }
            required="true"
            size="small"
            value={values.newpwd || ""}
            onChange={handleChange}
            actionType="hasNewPasswordTextField"
            componentAccess={changePasswordAccess?.childComponents}
            inputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    showPwdIconNEWPWD
                      ? t("erps.common.label.showPassword")
                      : t("erps.common.label.hidePassword")
                  }
                >
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPwdIconNEWPWD
                          ? t("erps.common.label.showPassword")
                          : t("erps.common.label.hidePassword")
                      }
                      aria-pressed={showPwdIconNEWPWD ? "true" : "false"}
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPwdIconNEWPWD ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          {customError?.isNewPasswordError ? (
            <Box
              className="manageUsers__errorMsg__container"
              role={"alert"}
              mt={customError?.isNewPasswordError ? 2 : 0}
            >
              {" "}
              {customError?.isNewPasswordError && (
                <ErrorMessage
                  error={t("erps.userdetails.newpassword.notblank.error.msg")}
                  errorRef={errorRef}
                />
              )}
            </Box>
          ) : (
            ""
          )}
          <Box lg={4} sm={6} className="changepassword__instructions">
            <Box mt={1.5} ml={2}>
              <Typography variant="label">
                {t(
                  "erps.userdetails.password.form.instruction.header",
                  "Your new Password must"
                )}{" "}
              </Typography>
            </Box>
            <Box ml={errors?.isValidPassword === undefined ? 0 : -3}>
              <ul
                style={{
                  listStyleType:
                    errors?.isValidPassword === undefined ? "disc" : "none",
                }}
              >
                {errorLists.map(({ errorType, errorMessage }) => (
                  <li style={{ marginBottom: "8px" }}>
                    {errors?.isValidPassword !== undefined ? (
                      <div style={{ display: "flex" }}>
                        {errors?.[errorType] ? (
                          <Icon
                            aria-hidden={false}
                            className="password_criteria__icon"
                          >
                            <img
                              src={doneIcon}
                              className="password_criteria__success"
                              alt="New password criteria is met"
                            />
                          </Icon>
                        ) : (
                          <Icon
                            aria-hidden={false}
                            className="password_criteria__icon"
                          >
                            <img
                              src={closeIcon}
                              className="password_criteria__error"
                              alt="New password criteria is not met"
                            />
                          </Icon>
                        )}
                        <Typography
                          variant="label"
                          style={{ marginLeft: "8px" }}
                        >
                          {t(errorMessage)}
                        </Typography>
                      </div>
                    ) : (
                      <Typography variant="label">{t(errorMessage)}</Typography>
                    )}
                  </li>
                ))}
              </ul>
            </Box>
          </Box>

          <ERPSBaseTextField
            id="confirmnewpwd"
            type={showPwdIconConfirmPWD ? "text" : "password"}
            label={t(
              "erps.userdetails.password.form.confirmnewpwd",
              "Confirm new password"
            )}
            className={"three_column__textField"}
            error={
              errors
                ? errors.isConfirmPasswordError
                : customError?.isConfirmPasswordError || false
            }
            required="true"
            size="small"
            value={values.confirmnewpwd || ""}
            onChange={handleChange}
            actionType="hasConfirmPasswordTextField"
            componentAccess={changePasswordAccess?.childComponents}
            inputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    showPwdIconConfirmPWD
                      ? t("erps.common.label.showPassword")
                      : t("erps.common.label.hidePassword")
                  }
                >
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPwdIconConfirmPWD
                          ? t("erps.common.label.showPassword")
                          : t("erps.common.label.hidePassword")
                      }
                      aria-pressed={showPwdIconConfirmPWD ? "true" : "false"}
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPwdIconConfirmPWD ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          {errors?.isConfirmPasswordError ||
          customError?.isConfirmPasswordError ? (
            <Box
              className="manageUsers__errorMsg__container"
              role={"alert"}
              mt={
                errors?.isConfirmPasswordError ||
                customError?.isConfirmPasswordError
                  ? 2
                  : 0
              }
            >
              {" "}
              {(errors?.isConfirmPasswordError ||
                customError?.isConfirmPasswordError) && (
                <ErrorMessage
                  error={
                    customError?.isConfirmPasswordError
                      ? t("erps.userdetails.confirmpassword.notblank.error.msg")
                      : t(
                          "erps.userdetails.confirmpassword.error.msg",
                          "Confirm password does not match"
                        )
                  }
                  errorRef={errorRef}
                />
              )}
            </Box>
          ) : (
            ""
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            onClick={navigationToMangerUserPage}
            label={t("erps.common.button.reset", "Reset")}
            actionType="hasChangePasswordCancelBtn"
            componentAccess={changePasswordAccess?.childComponents}
          />
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            onClick={handleSubmit}
            label={t("erps.newuser.button.save", "Save")}
            loading={loginPending}
            actionType="hasChangePasswordAccess"
            componentAccess={changePasswordAccess?.childComponents}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default ChangePassword;
