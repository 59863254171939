import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import { convertDateIntoERPSDateFormat, formatAmount, getFormatDetails, statisticsFormatDate } from "../../utils/helper";
import { transactionStatusMap }  from "../../constants/sharedConstants.js";
import dndEn from "../../assets/images/dndEn.png";

const ERPSTransactionDetails = forwardRef((props, ref) => {
  const {
    data,
    recallReason,
    recalledBy,
    rejectReason,
    rejectedBy
  } = props;
  const { t } = useTranslation();

  const displayPaymentTime = (paymentTime) => {
    if (paymentTime || paymentTime === 0) {
        const parsedPaymentTime = paymentTime.toString();
// WHY PADDING TO 8 ???
        const updatedPaymentTime = parsedPaymentTime.padStart(8, "0");
        return updatedPaymentTime.replace(/(..)(?!$)/g, "$1:");

// MAYBE REPLACE WITH THIS ??
        // const updatedPaymentTime = paymentTime.toString().padStart(6, "0");
        // return updatedPaymentTime.replace(/(\d\d)(\d\d)(\d\d)/g, "$1:$2:$3");
      }
  };

  const isRecall = (recallReason || recalledBy) ? true : false;
  const currentDate = new Date();
  const formattedDate = `${statisticsFormatDate(currentDate)},
                         ${currentDate.toLocaleTimeString(navigator.language, { hour: "2-digit", minute: "2-digit" })}`;

  return (
    <div ref={ref}>
      <Box className="manageUsers__page__title forPrint" sx={{ ml: 3, mb: 3 }}>
          <Typography variant="label">{formattedDate}</Typography>
          <br />
          <Typography variant="h2">
            {t("erps.app.title.message")}
          </Typography>
      </Box>
      <Box className="manageUsers__page__title forScreen" sx={{ ml: 3, mb:3 }}>
        {/* For Title update */}
        <CommonContainerBox title={t("erps.transactionEnquiry.page.transactionDetails.page.heading")}/>
        <Typography variant="h1">{t("erps.transactionEnquiry.page.transactionDetails.page.heading")}</Typography>
      </Box>

      <Box id={"screenshot-element"} sx={{ ml: 3 }}>
        <Box sx={{ mb: 1 }}>
          <Typography variant="h3">
            {t("erps.transactionEnquiry.page.transactionDetails.page.subheading")}
          </Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="transaction__enquiry__value">
            {t("erps.common.label.effectiveDate")}
            <Typography variant="body2">
              { convertDateIntoERPSDateFormat(data?.effective_date) }
            </Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.paymentDate/Time")}
            <Typography variant="body2">
              { convertDateIntoERPSDateFormat(data?.payment_date) + " " + displayPaymentTime(data?.payment_time)?.substring(0, 8) }
            </Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.deliveryDate")}
            <Typography variant="body2">
              { convertDateIntoERPSDateFormat(data?.delivery_date) }
            </Typography>
          </Typography>
        </Box>

        <Box sx={{ mt: 2, display: "flex" }}>
          <Typography variant="label" className="transaction__enquiry__value">
            {t("erps.common.label.paymentAmount")}
            <Typography variant="body2">{formatAmount(data?.transaction_amount)}</Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.confirmationNumber")}
            <Typography variant="body2">{data?.verification_number}</Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.auditNumber")}
            <Typography variant="body2">{data?.biller_file_number}</Typography>
          </Typography>
        </Box>

        <Box sx={{ mt: 2, display: "flex" }}>
          <Typography variant="label" className="transaction__enquiry__value">
            {t("erps.common.label.transactionOrigination")}
            <Typography variant="body2">{`${ data?.cap_type || ""} ${ data?.caption_type_description || "" }`}</Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.status")}
            <Typography variant="body2">{ t(transactionStatusMap.get(data?.transaction_status), data?.transaction_status)}</Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">{""}</Typography> {/* 3rd field needed for proper alignment when printing */}
        </Box>

        <Box sx={{ mt: 4, mb: 1 }}>
          <Typography variant="h3">
            {t("erps.common.label.billerDetails")}
          </Typography>
        </Box>

        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="transaction__enquiry__value">
            {t("erps.common.label.corporateBiller")}
            <Typography variant="body2">
              {data?.biller_name} <br />
              {t("erps.common.label.attention")} {data?.primary_contact_name}{" "}
              <br />
              {t("erps.common.label.telephone")}{" "}
              {getFormatDetails(data?.primary_contact_phone)} <br />
              {t("erps.common.label.fax")}{" "}
              {getFormatDetails(data?.primary_contact_fax)} <br />
            </Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.billerID")}
            <Typography variant="body2">{data?.fi_biller_id || data?.display_biller_id}</Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">{""}</Typography> {/* 3rd field needed for proper alignment when printing */}
        </Box>

        <Box sx={{ mt: 4, mb: 1 }}>
          <Typography variant="h3">{t("erps.common.label.customerDetails")}</Typography>
        </Box>
        <Box sx={{ display: "flex" }}>
          <Typography variant="label" className="transaction__enquiry__value">
            {t("erps.common.label.customerAccountNumber")}
            <Typography variant="body2">{data?.biller_customer_number}</Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
            {t("erps.common.label.customerName")}
            <Typography variant="body2">{data?.fi_customer_name}</Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">{""}</Typography> {/* 3rd field needed for proper alignment when printing */}
        </Box>
      </Box>

      {(recallReason || recalledBy || rejectReason || rejectedBy) ? (
        // It is assumed that either the pair recallReason/recalledBy, or the pair rejectReason/rejectedBy will be passed, but not both
        <Box sx={{ ml: 3, mt: 3, display: "flex" }}>
          <Typography variant="label" className="transaction__enquiry__value">
              {t(isRecall ? "erps.transactionEnquiry.label.recall.reason" : "erps.rejectpaymentmerchants.page.label.reject.reason")}
              <Typography variant="body2">{isRecall ? recallReason : rejectReason}</Typography>
          </Typography>
          <Typography variant="label" className="transaction__enquiry__value left_space">
              {t(isRecall ? "erps.transactionEnquiry.label.recalled.by" : "erps.rejectpaymentmerchants.page.label.rejected.by")}
              <Typography variant="body2">{isRecall ? recalledBy : rejectedBy}</Typography>
          </Typography>
          <Typography className="transaction__enquiry__value left_space">{""}</Typography> {/* 3rd field needed for proper alignment when printing */}
        </Box>
      ) : ("")
      }

      <Box className="forPrint" sx={{ ml: 3, mr: "20px", mb: "10px", position: "fixed", bottom: "0", width: "95%" }}>
        <hr style={{ height: "1px", border: "none", color: "rgba(224, 224, 224, 1)", backgroundColor: "rgba(224, 224, 224, 1)", paddingRight: "20px" }}/>
        <Typography sx={{ width: "180px" }} variant="p">
          <Box display={"flex"}>
            <Box>
              <p style={{ fontSize: "8px", marginBottom: "-12px", color: "#6D1652", paddingLeft: "10px" }}>
                powered by
              </p>
              <img src={dndEn} alt={"Dye and Durham"} height="60" />
            </Box>
            <Box sx={{ marginLeft: "auto" }} mt={3}>
              <Typography variant="label">{window.location.href}</Typography>
            </Box>
          </Box>
        </Typography>
      </Box>
    </div>
  );
});

export default ERPSTransactionDetails;
