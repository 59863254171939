

export const onSuccess = (data, type, props) => {
    processResponse(true, props);
    return { type, data };
}

export const onError = (errorMessage, type, props) => {
    processResponse(false, props, errorMessage);
    return { type, error: errorMessage };
}

const processResponse = (isSuccess, props, errorMessage = null) => {
    if (props?.setLoading)
        props.setLoading(false);

    if (props?.setBtnLoading)
        props.setBtnLoading(false);

    if (props?.setShowSuccessMessage)
        props.setShowSuccessMessage(isSuccess);

    if (props?.setErrorMessage)
        props.setErrorMessage(isSuccess ? null : errorMessage);
}
