import { t } from "i18next";
import { Link } from "@material-ui/core";

export const getAvailableReportsHeading = (fileDownload, roleId) => {
  if (roleId == 1 || roleId == 2) {
    return [
      {
        field: "file_type",
        headerName: t("erps.downloadreports.label.reportname"),
        width: 340,
        sortable: false,
      },
      {
        field: "file_date",
        headerName: t("erps.downloadreports.label.reportdate"),
        width: 160,
        sortable: false,
        renderCell: (params) => {
          if (params.value) {
            return params.value?.split("T")[0];
          }
        },
      },
      {
        field: "pdf_link",
        headerName: t("erps.downloadreports.label.download"),
        width: 110,
        sortable: false,
        renderCell: (params) => {
          const regExp = /(webportal|document|reports|LegacyReports)/i;
          const pdfLink = params.row.pdf_link;
          const csvLink = params.row.csv_link;
          let pdfWithReportPath = (pdfLink && pdfLink.match(regExp) !== null);
          let csvWithReportPath = (csvLink && csvLink.match(regExp) !== null);

          if (pdfLink || csvLink) {
            return (
              <>
                {pdfWithReportPath && (
                  <Link underline="always" component="button"
                        onClick={() => fileDownload(params.row, "PDF")} >
                    {t("erps.downloadreports.label.pdf")}
                  </Link>
                )}
                {(pdfWithReportPath && csvWithReportPath) && (<>&nbsp;&nbsp;</>)}
                {csvWithReportPath && (
                  <Link underline="always" component="button"
                        onClick={() => fileDownload(params.row, "CSV")} >
                    {t("erps.downloadreports.label.csv")}
                  </Link>
                )}
              </>
            );
          }
        },
      },
    ];
  } else {
    return [
      {
        field: "reportDescription",
        headerName: t("erps.downloadreports.label.reportname"),
        width: 340,
        sortable: false,
      },
      {
        field: "reportDate",
        headerName: t("erps.downloadreports.label.reportdate"),
        width: 160,
        sortable: false,
        renderCell: (params) => {
          if (params.value) {
            return params.value?.split("T")[0];
          }
        },
      },
      {
        field: "reportPath",
        headerName: t("erps.downloadreports.label.download"),
        width: 110,
        sortable: false,
        renderCell: (params) => {
          const regExp = /(reports|LegacyReports)/i;
          let reportPath = params?.row?.reportPath;
          let reportPathCsv = params?.row?.reportPathCsv;
          let hasReportPath = (reportPath && reportPath.match(regExp) !== null);
          let hasReportPathCsv = (reportPathCsv && reportPathCsv.match(regExp) !== null);
          return (
            <>
              {hasReportPath && (
                <Link underline="always" component="button"
                      onClick={() => fileDownload(params.row, "PDF")} >
                  {t("erps.downloadreports.label.pdf")}
                </Link>
              )}
              {(pdfWithReportPath && csvWithReportPath) && (<>&nbsp;&nbsp;</>)}
              {hasReportPathCsv && (
                <Link underline="always" component="button"
                      onClick={() => fileDownload(params.row, "TXT")} >
                  {t("erps.downloadreports.label.txt")}
                </Link>
              )}
            </>
          );
        },
      },
    ];
  }
};

export const EMPTY_DOWNLOAD_REPORT_CRITERIA = {
  startDate: "",
  endDate: "",
  reportId: "",
  reportName: "",
  startOffset: 0,
  endOffset: 25,
};

export const MOCK_DOWNLOAD_REPORT_DATA = [
  {
    id: 0,
    reportName: "Monthly billing volume file",
    reportDate: "2020-10-01",
    download: "CSV",
  },
  {
    id: 1,
    reportName: "Monthly billing volume file",
    reportDate: "2020-09-31",
    download: "CSV",
  },
  {
    id: 2,
    reportName: "Monthly billing volume file",
    reportDate: "2020-09-31",
    download: "CSV",
  },
  {
    id: 3,
    reportName: "Monthly billing volume file",
    reportDate: "2020-09-30",
    download: "CSV",
  },
];
