import React, { useState, useEffect, useRef } from "react";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";

const GeneratePassword = (props) => {
  const { t } = useTranslation();
  const errorRef = useRef();

  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const errorMessage = useSelector(
    (state) => state.forgetPasswordReducer.data?.errorMessage
  );
  const exceptionErrorMessage = useSelector(
    (state) => state.forgetPasswordReducer.error
  );

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage, exceptionErrorMessage]);
  const handleSubmit = () => {
    setLoading(true);
    const req = {
      language: loginUser?.languageCode,
      fiNumber: props.userData?.fiNumber,
      userId: props.userData?.userId,
      emailAddress: props.userData?.emailAddress,
      loggedInUserId: loginUser.userId,
      changePasswordIndex: "Y",
      roleId: props.userData?.roleId,
    };

    dispatch(
      actions.passwordActions.generatedAutoPasswordAndSendEmailForLoggedInUser({
        ...req,
        setLoading,
        setShowMessage: props.setShowMessage,
        t,
      })
    );
  };

  const navigationToMangerUserPage = (event) => {
    event.preventDefault();
    if (props.pageName === "manage-users") {
      props.history.push("/manage-users", {
        componentAccess: props.parentComponentAccess,
      });
    } else if (props?.pageName === "manage-biller" && props?.handleClickLink) {
      props?.handleClickLink();
    }
  };

  return (
    <Box>
      {errorMessage || exceptionErrorMessage ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={errorMessage ? 2 : 0}
        >
          {(errorMessage || exceptionErrorMessage) && (
            <ErrorMessage
              error={errorMessage || exceptionErrorMessage}
              errorRef={errorRef}
            />
          )}
        </Box>
      ) : (
        ""
      )}
      <Box mt={2}>
        <Typography variant="h2">
          {t("erps.manageusers.generatepassword.title", "Generate a password")}
        </Typography>
      </Box>
      <Box>
        <p>
          {t(
            "erps.manageusers.generatepassword.content",
            "A password meeting the security requirements will be generated and emailed to the user."
          )}
        </p>
      </Box>
      <Box component="form" autoComplete="off">
        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            onClick={navigationToMangerUserPage}
            label={t("erps.common.button.cancel", "Cancel")}
          />
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            onClick={handleSubmit}
            label={t(
              "erps.manageusers.generatepassword.emailbutton",
              "Email new password"
            )}
            loading={loading}
          />
        </Stack>
      </Box>
    </Box>
  );
};

export default GeneratePassword;
