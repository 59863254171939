import React, { useState, useEffect, useRef } from "react";
import {
  getBudgetManagementGridHeading,
  EMPTY_BUDGET_CRITERIA,
} from "../../constants/BudgetManagementConstant";
import { withSidebar } from "../hoc/withSidebar";
import warningIcon from "../../assets/images/warning-icon.png";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import CommonLayout from "../custom/containers/CommonLayout";
import { useCustomHook } from "../custom/useCustomHook";
import { useTranslation } from "react-i18next";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import budgetCategoryActionType from "../../actions/types/budgetCategory.action.type";

const BudgetManagement = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  const {
    history,
    location: {
      pathname,
      state: { componentAccess, ...restProps },
    },
  } = props;
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [budgetDetails, setBudgetDetails] = useState(EMPTY_BUDGET_CRITERIA);
  const [initialLoading, setInitialLoading] = useState(false);

  const budgetResult = useSelector(
    (state) => state.budgetCategoryReducer.budget
  );

  const budgetCategoryUpdated = useSelector(
    (state) => state.budgetCategoryReducer?.budgetCategoryUpdated
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const [budgetData, setBudgetData] = useState(null);
  useEffect(() => {
    if (!restProps?.fromDetailPage) {
      dispatch(
        actions.budgetCategoryActions.getBudgetCategory(0, "", "", 25, 0, t, 25)
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (budgetResult) {
      setBudgetData(budgetResult.data);
    }
    if (budgetResult?.budgetDetails) {
      const { startOffset, endOffset } = budgetResult?.budgetDetails;
      const rowPerPage = endOffset - startOffset;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      setBudgetDetails(budgetResult?.budgetDetails);
    }
  }, [budgetResult]);

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [budgetCategoryUpdated?.status && budgetCategoryUpdated?.message]);

  const pageName = pathname.split("/")[1];

  const {
    handleBudgetCategorySearch,
    searchCriteriaChangeHandler,
    isSearchValid,
    searchResults,
    searchCriteria,
    paginateBudgetCategories,
    resetBudgetCategoryHandler,
  } = useCustomHook({
    tableResults: budgetData,
    tableHeadings: getBudgetManagementGridHeading(
      loginUser.role.role_type,
      componentAccess
    ),
    initialStates: EMPTY_BUDGET_CRITERIA,
    setManageUserState: setBudgetDetails,
    pageName: "manageBudget",
    userDetails: budgetDetails,
    setInitialLoading,
  });

  const navigateToNewUserComponent = () => {
    history.push(pathname + "/new-budget-category", { componentAccess });
  };

  const roleType = loginUser?.role?.role_type;

  const hasButtonVisible =
    (roleType === "CE" || roleType === "BA") &&
    pageName === "manage-budget-category";
  const handleClose = () => {
    dispatch({
      type: budgetCategoryActionType.DELETE_BUDGET_CATEGORY_SUCCESS,
      data: null,
      budget: budgetResult,
    });
  };
  return (
    <>
      {budgetCategoryUpdated?.status && budgetCategoryUpdated?.message && (
        <ConfirmationMessage
          message={budgetCategoryUpdated?.message}
          handleClose={handleClose}
          inputRef={successRef}
        />
      )}

      <CommonLayout
        pageTitle={t("erps.budgetmanagement.page.title")}
        pageDescription={
          roleType === "PA" || roleType === "WA"
            ? t("erps.budgetmanagement.page.full.description")
            : t("erps.budgetmanagement.page.description")
        }
        primaryButtonLabel={t("erps.budgetmanagement.button.newbudgetcategory")}
        pageSubHeading={t("erps.budgetmanagement.filterbudgetcategory.heading")}
        pageSubHeadingDescription={t(
          "erps.budgetmanagement.filterbudgetcategory.subheading"
        )}
        errorMssg={t(
          "erps.budgetmanagement.filterbudgetcategory.error.emptyinput"
        )}
        primaryButtonLabelV2={t("erps.common.button.reset")}
        secondaryButtonLabel={t("erps.common.button.filter")}
        searchResultLabel={t(
          "erps.budgetmanagement.budgetcategorycodes.budgetcategorycodesresults"
        )}
        searchResultFoundLabel={t(
          "erps.budgetmanagement.budgetcategorycodes.recordsfound"
        )}
        firstTextInputFieldLabel={t("erps.budgetmanagement.label.budgetcode")}
        firstTextInputFieldId="budgetCode"
        secondTextInputFieldLabel={t("erps.budgetmanagement.label.description")}
        secondTextInputFieldId="description"
        startSearchHandler={handleBudgetCategorySearch}
        searchCriteriaChangeHandler={searchCriteriaChangeHandler}
        resetHandler={resetBudgetCategoryHandler}
        navigateToNewUserComponent={navigateToNewUserComponent}
        isSearchValid={isSearchValid}
        warningIcon={warningIcon}
        searchCriteria={budgetDetails}
        initiateSearch={paginateBudgetCategories}
        searchNotStarted={t("erps.budgetmanagement.searchuser.notstarted")}
        pageNumber={pageNumber}
        rowPerPage={rowPerPage}
        searchResults={
          searchResults || {
            rows: budgetData ? budgetData.data : [],
            count: budgetData && budgetData.count,
            columns: getBudgetManagementGridHeading(
              loginUser.role.role_type,
              componentAccess
            ),
          }
        }
        //budgetData = {budgetData}
        labelLinkValue={["budgetCode"]}
        pathName={"/manage-budget-category/budget-category-details"}
        isRequired="false"
        history={history}
        functionType="MBC"
        componentAccess={componentAccess}
        actionType={{
          primaryButtonActionTypeV1: "hasNewBudgetCategoryBtn",
          firstTextInputFieldActionType: "hasBudgetCodeTextField",
          secondTextInputFieldActionType: "hasBudgetDesciptionTextField",
          primaryButtonActionTypeV2: "hasResetBtn",
          secondaryButtonActionType: "hasSearchBudgetCategoryAccess",
        }}
        isDisabled={hasButtonVisible}
        dataGridWidth="dataGrid__box__container__manageBudgetCategory"
        initialLoading={initialLoading}
      />
    </>
  );
};

export default withSidebar(BudgetManagement);
