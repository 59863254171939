import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import { PRICE_PLAN_UPDATE_HEADINGS } from "../../constants/PricePlanPendingApprovalConstant";
import ERPSGrid from "../custom/containers/ERPSGrid";
import ErrorMessage from "../custom/containers/ErrorMessage";
import {
  getNextFilterId,
  requiredFormatDate,
  getLabelName,
} from "../../utils/helper";

export default function UpdatePricePlanApproval(props) {
  const { t } = useTranslation();
  const {
    data,
    existingDataList,
    setShowSuccessMessage,
    componentAccess,
    pricePlanPendingApprovalData,
    userId,
  } = props;
  const dispatch = useDispatch();
  const pricePlanActionError = useSelector(
    (state) => state.pricePlanQueueReducer?.pricePlanActionError
  );
  const errorRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [pricePlanActionError]);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [lastRecordDisable, setLastRecordDisable] = useState(false);
  const [viewUpdatedAttribute, setViewUpdatedAttribute] = useState("");

  function camelToPascalCase(key) {
    const result = key.replace(/([A-Z])/g, (val) => ` ${val.toLowerCase()}`);
    return result.charAt(0).toUpperCase() + result.slice(1);
  }

  const hasLastActiveRecord = () => {
    if (
      pricePlanPendingApprovalData &&
      pricePlanPendingApprovalData.length === 1
    ) {
      return true;
    }
    return false;
  };

  function addZeroes(num) {
    if (num) {
      const dec = num.split(".")[1];
      const len = dec && dec.length > 2 ? dec.length : 2;
      return Number(num).toFixed(len);
    }
  }

  const getAccontSettingData = () => {
    let obj = [];
    const allowedKeys = [
      "pricePlanId",
      "pricePlanName",
      "baseFee",
      "csvFee",
      "notificationFee",
      "perDocumentRate",
      "minimumDocumentRate",
      "maximumDocumentRate",
    ];
    for (let key in data) {
      if (allowedKeys.includes(key)) {
        const updatedKeyValue = camelToPascalCase(key);
        if (data[key] !== existingDataList[key]) {
          obj.push({
            id: key,
            attribute: updatedKeyValue,
            currentValue:
              key !== "pricePlanName" && key != "pricePlanId"
                ? addZeroes(existingDataList[key]?.toString())
                : existingDataList[key],
            updatedValue:
              key !== "pricePlanName" && key != "pricePlanId"
                ? addZeroes(data[key].toString())
                : data[key],
          });
        } else {
          obj.push({
            id: key,
            attribute: updatedKeyValue,
            currentValue:
              key !== "pricePlanName" && key != "pricePlanId"
                ? addZeroes(existingDataList[key]?.toString())
                : existingDataList[key],
            updatedValue: "",
          });
        }
      }
    }
    const maximumDocumentRateData = obj.find(
      ({ id }) => id === "maximumDocumentRate"
    );
    const minimumDocumentRateData = obj.find(
      ({ id }) => id === "minimumDocumentRate"
    );
    let updatedObjData = obj.filter(
      ({ id }) => id !== "minimumDocumentRate" && id !== "maximumDocumentRate"
    );
    updatedObjData.push(minimumDocumentRateData);
    updatedObjData.push(maximumDocumentRateData);
    return updatedObjData;
  };

  const navigateToPricePlanPendingApporvalPage = () => {
    props.history.push("/price-plan-pending-approvals", { componentAccess });
  };

  const pricePendingApprovalChildAccess = componentAccess?.find(
    ({ componentType }) => componentType === "PPAFV"
  );

  const pricePendingApprovalChildComponent =
    pricePendingApprovalChildAccess?.childComponents;

  const handleDecline = (event) => {
    setDeclineLoading(true);
    if (event) event.preventDefault();

    const declinedData = {
      billingModelNumber: data?.pricePlanId,
      billingModelDescription: data?.pricePlanName,
      ...data,
    };

    const filteredQueueDataList = pricePlanPendingApprovalData.filter(
      ({ billingModelNumber: bmn }) => data?.pricePlanId != bmn
    );

    const currentIndexVal = pricePlanPendingApprovalData.findIndex(
      ({ billingModelNumber: bmn }) => data?.pricePlanId == bmn
    );
    const slicedArr = pricePlanPendingApprovalData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();

    const nextPricePlanId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      userId,
      "billingModelNumber"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    dispatch(
      actions.pricePlanPendingApprovalsActions.declinePricePlanApprovalData(
        declinedData,
        {
          ...props,
          t,
          setUpdateLoading,
          setShowSuccessMessage,
          setDeclineLoading,
          nextPricePlanId,
          queueDataList:
            nextPricePlanId === ""
              ? pricePlanPendingApprovalData
              : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
          t,
        }
      )
    );
  };

  const handleApprove = (event) => {
    setUpdateLoading(true);
    if (event) event.preventDefault();

    const updatedData = {
      billingModelNumber: data?.pricePlanId,
      billingModelDescription: data?.pricePlanName,
      ...data,
    };
    const filteredQueueDataList = pricePlanPendingApprovalData.filter(
      ({ billingModelNumber: bmn }) => data?.pricePlanId != bmn
    );

    const currentIndexVal = pricePlanPendingApprovalData.findIndex(
      ({ billingModelNumber: bmn }) => data?.pricePlanId == bmn
    );
    const slicedArr = pricePlanPendingApprovalData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();
    const nextPricePlanId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      userId,
      "billingModelNumber"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    dispatch(
      actions.pricePlanPendingApprovalsActions.updatePricePlanApprovalData(
        updatedData,
        {
          ...props,
          t,
          setUpdateLoading,
          setShowSuccessMessage,
          pricePlanPendingApprovalData,
          nextPricePlanId,
          queueDataList:
            nextPricePlanId === ""
              ? pricePlanPendingApprovalData
              : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
        }
      )
    );
  };

  const accountSettingData = getAccontSettingData();

  return (
    <>
      {pricePlanActionError && (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={pricePlanActionError ? 2 : 0}
        >
          {pricePlanActionError && (
            <ErrorMessage error={pricePlanActionError} errorRef={errorRef} />
          )}
        </Box>
      )}
      <Grid container>
        <Grid item lg={8} sm={12}>
          <Box className="manageUsers__page__title">
            <Typography variant="h1">{`${t(
              "erps.managePricePlan.approval.approvepriceplan"
            )}: ${data.pricePlanId}`}</Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h2">
              {t("erps.managePricePlan.approval.aprovaldetailsforpdate")}
            </Typography>
          </Box>
          <Box mt={3} sx={{ display: "flex" }}>
            <Typography className="available__documents__value">
              {t("erps.managePricePlan.approval.updatedby")}
              <Typography variant="body2">{data.displayUpdatedBy}</Typography>
            </Typography>
            <Typography className="available__documents__value">
              {t("erps.managePricePlan.approval.updatedon")}
              <Typography variant="body2">
                {requiredFormatDate(data.updatedFormattedDate)}
              </Typography>
            </Typography>
            <Typography className="available__documents__value">
              {t("erps.managePricePlan.approval.action")}
              <Typography variant="body2">{data.actionName}</Typography>
            </Typography>
          </Box>
          <Box mt={4}>
            <Typography variant="h2">
              {t("erps.managePricePlan.approval.priceplan")}
            </Typography>
            <Box>
              <p>
                {t("erps.managePricePlan.approval.updatedescription1")}
                <br />
                {t("erps.managePricePlan.approval.updatedescription2")}
              </p>
            </Box>
            <ERPSBaseCheckBoxGrp
              label={"View updated attributes only"}
              value={viewUpdatedAttribute}
              onChange={(e) => setViewUpdatedAttribute(e.target.checked)}
            />
          </Box>
          <Box mt={2}>
            <ERPSGrid
              data={{
                rows: viewUpdatedAttribute
                  ? accountSettingData &&
                    accountSettingData.length &&
                    accountSettingData.filter(
                      ({ updatedValue }) => updatedValue
                    )
                  : accountSettingData || [],
                count: accountSettingData?.length,
                columns: PRICE_PLAN_UPDATE_HEADINGS,
              }}
              dataGridWidth="dataGrid__box__container__pendingApproval__updatePricePlan"
              hideFooterPagination={true}
              getCellClassName={(params) => {
                if (params.field === "currentValue") {
                  return "column_separator_border-right";
                }
              }}
              tablelabelText={t(
                "erps.managePricePlan.approval.approvepriceplan"
              )}
            />
          </Box>

          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={t("erps.common.button.cancel", "Cancel")}
              onClick={() => navigateToPricePlanPendingApporvalPage()}
              componentAccess={pricePendingApprovalChildComponent}
              actionType={"hasPricePlanCancelButton"}
            />
            <ERPSBaseButton
              variant="secondary"
              onClick={(e) => handleDecline(e)}
              loading={declineLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "decline",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={pricePendingApprovalChildComponent}
              actionType={"hasPricePlanDeclineAccess"}
              isDisabled={lastRecordDisable}
            />
            <ERPSBaseButton
              // actionType="hasAddUser"
              // functionType="NU"
              variant="primary"
              onClick={(e) => handleApprove(e)}
              loading={updateLoading}
              className="button_with_margin48"
              label={getLabelName({
                key: "approve",
                hasLastActiveRecord,
                lastRecordDisable,
                t,
              })}
              componentAccess={pricePendingApprovalChildComponent}
              actionType={"hasPricePlanApprovalAccess"}
              isDisabled={userId == data?.updatedBy || lastRecordDisable}
            />
          </Stack>
        </Grid>
      </Grid>
    </>
  );
}
