import React, { useRef, useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import ErrorMessage from "../custom/containers/ErrorMessage";
import { RoleType }  from "../../constants/sharedConstants.js";
import { recallPendingApprovalsHeading } from "../../constants/RecallPendingApprovalsConstant";


const RecallPendingApprovals = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        history,
        location: { state },
    } = props;

    const [errorMessage, setErrorMessage] = useState(null);
    const user = useSelector((state) => state.loginUserReducer.user);
    const rpaResponse = useSelector(
        (state) => state.transactionEnquiryReducer
    );

    const [transactionData, setTransactionData] = useState(  rpaResponse?.recallPendingApprovalsData || null);

    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(100);
    const [dataCount, setDataCount] = useState(0);

    const [searchValues, setSearchValues] = useState({
        offset: 0,
        limit: rowPerPage,
    });

    const errorRef = useRef();
    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.focus();
        }
    }, [errorMessage]);


    useEffect(() => {
        if (state?.fromDetailPage && rpaResponse?.recallPendingApprovalsDetails) { // if coming back from the Details page, restore search values
            // Above we have already set  searchType and transactionData.
            // The only thing we need to do is update the page number.
    
            // set searchValues' attriutes from rpaResponse.recallPendingApprovalsDetails
            Object.keys(searchValues).filter(key => rpaResponse.recallPendingApprovalsDetails[key])
                .forEach(key => searchValues[key] = rpaResponse.recallPendingApprovalsDetails[key]);
            setDataCount(rpaResponse?.recallPendingApprovalsData?.count || 0);
            setRowPerPage(searchValues.limit);
            setPageNumber(searchValues.offset / searchValues.limit);
            state.fromDetailPage = null;  // resetting the coming-from-detail-page flag
        }
        searchValues.currentFiNumber = user?.fiNumber;
        let roleType = user.role.role_type;
        if (roleType === RoleType.BILLER_ADMIN || roleType === RoleType.BILLER_USER)
            searchValues.billerId = user.billerNonVolatileDto.biller_fi_number
    }, []);

    useEffect(() => {
        if (typeof actions.transactionEnquiryActions === 'undefined') {
            console.log(ts() + " useEffect:  actions.transactionEnquiryActions is undefined");
        } else {
            loadResults("from useEffect[searchType]");
        }
    }, [searchValues]);

    useEffect(() => {
        if (rpaResponse) {
            const responseData = rpaResponse.recallPendingApprovalsData?.data;
            if (responseData?.length) {
                for(let i=0; i < responseData.length; i++)
                    responseData[i].seqNo = i + 1 + searchValues.offset;
            }
            setDataCount(rpaResponse.recallPendingApprovalsData?.count || 0);
            setTransactionData(responseData);
        }
    }, [rpaResponse]);

    const paginate = (values) => {
        searchValues.offset = values.startOffset;
        if (searchValues.limit !== (values.endOffset - values.startOffset))
            searchValues.limit = values.endOffset - values.startOffset;

        loadResults();
    };

    let loadResults = (tempText = "") => {
        if (errorMessage)
            setErrorMessage(null);
        setLoading(true);
        dispatch(
            actions.transactionEnquiryActions.searchRecallPendingApprovals(
                searchValues,
                { ...props, t, history, setLoading, setErrorMessage }
            )
        );
    };

    return (
        <div role="main">
            <Box sx={{ ml: 3 }}>
                {!errorMessage ? ("") :
                    (<Box className="manageUsers__errorMsg__container" role="alert" mt={errorMessage ? 2 : 0} >
                        {<ErrorMessage error={errorMessage} errorRef={errorRef} />}
                    </Box>)
                }

                <Box className="manageUsers__page__title">
                    {/* For Title update */}
                    <CommonContainerBox title={t("erps.recallpendingapprovals.page.title")} />
                    <Typography variant="h1">{t("erps.recallpendingapprovals.page.title")}</Typography>
                </Box>

                {transactionData !== null && transactionData !== undefined && Array.isArray(transactionData) && transactionData.length > 0 ? (
                        <>
                            <Box className="manageUsers_table_visible">
                                <ERPSGrid
                                    data={{
                                        rows: transactionData,
                                        columns: recallPendingApprovalsHeading(),
                                        count: dataCount,
                                    }}
                                    onAnyPaginationAction={paginate}
                                    sumOfColumnWidth="true"
                                    pageNumber={pageNumber}
                                    setPage={setPageNumber}
                                    rowPerPage={rowPerPage}
                                    tableLabelText={"_Page Title_"}
                                    hideFooterPagination={false}
                                    pageName={"transaction-enquiry-rpa"}
                                />
                            </Box>
                            <Box className="manageUsers_card_visible">
                                <CardList
                                    data={{
                                        rows: transactionData,
                                        columns: recallPendingApprovalsHeading(),
                                        count: dataCount,
                                    }}
                                    hideFooterPagination={false}
                                    pageName={"recall-pending-approvals"}
                                    pageNumber={pageNumber}
                                    setPage={setPageNumber}
                                    rowPerPage={rowPerPage}
                                />
                            </Box>
                        </>
                    )
                    : (
                        <Box mt={1}>
                            <Typography variant="body1">
                                { (transactionData?.length === 0)
                                    ? (`0 ${t("erps.transactionEnquiry.page.resultfound")}`)
                                    : (t("erps.availabledocuments.searchuser.notstarted", "Search not started"))
                                }
                            </Typography>
                        </Box>
                    )
                }
            </Box>
        </div>
    );
};

export default withSidebar(RecallPendingApprovals);

function ts() {  return new Date().toISOString().substring(11, 23);  }
