import React from "react";
import { BrowserRouter as Switch, Route } from "react-router-dom";
import { withRouter } from "react-router";
import { Box } from "@material-ui/core";
import SignIn from "../components/Logon/SignIn";
import KcLanding from "../components/Logon/KcLanding";
import KcRedirect from "../components/Logon/KcRedirect";
import KcLogoutPage from "../components/Logon/LogoutPage";
import ForgotPassword from "../components/ForgotPassword/ForgotPassword";
import Dashboard from "../components/Todo";
import DashboardPage from "../components/Dashboard/Dashboard";
import ManageUsers from "../components/ManageUsers/ManageUsers";
import AccountSettings from "../components/MyProfile/AccountSettings";
import UpdatePassword from "../components/MyProfile/UpdatePassword";
import NewUser from "../components/User/NewUser";
import AuthRoute from "./AuthRoute";
import UserProfileTab from "../components/User/UserProfileTab";
import BudgetManagement from "../components/BudgetManagement/BudgetManagement";
import NewBudgetCategory from "../components/BudgetManagement/NewBudgetCategory";
import BudgetCategory from "../components/BudgetManagement/BudgetCategory";
import ViewBudgetCategory from "../components/BudgetManagement/ViewBudgetCategory";
import Welcome from "../components/WelcomePage/Welcome";
import WelcomeAccountSettings from "../components/WelcomePage/WelcomeAccountSettings";
import CredentialMatch from "../components/ForgotPassword/CredentialMatch";
import ApprovalUser from "../components/ManageUsers/ApproverUser";
import DownloadDocuments from "../components/AvailableDocuments/DownloadDocuments";
import AvailableDocuments from "../components/AvailableDocuments/AvailableDocuments";
import UserPendingApprovals from "../components/ManageUsers/UserPendingApprovals";
import BillerPendingApprovals from "../components/BillerManagement/BillerPendingApprovals";
import PricePlanPendingApprovals from "../components/ManagePricePlan/PricePlanPendingApproavals";
import ManagePricePlan from "../components/ManagePricePlan/ManagePricePlan";
import NewPricePlan from "../components/ManagePricePlan/NewPricePlan";
import UpdatePricePlanDetails from "../components/ManagePricePlan/PricePlaneDetails";
import AccountRegistration from "../components/MyProfile/AccountRegistration";
import ViewFIManagement from "../components/ViewFIManagement/ViewFIManagement";
import ApprovePricePlan from "../components/ManagePricePlan/ApprovePricePlan";
import BillerManagement from "../components/BillerManagement/BillerManagement";
import UpdateBillerProfileTab from "../components/BillerManagement/UpdateBillerProfileTab";
import NewBillerProfileUser from "../components/BillerManagement/NewBillerUser";
import ApprovalBiller from "../components/BillerManagement/ApprovalsBiller";
import ViewUserProfile from "../components/User/ViewUserProfile";
import ViewPricePlanDetails from "../components/ManagePricePlan/ViewPricePlanDetails";
import MarketManagement from "../components/MarketingManagement/ManageMessageCentre";
import ManageLoginPage from "../components/MarketingManagement/ManageLoginPage";
import TransactionEnquiry from "../components/TransactionEnqiry/TransactionEnquiry";
import StatisticsEnquiry from "../components/StatisticsEnquiry/StatisticsEnquiry";
import ViewStatistics from "../components/StatisticsEnquiry/ViewStatistics";
import TransactionDetails from "../components/TransactionEnqiry/TransactionDetails";
import UpcomingRecallsRejects from "../components/TransactionEnqiry/UpcomingRecallsRejects";
import RecallPendingApprovals from "../components/TransactionEnqiry/RecallPendingApprovals";
import DownloadReports from "../components/Reports/DownloadReports";
import DashboardPendingApproval from "../components/MarketingManagement/DashboardPendingApproval";
import DashboardLoginPendingApproval from "../components/MarketingManagement/DashboardLoginPendingApproval";
import BatchHoliday from "../components/BatchHoliday/BatchHoliday";
import Synchronization from "../components/Synchronization/Synchronization";
import AccessDeniedIp from "../components/Error/accessDeniedIp";
import FailedFaxes from "../components/FaxManagement/FailedFaxes";
import ArchivedSentFaxes from "../components/FaxManagement/ArchivedSentFaxes";
import ViewFax from "../components/FaxManagement/ViewFax";
import WebportalLandingPage from "../components/WelcomePage/WebportalLandingPage";
import RecallPaymentCustomer from "../components/TransactionEnqiry/RecallPaymentCustomer";
import RejectTransactions from "../components/TransactionEnqiry/RejectTransactions";
import RejectPaymentDetails from "../components/TransactionEnqiry/RejectPaymentDetails";
import RecallRejectDetails from "../components/TransactionEnqiry/RecallRejectDetails";
import RecallPaymentCustomerDetails from "../components/TransactionEnqiry/RecallPaymentCustomerDetails";
import RecallPendingApprovalsDetails from "../components/TransactionEnqiry/RecallPendingApprovalsDetails";
import RecallRejectHistory from "../components/TransactionEnqiry/RecallRejectHistory";

const AppRoutes = (props) => {
  const protectedRoute = (
    component,
    parentComponentType,
    componentType,
    props
  ) => {
    return AuthRoute(component, parentComponentType, componentType, props);
  };
  const roleType = localStorage.getItem("type");

  return (
    <>
      <div className="container">
        <Box>
          <Switch>
            <Route
              exact={true}
              path={["/"]}
              component={
                roleType === "webportal" ? WebportalLandingPage : KcRedirect
              }
            />{" "}
            <Route
              exact={true}
              path={"/webportal-redirect"}
              component={KcRedirect}
            />
            <Route exact={true} path={"/auth-redirect"} component={KcLanding} />
            <Route
              exact={true}
              path={"/auth-redirect-logout"}
              component={KcLogoutPage}
            />
            <Route exact={true} path={"/welcome-page"} component={Welcome} />
            <Route
              exact={true}
              path={"/custom_error"}
              component={AccessDeniedIp}
            />
            <Route
              exact={true}
              path={"/welcome-account-settings"}
              component={WelcomeAccountSettings}
            />
            <Route
              exact={true}
              path={"/credential-match"}
              component={CredentialMatch}
            />
            <Route
              exact={true}
              path={"/forgot-password"}
              component={ForgotPassword}
            />
            <Route
              exact={true}
              path={"/manage-users"}
              render={(props) => {
                return protectedRoute(ManageUsers, "UM", "MU", props);
              }}
            />
            <Route
              exact={true}
              path={"/batch-holiday-dates"}
              component={BatchHoliday}
              render={(props) => {
                return protectedRoute(BatchHoliday, "ESU", "BHD", props);
              }}
            />
            <Route
              exact={true}
              path={"/dashboard"}
              render={(props) => {
                return protectedRoute(DashboardPage, "HO", "DS", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-users/new-user"}
              render={(props) => {
                return protectedRoute(NewUser, "UM", "MU", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-users/user-profile"}
              render={(props) => {
                return protectedRoute(UserProfileTab, "UM", "MU", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-users/view-user-profile"}
              render={(props) => {
                return protectedRoute(ViewUserProfile, "UM", "MU", props);
              }}
            />
            <Route
              exact={true}
              path={"/account-settings"}
              render={(props) => {
                return protectedRoute(AccountSettings, "MP", "AS", props);
              }}
            />
            <Route
              exact={true}
              path={"/update-password"}
              render={(props) => {
                return protectedRoute(UpdatePassword, "MP", "UP", props);
              }}
            />
            <Route
              exact={true}
              path={"/login-page-pending-approvals"}
              render={(props) => {
                return protectedRoute(
                  DashboardLoginPendingApproval,
                  "MM",
                  "LPPA",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/manage-login-page"}
              render={(props) => {
                return protectedRoute(ManageLoginPage, "MM", "MLP", props);
              }}
            />
            <Route
              exact={true}
              path={"/message-centre-pending-approvals"}
              render={(props) => {
                return protectedRoute(
                  DashboardPendingApproval,
                  "MM",
                  "MCPA",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/manage-message-centre"}
              render={(props) => {
                return protectedRoute(MarketManagement, "MM", "MMC", props);
              }}
            />
            <Route
              exact={true}
              path={"/downloads-documents"}
              render={(props) => {
                return protectedRoute(DownloadDocuments, "AD", "DD", props);
              }}
            />
            <Route
              exact={true}
              path={"/downloads-documents/view-documents"}
              render={(props) => {
                return protectedRoute(AvailableDocuments, "AD", "DD", props);
              }}
            />
            <Route
              exact={true}
              path={"/downloads-documents/view-documents"}
              render={(props) => {
                return protectedRoute(AvailableDocuments, "AD", "DD_BMO", props);
              }}
            />
            <Route
              exact={true}
              path={"/downloads-documents/TODO_redirect"}
              render={(props) => {
                return protectedRoute(null, "AD", "URL", props);
              }}
            />
            <Route
              exact={true}
              path={"/download-reports"}
              render={(props) => {
                return protectedRoute(DownloadReports, "RE", "DR", props);
              }}
            />
            <Route
              exact={true}
              path={"/statistics-enquiry"}
              render={(props) => {
                return protectedRoute(StatisticsEnquiry, "SE", "GR", props);
              }}
            />
            <Route
              exact={true}
              path={"/statistics-enquiry/view-statistics"}
              render={(props) => {
                return protectedRoute(ViewStatistics, "SE", "GR", props);
              }}
            />
            <Route
              exact={true}
              path={"/review-transactions"}
              render={(props) => protectedRoute(TransactionEnquiry, "TE", "RT", props)}
            />
            <Route
              exact={true}
              path={"/review-transactions/transaction-details"}
              render={(props) => protectedRoute(TransactionDetails, "TE", "RT", props)}
            />
            <Route
              exact={true}
              path={"/recall-payments-customers"}
              render={(props) => protectedRoute(RecallPaymentCustomer, "TE", "RPC", props)}
            />
            <Route
               exact={true}
               path={"/recall-payments-customers/transaction-details"}
               render={(props) => protectedRoute(RecallPaymentCustomerDetails, "TE", "RPC", props)}
            />
            <Route
               exact={true}
               path={"/reject-payments-merchants"}
               render={(props) => protectedRoute(RejectTransactions, "TE", "RPM", props)}
            />
            <Route
               exact={true}
               path={"/reject-payments-merchants/transaction-details"}
               render={(props) => protectedRoute(RejectPaymentDetails, "TE", "RPM", props)}
            />
            <Route
              exact={true}
              path={"/recall-reject-history"}
              render={(props) => protectedRoute(RecallRejectHistory, "TE", "RRH", props) }
            />
            <Route
              exact={true}
              path={"/recall-reject-history/recall-reject-details"}  // or "recall-reject-history-details" ??
              render={(props) => protectedRoute(RecallRejectDetails, "TE", "RRH", props) }
            />
            <Route
              exact={true}
              path={"/upcoming-recalls-rejects"}
              render={(props) => protectedRoute(UpcomingRecallsRejects, "TE", "URR", props) }
            />
            <Route
              exact={true}
              path={"/upcoming-recalls-rejects/recall-reject-details"}
              render={(props) => protectedRoute(RecallRejectDetails, "TE", "URR", props) }
            />
            <Route
              exact={true}
              path={"/recall-pending-approvals"}
              render={(props) => protectedRoute(RecallPendingApprovals, "TE", "RPA", props)}
            />
            <Route
                exact={true}
                path={"/recall-pending-approvals/recall-pending-approvals-detail"}
                render={(props) => protectedRoute(RecallPendingApprovalsDetails, "TE", "RPA", props)}
            />
            <Route
              exact={true}
              path={"/view-financial-institution"}
              render={(props) => {
                return protectedRoute(ViewFIManagement, "FM", "VFI", props);
              }}
            />
            <Route
              exact={true}
              path={"/user-pending-approvals/approve-user"}
              render={(props) => {
                return protectedRoute(ApprovalUser, "UM", "UPA", props);
              }}
            />
            <Route
              exact={true}
              path={"/user-pending-approvals"}
              render={(props) => {
                return protectedRoute(UserPendingApprovals, "UM", "UPA", props);
              }}
            />
            <Route
              exact={true}
              path={"/price-plan-pending-approvals/approve-price-plan"}
              render={(props) => {
                return protectedRoute(ApprovePricePlan, "BSAU", "PPPA", props);
              }}
            />
            <Route
              exact={true}
              path={"/price-plan-pending-approvals"}
              render={(props) => {
                return protectedRoute(
                  PricePlanPendingApprovals,
                  "BSAU",
                  "PPPA",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/manage-price-plan"}
              render={(props) => {
                return protectedRoute(ManagePricePlan, "BSAU", "MPP", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-price-plan/new-price-plan"}
              render={(props) => {
                return protectedRoute(NewPricePlan, "BSAU", "MPP", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-price-plan/price-plan-details"}
              render={(props) => {
                return protectedRoute(
                  UpdatePricePlanDetails,
                  "BSAU",
                  "MPP",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/manage-price-plan/view-price-plan-details"}
              render={(props) => {
                return protectedRoute(
                  ViewPricePlanDetails,
                  "BSAU",
                  "MPP",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/synchronization"}
              render={(props) => {
                return protectedRoute(Synchronization, "BSAU", "SZ", props);
              }}
            />
            <Route
              exact={true}
              path={"/budget-category-management"}
              render={(props) => {
                return protectedRoute(Dashboard, "BSAU", "BCM", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-billers"}
              render={(props) => {
                return protectedRoute(BillerManagement, "BM", "MB", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-billers/biller-profile"}
              render={(props) => {
                return protectedRoute(
                  UpdateBillerProfileTab,
                  "BM",
                  "MB",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/manage-billers/new-biller"}
              render={(props) => {
                return protectedRoute(NewBillerProfileUser, "BM", "MB", props);
              }}
            />
            <Route
              exact={true}
              path={"/biller-pending-approvals"}
              render={(props) => {
                return protectedRoute(
                  BillerPendingApprovals,
                  "BM",
                  "BPA",
                  props
                );
              }}
            />
            <Route
              exact={true}
              path={"/biller-pending-approvals/approver-biller"}
              render={(props) => {
                return protectedRoute(ApprovalBiller, "BM", "BPA", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-budget-category"}
              render={(props) => {
                return protectedRoute(BudgetManagement, "BSAU", "MBC", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-budget-category/new-budget-category"}
              render={(props) => {
                return protectedRoute(NewBudgetCategory, "BSAU", "MBC", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-budget-category/budget-category-details"}
              render={(props) => {
                return protectedRoute(BudgetCategory, "BSAU", "MBC", props);
              }}
            />
            <Route
              exact={true}
              path={"/manage-budget-category/view-budget-category-details"}
              render={(props) => {
                return protectedRoute(ViewBudgetCategory, "BSAU", "MBC", props);
              }}
            />
            <Route
              exact={true}
              path={"/account-registration"}
              component={AccountRegistration}
            />
            <Route
              exact={true}
              path={"/failed-faxes"}
              render={(props) => {
                return protectedRoute(FailedFaxes, "FAXM", "FF", props);
              }}
            />
            <Route
              exact={true}
              path={"/archived-sent-faxes"}
              render={(props) => {
                return protectedRoute(ArchivedSentFaxes, "FAXM", "ASF", props);
              }}
            />
            <Route
              exact={true}
              path={["/archived-sent-faxes/view-fax", "/failed-faxes/view-fax"]}
              component={ViewFax}
            />
            <Route
              exact={true}
              path={"/returns-portal"}
              render={() => window.location = props.returnsPortalUrl}
            />
          </Switch>
        </Box>
      </div>
    </>
  );
};
export default withRouter(AppRoutes);
