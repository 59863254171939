
export const RoleType = {
    FI_ADMIN: "WA",
    FI_USER: "WU",
    BILLER_ADMIN: "MA",
    BILLER_USER: "MU"
};

export const Regex = {
    alphaNumeric: /^[0-9A-Za-z\s]+$/,
    alphaNumericWithHypen: /^[0-9A-Za-z-%\s]+$/,
    alphaNumericWithMarks: /^[0-9A-Za-z-+/*%.,:;!?()\s]+$/,
    name: /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/,
    nameWildChar: /^(?:%?[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+%?)$/,
    regularText:     // used for free flow text, such as description, reject reason and such
        /^[-.,_' éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9]+$/,  // may add other punctuation marks in the future [2024-11-19]
    
    decimal2: /^(?:\+|-)?\d*(\.\d{0,2})*$/
}; 

// Transaction Statuse in tables PCE and RRP (payment_central_enquiry and recalled_rejected_payment)
export const transactionStatusMap = new Map([
    ["A", "erps.common.status.recallApproved"],  ["B", "erps.common.status.pendingApproval"],
    ["C", "erps.common.status.recallCancelled"], ["D", "erps.common.status.originalPaymentProcessed"],
    ["E", "erps.common.status.expired"],         ["F", "erps.common.status.processingCompleted"],
    ["N", "erps.common.status.toBeProcessed"],   ["Q", "erps.common.status.toBeProcessed"],
    ["R", "erps.common.status.recallRejected"],  ["W", "erps.common.status.waitingForAck"],
    ["X", "erps.common.status.paymentFailed"],   ["Z", "erps.common.status.rejectInitiated"]]);

// Transaction Type in tables PCE and RRP
export const transactionTypes = [
    { value: "RT", name: "erps.transactionManagement.label.showCustomerRecalls" },
    { value: "RJ", name: "erps.transactionManagement.label.showMerchantRejects" }
];

