import IconButton from "@material-ui/core/IconButton";
import FirstPageIcon from "@material-ui/icons/FirstPage";
import LastPageIcon from "@material-ui/icons/LastPage";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import Tooltip from "@mui/material/Tooltip";

const CustomPaginationActions = ({
  count,
  page,
  rowsPerPage,
  onPageChange,
}) => {
  const handleFirstPageButtonClick = (e) => {
    onPageChange(e, 0);
  };
  const handleBackButtonClick = (e) => {
    onPageChange(e, page - 1);
  };
  const handleNextButtonClick = (e) => {
    onPageChange(e, page + 1);
  };
  const handleLastPageButtonClick = (e) => {
    onPageChange(e, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  const isLastPage = page === Math.max(0, Math.ceil(count / rowsPerPage) - 1);

  return (
    <>
      <Tooltip title={page === 0 ? "" : "First page"}>
          <IconButton
            onClick={handleFirstPageButtonClick}
            disabled={page === 0}
            aria-label="Go to first page X"
          >
            <FirstPageIcon />
          </IconButton>
      </Tooltip>
      <Tooltip title={page === 0 ? "" : "Previous Page"}>
          <IconButton
            onClick={handleBackButtonClick}
            disabled={page === 0}
            aria-label="Go to previous page"
          >
            <KeyboardArrowLeft />
          </IconButton>
      </Tooltip>
      <Tooltip title="Current Page">
        <label style={{ padding: "10px" }}>
          {page + 1}
        </label>
      </Tooltip>
      <Tooltip title={isLastPage ? "" : "Next Page"}>
          <IconButton
            onClick={handleNextButtonClick}
            disabled={isLastPage}
            aria-label="Go to next page"
          >
            <KeyboardArrowRight />
          </IconButton>
      </Tooltip>
      <Tooltip title={isLastPage ? "" : "Last Page"}>
          <IconButton
            onClick={handleLastPageButtonClick}
            disabled={isLastPage}
            aria-label="Go to last page"
          >
            <LastPageIcon />
          </IconButton>
      </Tooltip>
    </>
  );
};

export default CustomPaginationActions;
