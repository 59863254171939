import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { recallPaymentCustomerHeading } from "../../constants/RecallPaymentCustomerConstant";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import CardList from "../custom/containers/cardList";
import moment from "moment";
import { formatDate , listProps } from "../../utils/helper";
import ERPSRecallRejectSearch, { getDateLimits, billerDetails } from "../custom/ERPSRecallRejectSearch";

const RecallPaymentCustomer = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { pathname, state },
  } = props;

  const recallPaymentCustomerResponse = useSelector(
    (state) => state.recallPaymentCustomerReducer
  );

  const user = useSelector((state) => state.loginUserReducer.user);
  const loggedInBillerId = user?.billerNonVolatileDto?.biller_id;
  const internalBMOUserId = user?.internalBMOUserId;
  const [errorMessage, setErrorMessage] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);

  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
  const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;

  const sortDetails = [
    { 
      name: t("erps.common.label.effectiveDate"), 
      value: "ED" 
    },
  ];

  useEffect(() => {
    if (state?.fromDetailPage) {
      Object.keys(searchValues).filter(key => actualCriteria[key]).forEach(key => searchValues[key] = actualCriteria[key]);
      setPageNumber(actualCriteria.offset / actualCriteria.limit);
      setRowPerPage(actualCriteria.limit);
      state.fromDetailPage = null;  // resetting the coming-from-detail-page flag
      if (state.isRecalled)
        loadResults(actualCriteria, " (After Recall)");
    } else {  // reset search resutls previously saved in the reducer
      dispatch({
        type: "GET_RPC_SUCCESS",
        data: { data: null, rpcDetails: null },
      });
    }
  }, []);

  useEffect(() => {
    if (recallPaymentCustomerResponse) {
      const recallPaymentCustomerRes = recallPaymentCustomerResponse?.rpcData?.data;
      if (recallPaymentCustomerRes?.length) {
        for(let i=0; i < recallPaymentCustomerRes.length; i++)
          recallPaymentCustomerRes[i].seqNo = i + 1 + actualCriteria.offset;
      }
      setRpcData(recallPaymentCustomerRes);
    }
  }, [recallPaymentCustomerResponse]);


  const dates = getDateLimits();

  const initialSearchValues = {
    paymentFromDate: null,
    paymentToDate: dates.today,
    searchBy: billerDetails[0].value,   // "billerId"
    transactionToAmount: "",
    transactionFromAmount: null,
    verificationNumber: "",
    bmoBillerId: "",
    billerName: "",
    billerCustomerNumber: "",
    fiCustomerName: "",
    requestFrom: "recallPaymentForCustomers",
    sortBy: "Biller ID"
  };

  const [searchValues, setSearchValues] = useState(initialSearchValues);

  const [actualCriteria, setActualCriteria] = useState(
      state?.fromDetailPage ? recallPaymentCustomerResponse?.rpcDetails : { ...initialSearchValues, offset: 0, limit: rowPerPage}
  );
  const [rpcData, setRpcData] = useState( state?.fromDetailPage && recallPaymentCustomerResponse?.rpcData?.data || null );


  const handleReset = () => {
    setSearchValues({...initialSearchValues});
    setActualCriteria({ ...initialSearchValues, offset: 0});
    setPageNumber(0);
    setErrorMessage(null);
  
    dispatch({
      type: "GET_RPC_SUCCESS",
      data: { data: null, rpcDetails: null },
    });
  };

  const handleSearch = (groomedValues) => {
    if (groomedValues.paymentToDate)
      groomedValues.paymentToDate = formatDate(moment(groomedValues.paymentToDate).format());

    if (pageNumber)
      setPageNumber(0);
    if (actualCriteria.offset)
        actualCriteria.offset = 0;

    groomedValues.limit = actualCriteria.limit;
    groomedValues.offset = actualCriteria.offset;
    setActualCriteria({...groomedValues});

    if (groomedValues.searchBy === billerDetails[0].value)  // "billerId"
      delete groomedValues["billerName"];  // NOT sending billerName when searching by biller id
    else if (groomedValues.searchBy === billerDetails[1].value)  //  "billerName"
      delete groomedValues["bmoBillerId"]; // NOT sending bmoBillerId when searching by biller Name

//console.log("bmoBillerId = " + groomedValues.bmoBillerId + ",   billerId = " + groomedValues.billerId);
//console.log("groomedValues.bmoBillerId || internalBMOUserId?.slice(0, -2) || loggedInBillerId:  " + (groomedValues.bmoBillerId || internalBMOUserId?.slice(0, -2) || loggedInBillerId));
    groomedValues.billerId = groomedValues.bmoBillerId || internalBMOUserId?.slice(0, -2) || loggedInBillerId;

//console.log("***************** SEARCHING ********************************");
//console.log("groomedValues: " + listProps(groomedValues, true));
    loadResults(groomedValues, " (search)");
  };

  const loadResults = (queryParams, prompt) => {
    if (errorMessage)
      setErrorMessage(null);
    setLoading(true);

    dispatch(
      actions.recallPaymentCustomerActions.getRPC(
        queryParams,
        { ...props, t, history, setLoading, fiNumberValue, setErrorMessage}
      )
    );
  }

  const paginate = (values) => {  // 'values' has fields startOffset and endOffset (e.g., for 1st Page: 0,25;  2nd Page: 25,50;  3rd: 50,75...)
    actualCriteria.offset = values.startOffset;
    if (actualCriteria.limit !== (values.endOffset - values.startOffset)) // if the user changed the number of rows per page
      actualCriteria.limit = values.endOffset - values.startOffset;

    loadResults(actualCriteria, " (paginate)");
  };


  return (
    <div role="main">
      <Box ml={3}>
        <ERPSRecallRejectSearch 
            pageType="RPC"
            pageTitle={t("erps.recallpaymentcustomers.page.title")}
            pageDescription={t("erps.transactionEnquiry.page.description")}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            dates={dates}
            loading={loading}
            errorMessage={errorMessage}
            handleReset={handleReset}
            handleSearch={handleSearch}
        />

        {(rpcData !== null && rpcData !== undefined && Array.isArray(rpcData) && rpcData.length > 0) ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: rpcData,
                  columns: recallPaymentCustomerHeading(),
                  count: recallPaymentCustomerResponse?.rpcData?.count || 0
                }}
                sumOfColumnWidth="true"
                initialLoading={loading}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
                onAnyPaginationAction={paginate}
                tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={false}
                pageName={"transaction-enquiry"}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: rpcData,
                  columns: recallPaymentCustomerHeading(),
                  count: recallPaymentCustomerResponse?.rpcData?.count || 0
                }}
                onAnyPaginationAction={paginate}
                hideFooterPagination={false}
                pageName={"transaction-enquiry"}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
              />
            </Box>
          </>
        ) : (
          <Box mt={1}>
            <Typography variant="body1">
                { (rpcData?.length === 0)
                    ? (`0 ${t("erps.transactionEnquiry.page.resultfound")}`)
                    : (t("erps.availabledocuments.searchuser.notstarted", "Search not started"))
                }
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withSidebar(RecallPaymentCustomer);
