import React, { useState, useEffect, useRef } from "react";

import {
  getAvailableReportsHeading,
  EMPTY_DOWNLOAD_REPORT_CRITERIA,
} from "../../constants/ReportsConstant";
import { Box } from "@material-ui/core";

import { withSidebar } from "../hoc/withSidebar";
import warningIcon from "../../assets/images/warning-icon.png";
import CommonLayout from "../custom/containers/CommonLayout";
import { useCustomHook } from "../custom/useCustomHook";
import { useTranslation } from "react-i18next";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import ErrorMessage from "../custom/containers/ErrorMessage";
import availableDocumentTypes from "../../actions/types/availableDocument.action.type";
import CommonContainerBox from "../custom/containers/CommonContainerBox";


const DownloadReports = (props) => {
  const { t } = useTranslation();
  const {
    location: { pathname },
  } = props;
  const dispatch = useDispatch();
  const downloadReportResultForWP = useSelector(
    (state) => state.availableDocumentReducer?.availableDocument
  );
  const downloadReportResult = useSelector(
    (state) => state.availableDocumentReducer?.downloadReportListData
  );
  const downloadReportErrorForWP = useSelector(
    (state) => state.availableDocumentReducer?.error
  );
  const downloadReportListError = useSelector(
    (state) => state.availableDocumentReducer?.downloadReportListError
  );

  const reportNames = useSelector(
    (state) => state.availableDocumentReducer?.reportNames
  );

  const reportListDropDown = useSelector(
    (state) => state.availableDocumentReducer?.reportListDropDown
  );
  const [downloadReportList, setDownloadReportList] = useState(null);
  const [dropdownReportListLoading, setDropdownReportListLoading] =
    useState(false);
  const [reportNameList, setReportNameList] = useState([]);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);
  const apiExceptionMessage = useSelector(
    (state) => state.availableDocumentReducer?.downloadReportError
  );
  const apiExceptionMessageDownloadDocument = useSelector(
    (state) => state.availableDocumentReducer?.downloadDocumentError
  );
  const [pageNumber, setPageSize] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const [loading, setLoading] = useState(false);
  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const roleId = loginUser?.role?.role_id;
  const apiExceptionRef = useRef();
  const [searchResultException, setSearchResultException] = useState(null);

  useEffect(() => {
    dispatch({
      type: availableDocumentTypes.GET_AVAILABLE_REPORTS_SUCCESS,
      data: null,
    });
    const reqApiData = {
      start_off_set: 0,
      end_off_set: 25,
      start_date: "",
      end_date: "",
    };

    if (roleId == 1 || roleId == 2) {
      setDropdownReportListLoading(true);
      dispatch(
        actions.availableDocumentActions.getReportsList(
          {
            ...reqApiData,
            reportName: "",
            biller_id: 0,
            languageCode: loginUser?.languageCode,
          },
          t
        )
      );
    } else {
      setDropdownReportListLoading(true);

      dispatch(
        actions.availableDocumentActions.getReportNames(
          {
            ...reqApiData,
            reportName: "",
            languageCode: loginUser?.languageCode,
          },
          t
        )
      );
    }
  }, []);
  useEffect(() => {
    const downloadReportResponse =
      roleId == 1 || roleId == 2
        ? downloadReportResultForWP
        : downloadReportResult;
    if (downloadReportResponse) {
      const updatedData = downloadReportResponse?.data?.map(
        (downloadReport, idx) => ({
          ...downloadReport,
          id: idx,
        })
      );
      setDownloadReportList(updatedData);
    }
  }, [downloadReportResult, downloadReportResultForWP]);

  useEffect(() => {
    if (roleId == 1 || roleId == 2) {
      if (reportListDropDown) {
        const reportNameList = reportListDropDown?.map((val) => ({
          label: val,
          value: val,
        }));
        reportNameList?.unshift({
          label: "",
          value: "",
        });
        setDropdownReportListLoading(false);
        setReportNameList(reportNameList);
      }
    } else {
      const reportList = reportNames ? [...reportNames] : []; // Some array I got from async call

      const uniqueReportName = Array.from(
        new Set(reportList.map((a) => a.reportName.trim()))
      ).map((reportName) => {
        return reportList.find(
          (a) => a.reportName?.trim() === reportName?.trim()
        );
      });
      const reportNameList = uniqueReportName.map(
        ({ reportName, reportDescription }) => ({
          label: `${reportName} - ${reportDescription}`,
          value: reportName,
        })
      );
      reportNameList?.unshift({
        label: "",
        value: "",
      });
      setDropdownReportListLoading(false);
      setReportNameList(reportNameList);
    }
  }, [reportListDropDown, reportNames]);

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [
    apiExceptionMessage && showApiExceptionMessage,
    validationErrors,
    searchResultException,
  ]);
  const pageName = pathname.split("/")[1];
  const hasButtonVisible = true;
  const date = new Date();
  const minDateVal = new Date(
    date.getFullYear(),
    date.getMonth() - 13,
    date.getDate()
  );
  const maxDateVal = new Date();
  const {
    isSearchValid,
    searchCriteria,
    searchResults,
    searchCriteriaChangeHandler,
    handleAvailableReportsSearch,
    resetAvailableReportsHandler,
    paginateAvailableReports,
    hasSearch,
  } = useCustomHook({
    tableResults: downloadReportList,
    tableHeadings: getAvailableReportsHeading(fileDownload, roleId),
    initialStates: EMPTY_DOWNLOAD_REPORT_CRITERIA,
    roleId,
    loginUser,
    setPageSize,
    setRowPerPage,
    setLoading,
    rowPerPage,
    pageNumber,
    setErrorMessage: setValidationErrors,
    errorMessage: validationErrors,
    setHighlightError,
    minDateValue: minDateVal,
    maxDateValue: maxDateVal,
  });

  useEffect(() => {
    if (hasSearch) {
      const error = downloadReportErrorForWP || downloadReportListError;
      setSearchResultException(error);
    } else {
      setSearchResultException(null);
    }
  }, [downloadReportErrorForWP, downloadReportListError]);
  const getReportPath = (reportDocData) => {
    return roleId == 1 || roleId == 2
      ? reportDocData?.pdf_link || reportDocData?.csv_link
      : reportDocData?.reportPath || reportDocData?.reportPathCsv;
  };

  function fileDownload(reportDocData, fileFormat) {
    const reportPath = getReportPath(reportDocData);
    const pattern = /\\/;
    const updatedReportPath = pattern.test(reportPath.charAt(0))
      ? reportPath.slice(1)
      : reportPath;

    setShowApiExceptionMessage(false);
    dispatch(
      actions.availableDocumentActions.downloadReports(
        {
          ...reportDocData,
          reportPath: updatedReportPath,
          languageCode: loginUser?.languageCode,
          downloaded_file_format: fileFormat,
        },
        { t, setShowApiExceptionMessage }
      )
    );
  }

  return (
    <>
      {((showApiExceptionMessage &&
        (apiExceptionMessage || apiExceptionMessageDownloadDocument)) ||
        searchResultException ||
        Boolean(validationErrors.length)) && (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          sx={{ mt: 4, ml: 2 }}
        >
          <ErrorMessage
            error={
              apiExceptionMessage ||
              apiExceptionMessageDownloadDocument ||
              (validationErrors.length
                ? validationErrors
                : searchResultException)
            }
            errorRef={apiExceptionRef}
          />
        </Box>
      )}
      {/* For Title update */}
      <CommonContainerBox title={t("erps.downloadreports.page.title")} />
      <CommonLayout
        pageTitle={t("erps.downloadreports.page.title")}
        pageSubHeading={t("erps.downloadreports.heading")}
        primaryButtonLabelV2={t("erps.common.button.reset")}
        secondaryButtonLabel={t("erps.common.button.search")}
        firstTextInputFieldLabel={t("erps.downloadreports.label.from")}
        firstTextInputFieldId="downloadreports__from"
        secondTextInputFieldLabel={t("erps.downloadreports.label.to")}
        secondTextInputFieldId="downloadreports__to"
        isEnable={false}
        pageName={pageName}
        warningIcon={warningIcon}
        searchResultLabel={t("erps.downloadreports.label.searchResult")}
        searchResultFoundLabel={t(
          "erps.downloadreports.label.searchResultFound"
        )}
        errorMssg={t("erps.downloadreports.filter.error.emptyinput")}
        searchNotStarted={t("erps.downloadreports.searchreport.notstarted")}
        startSearchHandler={handleAvailableReportsSearch} // ToDo - Update handler to Report
        searchCriteriaChangeHandler={searchCriteriaChangeHandler}
        resetHandler={resetAvailableReportsHandler} // ToDo - Update handler to Report
        isSearchValid={isSearchValid}
        searchCriteria={searchCriteria}
        initiateSearch={paginateAvailableReports} // ToDo - Update handler to Report
        searchResults={
          searchResults || {
            rows: downloadReportList ? [...downloadReportList] : [],
            count: downloadReportResult
              ? downloadReportResult?.count
              : downloadReportResultForWP && downloadReportResultForWP.count,
            columns: getAvailableReportsHeading(fileDownload, roleId),
          }
        }
        labelLinkValue={["downloadReportId"]}
        actionType={{
          primaryButtonActionTypeV1: "hasDownloadReportAccess",
          firstTextInputFieldActionType: "hasFromTextField",
          secondTextInputFieldActionType: "hasToTextField",
          primaryButtonActionTypeV2: "hasResetBtn",
          secondaryButtonActionType: "hasSearchReportAccess",
        }}
        isDisabled={hasButtonVisible}
        sumOfColumnWidth="true"
        thirdTextInputFieldId="Report name"
        reportNameList={reportNameList}
        pageNumber={pageNumber}
        rowPerPage={rowPerPage}
        loading={loading}
        hasDropDownLoading={dropdownReportListLoading}
        highlightError={highlightError}
        minDateVal={minDateVal}
        maxDateVal={maxDateVal}
      />
    </>
  );
};

export default withSidebar(DownloadReports);
