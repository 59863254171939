import React, { useEffect, useState, useRef } from "react";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { useTranslation } from "react-i18next";
import { Box, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../actions/user.actions";
import { billerProfileActions } from "../../actions/billerProfile.action";
import { loginActions } from "../../actions/login.actions";
import warningIcon from "../../assets/images/warning-icon.png";
import ERPSBaseDialog from "../custom/inputs/style/ERPSBaseModelDialogStyle";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import IconButton from "@mui/material/IconButton";

import useFormBillerProfile from "../custom/useFormBillerProfile";

import CloseSharpIcon from "@mui/icons-material/CloseSharp";
import DoneSharpIcon from "@mui/icons-material/DoneSharp";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import userActionTypes from "../../actions/types/user.action.type";
import ErrorMessage from "../custom/containers/ErrorMessage";
import Tooltip from "@mui/material/Tooltip";
import UserService from "../../utils/keycloak.service.js";

const BillerProfile = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const userResult = useSelector((state) => state.userDetailReducer.userData);
  let user = useSelector((state) => state.loginUserReducer.user);

  const userRoleTypes = useSelector((state) => state.userRoleTypeReducer);
  const subscribeEntities = useSelector(
    (state) => state.subscribingEntitiesReducer
  );
  const billerProfile = useSelector((state) => state.billerProfileReducer);
  const [userRoleListDropdown, setUserRoleListDropdown] = useState(null);
  const [errorMessage, setErrorMessage] = useState([]);
  const [errors, setErrors] = useState(null);
  const [subscribeEntityList, setSubscribeEntityList] = useState([]);
  const [billerProfileData, setBillerProfileData] = useState([]);
  const roleTypeOfLoggedInUser = user?.role?.role_type;
  const [dialogCancel, setDialogCancel] = React.useState(false);
  const [openCancelDialog, setOpenCancelDialog] = React.useState(false);
  const [dialoagButtonloading, setDialoagButtonloading] = React.useState(false);
  const [errorHighlights, setErrorHighlights] = React.useState({});
  useEffect(() => {
    if (userRoleTypes) {
      if (userRoleTypes.userRoleTypeList) {
        const data = userRoleTypes.userRoleTypeList.map(
          ({ role_id, role_type, role_name }) => ({
            roleId: role_id,
            value: role_type,
            label: role_name,
          })
        );
        setUserRoleListDropdown(data);
      }
    }
    if (userRoleTypes.error) {
      setErrorMessage([userRoleTypes.error]);
    }
  }, [userRoleTypes]);

  useEffect(() => {
    if (billerProfile) {
      console.log("billerProfile", billerProfile);
      if (billerProfile.billerProfileData) {
        setBillerProfileData(billerProfile.billerProfileData.data);
      } else if (billerProfile.billerProfileError) {
        setErrorMessage([billerProfile.billerProfileError]);
      } else if (
        billerProfile.billerProfileUpdatedData &&
        billerProfile.billerProfileUpdatedData.statusCode == 200 &&
        !billerProfile?.billerProfileUpdatedData?.errors
      ) {
        navigateToDashBoard();
      } else if (
        billerProfile.billerProfileUpdatedData &&
        billerProfile.billerProfileUpdatedData.statusCode === 200 &&
        billerProfile?.billerProfileUpdatedData?.errors
      ) {
        if (billerProfile.billerProfileUpdatedData.errors) {
          setErrors(billerProfile.billerProfileUpdatedData.errors);
        }
        setErrorMessage([billerProfile.billerProfileUpdatedData.message]);
        window.scrollTo(0, 0);
      } else if (
        billerProfile?.billerProfileUpdatedData?.statusCode == 401 &&
        billerProfile?.billerProfileUpdatedData?.message
      ) {
        setErrorMessage([billerProfile?.billerProfileUpdatedData?.message]);
      } else if (billerProfile.billerProfileUpdateError) {
        setErrorMessage([billerProfile.billerProfileUpdateError]);
      }
    }
  }, [billerProfile]);

  useEffect(() => {
    if (subscribeEntities) {
      if (subscribeEntities.subscribingEntitiesList) {
        const data = subscribeEntities.subscribingEntitiesList.map(
          ({ fiCompanyNumber, fiCompanyName }) => ({
            value: fiCompanyNumber,
            label: fiCompanyName,
          })
        );
        setSubscribeEntityList(data);
      }
    }
    if (subscribeEntities.error) {
      setErrorMessage([subscribeEntities.error]);
    }
  }, [subscribeEntities]);

  useEffect(() => {
    dispatch(
      userActions.getUserRoleTypeList(
        values.fiNumber,
        roleTypeOfLoggedInUser,
        "manageusercomponent",
        t,
        null,
        false
      )
    );
  }, []);

  const errorRef = useRef();
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [errorMessage, errors]);

  const [openDialogForRadio, setOpenDialogForRadio] = useState(false);

  const navigateToLoginPage = (event) => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };
  const handleClose = () => {
    if (openDialogForRadio && openDialogForRadio?.isOpen) {
      setOpenDialogForRadio({ isOpen: false });
    }
  };

  const getDialogHeader = () => {
    if (openDialogForRadio) {
      return `${window.location.host} ${t(
        "erps.manageusers.pendingUser.heading.dialog.additional.text"
      )}`;
    }
  };
  const getDialogContent = () => {
    if (openDialogForRadio) {
      return (
        <Typography gutterBottom aria-describedby="dialog-message">
          {fiNumber == 10
            ? t("erps.manageusers.pendingUser.heading.dialog.radiobutton.cibc")
            : t("erps.manageusers.pendingUser.heading.dialog.radiobutton")}
        </Typography>
      );
    }
  };
  const getDialogActions = () => {
    if (openDialogForRadio) {
      return (
        <ERPSBaseButton
          variant="primary"
          className="button_with_zeromargin"
          onClick={() => {
            if (openDialogForRadio?.key) {
              setValues((preValues) => ({
                ...preValues,
                [openDialogForRadio?.key]: openDialogForRadio?.value,
              }));
            }

            setOpenDialogForRadio({ isOpen: false });
          }}
          label={t("erps.newuser.button.ok")}
        />
      );
    }
  };

  const logout = () => {
    localStorage.setItem("logoutClicked", "true");
    localStorage.removeItem("persist:erps");
    UserService.doLogout();
  };

  const handleRedirect = () => {
    setDialoagButtonloading(true);
    // dispatch(
    //   loginActions.logout({
    //     ...props,
    //     setOpen: setOpenCancelDialog,
    //   })
    // );
    logout();
  };

  const navigateToDashBoard = () => {
    if (billerProfile?.billerProfileUpdatedData?.userDetails) {
      if (user?.statusCode !== "I") {
        if (user?.emailAddress || user?.emailAddress != "") {
          props.history.push("/dashboard");
        } else {
          props.history.push("/account-settings");
        }
      }
    }
  };

  useEffect(() => {
    dispatch(userActions.getSubscribingEntitiesList(values.fiNumber));
  }, []);

  useEffect(() => {
    let owningFiNumber = values.fiNumber;
    if (values.fiNumber == 99) {
      owningFiNumber = 50;
    }
    const billerId = user?.searchBillerFilesDto?.biller_id;
    dispatch(billerProfileActions.getBillerProfile(billerId, owningFiNumber));
  }, []);

  const {
    showPwdIconPWD,
    showPwdIconNEWPWD,
    showPwdIconConfirmPWD,
    valuesPass,
    handleSubmit,
    handleChange,
    handleClickShowPassword,
    handleClickShowNewPassword,
    handleClickShowConfirmPassword,
    handleMouseDownPassword,
  } = useFormBillerProfile(changePassword, { ...props });

  function changePassword() {
    console.log(" Change Password Form successfully submit");
  }

  const getUserData = () => {
    let data = null;
    if (props.pageName === "account-registration") {
      data = {
        userId: user?.internalBMOUserId || user?.userId,
        firstName: user?.firstName,
        lastName: user?.lastName,
        emailAddress: user?.emailAddress,
        phoneNumber: user?.phone,
        role: user?.role.role_name,
        statusCode: user?.statusCode,
        roleId: user?.role.role_id,
        language: user?.languageCode,
        fiCompanyNumber: user?.fiCompanyNumber,
        challengeNumber: user?.challengeNumber,
        challengeResponse: user?.challengeResponse,
        fiNumber: user?.fiNumber,
        roleType: user?.role.role_type,
        billerId: user?.searchBillerFilesDto?.fi_biller_with_biller_id,
        billerAccountNumber: user?.billerNonVolatileDto?.debit_account_number,
        emailNotificationOk: user?.billerNonVolatileDto?.email_notification_ok,
        csvAdviceOk: user?.billerNonVolatileDto?.csv_advice_ok,
      };
    }

    return data;
  };

  const {
    userId,
    firstName,
    lastName,
    emailAddress,
    phoneNumber,
    roleName,
    statusCode,
    roleId,
    language,
    fiCompanyNumber,
    challengeNumber,
    challengeResponse,
    fiNumber,
    roleType,
    billerId,

    billerAccountNumber,
    emailNotificationOk,
    csvAdviceOk,
  } = getUserData();

  const [values, setValues] = useState({
    userID: userId,
    fiNumber: fiNumber,
    roleId: roleId,
    roleType: roleType,
    roleName: roleName,
    language: language,
    fiCompanyNumber: fiCompanyNumber,
    firstname: firstName,
    lastname: lastName,
    emailAddress: emailAddress,
    phoneNumber: phoneNumber,
    status: statusCode === "A" ? "Active" : "Inactive",
    challengeNumber: challengeNumber,
    challengeResponse: challengeResponse,
    billerId: billerId,

    billerAccountNumber: billerAccountNumber,
    emailNotificationOk: emailNotificationOk,
    csvAdviceOk: csvAdviceOk || "N",
  });
  const [dialogLoading, setDialogLoading] = useState(false);

  const languageItems = [
    { name: t("erps.common.label.english"), value: "E" },
    { name: t("erps.common.label.french"), value: "F" },
  ];

  const documentFormatItems = [
    { name: t("erps.common.label.pdf"), value: "N" },
    { name: t("erps.common.label.pdfAndCsv"), value: "Y" },
  ];

  const fiList = [
    { value: "99", label: "BMO" },
    { value: "10", label: "CIBC" },
    { value: "2", label: "BNS" },
    { value: "50", label: "TELUS" },
  ];

  const challengeQuesList = [
    {
      value: 0,
      label: "",
    },
    {
      value: 1,
      label: t(
        "erps.userprofile.challengequestion1.label",
        "What is your mother's maiden name?"
      ),
    },
    {
      value: 2,
      label: t(
        "erps.userprofile.challengequestion2.label",
        "What is your's pet name?"
      ),
    },
    {
      value: 3,
      label: t(
        "erps.userprofile.challengequestion3.label",
        "Where is your favourite hoilday destination?"
      ),
    },
  ];
  const handleChangeText = (event, key) => {
    const webPortalRolesId = [1, 2, 3];
    if (key === "csvAdviceOk" && event.target.value === "Y") {
      if (
        webPortalRolesId.includes(values?.roleId) &&
        fiNumber != 10 &&
        props.pageName !== "account-settings"
      ) {
        setOpenDialogForRadio({ isOpen: true, key, value: event.target.value });
      } else if (webPortalRolesId.includes(values?.roleId) && fiNumber == 10) {
        setOpenDialogForRadio({ isOpen: true, key, value: event.target.value });
      } else {
        setValues({ ...values, [key]: event.target.value });
      }
    } else {
      setValues({ ...values, [key]: event.target.value });
    }
  };

  const handleChangeEmailNotification = (event, key) => {
    if (event.target.checked === true) {
      setValues({ ...values, [key]: "Y" });
    } else {
      setValues({ ...values, [key]: "N" });
    }
  };

  const validateEmail = (email) => {
    var regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return regexEmail.test(email);
  };
  const minTwoDigits = (n) => (n < 10 ? "0" : "") + n;

  const handleSave = () => {
    const errormsg = validateUser();
    console.log("values", values);
    setErrorMessage(errormsg);
    if (!errormsg.length) {
      const userDetailUpdated = {
        userId: values.userID,
        fiNumber: values.fiNumber,
        firstName: values.firstname,
        lastName: values.lastname,
        challengeNumber: values.challengeNumber,
        challengeResponse: values.challengeResponse,
        emailNotificationOk: values.emailNotificationOk,
        csvAdviceOk: values.csvAdviceOk,
        phone: values.phoneNumber,
        languageCode: values.language,
        emailAddress: values.emailAddress,
        // currentPassword: valuesPass.currentpwd,
        // newPassword: valuesPass.newpwd,
        // confirmPassword: valuesPass.confirmnewpwd,
      };
      let loginFieldsUpdation;
      if (user?.billerNonVolatileDto) {
        const { billerNonVolatileDto: billerNonVolatileDetails, ...rest } =
          user;
        let currentDate = new Date();
        const modifiedCurrentDate =
          currentDate.getFullYear() +
          minTwoDigits(currentDate.getMonth() + 1) +
          minTwoDigits(currentDate.getDate());

        loginFieldsUpdation = {
          ...rest,
          firstName: values.firstname,
          lastName: values.lastname,
          challengeNumber: values.challengeNumber,
          challengeResponse: values.challengeResponse,
          phone: values.phoneNumber,
          emailAddress: values.emailAddress,
          languageCode: values.language,
          changePasswordIndex: "N",
          statusCode: "A",
          billerNonVolatileDto: {
            ...billerNonVolatileDetails,
            email_notification_ok: values.emailNotificationOk,
            csv_advice_ok: values.csvAdviceOk,
            agreement_accepted: "A",
            agreement_accepted_date: modifiedCurrentDate,
          },
        };
      }
      setDialogLoading(true);
      dispatch(
        billerProfileActions.updateBillerProfile(userDetailUpdated, {
          setDialogLoading,
          loginFieldsUpdation,
          t,
        })
      );
    } else {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  };

  function validateUser() {
    let errormsg = [];
    const alphaNumericRegEx = /^[0-9A-Za-z]+$/;
    var alphaNumericRegExWithSpace = /^[a-zA-Z0-9.\-' À-ÿ]+$/;

    const numericRegEx = /^[0-9]+$/;
    const alpha = /^[A-Za-z]+$/;
    let errorHighlights = {
      currentpwd: false,
      newpwd: false,
      confirmnewpwd: false,
    };

    if (!values.firstname) {
      errormsg.push(
        t("erps.manageusers.blank.firstname.msg", "First name cannot be blank.")
      );
      errorHighlights = { ...errorHighlights, firstname: true };
    }
    if (
      values.firstname &&
      (values.firstname.trim().length < 1 ||
        values.firstname.trim().length > 25)
    ) {
      errormsg.push(
        t(
          "erps.manageusers.length.firstname.msg",
          "First name length is between 1 and 25 characters."
        )
      );
      errorHighlights = { ...errorHighlights, firstname: true };
    }
    if (
      values.firstname &&
      values.firstname.match(alphaNumericRegExWithSpace) === null
    ) {
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.firstname.msg",
          "First name must be alpha numeric."
        )
      );
      errorHighlights = { ...errorHighlights, firstname: true };
    }

    if (!values.lastname) {
      errormsg.push(
        t("erps.manageusers.blank.lastname.msg", "Last name cannot be blank.")
      );
      errorHighlights = { ...errorHighlights, lastname: true };
    }
    if (
      values.lastname &&
      (values.lastname.trim().length < 1 || values.lastname.trim().length > 25)
    ) {
      errormsg.push(
        t(
          "erps.manageusers.length.lastname.msg",
          "Last name length is between 1 and 25 characters."
        )
      );
      errorHighlights = { ...errorHighlights, lastname: true };
    }
    if (
      values.lastname &&
      values.lastname.match(alphaNumericRegExWithSpace) === null
    ) {
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.lastname.msg",
          "Last name  must be alpha numeric."
        )
      );
      errorHighlights = { ...errorHighlights, lastname: true };
    }
    if (!values.challengeNumber || values.challengeNumber == 0) {
      errormsg.push(
        t(
          "erps.manageusers.blank.challengeQuestion.msg",
          "Challenge question name cannot be blank."
        )
      );
      errorHighlights = { ...errorHighlights, challengeNumber: true };
    }
    if (!values.challengeResponse) {
      errormsg.push(
        t(
          "erps.manageusers.blank.challengeResponse.msg",
          "Challenge response name cannot be blank."
        )
      );
      errorHighlights = { ...errorHighlights, challengeResponse: true };
    }
    if (
      values.challengeResponse &&
      (!values.challengeResponse.trim().length ||
        values.challengeResponse.trim().length > 60)
    ) {
      errormsg.push(
        t(
          "erps.manageusers.length.challengeResponse.msg",
          "Challenge response name length is between 1 and 60 characters."
        )
      );
      errorHighlights = { ...errorHighlights, challengeResponse: true };
    }
    if (
      values.challengeResponse &&
      values.challengeResponse.match(alphaNumericRegExWithSpace) === null
    ) {
      errormsg.push(
        t(
          "erps.manageusers.alpha.challengeResponse.msg",
          "Challenge response name must be alpha."
        )
      );
      errorHighlights = { ...errorHighlights, challengeResponse: true };
    }

    if (values.phoneNumber && values.phoneNumber.trim().length !== 10) {
      errormsg.push(
        t("erps.manageusers.length.phone.msg", "Phone length should be 10.")
      );
      errorHighlights = { ...errorHighlights, phoneNumber: true };
    }
    if (values.phoneNumber && values.phoneNumber.match(numericRegEx) === null) {
      errormsg.push(
        t("erps.manageusers.numeric.phone.msg", "Phone Must be numeric.")
      );
      errorHighlights = { ...errorHighlights, phoneNumber: true };
    }
    if (!validateEmail(values.emailAddress)) {
      errormsg.push(
        t(
          "erps.manageusers.format.emailaddress.msg",
          "Please enter your e-mail address in this format 'name@example.com'"
        )
      );
      errorHighlights = { ...errorHighlights, emailAddress: true };
    }
    if (values.emailAddress && values.emailAddress.length > 80) {
      errormsg.push(
        t(
          "erps.manageusers.length.emailaddress.msg",
          "Email address length should not be greater than 60 characters."
        )
      );
      errorHighlights = { ...errorHighlights, emailAddress: true };
    }

    // if (!valuesPass.currentpwd) {
    //   errorHighlights = { ...errorHighlights, currentpwd: true };

    //   errormsg.push(
    //     t(
    //       "erps.manageusers.blank.currentpwd.msg",
    //       "Current password cannot be blank."
    //     )
    //   );
    // }
    // if (!valuesPass.newpwd) {
    //   errorHighlights = { ...errorHighlights, newpwd: true };
    //   errormsg.push(
    //     t("erps.manageusers.blank.newpwd.msg", "New password cannot be blank.")
    //   );
    // }
    // if (!valuesPass.confirmnewpwd) {
    //   errorHighlights = { ...errorHighlights, confirmnewpwd: true };
    //   errormsg.push(
    //     t(
    //       "erps.manageusers.blank.confirmpwd.msg",
    //       "Confirm password cannot be blank."
    //     )
    //   );
    // }
    // if (valuesPass.confirmnewpwd !== valuesPass.newpwd) {
    //   errorHighlights = {
    //     ...errorHighlights,
    //     confirmnewpwd: true,
    //     newpwd: true,
    //   };

    //   errormsg.push(
    //     t(
    //       "erps.manageusers.notmatch.confirmpwd.msg",
    //       "Confirm password does not match with new Password"
    //     )
    //   );
    // }
    setErrorHighlights(errorHighlights);
    return errormsg;
  }

  let errorLists = [
    {
      errorType: "newpwdMinLengthValidation",
      errorMessage: "erps.billerregistration.password.form.instruction.msg1",
    },
    {
      errorType: "newpwdSpCharValidation",
      errorMessage: "erps.billerregistration.password.form.instruction.msg2",
    },
    {
      errorType: "newpwdUpCaseValidation",
      errorMessage: "erps.billerregistration.password.form.instruction.msg3",
    },
    {
      errorType: "newpwdLoCaseValidation",
      errorMessage: "erps.billerregistration.password.form.instruction.msg4",
    },
    {
      errorType: "newpwdDigitValidation",
      errorMessage: "erps.billerregistration.password.form.instruction.msg5",
    },
    {
      errorType: "notMatchPrevious12Months",
      errorMessage: "erps.billerregistration.password.form.instruction.msg6",
    },
  ];

  const errorMessageResponse = errorMessage.filter((err) => err !== null);

  const getFormatFiNumber = (fi) => {
    if (fi !== undefined || fi !== null) {
      if (fi < 10) {
        return `00${fi}`;
      } else if (fi > 9 && fi < 100) {
        return `00${fi}`;
      }
    }
    return "";
  };
  const getFormatTransitNumber = (transitValue) => {
    if (transitValue !== undefined || transitValue !== null) {
      const transitValLen = transitValue?.toString()?.length;
      if (transitValLen === 5) {
        const updatedTransitNumber = transitValue?.toString().slice(1, 4);
        return `*${updatedTransitNumber}*`;
      } else if (transitValLen > 0 && transitValLen < 5) {
        const numberOfZeros = 5 - transitValLen;
        const updatedVal = new Array(numberOfZeros)?.fill(0)?.join("");
        const updatedTransitNumber = `${updatedVal}${transitValue}`.slice(1, 4);
        return `*${updatedTransitNumber}*`;
      }
    }
    return "";
  };

  const getFiAccountNumber = (accountNumber) => {
    if (accountNumber !== undefined || accountNumber !== null) {
      const slicedAccountNumberLength = accountNumber?.toString()?.length - 3;
      const maskedValue = slicedAccountNumberLength
        ? new Array(slicedAccountNumberLength).fill("*").join("")
        : "";
      const slicedAccountNumber = accountNumber
        ?.toString()
        .slice(slicedAccountNumberLength);
      return `${maskedValue}${slicedAccountNumber}`;
    }
    return "";
  };
  const getFormatAccountNumber = (fiNumber, transitNumber, accountNumber) => {
    const formatFiNumber = getFormatFiNumber(fiNumber);
    const formatTransitNumber = getFormatTransitNumber(transitNumber);
    const formatAccountNumber = getFiAccountNumber(accountNumber);

    return `${formatFiNumber}-${formatTransitNumber}-${formatAccountNumber}`;
  };

  return (
    <Box>
      {errorMessageResponse.length ? (
        <Box
          className="manageUsers__errorMsg__container"
          role={"alert"}
          mt={errorMessageResponse?.length ? 2 : 0}
        >
          {errorMessageResponse.length > 0 && (
            <ErrorMessage error={errorMessage} errorRef={errorRef} />
          )}
        </Box>
      ) : (
        ""
      )}
      <Box component="form" autoComplete="off">
        <Stack direction="row" spacing={3}>
          <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
            <Typography variant="body1">
              {t("erps.manageusers.label.userid", "user ID")} :<br />
            </Typography>

            <label className="test-label">{values.userID}</label>
          </div>
          <ERPSBaseTextField
            id="financialInstitution"
            type="text"
            select={true}
            label={t("erps.newuser.label.fi", "Financial Institution")}
            className="common__textField__width"
            size="small"
            value={values.fiNumber}
            items={fiList}
            isDisabled={props.pageName === "account-registration"}
            onChange={(e) => handleChangeText(e, "financialInstitution")}
            SelectProps={{
              native: true,
            }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="role"
            type="text"
            select={true}
            label={t("erps.newuser.label.role", "Role")}
            className="common__textField__width"
            value={values.roleType}
            onChange={(e) => handleChangeText(e, "roleType")}
            size="small"
            required="true"
            items={userRoleListDropdown}
            isDisabled={
              props.pageName === "account-settings" ||
              props.pageName === "account-registration"
            }
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          {values.roleType === "CA" && (
            <ERPSBaseTextField
              id="subscribeEntityId"
              type="text"
              select={true}
              label={t(
                "erps.newuser.label.subscribingentityid",
                "Subscribing entity id"
              )}
              className="common__textField__width"
              size="small"
              items={subscribeEntityList}
              onChange={(e) => handleChangeText(e, "fiCompanyNumber")}
              value={values.fiCompanyNumber}
              isDisabled={
                props.pageName === "account-settings" ||
                props.pageName === "account-registration"
              }
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: true }}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="firstname"
            type="text"
            label={t("erps.newuser.label.firstname", "First name")}
            className="common__textField__width"
            size="small"
            value={values.firstname}
            onChange={(e) => handleChangeText(e, "firstname")}
            required="true"
            error={errorHighlights?.firstname}
          />
          <ERPSBaseTextField
            id="lastName"
            type="text"
            label={t("erps.newuser.label.lastname", "Last name")}
            className="common__textField__width"
            size="small"
            value={values.lastname}
            onChange={(e) => handleChangeText(e, "lastname")}
            required="true"
            error={errorHighlights?.lastname}
          />
        </Stack>
        <Stack direction="row" spacing={3}>
          <ERPSBaseTextField
            id="emailAddress"
            type="text"
            error={errorHighlights?.emailAddress}
            label={t("erps.newuser.label.emailaddress", "Email Address")}
            className="common__textField__width"
            size="small"
            value={values.emailAddress}
            onChange={(e) => handleChangeText(e, "emailAddress")}
            required="true"
          />
          <ERPSBaseTextField
            id="phoneNumber"
            type="text"
            label={t("erps.newuser.label.phonenumber", "Phone number")}
            className="common__textField__width"
            size="small"
            value={values.phoneNumber}
            onChange={(e) => handleChangeText(e, "phoneNumber")}
          />
        </Stack>

        <Stack direction="row" spacing={3}>
          <ERPSBaseRadioGroup
            name="language"
            value={values.language}
            label="Language"
            onChange={(e) => handleChangeText(e, "language")}
            items={languageItems}
          />
        </Stack>
        <Stack direction="column" style={{ marginTop: "4rem" }}>
          <Typography variant="h2">
            {t("erps.billerregistration.label.billing", "Billing")}
          </Typography>
        </Stack>

        <Stack direction="row" spacing={3}>
          <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
            <Typography variant="body1">
              {t("erps.billerregistration.label.billerId", "Billing ID")} :
              <br />
            </Typography>

            <label className="test-label">{values.billerId}</label>
          </div>

          <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
            <Typography variant="body1">
              {t("erps.billerregistration.label.billerName", "Biller Name")} :
              <br />
            </Typography>

            <label className="test-label">
              {billerProfileData?.billerName}
            </label>
          </div>
        </Stack>

        <Stack direction="row" spacing={3}>
          <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
            <Typography variant="body1">
              {t(
                "erps.billerregistration.label.billingAccount",
                "Billing account"
              )}{" "}
              :<br />
            </Typography>
            <label className="test-label">
              {getFormatAccountNumber(
                user?.billerNonVolatileDto?.debit_fi_number,
                user?.billerNonVolatileDto?.debit_transit_number,
                values.billerAccountNumber
              )}
            </label>
          </div>

          <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
            <Typography variant="body1">
              {t(
                "erps.billerregistration.label.creditingAccount",
                "Crediting account"
              )}{" "}
              :<br />
            </Typography>

            <label className="test-label">
              {getFormatAccountNumber(
                user?.searchBillerFilesDto?.creditor_fi_number,
                user?.searchBillerFilesDto?.creditor_transit_number,
                user?.searchBillerFilesDto.creditor_account_number
              )}
            </label>
          </div>
        </Stack>

        <Stack mt={2} direction="row" spacing={3}>
          <ERPSBaseCheckBoxGrp
            name="emailNotificationOk"
            value={values.emailNotificationOk == "Y" ? true : false}
            label="Send email notification"
            onChange={(e) =>
              handleChangeEmailNotification(e, "emailNotificationOk")
            }
          />
          <ERPSBaseRadioGroup
            name="csvAdviceOk"
            marginTop=".8rem"
            className="document_format"
            value={values.csvAdviceOk}
            label="Document format"
            onChange={(e) => handleChangeText(e, "csvAdviceOk")}
            items={documentFormatItems}
          />
        </Stack>

        <Stack direction="column" style={{ marginTop: "4rem" }}>
          <Typography variant="h2">
            {t("erps.newuser.label.challengeques", "Challenge question")}
          </Typography>

          <Typography variant="body1" style={{ marginTop: "1rem" }}>
            {t(
              "erps.newuser.label.challengetext",
              "The challenge question and response are used to help securely identify you if you call support."
            )}
          </Typography>
        </Stack>

        <Stack direction="column" style={{ marginTop: "1rem" }}>
          <ERPSBaseTextField
            id="challengeques"
            type="text"
            select={true}
            label={t("erps.newuser.label.challengeques", "Challenge question")}
            className="three_column__textField"
            required="true"
            size="small"
            items={challengeQuesList}
            value={values.challengeNumber}
            onChange={(e) => handleChangeText(e, "challengeNumber")}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: true }}
            error={errorHighlights?.challengeNumber}
          />
          <ERPSBaseTextField
            id="challengeresponse"
            type="text"
            label={t(
              "erps.newuser.label.challengeresponse",
              "Challenge response"
            )}
            className="three_column__textField"
            required="true"
            size="small"
            onChange={(e) => handleChangeText(e, "challengeResponse")}
            value={values.challengeResponse}
            error={errorHighlights?.challengeResponse}
          />
        </Stack>
      </Box>

      {/* <Stack direction="column" style={{ marginTop: "4rem" }}>
        <Typography variant="h2">
          {t("erps.billerregistration.label.password", "Password")}
        </Typography>
      </Stack> */}

      <Box component="form" autoComplete="off">
        {/* <Stack direction="column">
          <ERPSBaseTextField
            id="currentpwd"
            type={showPwdIconPWD ? "text" : "password"}
            label={t(
              "erps.billerregistration.label.currentpassword",
              "Current password"
            )}
            className={"three_column__textField"}
            required="true"
            size="small"
            error={
              errorHighlights?.currentpwd ||
              (errors ? errors.isCurrentPasswordError : false)
            }
            value={valuesPass.currentpwd || ""}
            onChange={handleChange}
            inputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    showPwdIconPWD
                      ? t("erps.common.label.showPassword")
                      : t("erps.common.label.hidePassword")
                  }
                >
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPwdIconPWD
                          ? t("erps.common.label.showPassword")
                          : t("erps.common.label.hidePassword")
                      }
                      aria-pressed={showPwdIconPWD ? "true" : "false"}
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPwdIconPWD ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          <ERPSBaseTextField
            id="newpwd"
            type={showPwdIconNEWPWD ? "text" : "password"}
            label={t("erps.userdetails.password.form.newpwd", "New password")}
            className={"three_column__textField"}
            error={
              errorHighlights?.newpwd ||
              (errors ? errors.isNewPasswordError : false)
            }
            required="true"
            size="small"
            value={valuesPass.newpwd || ""}
            onChange={handleChange}
            inputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    showPwdIconNEWPWD
                      ? t("erps.common.label.showPassword")
                      : t("erps.common.label.hidePassword")
                  }
                >
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPwdIconNEWPWD
                          ? t("erps.common.label.showPassword")
                          : t("erps.common.label.hidePassword")
                      }
                      aria-pressed={showPwdIconNEWPWD ? "true" : "false"}
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPwdIconNEWPWD ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          <Box lg={4} sm={6} className="changepassword__instructions">
            <Box mt={1.5} ml={2}>
              <Typography variant="label">
                {t(
                  "erps.userdetails.password.form.instruction.header",
                  "Your new Password must"
                )}{" "}
              </Typography>
            </Box>
            <Box ml={errors?.isValidPassword === undefined ? 0 : -3}>
              <ul
                style={{
                  listStyleType:
                    errors?.isValidPassword === undefined ? "disc" : "none",
                }}
              >
                {errorLists.map(({ errorType, errorMessage }) => (
                  <li style={{ marginBottom: "8px" }}>
                    {errors?.isValidPassword !== undefined ? (
                      <div style={{ display: "flex" }}>
                        {errors?.[errorType] ? (
                          <DoneSharpIcon fontSize="small" color="success" />
                        ) : (
                          <CloseSharpIcon fontSize="small" color="error" />
                        )}
                        <Typography
                          variant="label"
                          style={{ marginLeft: "8px" }}
                        >
                          {t(errorMessage)}
                        </Typography>
                      </div>
                    ) : (
                      <Typography variant="label">{t(errorMessage)}</Typography>
                    )}
                  </li>
                ))}
              </ul>
            </Box>
          </Box>

          <ERPSBaseTextField
            id="confirmnewpwd"
            type={showPwdIconConfirmPWD ? "text" : "password"}
            label={t(
              "erps.userdetails.password.form.confirmnewpwd",
              "Confirm new password"
            )}
            className={"three_column__textField"}
            error={
              errorHighlights?.confirmnewpwd ||
              (errors ? errors.isConfirmPasswordError : false)
            }
            required="true"
            size="small"
            value={valuesPass.confirmnewpwd || ""}
            onChange={handleChange}
            inputProps={{
              endAdornment: (
                <Tooltip
                  title={
                    showPwdIconConfirmPWD
                      ? t("erps.common.label.showPassword")
                      : t("erps.common.label.hidePassword")
                  }
                >
                  <InputAdornment position="end">
                    <IconButton
                      aria-label={
                        showPwdIconConfirmPWD
                          ? t("erps.common.label.showPassword")
                          : t("erps.common.label.hidePassword")
                      }
                      aria-pressed={showPwdIconConfirmPWD ? "true" : "false"}
                      onClick={handleClickShowConfirmPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {showPwdIconConfirmPWD ? (
                        <VisibilityOff />
                      ) : (
                        <Visibility />
                      )}
                    </IconButton>
                  </InputAdornment>
                </Tooltip>
              ),
            }}
          />
          {errors?.isConfirmPasswordError ? (
            <Box
              className="manageUsers__errorMsg__container"
              role={"alert"}
              mt={errors?.isConfirmPasswordError ? 2 : 0}
            >
              {" "}
              {errors?.isConfirmPasswordError && (
                <ErrorMessage
                  error={t(
                    "erps.userdetails.confirmpassword.error.msg",
                    "Confirm password does not match"
                  )}
                  errorRef={errorRef}
                />
              )}
            </Box>
          ) : (
            ""
          )}
        </Stack> */}
        <Stack direction="row" spacing={2}>
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            onClick={navigateToLoginPage}
            loading={dialogCancel}
            label={t("erps.common.button.cancel", "Cancel")}
          />
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            onClick={handleSave}
            label={t("erps.newuser.button.save", "Save")}
            loading={dialogLoading}
          />
        </Stack>
      </Box>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
      <div>
        <ERPSBaseDialog
          role="dialog"
          onClose={handleClose}
          aria-labelledby={
            openDialogForRadio?.isOpen
              ? "document-format-dialog"
              : "delete-profile-dialog"
          }
          open={openDialogForRadio?.isOpen}
        >
          <DialogHeader
            id="delete-profile-dialog"
            onClose={handleClose}
            style={{
              fontFamily: "Roboto,Regular",
              fontSize: "18px",
              color: "#2a2c2e",
            }}
          >
            {getDialogHeader()}
          </DialogHeader>
          <DialogContent>{getDialogContent()}</DialogContent>
          <DialogActions>{getDialogActions()}</DialogActions>
        </ERPSBaseDialog>
      </div>
    </Box>
  );
};

export default BillerProfile;
