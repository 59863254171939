import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { Link } from "@material-ui/core";
import { t } from "i18next";

export const getAvailableDocumentsHeading = (fileDownload) => [
  {
    field: "formatted_file_date",
    headerName: t("erps.availabledocuments.label.filedate"),
    width: 100,
    sortable: false,
  },
  {
    field: "file_type",
    headerName: t("erps.availabledocuments.label.source"),
    width: 180,
    sortable: false,
  },
  {
    field: "file_number",
    headerName: t("erps.availabledocuments.label.filenumber"),
    width: 100,
    sortable: false,
    renderCell: (params) => (params.value == 0) ? "" : params.value,
  },
  {
    field: "transactions_amount",
    headerName: t("erps.availabledocuments.label.value"),
    width: 136,
    sortable: false,
    renderCell: (params) => `$ ${params.value.toFixed(2).toString()}`,
  },
  {
    field: "transaction_count",
    headerName: t("erps.availabledocuments.label.numberoftransactions"),
    width: 165,
    sortable: false,
    renderCell: (params) => (params.value == 0) ? "" : params.value,
  },
  {
    field: "pdf_link",
    headerName: t("erps.availabledocuments.label.download"),
    width: 100,
    sortable: false,
    renderCell: (params) => {
      const pdfLink = params?.row?.pdf_link;
      const csvLink = params?.row?.csv_link;
      const regExp = /(webportal|document|reports|LegacyReports)/i;
      let pdfWithReportPath = (pdfLink && pdfLink.match(regExp) !== null);
      let csvWithReportPath = (csvLink && csvLink.match(regExp) !== null);

      if (pdfLink || csvLink) {
        return (
          <>
            {pdfWithReportPath && (
              <Link underline="always" component="button"
                    onClick={() => fileDownload(params.row, "PDF")} >
                {t("erps.availabledocuments.label.pdf")}  
              </Link> /* label   "erps.downloadreports.label.pdf" */
            )}
            {(pdfWithReportPath && csvWithReportPath) && (<>&nbsp;&nbsp;</>)}
            {csvWithReportPath && (
              <Link underline="always" component="button"
                    onClick={() => fileDownload(params.row, "CSV")} >
                {t("erps.availabledocuments.label.csv")}
              </Link>
            )}
          </>
        );
      }
    },
  },
  {
    field: "download_by",
    headerName: t("erps.availabledocuments.label.downloadedby"),
    width: 120,
    sortable: false,
  },
  {
    field: "formatted_last_download",
    headerName: t("erps.availabledocuments.label.downloadedtime"),
    width: 140,
    sortable: false,
    renderCell: ({ value: lastDOwnloadedDate }) => {
      if (lastDOwnloadedDate) {
        const timeString12hr = new Date(lastDOwnloadedDate + " UTC")
          .toLocaleTimeString("en-US", {
            hour12: true,
            hour: "numeric",
            minute: "numeric",
          })
          ?.replace(" ", "");
        const dateString = lastDOwnloadedDate
          ?.split(" ")?.[0]
          ?.replaceAll("-", "/");
        console.log({ timeString12hr, dateString, lastDOwnloadedDate });

        return dateString && timeString12hr
          ? dateString + " " + timeString12hr
          : dateString || timeString12hr;
      }
      return t("erps.availabledocuments.label.downloadedtime.new");
    },
  },
];

export const viewRelatedBillerDocuments = (props) => [
  {
    field: "billerIdStr",
    headerName: t("erps.availabledocuments.label.billerid"),
    width: 180,
    sortable: false,
  },
  {
    field: "billerName",
    headerName: t("erps.availabledocuments.label.billername"),
    width: 480,
    sortable: false,
  },
  {
    field: "documents",
    headerName: t("erps.availabledocuments.label.viewdocument"),
    sortable: false,
    width: 180,
    renderCell: (params) => {
      if (props?.pageName === "biller-profile") {
        return (
          <Link underline="always" component="button"
                onClick={() => props?.handleOpenViewDocument({billerId: params.row.billerIdStr, billerType: "child"}) } >
            {t("erps.availabledocuments.label.viewdocument")}
          </Link>
        );
      } else {
        let billerId = params?.row?.billerIdStr;
        let reqBillerId;
        if (typeof billerId === "string") {
          if (billerId.includes("-")) {
            reqBillerId = billerId.split("-")[1];
          } else {
            reqBillerId = billerId;
          }
        }
        return (
          <ERPSBaseLink
            to={{
              pathname: `/downloads-documents/view-documents`,
              state: {
                billerId: reqBillerId,
                billerType: "child",
                searchedBillerId: billerId,
              },
            }}
            className="link__blue"
            label={"F. "+ t("erps.availabledocuments.label.viewdocument")}
          />
        );
      }
    },
  },
];

export const EMPTY_DOCUMENTS_CRITERIA = {
  fileDate: "",
  source: "",
  startOffset: 0,
  endOffset: 25,
  startDate: "",
  endDate: "",
};
