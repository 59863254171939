import React, { useEffect } from "react";
import { GlobalStyles } from "@mui/material";
import { StylesProvider } from "@material-ui/styles";
import { BrowserRouter as Router } from "react-router-dom";
import AppRoutes from "./routes/AppRoutes";
import { createBaseMuiTheme } from "./theme";
import { ThemeProvider as MatieralUiThemeProvider } from "@mui/material/styles";
import { useTranslation } from "react-i18next";
import { Helmet } from "react-helmet";
import fiConfig from "./fiConfig.json";
import actions from "./actions";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();

  const appConfig = useSelector(
    (state) => state.appConfigReducer?.data?.API_BASE_URL
  );
  useEffect(() => {
    if (!appConfig) {
      dispatch(actions.appConfigActions.loadEnvProperties());
    }
  }, []);

  useEffect(() => {
    window.addEventListener("popstate", function () {
      window.history.forward();
    });
  }, []);

  const currentUrl = window.location.href;
  const urlFragments = currentUrl.split("/");
  const urlFragmentsWithoutProtocol = urlFragments[2].split(".");

  const currentFI = urlFragmentsWithoutProtocol[0];
  const currentDomain = urlFragmentsWithoutProtocol[1] + "." + urlFragmentsWithoutProtocol[2];

  const referrerFragment = (currentFI !== "canact-billpayportal") ? `?referrer=${currentFI}` : "";
  const returnsPortalUrl = `https://canact-billpayportal.${currentDomain}${referrerFragment}`
  
  let fiConfigKey = currentFI;

  if(currentFI == "canact-billpayportal" ){
    if(currentUrl.includes("referrer")){
      sessionStorage.setItem("referrer", urlFragments[3].split("=")[1]);
    }

    if(sessionStorage.getItem("referrer")){
      fiConfigKey = currentFI + "-" + sessionStorage.getItem("referrer");
    }
  }

  const loadThemeStyle = () => {
    let fiName, fiNumber, type;

    if (fiConfig[fiConfigKey]) {
      ({ fiName, fiNumber, type } = fiConfig[fiConfigKey]);
    } else {
      (fiName = "D&D"), (fiNumber = 50), (type = "admin");
    }

    localStorage.setItem("fiName", fiName);
    localStorage.setItem("fiNumber", fiNumber);
    localStorage.setItem("type", type);

    const themeName = `${fiName}.css`;
    return import(`./theme/${themeName}`);
  };

  const muiTheme = createBaseMuiTheme();
  const { i18n } = useTranslation();
  console.log("App loaded");

  return (
    <StylesProvider injectFirst>
      <MatieralUiThemeProvider theme={muiTheme}>
        <GlobalStyles styles={loadThemeStyle} />
        <Router initialEntries={["/"]}>
          <AppRoutes returnsPortalUrl={returnsPortalUrl}/>
        </Router>
        <Helmet htmlAttributes={{ lang: i18n.language }} />
      </MatieralUiThemeProvider>
    </StylesProvider>
  );
}

export default App;
