import { useTranslation } from "react-i18next";
import {
  getManageUsersGridHeadings,
  EMPTY_SEARCH_CRITERIA,
} from "../../constants/ManageUsersConstants";
import { withSidebar } from "../hoc/withSidebar";
import warningIcon from "../../assets/images/warning-icon.png";
import { useCustomHook } from "../custom/useCustomHook";
import CommonLayout from "../custom/containers/CommonLayout";
import { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import { userActions } from "../../actions/user.actions";
import { billerProfileActions } from "../../actions/billerProfile.action";
import { getComponentAccess } from "../../utils/helper";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import { Box } from "@material-ui/core";
import userActionTypes from "../../actions/types/user.action.type";

const ManageUsers = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const {
    history,
    location: { pathname, state },
    setHasNewUser,
  } = props;
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const userResult = useSelector((state) => state.userDetailReducer);
  const userRoles = useSelector((state) => state.userRoleTypeReducer);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const agreement_accepted = useSelector(
    (state) =>
      state.billerProfileReducer?.billerProfileAssociatedData
        ?.billerNonVolatileDto?.agreement_accepted
  );
  const usrQueueMsg = useSelector((state) => state.userQueueReducer?.message);
  const [userData, setUserData] = useState(null);
  const [userRoleList, setUserRoleList] = useState(null);
  const loginUserRoleTypeRef = useRef("");
  const [errorMessage, setErrorMessage] = useState([]);
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [userDetails, setUserDetails] = useState(EMPTY_SEARCH_CRITERIA);
  const successRef = useRef(null);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [usrQueueMsg]);

  useEffect(() => {
    if (userRoles?.userRoleTypeList) {
      setUserRoleList(userRoles.userRoleTypeList);
    }
  }, [userRoles]);

  useEffect(() => {
    if (loginUser?.role) {
      loginUserRoleTypeRef.current = loginUser.role.role_type;
    }
  }, [loginUser]);

  useEffect(() => {
    // Manage Biller -> Users tab
    if (props?.pageName === "biller-profile") {
      dispatch(
        billerProfileActions.getBillerProfileWithAssociatedObjects(
          props?.billerId || loginUser?.billerNonVolatileDto?.biller_id,
          t,
          props?.handleTabLoading
        )
      );
    }
  }, []);

  useEffect(() => {
    if (userResult?.userData) {
      const data = userResult.userData.map((element) => {
        if (element?.internalBMOUserId) {
          return {
            ...element,
            userId: element?.internalBMOUserId,
          };
        }
        return element;
      });
      setUserData(data);
    }
    if (userResult?.userDetails) {
      const { startOffset, endOffset } = userResult?.userDetails;
      const rowPerPage = endOffset - startOffset;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      setPage(pageNumber);
      setRowPerPage(rowPerPage);
      setUserDetails(userResult.userDetails);
    }
    if (userResult?.error) {
      setErrorMessage([userResult.error]);
    }
  }, [userResult]);

  useEffect(() => {
    if (
      usrQueueMsg &&
      ((props?.pageName !== "biller-profile" &&
        state?.componentAccess &&
        !state?.fromDetailPage) ||
        (!props?.fromUserProfile &&
          state?.billerId &&
          props?.pageName === "biller-profile") ||
        state?.dropDownChange)
    ) {
      dispatch({ type: userActionTypes.USER_QUEUE_DELETE_ERROR, error: null });
    } else if (state?.fromDetailPage || props?.fromUserProfile) {
      const roleList = userRoleList?.map(({ role_type }) => role_type);
      dispatch(
        userActions.getUserDetails({
          count: 0,
          ...userResult?.userDetails,
          roleList,
          setLoading,
          t,
          billerId:
            props?.billerId || loginUser?.billerNonVolatileDto?.biller_id,
        })
      );
    }

    if (
      (state?.componentAccess || state?.billerId || state?.dropDownChange) &&
      userResult?.userDetails &&
      !state?.fromDetailPage &&
      props?.pageName !== "biller-profile"
    ) {
      dispatch({ type: userActionTypes.GET_USER_DETAIL_SUCCESS, data: [] });
      setUserDetails(EMPTY_SEARCH_CRITERIA);
      setUserData(null);
    } else if (
      props?.pageName === "biller-profile" &&
      !props?.fromUserProfile
    ) {
      dispatch({ type: userActionTypes.GET_USER_DETAIL_SUCCESS, data: [] });
      setUserDetails(EMPTY_SEARCH_CRITERIA);
      setUserData(null);
    }
    dispatch(
      userActions.getUserRoleTypeList(
        loginUser.fiNumber,
        loginUserRoleTypeRef.current,
        "manageusercomponent",
        t,
        null,
        false
      )
    );
  }, []);

  const getComponentAccessData = () => {
    if (props?.pageName === "biller-profile") {
      const manageBillerUserForAccess = props?.componentAccess.find(
        ({ componentType }) => componentType === "BMUTAB"
      );
      return manageBillerUserForAccess?.childComponents;
    }
    return (
      state?.componentAccess ||
      getComponentAccess(
        {
          function_type_code: "MU",
          label_type_code: "UM",
        },
        loginUser
      )
    );
  };

  const componentAccessData = getComponentAccessData();

  const checkUserExists = (
    roleId,
    userId,
    redirectPath,
    pathProps,
    args,
    callbackFunc
  ) => {
    const fiNumberValue = selectedFi
      ? parseInt(selectedFi)
      : loginUser.fiNumber;
    const updatedFiNumber =
      callbackFunc && fiNumberValue == 50 ? 99 : fiNumberValue;
    setInitialLoading(true);
    dispatch(
      userActions.checkUserExists({
        userId,
        fiNumber: updatedFiNumber,
        translation: t,
        setLoading: setInitialLoading,
        setErrorMessage,
        history,
        redirectPath,
        pathProps,
        args,
        callbackFunc,
        hasSuccessMessageAvailable: usrQueueMsg ? true : false,
        handleClose,
        roleId,
      })
    );
  };

  const pageName = pathname.split("/")[1];

  const handleClose = () => {
    dispatch({ type: userActionTypes.USER_QUEUE_DELETE_ERROR, error: null });
    if (setHasNewUser) {
      setHasNewUser(false);
    }
  };
  const {
    handleManageUserDetailsSearch,
    searchCriteriaChangeHandler,
    resetHandler,
    isSearchValid,
    searchResults,
    initiateSearch,
    paginateManageUsers,
  } = useCustomHook({
    tableResults: userData,
    tableHeadings: getManageUsersGridHeadings(
      loginUserRoleTypeRef.current,
      componentAccessData,
      props?.handleOpenUserProfile,
      props?.stateId,
      props?.parentComponentAccess,
      props?.pageName
    ),
    initialStates: EMPTY_SEARCH_CRITERIA,
    setErrorMessage,
    errorMessage,
    userRoleList,
    setLoading,
    setInitialLoading,
    setUserData,
    setManageUserState: setUserDetails,
    pageName: "manageUser",
    userDetails,
    billerId: props?.billerId || loginUser?.billerNonVolatileDto?.biller_id,
    handleCloseSuccessMessage: handleClose,
    hasSuccessMessageAvailable: usrQueueMsg ? true : false,
  });

  const navigateToNewUserComponent = () => {
    if (loginUser) {
      if (setHasNewUser) {
        setHasNewUser(true);
      } else {
        dispatch(
          userActions.getUserRoleTypeList(
            loginUser.fiNumber,
            loginUserRoleTypeRef.current,
            "manageusercomponent",
            t,
            null,
            false
          )
        ); // roleType "FU"
        const fiNumberValue = selectedFi
          ? parseInt(selectedFi)
          : loginUser.fiNumber;
        if (fiNumberValue == 99 || fiNumberValue == 88) {
          dispatch(userActions.getSubscribingEntitiesList(fiNumberValue, t));
        }
        history.push(pathname + "/new-user", {
          componentAccess: componentAccessData,
        });
      }
    }
  };

  const roleType = loginUser?.role?.role_type;
  const hasButtonVisible =
    (roleType === "CE" || roleType === "BA") && pageName === "manage-users";
  const hasNewButtonVisible =
    props?.pageName === "biller-profile"
      ? agreement_accepted === "A"
        ? true
        : false
      : true;
  const getActionTypes = () => {
    if (props?.pageName === "biller-profile") {
      return {
        primaryButtonActionTypeV1: "hasBillerNewUserAccess",
        firstTextInputFieldActionType: "hasBillerUserIDTextField",
        secondTextInputFieldActionType: "hasBillerFNameTextField",
        thirdTextInputFieldActionType: "hasBillerLNameTextField",
        primaryButtonActionTypeV2: "hasResetBillerUserBtn",
        secondaryButtonActionType: "hasSearchBillerUserAccess",
      };
    }
    return {
      primaryButtonActionTypeV1: "hasNewUserAccess",
      firstTextInputFieldActionType: "hasUserIDTextField",
      secondTextInputFieldActionType: "hasFNameTextField",
      thirdTextInputFieldActionType: "hasLNameTextField",
      primaryButtonActionTypeV2: "hasResetBtn",
      secondaryButtonActionType: "hasSearchUserAccess",
    };
  };

  return (
    <>
      {usrQueueMsg && (
        <Box>
          <ConfirmationMessage
            message={usrQueueMsg}
            setShowMessage={handleClose}
            inputRef={successRef}
          />
        </Box>
      )}
      <CommonLayout
        pageTitle={
          props?.pageName === "biller-profile"
            ? t("erps.managebillerusers.page.title")
            : t("erps.manageusers.page.title")
        }
        pageDescription={
          hasNewButtonVisible && (roleType === "PA" || roleType === "WA")
            ? t("erps.manageusers.page.full.description")
            : t("erps.manageusers.page.description")
        }
        primaryButtonLabel={t("erps.manageusers.button.newuser")}
        pageSubHeading={t("erps.manageusers.searchuser.heading")}
        pageSubHeadingDescription={t("erps.manageusers.searchuser.subheading")}
        errorMssg={
          errorMessage.length
            ? errorMessage
            : t("erps.manageusers.searchuser.error.emptyinput")
        }
        primaryButtonLabelV2={t("erps.common.button.reset")}
        secondaryButtonLabel={t("erps.common.button.search")}
        searchResultLabel={t("erps.manageusers.searchuser.searchresults")}
        searchResultFoundLabel={t("erps.manageusers.searchuser.resultsfound")}
        searchNotStarted={t("erps.manageusers.searchuser.notstarted")}
        firstTextInputFieldLabel={t("erps.manageusers.label.userid")}
        firstTextInputFieldId="userId"
        secondTextInputFieldLabel={t("erps.manageusers.label.firstname")}
        secondTextInputFieldId="firstName"
        thirdTextInputFieldLabel={t("erps.manageusers.label.lastname")}
        thirdTextInputFieldId="lastName"
        startSearchHandler={handleManageUserDetailsSearch}
        searchCriteriaChangeHandler={searchCriteriaChangeHandler}
        resetHandler={resetHandler}
        navigateToNewUserComponent={navigateToNewUserComponent}
        isSearchValid={isSearchValid}
        warningIcon={warningIcon}
        searchCriteria={userDetails}
        initiateSearch={paginateManageUsers}
        pageNumber={pageNumber}
        rowPerPage={rowPerPage}
        searchResults={{
          rows: userData && userData.length ? userData : [],
          count: userData ? userResult.count : -1,
          columns: getManageUsersGridHeadings(
            loginUserRoleTypeRef.current,
            componentAccessData,
            props?.handleOpenUserProfile,
            props?.stateId,
            props?.parentComponentAccess,
            props?.pageName,
            checkUserExists
          ),
        }}
        pageName={props?.pageName || pageName}
        pathName={"/manage-users/user-profile"}
        history={history}
        loading={loading}
        initialLoading={initialLoading}
        isDisabled={hasButtonVisible}
        hasNewButtonVisible={hasNewButtonVisible}
        isRequired={false}
        componentAccess={componentAccessData}
        actionType={getActionTypes()}
        dataGridWidth={
          props?.pageName === "biller-profile"
            ? "dataGrid__box__container__manageUser_table_editBillerProfile"
            : "dataGrid__box__container__manageUser_table"
        }
        initialStates={{
          sorting: {
            sortModel: [
              {
                field: "userId",
                sort: "asc",
              },
            ],
          },
        }}
      />
    </>
  );
};

export default withSidebar(ManageUsers);
