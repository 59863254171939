import React, { useEffect, useState } from "react";
import UserService from "../../utils/keycloak.service.js";
import LoadingIcon from "../custom/inputs/ERPSLoadingIcon.js";
import { Box } from "@material-ui/core";
import { useDispatch } from "react-redux";
import appConfigType from "../../actions/types/app.config.type";

export default function KcLanding(props) {
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const logoutClicked = localStorage.getItem("logoutClicked");
    const fiNumber = localStorage.getItem("fiNumber");
    const fiName = localStorage.getItem("fiName");
    const roleType = localStorage.getItem("type");
    async function initKc() {
      // const logoutClicked = localStorage.getItem("logoutClicked");
      await UserService.initKeycloak({ props, setIsLoggedIn });
      if (logoutClicked) {
        if (roleType === "webportal") {
          localStorage.clear();
          sessionStorage.clear();
          localStorage.setItem("fiName", fiName);
          localStorage.setItem("fiNumber", fiNumber);
          localStorage.setItem("type", roleType);
          dispatch({
            type: appConfigType.LOAD_ENV_PROPERTY_SUCCESS,
            data: null,
          });
          window.location.href = "/";
        } else if(roleType === "rrportal") {
          sessionStorage.clear();
        } else {
          sessionStorage.clear();
          localStorage.clear();
          UserService.doLogin();
        }
      }
    }
    initKc();
    // if (!logoutClicked) {
    //   localStorage.setItem("logoutClicked", "true");
    // }
  }, []);

  const logout = () => {
    // localStorage.setItem("logoutClicked", "true");
    // debugger
    UserService.doLogout();
  };
  return (
    <>
      <Box textAlign={"center"} mt={30}>
        <LoadingIcon />
      </Box>
      {/* {isLoggedIn ? (
        <>
          {" "}
          <Typography variant="h3">Successfully logged in</Typography>{" "}
          <ERPSBaseLink
            to="/"
            className="header__link__active"
            onClick={logout}
            label={"Logout"}
          />
        </>
      ) : (
        <LoadingIcon />
      )} */}
    </>
  );
}
