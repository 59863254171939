import React, { useContext, useEffect, Fragment } from "react";
import { Box } from "@material-ui/core";
import { Divider } from "@material-ui/core";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomPagination from "../../Pagination/CustomPagination";
import ERPSBaseLink from "../inputs/ERPSBaseLink";
import ERPSBaseButton from "../inputs/ERPSBaseButton";


// TODO: Values  driven by DB
export default function CardList(props) {
  const {
    data,
    onAnyPaginationAction,
    hideFooterPagination,
    pageName,
    nextBtnLoading,
    prevBtnLoading,
  } = props;
  const { rows, count, columns } = data;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    hideFooterPagination ? count : 25
  );

  useEffect(() => {
    if (props?.pageNumber !== undefined) setPage(props?.pageNumber);
  }, [props?.pageNumber]);

  useEffect(() => {
    if (props?.rowPerPage) {
      setRowsPerPage(props?.rowPerPage);
    }
  }, [props?.rowPerPage]);

  const handleChangePage = (event, newPage, actionType) => {
    onAnyPaginationAction({
      ...data,
      startOffset: newPage * rowsPerPage,
      endOffset: newPage * rowsPerPage + rowsPerPage,
      actionType,
    });
    setPage(newPage);
    if (props?.setPage) {
      props?.setPage(newPage);
    }
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    onAnyPaginationAction({
      ...data,
      startOffset: 0,
      endOffset: newRowsPerPage,
    });
    setRowsPerPage(newRowsPerPage);
    setPage(0);
  };

  return (
    <>
      <TableContainer component={Paper} className="cardList_tableContainer">
        <Table
          sx={{
            [`& .${tableCellClasses.root}`]: {
              borderBottom: "none",
              padding: "8px",
            },
          }}
          role="presentation"
          aria-label="simple table"
        >
          <TableBody>
            {rows.map((data, key) => (
              <Fragment key={key}>
              {/*  WHAT IS THIS LABEL FOR??  ITS className MAKES IT HIDDEN ANYWAY
                <label className="cardList_row_hidden_label" key={"l"+key}>{`Result ${
                  key + 1
                }`}</label>
              */}
                {columns.map((k, i) => (
                  <TableRow key={i}>
                    <TableCell>{k.headerName}</TableCell>
                    <TableCell>
                      {k.renderCell
                        ? k.renderCell({
                            value: data[k.field],
                            row: data,
                            ...(pageName === "fax-management" &&
                              data?.id !== undefined && { id: data.id }),
                          })
                        : data[k.field]}
                    </TableCell>
                  </TableRow>
                ))}
                {key !== rows.length - 1 && (
                  <TableRow>
                    <TableCell>
                      <Box mt={4} p={1} sx={{ width: "200%" }}>
                        <Divider
                          variant="fullWidth"
                          //textAlign="center" // commented it out b/c it gives error in the console and it doesn't seem to have any effect
                          light={true}
                        />
                      </Box>
                    </TableCell>
                  </TableRow>
                )}
              </Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {!hideFooterPagination && (
        <CustomPagination
          page={page}
          rowsPerPage={rowsPerPage}
          count={count}
          handleChangePage={handleChangePage}
          handleChangeRowsPerPage={handleChangeRowsPerPage}
        />
      )}
      {pageName === "transaction-enquiry" && (
        <Box display="flex" justifyContent={"start"} ml={40}>
          {page !== 0 ? (
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={"Previous"}
              onClick={(e) => handleChangePage(e, page - 1, "prev")}
              loading={prevBtnLoading}
            />
          ) : (
            ""
          )}
          {!(rows.length < rowsPerPage) ? (
            <Box pl={2}>
              <ERPSBaseButton
                variant="primary"
                className="button_with_margin48"
                label={"Next"}
                onClick={(e) => handleChangePage(e, page + 1, "next")}
                loading={nextBtnLoading}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      )}
    </>
  );
}
