import React, {useEffect, useRef, useState} from "react";
import { useReactToPrint } from "react-to-print";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import {useDispatch, useSelector} from "react-redux";
import ERPSTransactionDetails from "../custom/ERPSTransactionDetails";
import actions from "../../actions";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";
import Stack from "@mui/material/Stack";
import Dialog from "@mui/material/Dialog";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { RoleType }  from "../../constants/sharedConstants.js";


const RecallPendingApprovalsDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        location: { state },
    } = props;

    const [openDialog, setDialogOpen] = useState({ isOpen: false });

    const recallPendingApprovalsData = useSelector(
        (state) => state.transactionEnquiryReducer?.recallPendingApprovalsData?.data
    );
    const successMessage = useSelector(
        (state) => state.transactionEnquiryReducer?.pendingApprovalData  // .cancelTransactionData
    );
    const errorMessage = useSelector(
        (state) => state.transactionEnquiryReducer?.pendingApprovalError   // .cancelTransactionError
    );

    const user = useSelector((state) => state.loginUserReducer.user);
    const roleType = user.role.role_type;

    const errorRef = useRef();
    const successRef = useRef();
    const printComponentRef = useRef();

    const [recallAction, setRecallAction] = useState(null);
    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [pageLoading, setPageLoading] = useState(false);

    useEffect(() => {
        if (successRef.current) {
            successRef.current.focus();
        }
    }, [successMessage && showSuccessMessage]);

    useEffect(() => {
        if (errorRef.current) {
            errorRef.current.focus();
        }
    }, [errorMessage?.length]);

    useEffect(() => {
        dispatch({ type: "UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_SUCCESS", data: null })

        return onExit;  // clean up the ref on exit
    }, []);

    const onExit = () => {
        errorRef.current = null;
        successRef.current = null;
        printComponentRef.current = null;
    }

    let data = recallPendingApprovalsData
        ? recallPendingApprovalsData.find(({ id }) => id === state.id)
        : {};
    if (!data.id)  // SHOULD NOT HAPPEN
        console.log("! ! ! ! ! !  data HAS NO ID ! ! ! ! !");

    const processPendingApproval = async () => {
        setPageLoading(true);
        if (showSuccessMessage)
            setShowSuccessMessage(false);

        let request = {
            action: recallAction,
            recall_reject_id: data.recall_reject_id,
            fi_number: data.fi_number
        };
        if (recallAction === "C") {
            request.correlation_id = data.correlation_id;
            request.payment_date = data.payment_date;
        }

        dispatch(
            actions.transactionEnquiryActions.processPendingApproval(
                {  ...request },
                { setShowSuccessMessage, setLoading: setPageLoading, t }
            )
        );
    };

    const confirmCancelTransaction = () =>  {
        setRecallAction("C");
        setDialogOpen({ isOpen: true });
    };

    const confirmRejectRecall = () =>  {
        setRecallAction("R");
        setDialogOpen({ isOpen: true });
    };

    const confirmApproveRecall = () =>  {
        setRecallAction("A");
        setDialogOpen({ isOpen: true });
    };

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    const getSuccessMessageText = () => {
        if (recallAction === "C")
            return t("erps.recallpendingapprovals.recall.cancelled");
        if (recallAction === "R")
            return t("erps.recallpendingapprovals.recall.rejected");
        if (recallAction === "A")
            return t("erps.recallpendingapprovals.recall.approved");
        return "Unrecognized recallAction: " + recallAction;
    }

    const getConfirmationMessageText = () => {
        if (recallAction === "C")
            return t("erps.rejectpaymentmerchants.dialog.confirm.initiate.reject.request");
        if (recallAction === "R")
            return t("erps.rejectpaymentmerchants.dialog.confirm.reject.recall.request");
        if (recallAction === "A")
            return t("erps.rejectpaymentmerchants.dialog.confirm.approve.recall.request");
        return "Unrecognized recallAction: " + recallAction;
    }

    const renderButtons = () => {
        if (successMessage ||  ["A","E","R"].includes(data?.transaction_status)) {
            return <ERPSBaseButton                  // PRINT
                        variant="primary"
                        className="button_with_margin48"
                        label={t("erps.transactionEnquiry.page.transactionDetails.button.print")}
                        onClick={handlePrint}
                        loading={pageLoading}
            />;
        } else if (roleType === RoleType.BILLER_ADMIN || roleType === RoleType.BILLER_USER) {
            return (
                <Stack direction="row" spacing={2} ml={3}>
                    <ERPSBaseButton                 // REJECT (for Billers)
                        variant="primary"
                        className="button_with_margin48"
                        label={t("erps.common.button.reject")}
                        onClick={confirmRejectRecall}
                        loading={pageLoading}
                    />
                    <ERPSBaseButton                 // APPROVE (for Billers)
                        variant="primary"
                        className="button_with_margin48"
                        label={t("erps.common.button.approve")}
                        onClick={confirmApproveRecall}
                        loading={pageLoading}
                    />
                </Stack>
            );
        } else if (roleType === RoleType.FI_ADMIN || roleType === RoleType.FI_USER) {
            return <ERPSBaseButton                  // CANCEL (for FI Users)
                variant="primary"
                className="button_with_margin48"
                label={t("erps.recallpendingapprovals.page.label.cancel.transaction")}
                onClick={confirmCancelTransaction}
                loading={pageLoading}
            />;
        }
    };

    const detailArguments = {data: data, recallReason: data.recall_reject_reason, recalledBy: data.recalled_rejected_by};

    return (
        <div role="main">
            {successMessage && showSuccessMessage && (
                <ConfirmationMessage
                    message={getSuccessMessageText()}
                    setShowMessage={setShowSuccessMessage}
                    inputRef={successRef}
                />
            )}
            {errorMessage && (
                <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
                    <ErrorMessage error={errorMessage} errorRef={errorRef} />
                </Box>
            )}
            <Box sx={{ ml: 3 }}>
                <Breadcrumb
                    {...props}
                    fromDetailPage={true}
                />
            </Box>

            <ERPSTransactionDetails {...detailArguments} ref={printComponentRef}/>

            <Stack direction="row" spacing={2} ml={3}>
                <ERPSBaseButton
                    variant="secondary"
                    className="button_with_margin48"
                    label={t("erps.newuser.button.back", "Back")}
                    onClick={() =>
                        props.history.push("/recall-pending-approvals/", {
                            fromDetailPage: true
                        })
                    }
                />
                {renderButtons()}
            </Stack>

            <Dialog
                open={openDialog?.isOpen}
                onClick={() => {
                    setDialogOpen({ isOpen: false });
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogHeader
                    id="alert-dialog-title"
                    onClose={() => {
                        setDialogOpen({ isOpen: false });
                    }}
                    className={"box-dialog-header"}
                >
                </DialogHeader>
                <DialogContent
                    id="alert-dialog-description"
                    className={"cancel-dialog-content-size"}
                >
                    {getConfirmationMessageText()}
                </DialogContent>
                <DialogActions className={"box-dialog-buttons"}>
                    <ERPSBaseButton
                        variant="secondary"
                        className="button_with_zeromargin"
                        onClick={() => {
                            setDialogOpen({ isOpen: false });
                        }}
                        label={t("erps.common.button.cancel")}
                    />
                    <ERPSBaseButton
                        variant="primary"
                        className="button_with_zeromargin"
                        onClick={processPendingApproval}
                        label={t("erps.common.button.confirm")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withSidebar(RecallPendingApprovalsDetails);
