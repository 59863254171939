import React, { useEffect, useState } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import { recallRejectHistoryHeading } from "../../constants/RecallRejectHistoryConstants";
import moment from "moment";
import { transactionStatusMap, transactionTypes, RoleType }  from "../../constants/sharedConstants.js";
import ERPSRecallRejectSearch, { getDateLimits, billerDetails } from "../custom/ERPSRecallRejectSearch";
import { getComponentAccess, formatDate } from "../../utils/helper";

const RecallRejectHistory = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { state },
  } = props;

  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageNumber] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const [errorMessage, setErrorMessage] = useState(null);

  const user = useSelector((state) => state.loginUserReducer.user);
  const roleType = user.role.role_type;

  const componentAccess = state?.componentAccess
          || getComponentAccess( { function_type_code: "RRH", label_type_code: "TE" }, user );

  // filter will only keep Txn Statuses for F, W, X.
  const transactionStatusList = transactionStatusMap.entries().filter(e => "FWX".indexOf(e[0]) > -1)
                                 .map(e => ({value: e[0], label: t(e[1])})).toArray();
  transactionStatusList.unshift({value: " ", label: t("erps.common.label.all")});

  let dates = getDateLimits();;
  
  const initialSearchValues = {
    transactionType: transactionTypes[0].value,
    paymentFromDate: dates.aMonthAgo,
    paymentToDate: dates.today,
    transactionStatus: transactionStatusList[0].value,
    fiNumber: "0",
    searchBy: billerDetails[0].value,   // "billerId"
    bmoBillerId:  (roleType === RoleType.BILLER_ADMIN || roleType === RoleType.BILLER_USER) ? user.billerNonVolatileDto.biller_fi_number : "",
    billerName: "",
    billerCustomerNumber: "",
    fiCustomerName: "",
  };

  const rrhResponse = useSelector( (state) => state.transactionEnquiryReducer );

  const [searchValues, setSearchValues] = useState(initialSearchValues);
  const [actualCriteria, setActualCriteria] = useState(
    state?.fromDetailPage ? rrhResponse?.recallRejectHistoryDetails : { ...initialSearchValues, offset: 0, limit: rowPerPage}
  );

  const [transactionData, setTransactionData] = useState( state?.fromDetailPage && rrhResponse?.recallRejectHistoryData || null );
  const [financialInstitutionList, setFinancialInstitutionList] = useState([]);
  const [financialInstitutionMap, setFinancialInstitutionMap] = useState(null);

  const financialInstitutionResponse = useSelector( (state) => state.fiReducer?.financialInstitutionList );

  useEffect(() => {
    if (!financialInstitutionResponse || financialInstitutionResponse.length == 0)
      dispatch(actions.fiActions.getFinancialInstitutionList(t));
    if (state?.fromDetailPage) {
      // transfer into searchValues the attributes saved in rejectTransactionResponse.transactionForRejectDetails
      Object.keys(searchValues).filter(key => rrhResponse.recallRejectHistoryDetails[key])
            .forEach(key => searchValues[key] = rrhResponse?.recallRejectHistoryDetails[key]);
      setRowPerPage(actualCriteria.limit);
      setPageNumber(actualCriteria.offset / actualCriteria.limit);
      state.fromDetailPage = null;  // resetting the coming-from-detail-page flag
    } else if (state?.searchedValues) {  // WHAT DOES THIS DO ??
        setSearchValues(state?.searchedValues);
    } else {  // reset search resutls previously saved in the reducer
      dispatch({
        type: "GET_RECALL_REJECT_HISTORY_SUCCESS",
        data: { data: null, recallRejectHistoryDetails: null },
      });
    }
  }, []);

  useEffect(() => {
    if (financialInstitutionResponse && financialInstitutionResponse.length) {
        const data = financialInstitutionResponse.map(
            ({ fi_name, fi_number }) => ({ value: fi_number, label: `${fi_number} - ${fi_name}` })
        );
        data.unshift({value: "0", label: "All FI's"});
        setFinancialInstitutionList(data);
        setFinancialInstitutionMap(new Map(financialInstitutionResponse.map( ({ fi_number, fi_name }) => [fi_number, fi_name])));
    }
  }, [financialInstitutionResponse]);

//   useEffect(() => {
//     // probably don't need this useEffect
//   }, [searchValues]);

  useEffect(() => {
    if (rrhResponse) {
      const responseData = rrhResponse.recallRejectHistoryData?.data;
      if (responseData?.length) {
        for(let i=0; i < responseData.length; i++)
          responseData[i].seqNo = i + 1 + actualCriteria.offset;
      }
      setTransactionData(responseData);
    }
  }, [rrhResponse]);

  const paginate = (values) => {  // 'values' has fields startOffset and endOffset (e.g., for 1st Page: 0,25;  2nd Page: 25,50;  3rd: 50,75...)
    actualCriteria.offset = values.startOffset;
    if (actualCriteria.limit !== (values.endOffset - values.startOffset)) // if the user changed the number of rows per page
      actualCriteria.limit = values.endOffset - values.startOffset;

    loadResults(actualCriteria, " (paginate)");
  };

  const loadResults = (queryParams) => {
    if (errorMessage)
      setErrorMessage(null);
    setLoading(true);

    dispatch(
      actions.transactionEnquiryActions.searchRecallRejectHistory(
        queryParams,
        { ...props, t, history, setLoading, setErrorMessage}
      )
    );
  };

  const handleReset = () => {
    setSearchValues({...initialSearchValues});
    setActualCriteria({ ...initialSearchValues, offset: 0});
    setPageNumber(0);
    setErrorMessage(null);

    dispatch({
      type: "GET_RECALL_REJECT_HISTORY_SUCCESS",
      data: { data: null, recallRejectHistoryDetails: null },
    });
  };

  const handleSearch = (groomedValues) => {
    if (pageNumber)
      setPageNumber(0);

    if (groomedValues.paymentFromDate)
      groomedValues.paymentFromDate = formatDate(moment(groomedValues.paymentFromDate).format());
    if (groomedValues.paymentToDate)
      groomedValues.paymentToDate = formatDate(moment(groomedValues.paymentToDate).format());
    if (!groomedValues.transactionStatus)
      groomedValues.transactionStatus = transactionStatusList.slice(1).map(ts => ts.value).join(",");
    groomedValues.limit = actualCriteria.limit;
    groomedValues.offset = 0;
    groomedValues.currentFiNumber = user?.fiNumber;
    setActualCriteria({...groomedValues});

    if (roleType === RoleType.FI_ADMIN || roleType === RoleType.FI_USER) {
      if (groomedValues.searchBy === billerDetails[0].value)  // "billerId"
        delete groomedValues["billerName"];
      else if (groomedValues.searchBy === billerDetails[1].value)  //  "billerName"
        delete groomedValues["bmoBillerId"];
    }

    loadResults(groomedValues, " (search)");
  };

  return (
    <div role="main">
      <Box sx={{ ml: 3}}>
        <ERPSRecallRejectSearch 
            pageType="RRH"
            pageTitle={t("erps.recallsrejectshistory.page.title")}
            pageDescription={t("erps.transactionEnquiry.page.description")}
            searchValues={searchValues}
            setSearchValues={setSearchValues}
            dates={dates}
            loading={loading}
            errorMessage={errorMessage}
            handleReset={handleReset}
            handleSearch={handleSearch}
            financialInstitutionList={financialInstitutionList}
            transactionStatusList={transactionStatusList}
          />

        {transactionData !== null && transactionData !== undefined
         && Array.isArray(transactionData) && transactionData.length > 0 ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: transactionData,
                  columns: recallRejectHistoryHeading(componentAccess,
                                                      actualCriteria.transactionType == "RJ" ? financialInstitutionMap : null),
                  count: rrhResponse?.recallRejectHistoryData?.count || 0,
                }}
                sumOfColumnWidth="true"
                onAnyPaginationAction={paginate}
                initialLoading={loading}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
                tableLabelText={"_Page Title_"}
                //tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={false}
                pageName={"transaction-enquiry-rrh"}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: transactionData,
                  columns: recallRejectHistoryHeading(componentAccess, 
                                                      actualCriteria.transactionType == "RJ" ? financialInstitutionMap : null),
                  count: rrhResponse?.recallRejectHistoryData?.count || 0,
                }}
                onAnyPaginationAction={paginate}
                hideFooterPagination={false}
                pageName={"recall-reject-history"}
                pageNumber={pageNumber}
                setPage={setPageNumber}
                rowPerPage={rowPerPage}
              />
            </Box>
          </>
        ) : (
          <Box mt={1}>
            <Typography variant="body1">
                { (transactionData?.length === 0)
                    ? (`0 ${t("erps.transactionEnquiry.page.resultfound")}`)
                    : (t("erps.availabledocuments.searchuser.notstarted", "Search not started"))
                }
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withSidebar(RecallRejectHistory);
