import React from "react";
import AccessDenied from "../components/Error/accessDenied";
import SignIn from "../components/Logon/SignIn";
import { useSelector } from "react-redux";

const AuthRoute = (
  WrappedComponent,
  parentComponentType,
  componentType,
  props
) => {
  const RoutedComponent = () => {
    const loggedInUser = useSelector((state) => state.loginUserReducer?.user);
    const isLoggedIn = useSelector(
      (state) => state.loginUserReducer?.isAuthorized
    );

    const isAuthenticated = () => {
      let auth = false;
      if (loggedInUser) {
        const applicableRoleModules = localStorage.getItem("type") === "rrportal" ? loggedInUser.rrPortalRoles.modules : loggedInUser.role.modules;

        applicableRoleModules.forEach((module) => {
          module.childComponents.forEach((childModule) => {
            if (
              parentComponentType == childModule.parentComponentType &&
              componentType == childModule.componentType
            ) {
              auth = true;
            }
          });
        });
      }
      return auth;
    };
    const { history } = props;
    if (isLoggedIn && isAuthenticated()) {
      const propsWithComponentType = { ...props, componentType: componentType};
      //return <WrappedComponent {...props} />;
      return <WrappedComponent {...propsWithComponentType} />;
    } else if (isLoggedIn) {
      return <AccessDenied {...props} />;
    } else {
      history.push("/");
      return <SignIn />;
    }
  };
  return <RoutedComponent />;
};
export default AuthRoute;
