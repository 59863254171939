import React from "react";
import { PropTypes } from "prop-types";
import TextField from "./style/ERPSBaseTextFieldStyles";
import FormControl from "@mui/material/FormControl";
import { withERPSTextFieldActionValidator } from "../../hoc/withERPSTextFieldActionValidator";
import LoadingIcon from "./ERPSLoadingIcon";

function ERPSBaseTextField(props) {
  const {
    id,
    type,
    value,
    label,
    required,
    onChange,
    size,
    className,
    inputProps,
    items,
    select,
    SelectProps,
    error,
    isDisabled,
    InputLabelProps,
    onBlur,
    onInput,
    hasMargin,
    allowDecimal = false,
    loading = false,
    listDisabled = false,
    onKeyPress,
    helperText
  } = props;

  const handleKeyDown = (e) => {
    // Handle Arrow UP and DOWN key if number field
    if (
      type === "number" &&
      (e.keyCode === 38 ||
        e.keyCode === 40 ||
        e.key === "e" ||
        e.key === "E" ||
        e.key === "+" ||
        e.key === "-" ||
        (e.key === "." && allowDecimal === false))
    ) {
      e.preventDefault();
    }
  };

  const handleWheel = (e) => {
    if(type === "number") {
      // Stop value update on mouse scroll for number field
      e.target.blur();
    }
  }

  return (
    <FormControl>
      <TextField
        select={select}
        error={error}
        id={id}
        type={type}
        value={value}
        label={label}
        onChange={onChange}
        onBlur={onBlur}
        className={className}
        required={required === "true"}
        size={size}
        InputProps={inputProps}
        SelectProps={SelectProps}
        disabled={isDisabled || loading}
        InputLabelProps={InputLabelProps}
        inputProps={inputProps}
        onInput={onInput}
        hasMargin={hasMargin}
        onKeyDown={handleKeyDown}
        onWheel={handleWheel}
        onKeyPress={onKeyPress}
        helperText={helperText}
      >
        {items &&
          items.map((option) => (
            <option
              key={option.value}
              disabled={listDisabled}
              value={option.value}
            >
              {option.label}
            </option>
          ))}
      </TextField>
    </FormControl>
  );
}

ERPSBaseTextField.propTypes = {
  label: PropTypes.string.isRequired,
  size: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
};

export default withERPSTextFieldActionValidator(ERPSBaseTextField);
