import React, { useState, useRef, useEffect } from "react";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSGrid from "../custom/containers/ERPSGrid";
import DeliveryMethodComponent from "./DeliveryMethod";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import i18next from "i18next";
import { EditorState, convertToRaw } from "draft-js";
import { Editor } from "react-draft-wysiwyg";
import draftToHtml from "draftjs-to-html";
import ErrorMessage from "../custom/containers/ErrorMessage";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { getFinancialInstitutionHeadings } from "../../constants/FinancialInstitutionConstant";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { useCustomDropDownHook } from "./useCustomDropDownHook";
import actions from "../../actions";
import billerProfileTypes from "../../actions/types/billerProfile.action.type";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import { useDispatch, useSelector } from "react-redux";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";
import CircularProgress from "@mui/material/CircularProgress";
import {
  DYNAMIC,
  instructTypeList,
  getInstructionText,
} from "./helpers/instructText";

import TextEditor from "./SlateEditor/Editor";
import { useEditorState } from "./SlateEditor/useEditorState";

import { getWeighingValue, getNumericValue } from "./../../helpers/biller";
import { formatDate, getBillerGroupErrorMessage } from "./../../utils/helper";

function NewBillerProfileUser(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    htmlContent,
    englishValue,
    frenchValue,
    handleEditorChange,
    setHtmlContent,
  } = useEditorState({ addNewBiller: true });
  const {
    location: {
      state: { componentAccess },
    },
  } = props;
  const [showApiValidationErrorMessage, setShowApiValidationErrorMessage] =
    useState(false);
  const selectedFi = useSelector((state) => state.fiReducer?.selectedFi);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const fiNumber = selectedFi || loginUser.fiNumber;
  const [count, setCount] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);
  const [editorState, setEditorState] = useState({
    en: EditorState.createEmpty(),
    fr: EditorState.createEmpty(),
  });
  const errorRef = useRef();
  const [isLoading, setIsLoading] = useState(false);

  const apiExceptionRef = useRef();
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});
  const [billerProfile, setBillerProfile] = useState({
    billerLanguageCode: "E",
    billerLiveDate: new Date(),
    ...(fiNumber == "50" && { instructType: DYNAMIC }),
    ...(fiNumber == "10" && { productType: "APA" }),
  });
  const [selectedRow, setSelectedRow] = useState([]);

  const [hasCellError, setCellError] = useState([]);
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [initialDeliveryMethods, setInitialDeliveryMethods] = useState({
    deliveryMethod: "",
    distributionListCode: "",
    distributionFormat: "",
    distributionEDIMailbox: "",
    destinationAttention: "",
    distributionPhoneNumber: "",
    distributionExtensionNumber: "",
    distributionGroupRcpIndex: "C",
  });

  const [reqValidationForDelivery, setValidationForDelivery] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState({
    englishPreview: "",
    frenchPreview: "",
  });
  const [imageError, setImageError] = useState({});
  const [imageLoading, setImageLoading] = useState(null);
  const {
    billerFinancialInstitutionList,
    provinceList,
    deliveryMethodLists,
    formatedMethodMapList,
    errorMessageList,
    financialInstitutionTableList,
    setFinancialInstitutionTableList,
    budgetCategoryCodes,
    financialInstitutionList,
  } = useCustomDropDownHook({ t, billerId: 0, setIsLoading });

  useEffect(() => {
    if (financialInstitutionTableList) {
      const data = financialInstitutionTableList
        .map(({ billerStatus, id }) => {
          if (billerStatus === "A") {
            return id;
          }
          return "";
        })
        .filter((e) => (e === 0 ? true : Boolean(e)));
      setSelectedRow(data);
    }
  }, [financialInstitutionTableList]);
  const successMessage = useSelector(
    (state) => state.billerProfileReducer?.newBillerProfileData
  );

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [
    errorMessageList.length ||
      validationErrors.length ||
      Object.values(imageError).length ||
      showApiValidationErrorMessage,
  ]);

  const customRoutineListData = useSelector(
    (state) => state.billerProfileReducer?.customRoutineListData
  );
  const [customRoutines, setCustomRoutines] = useState([]);

  useEffect(() => {
    if (customRoutineListData) {
      const data = customRoutineListData.map(({ routineName }) => ({
        value: routineName,
        label: routineName,
      }));
      data.unshift({
        value: "",
        label: "",
      });
      setCustomRoutines(data);
    }
  }, [customRoutineListData]);

  useEffect(() => {
    if (!selectedFile?.English?.file) {
      setPreview((preState) => ({
        ...preState,
        englishPreview: "",
      }));
      return;
    }

    const objectUrl = URL.createObjectURL(selectedFile?.English?.file);
    setPreview((preState) => ({
      ...preState,
      englishPreview: objectUrl,
    }));

    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile?.["English"]?.file]);

  useEffect(() => {
    if (!selectedFile?.French?.file) {
      setPreview((preState) => ({
        ...preState,
        frenchPreview: "",
      }));
      return;
    }
    const objectUrl = URL.createObjectURL(selectedFile?.French?.file);
    setPreview((preState) => ({
      ...preState,
      frenchPreview: objectUrl,
    }));
    return () => URL.revokeObjectURL(objectUrl);
  }, [selectedFile?.French?.file]);

  useEffect(() => {
    setBillerProfile((prevBillerProfile) => ({
      ...prevBillerProfile,
      weighing: getWeighingValue(
        parseInt(prevBillerProfile?.validationToDigit),
        parseInt(prevBillerProfile?.validationFromDigit)
      ),
    }));
  }, [billerProfile.validationToDigit, billerProfile.validationFromDigit]);

  const additionalValidationLists = [
    { label: "", value: "" },
    { label: t("erps.billermanagement.label.noneValidation"), value: "NONE" },
    { label: t("erps.billermanagement.label.uniqueRoutine"), value: "URN" },
    {
      label: t("erps.billermanagement.label.checkDigitValidation"),
      value: "CDV",
    },
    {
      label: "Both",
      value: "CDV_URN",
    }
  ];
  const languageItems = [
    {
      name: t("erps.userprofile.language.english.text", "English"),
      value: "E",
    },
    { name: t("erps.userprofile.language.french.text", "French"), value: "F" },
  ];
  const productType = [
    { label: "", value: "" },
    { label: "APA", value: "APA" },
    { label: "MEBP", value: "MEBP" },
  ];
  const nilReports = [
    { name: t("erps.billermanagement.label.nilReports.yes"), value: "Y" },
    { name: t("erps.billermanagement.label.nilReports.no"), value: "N" },
  ];

  const statusList = [
    { label: "", value: "" },
    { label: t("erps.billermanagement.label.inactive"), value: "I" },
    { label: t("erps.billermanagement.label.active"), value: "A" },
  ];
  const appliesToItems = [
    {
      name: t("erps.billermanagement.label.appliesToItems.link"),
      value: "G",
    },
    {
      name: t("erps.billermanagement.label.appliesToItems.biller"),
      value: "C",
    },
  ];

  const imageApiExceptionMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileImageError
  );
  const imageSuccessMessage = useSelector(
    (state) => state.billerProfileReducer?.billerProfileImageData
  );

  const apiExceptionMessage = useSelector(
    (state) => state.billerProfileReducer?.newBillerProfileError
  );

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);
  const [lineOfbusinessData, setLineOfbusinessData] = useState({
    isImgCIBCTelephoneBankingIVRChecked: "Y",
    isImgCIBCBranchBankingChecked: "Y",
    isImgCIBCTelePhoneBankingDeskChecked: "Y",
    isImgCIBCOnlineBankingChecked: "Y",
    isImgPCFinancialTelephoneBankingIVRChecked: "Y",
    isImgPCFinancialTelePhoneBankingDeskChecked: "Y",
    isImgPCFinancialOnlineBankingChecked: "Y",
    isInstCIBCTelephoneBankingIVRChecked: "Y",
    isInstCIBCBranchBankingChecked: "Y",
    isInstCIBCTelePhoneBankingDeskChecked: "Y",
    isInstCIBCOnlineBankingChecked: "Y",
    isInstPCFinancialTelephoneBankingIVRChecked: "Y",
    isInstPCFinancialTelePhoneBankingDeskChecked: "Y",
    isInstPCFinancialOnlineBankingChecked: "Y",
  });

  const checkValidImage = (image, key) => {
    let MAX_IMAGE_SIZE = 100 * 1024;
    if (!image) {
      return `${key} image is required`;
    } else if (!image.name.match(/\.(jpg|jpeg|png|gif|JPG|JPEG|PNG|GIF)$/)) {
      return `${key} image has not an accepted image type`;
    } else if (image.size > MAX_IMAGE_SIZE) {
      return `The ${key} size is too big`;
    }
  };

  const handleFileInput = (e, key) => {
    setImageLoading((preState) => ({
      ...preState,
      [key]: true,
    }));
    e.preventDefault();
    let image = e.target.files[0];
    let error = checkValidImage(image, key);
    if (error) {
      setImageError((pre) => ({
        ...pre,
        [key]: error,
      }));
      setImageLoading((preState) => ({
        ...preState,
        [key]: false,
      }));
    } else {
      setImageError({});
      let reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = () => {
        const imageInfo = {
          file: image,
          fileName: image.name,
          fileBase64Content: reader.result,
          languageCode: key === "English" ? "en" : "fr",
        };

        setSelectedFile((prev) => ({
          ...prev,
          [key]: imageInfo,
        }));
        setImageLoading((preState) => ({
          ...preState,
          [key]: false,
        }));
      };
    }
  };

  const handleRemoveImage = (lang) => {
    if (selectedFile) {
      if (lang === "English") {
        const { French } = selectedFile;
        const { frenchPreview } = preview;
        setSelectedFile({ French });

        setPreview({ frenchPreview });
      } else if (lang === "French") {
        const { English } = selectedFile;
        const { englishPreview } = preview;
        setSelectedFile({ English });

        setPreview({ englishPreview });
      }
      dispatch({
        type: billerProfileTypes.BILLER_PROFILE_IMAGE_UPLOAD_SUCCESS,
        data: null,
      });
      // dispatch(
      //   actions.billerProfileActions.removeImageToS3(imageInfo, {
      //     setShowApiExceptionMessage,
      //     setShowSuccessMessage,
      //     t,
      //   })
      // );
    }
  };

  const newBillerUserComponent = componentAccess.find(
    ({ componentType }) => componentType === "NB"
  );

  const newBillerComponentAccess = newBillerUserComponent?.childComponents;

  const getFormatListItem = (deliveryMethod) => {
    if (formatedMethodMapList) {
      if (deliveryMethod) {
        let data = [];
        const formtedMap = formatedMethodMapList[deliveryMethod];
        if (
          deliveryMethod === "EDI" ||
          deliveryMethod === "FAX" ||
          deliveryMethod === "PC"
        ) {
          let filteredObj;
          if (deliveryMethod === "EDI") {
            filteredObj = { value: "H6 3030", label: formtedMap["H6 3030"] };
          } else if (deliveryMethod === "FAX") {
            filteredObj = {
              value: "DTL FAX EN",
              label: formtedMap["DTL FAX EN"],
            };
          } else if (deliveryMethod === "PC") {
            filteredObj = {
              value: "KITCHENER",
              label: formtedMap["KITCHENER"],
            };
          }

          data = Object.keys(formtedMap)
            .filter((formatKey) => formatKey && formatKey !== filteredObj.value)
            .map((key) => {
              return {
                value: key,
                label: formtedMap[key],
              };
            });
          data.unshift(filteredObj);
          data.unshift({
            value: "",
            label: "",
          });
        } else {
          data = Object.keys(formtedMap).map((key) => {
            return {
              value: key,
              label: formtedMap[key],
            };
          });
          data.unshift({
            value: "",
            label: "",
          });
        }
        data.shift();
        return data;
      }
    }
  };

  const checkValidBillPaymentValue = () => {
    let cellErrorVal = [];
    financialInstitutionTableList.forEach((row) => {
      if (
        row.billPaymentLimit < 0 ||
        row.billPaymentLimit?.toString().length > 10
      ) {
        cellErrorVal.push({
          hasError: true,
          value: row.billPaymentLimit?.toString(),
        });
      } else {
        cellErrorVal.push({
          hasError: false,
          value: row.billPaymentLimit?.toString(),
        });
      }
    });
    setCellError(cellErrorVal);
    return financialInstitutionTableList?.some(
      (row) =>
        row.billPaymentLimit?.toString().length > 10 || row.billPaymentLimit < 0
    );
  };

  const handleCellEditCommit = ({ id, field, value }) => {
    if (field === "billPaymentLimit") {
      const updatedRows = financialInstitutionTableList.map((row) => {
        if (row.id === id) {
          return { ...row, billPaymentLimit: value };
        }
        return row;
      });
      setFinancialInstitutionTableList(updatedRows);
    }
  };
  const handleSelectionModelChange = (id, value) => {
    const updatedRows = financialInstitutionTableList.map((r) => {
      if (id == r.id) {
        return { ...r, billerStatus: value ? "A" : "I" };
      }
      return r;
    });

    setFinancialInstitutionTableList(updatedRows);
  };

  const handleClickDeliveryMethod = () => {
    setCount(count + 1);

    setDeliveryMethods([
      ...deliveryMethods,
      {
        deliveryMethod: "",
        distributionListCode: "",
        distributionFormat: "",
        distributionEDIMailbox: "",
        destinationAttention: "",
        distributionPhoneNumber: "",
        distributionExtensionNumber: "",
        distributionGroupRcpIndex: "C",
      },
    ]);
  };

  const handleRedirect = () => {
    props.history.push("/manage-billers", { componentAccess });
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const getEdiMailBoxValue = (val) => {
    if (val === "AMEX") {
      return "AMEX";
    } else if (val === "CBSA 3030") {
      return "CBSA";
    } else if (val === "REV CAN") {
      return fiNumber == "99" || fiNumber == "50" ? "CCRAADRCPR" : "CIBC";
    } else if (val === "QUEBEC HYD") {
      return fiNumber == "99" || fiNumber == "50" ? "HYDROQUEBEC" : "CIBC";
    } else if (val) {
      return fiNumber == "99" || fiNumber == "50"
        ? "BMO"
        : fiNumber == "10"
        ? "CIBC"
        : "";
    }
    return "";
  };
  const handleChangeInitialDelivery = (key, value) => {
    if (
      key === "distributionFormat" &&
      initialDeliveryMethods?.deliveryMethod === "EDI"
    ) {
      const ediMailBoxValue = getEdiMailBoxValue(value);
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else if (key === "deliveryMethod") {
      let ediMailBoxValue = "";
      const distributionFormatValue = value
        ? getFormatListItem(value)[0]?.value
        : "";
      if (value === "EDI") {
        ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
      }

      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
        distributionFormat: distributionFormatValue,
        distributionEDIMailbox: ediMailBoxValue,
      });
    } else {
      setInitialDeliveryMethods({
        ...initialDeliveryMethods,
        [key]: value,
      });
    }
  };

  const handleChangeDeliveryMethods = (key, i) => (e) => {
    const value = e.target.value;
    const updatedDeliveryMethods = deliveryMethods.map((item, id) => {
      if (id === i) {
        if (key === "distributionFormat" && item.deliveryMethod === "EDI") {
          const ediMailBoxValue = getEdiMailBoxValue(value);
          return {
            ...item,
            [key]: value,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else if (key === "deliveryMethod") {
          let ediMailBoxValue = "";
          const distributionFormatValue = value
            ? getFormatListItem(value)[0]?.value
            : "";
          if (value === "EDI") {
            ediMailBoxValue = getEdiMailBoxValue(distributionFormatValue);
          }
          return {
            ...item,
            [key]: value,
            distributionFormat: distributionFormatValue,
            distributionEDIMailbox: ediMailBoxValue,
          };
        } else {
          return {
            ...item,
            [key]: value,
          };
        }
      }
      return item;
    });

    setDeliveryMethods(updatedDeliveryMethods);
  };

  const handleRemoveDeliveryMethod = (index) => (e) => {
    setCount(count - 1);
    const slicedArr = deliveryMethods.filter((item, i) => index !== i);
    const slicedValidationForDelivery = reqValidationForDelivery.filter(
      (item, i) => index !== i
    );
    setDeliveryMethods(slicedArr);
    setValidationForDelivery(slicedValidationForDelivery);
  };

  const handleGetCellClassName = (params) => {
    if (params.field === "billPaymentLimit") {
      const checkError = hasCellError.find((val, idx) => idx == params.id);
      if (checkError?.hasError) {
        return "billPaymentLimit_error_highlight";
      }
    }
  };
  const handleBlur = (key) => (e) => {
    const value = e.target.value;

    setBillerProfile((preBillerData) => ({
      ...preBillerData,
      [key]: value && typeof value === "string" ? value?.toUpperCase() : value,
    }));
  };
  const handleBillerProfile = (key) => (e) => {
    if (key === "accountValidationValue") {
      dispatch(actions.billerProfileActions.getCustomRoutineList(props));
      if (e.target.value.includes("CDV")) {
        setBillerProfile((prevBillerProfile) => ({
          ...prevBillerProfile,
          [key]: e.target.value,
          checkDigitLocation:
            prevBillerProfile?.billerCustomerNumberMinimum || "",
          validationFromDigit: 1,
          validationToDigit:
            prevBillerProfile?.billerCustomerNumberMinimum &&
            prevBillerProfile?.billerCustomerNumberMinimum - 1,
          modChecker: "10",
          subtract: "Y",
          fold: "Y",
          ...(fiNumber == "50" && { instructType: DYNAMIC }),
        }));
      } else {
        setBillerProfile((prevBillerProfile) => ({
          ...prevBillerProfile,
          [key]: key === "billerLiveDate" ? e : e.target.value,
          modChecker: "",
          checkDigitLocation: "",
          validationFromDigit: "",
          validationToDigit: "",
          fold: "N",
          subtract: "N",
          weighing: "",
          ...(fiNumber == "50" &&
            !e.target.value.includes("URN") && { instructType: DYNAMIC }),
        }));
      }
    } else {
      setBillerProfile((prevBillerProfile) => ({
        ...prevBillerProfile,
        [key]: key === "billerLiveDate" ? e : e.target.value,
      }));
    }
    if (
      fiNumber == "50" &&
      (key === "instructType" || key === "accountValidationValue")
    ) {
      setEditorState({
        en: EditorState.createEmpty(),
        fr: EditorState.createEmpty(),
      });
    }
  };

  const checkRequiredRecipientCcin = () => {
    const hasCcinRequired =
      (initialDeliveryMethods?.deliveryMethod === "EDI" &&
        initialDeliveryMethods?.distributionFormat === "H6 3030") ||
      deliveryMethods.some(
        (method) =>
          method?.deliveryMethod === "EDI" &&
          method?.distributionFormat === "H6 3030"
      );
    return hasCcinRequired;
  };
  const handleBillerProfileCheckbox = (key) => (e) => {
    setBillerProfile((prevBillerProfile) => ({
      ...prevBillerProfile,
      [key]: e.target.checked ? "Y" : "N",
    }));
  };

  const handleLineOfBusiness = (key) => (e) => {
    setLineOfbusinessData((prevState) => ({
      ...prevState,
      [key]: e.target.checked ? "Y" : "N",
    }));
  };

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [
    successMessage && successMessage !== "ERPS0055" && showSuccessMessage,
    imageSuccessMessage,
  ]);

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [
    (apiExceptionMessage || imageApiExceptionMessage) &&
      showApiExceptionMessage,
    imageApiExceptionMessage,
  ]);

  const getRequiredValidationForDeliveryMethod = () => {
    const deliveryValueValidation = [];
    const numericRegEx10 = /^[0-9]{10}$/;
    const invalidContactNumber = "0000000000";
    const validContactNumberRegex = /^([1-9]){1}[0-9]{9}$/;
    deliveryMethods.forEach(
      ({
        deliveryMethod,
        distributionListCode,
        distributionPhoneNumber,
        distributionGroupRcpIndex,
      }) => {
        let obj = {};
        if (!deliveryMethod) {
          obj = { ...obj, deliveryMethod: true };
        }
        if (!distributionListCode?.trim()) {
          obj = { ...obj, distributionListCode: true };
        } else {
          if (
            billerProfile?.billerLink &&
            distributionGroupRcpIndex === "G" &&
            distributionListCode?.trim()
          ) {
            obj = { ...obj, distributionListCode: true };
          }
        }
        if (deliveryMethod === "FAX") {
          if (
            distributionListCode?.trim() &&
            !numericRegEx10.test(distributionListCode?.trim())
          ) {
            obj = { ...obj, distributionListCode: true };
          }
        }
        if (
          distributionPhoneNumber &&
          (!validContactNumberRegex.test(distributionPhoneNumber?.trim()) ||
            distributionPhoneNumber?.trim() == invalidContactNumber)
        ) {
          obj = { ...obj, distributionPhoneNumber: true };
        }
        deliveryValueValidation.push(obj);
      }
    );
    return deliveryValueValidation;
  };

  function hasEvenNumberOfDoubleQuotes(str) {
    let count = 0;
    for (let i = 0; i < str.length; i++) {
      if (str[i] === '"') {
        count++;
      }
    }
    return count % 2 === 0;
  }

  function countCharacters(str) {
    let countDoubleQuotes = 0;
    let countSpaces = 0;
    let countQuestionMarks = 0;

    for (let i = 0; i < str.length; i++) {
      const char = str[i];

      if (char === '"' || char === "”") {
        countDoubleQuotes++;
      } else if (char === " ") {
        countSpaces++;
      } else if (char === "?") {
        countQuestionMarks++;
      }
    }
    return {
      doubleQuotes: countDoubleQuotes,
      spaces: countSpaces,
      questionMarks: countQuestionMarks,
    };
  }

  const checkDistributionLengthValid = () => {
    const invalidContactPhone = "0000000000";
    const numericRegEx10 = /^([1-9]){1}[0-9]{9}$/;

    const initialDistributionNumber =
      initialDeliveryMethods?.distributionPhoneNumber?.toString().trim();
    const hasValidDistributionNumber =
      initialDistributionNumber &&
      (!numericRegEx10.test(initialDistributionNumber) ||
        initialDistributionNumber == invalidContactPhone);
    const hasValidOtherDistributionNumber =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber?.toString()?.trim() &&
          (!numericRegEx10.test(distributionPhoneNumber?.toString()?.trim()) ||
            distributionPhoneNumber?.toString()?.trim() == invalidContactPhone)
      );
    return { hasValidDistributionNumber, hasValidOtherDistributionNumber };
  };

  const hasAccess = (key) => {
    if (key === "faxHeader") {
      return (
        (initialDeliveryMethods?.deliveryMethod === "FAX" &&
          initialDeliveryMethods?.deliveryMethod === "FAX") ||
        deliveryMethods.some((method) => method?.deliveryMethod === "FAX")
      );
    }
    if (key === "recipientCcin") {
      return (
        initialDeliveryMethods?.deliveryMethod ||
        deliveryMethods.some((method) => method?.deliveryMethod)
      );
    }
  };
  const isValidUrl = (url) => {
    try {
      const urlObj = new URL(url);
      const hasUrlValidProtocol =
        urlObj.protocol === "http:" || urlObj.protocol === "https:";
      if (
        hasUrlValidProtocol &&
        (url.includes("http://") || url.includes("https://"))
      ) {
        const urlHostPathName = urlObj?.hostname + urlObj?.pathname;
        const res = urlHostPathName.match(
          /[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-z]{1,126}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/g
        );
        return res !== null;
      }
    } catch (e) {
      return false;
    }
  };

  const checkPhoneNumberValidationForDeliveryMethod = () => {
    const numericRegEx10 = /^[0-9]{10}$/;
    const hasValidLengthForInitialMethod =
      initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim() &&
      !numericRegEx10.test(
        initialDeliveryMethods?.distributionPhoneNumber?.toString()?.trim()
      );

    const hasValidLengthForOtherMethods =
      deliveryMethods.length &&
      deliveryMethods.some(
        ({ distributionPhoneNumber }) =>
          distributionPhoneNumber?.toString()?.trim() &&
          !numericRegEx10.test(distributionPhoneNumber?.toString()?.trim())
      );

    return { hasValidLengthForInitialMethod, hasValidLengthForOtherMethods };
  };

  function validateText(
    text,
    min,
    max,
    alphanumericCommaRegEx,
    errorMessages,
    billerMaxKey,
    billerMinKey,
    highlightErrorKey,
    t
  ) {
    const { alphanumericWithComma, minLengthMustNotZero, totalLengthExceedsMax } =
      errorMessages;

    let errors = [];
    let highlightErrorV1 = {};

    if (!alphanumericCommaRegEx.test(text)) {
      errors.push(t(alphanumericWithComma));
      highlightErrorV1 = {
        ...highlightErrorV1,
        [highlightErrorKey]: true,
      };
    } else {
      if (!min) {
        errors.push(t(minLengthMustNotZero));
        highlightErrorV1 = {
          ...highlightErrorV1,
          [highlightErrorKey]: true,
          [billerMinKey]: true,
        };
      }
      if (!max) {
        errors.push(t(totalLengthExceedsMax));

        highlightErrorV1 = {
          ...highlightErrorV1,
          [highlightErrorKey]: true,
          [billerMaxKey]: true,
        };
      }

      if (min && max) {
        const splittedValue = text?.split(",");
        const invalidLength = splittedValue.find(
          (val) => !(val?.length <= max)
        );

        if (invalidLength) {
          let objMessage = {};

          if (invalidLength?.length > max) {
            objMessage.totalLengthExceedsMax = totalLengthExceedsMax;
          }

          if (Object.keys(objMessage).length) {
            errors.push(...Object.values(objMessage).map((msg) => t(msg)));
            highlightErrorV1[highlightErrorKey] = true;
          }
        }
      }
    }
    return { highlightErrorV1, errors };
  }

  function validateTextForCibc(
    text,
    regexForCibcSuffixPrefix,
    errorMessages,
    highlightErrorKey,
    t
  ) {
    const { totalLengthExceedsMax } = errorMessages;

    let errors = [];
    let highlightErrorV1 = {};

    if (!regexForCibcSuffixPrefix.test(text)) {
      errors.push(t(totalLengthExceedsMax));
      highlightErrorV1 = {
        ...highlightErrorV1,
        [highlightErrorKey]: true,
      };
    } else {
      if (text) {
        const splittedValue = text?.split(",");
        const invalidLength = splittedValue.find((val) => !(val?.length <= 15));

        if (invalidLength) {
          if (invalidLength?.length > 15) {
            errors.push(t(totalLengthExceedsMax));
            highlightErrorV1[highlightErrorKey] = true;
          }
        }
      }
    }
    return { highlightErrorV1, errors };
  }

  const validateBillerProfileUser = (billerProfile) => {
    const alphaNumericRegEx = /^[0-9A-Za-z ]+$/;
    const alphaNumericRegExWithSomeSpecialChars =
      /^[0-9A-Za-z\&\(\)_\-:;"',\.\/ ]+$/;

    const alphaNumericWithoutSpace = /^[0-9A-Za-z_]+$/;
    const invalidContactPhone = "0000000000";
    const validContactNumberRegEx = /^([1-9]){1}[0-9]{9}$/;

    const alphaNumericWithSpecialCharRegExV1 =
      /^[0-9A-Za-z\&\#\(\)_\-:;"',\.\/ ]+$/;

    const alphaNumericWithSpecialCharRegEx =
      /^[éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\&\#\(\)_\-:;"',\.\/ ]+$/;
    const billerNameRegex =
      /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;
    const regexForBillerName =
      fiNumber == 10 ? billerNameRegex : alphaNumericRegExWithSomeSpecialChars;
    const numericRegEx = /^[0-9]+$/;
    const alphanumericCommaRegEx = /^[0-9A-Za-z]+(,[0-9A-Za-z]+)*$/;
    const numericRegEx10 = /^[0-9]{10}$/;
    const regexForCibcSuffixPrefix = /^[^'><$]+$/;

    const modCheckBmo = ["10", "11", "7", "07"];
    const modCheckCibc = ["07", "10", "11", "7"];
    let errors = [];
    let highlightErrorV1 = {};
    const regexForSpecialCharacter = /^[À-ÿ!~@$%^*+=[]{}\|<>?]+$/;
    const regexForSpecialCharacterWithoutFrenchChar = /^[!~@$%^*+=[]{}\|<>?]+$/;

    const alphnumericWithHypen = /^[0-9A-Za-z\-]+$/;
    const billerCustomerNumberFormatRegEx = /^[B9A\-]+$/;

    const englishContent = editorState["en"].getCurrentContent();
    const englishContentLength = englishContent.getPlainText("").length;

    const frenchContent = editorState["fr"].getCurrentContent();
    const frenchContentLength = frenchContent.getPlainText("").length;

    const MAX_LENGTH = fiNumber == "99" ? 1200 : 1600;

    if (!billerProfile?.billerName) {
      errors.push(
        t("erps.billermanagement.error.cannotblank.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (billerProfile?.billerName && billerProfile?.billerName.length > 45) {
      errors.push(
        t("erps.billermanagement.error.nameNotGreaterThan45.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (!regexForBillerName.test(billerProfile?.billerName)) {
      errors.push(
        t("erps.billermanagement.error.nameAlphaNumeric.billerNameEnglish")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }
    if (
      billerProfile?.billerShortName &&
      billerProfile?.billerShortName.length > 20
    ) {
      errors.push(
        t(
          "erps.billermanagement.error.nameNotGreaterThan20.billerShortNameEnglish"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!regexForBillerName.test(billerProfile?.billerShortName)) {
      errors.push(
        t("erps.billermanagement.error.englishshortNameAlphaNumberic")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!billerProfile?.billerCity) {
      errors.push(t("erps.billermanagement.error.cityNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (billerProfile?.billerCity && billerProfile?.billerCity.length > 25) {
      errors.push(t("erps.billermanagement.error.cityNotGreaterThan25"));
      highlightErrorV1 = { ...highlightErrorV1, billerCity: true };
    }
    if (!billerProfile?.billerProvince) {
      errors.push(t("erps.billermanagement.error.provinceNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerProvince: true };
    }

    if (
      billerProfile?.billerPostalCode &&
      !alphaNumericRegEx.test(billerProfile?.billerPostalCode)
    ) {
      errors.push(t("erps.billermanagement.error.postalCodeAlphaNumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerPostalCode: true };
    }

    if (fiNumber == 10 && !billerProfile?.billerBudgetCategory) {
      errors.push(
        t("erps.billermanagement.billerBudgetCategory.error.cannotblank")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerBudgetCategory: true };
    }
    if (fiNumber == 10 && !billerProfile?.billerCounty) {
      errors.push(t("erps.billermanagement.billerCounty.error.cannotblank"));
      highlightErrorV1 = { ...highlightErrorV1, billerCounty: true };
    }
    if (
      fiNumber == 10 &&
      billerProfile?.billerCounty &&
      !alphaNumericWithoutSpace.test(billerProfile?.billerCounty)
    ) {
      errors.push(t("erps.billermanagement.billerCounty.error.alphanumeric"));
      highlightErrorV1 = { ...highlightErrorV1, billerCounty: true };
    }
    if (
      billerProfile?.billerCounty &&
      billerProfile?.billerCounty.length != 3
    ) {
      errors.push(t("erps.billermanagement.billerCounty.error.equalto3chars"));
      highlightErrorV1 = { ...highlightErrorV1, billerCounty: true };
    }

    if (fiNumber == 10 && !billerProfile?.billerShortName) {
      errors.push(
        t("erps.billermanagement.billershortnameenglish.error.cannotblank")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerShortName: true };
    }
    if (!billerProfile?.billerLanguageCode) {
      errors.push(t("erps.billermanagement.error.languageNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, billerLanguageCode: true };
    }
    if (
      billerProfile?.bmoBillerId !== undefined &&
      billerProfile?.bmoBillerId.toString().length < 8
    ) {
      errors.push(t("erps.billermanagement.error.bmoBillerIdMustBe8CharLong"));
      highlightErrorV1 = { ...highlightErrorV1, bmoBillerId: true };
    }
    if (!billerProfile?.primaryContactName) {
      errors.push(t("erps.billermanagement.error.primaryContactNameNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactName &&
      billerProfile?.primaryContactName.length > 35
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameNotGreaterThan35")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (
      billerProfile?.primaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(billerProfile?.primaryContactName)
    ) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactName: true };
    }
    if (!billerProfile?.primaryContactPhone) {
      errors.push(
        t("erps.billermanagement.error.primaryContactNumberNotBlank")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    }
    if (
      billerProfile?.primaryContactPhone !== undefined &&
      billerProfile?.primaryContactPhone?.toString().length != 10
    ) {
      errors.push(
        t("erps.billermanagement.error.telephoneNumberNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    } else if (
      billerProfile?.primaryContactPhone !== undefined &&
      (billerProfile?.primaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.primaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidprimaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, primaryContactPhone: true };
    }

    if (
      billerProfile?.secondaryContactName &&
      !alphaNumericWithSpecialCharRegEx.test(
        billerProfile?.secondaryContactName
      )
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryContactNameInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactName: true };
    }

    if (
      billerProfile?.secondaryContactPhone &&
      billerProfile?.secondaryContactPhone?.toString().length != 10
    ) {
      errors.push(
        t("erps.billermanagement.error.secondaryTelephoneNotGreaterThan10")
      );
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    } else if (
      billerProfile?.secondaryContactPhone &&
      (billerProfile?.secondaryContactPhone == invalidContactPhone ||
        !validContactNumberRegEx.test(billerProfile?.secondaryContactPhone))
    ) {
      errors.push(t("erps.billermanagement.error.invalidsecondaryTelephone"));
      highlightErrorV1 = { ...highlightErrorV1, secondaryContactPhone: true };
    }

    if (!billerProfile?.creditorFiNumber) {
      if (fiNumber == 10) {
        errors.push(
          t("erps.billermanagement.error.billerFiNotBlank.creditorfi")
        );
      } else {
        errors.push(t("erps.billermanagement.error.billerFiNotBlank"));
      }
      highlightErrorV1 = {
        ...highlightErrorV1,
        creditorFiNumber: true,
      };
    }
    if(fiNumber==10){
      if (!billerProfile?.debitTransitNumber) {
          errors.push(t("erps.billermanagement.error.debitTransitNumberNotBlank"));
          highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
      }
      if (
          billerProfile?.debitTransitNumber &&
          numericRegEx.test(billerProfile?.debitTransitNumber) &&
          billerProfile?.debitTransitNumber.length > 5
        ) {
          errors.push(
            t("erps.billermanagement.error.debitTransitNumberNotGreaterThan5Digit")
          );
          highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
        }
      if (
          billerProfile?.debitTransitNumber &&
          !numericRegEx.test(billerProfile?.debitTransitNumber)
        ) {
          errors.push(
            t(
              "erps.billermanagement.error.debitTransitNumberNumbericValue")
          );
          highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
        }
      if (!alphnumericWithHypen.test(billerProfile?.debitAccountNumber)) {
          errors.push(
            t("erps.billermanagement.error.debitAccountNumberInvalidValue")
          );
          highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
        }
      }

    if (!billerProfile?.creditorTransitNumber) {
      errors.push(t("erps.billermanagement.error.creditTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      numericRegEx.test(billerProfile?.creditorTransitNumber) &&
      billerProfile?.creditorTransitNumber.length > 5
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNotGreaterThan5Digit")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }
    if (
      billerProfile?.creditorTransitNumber &&
      !numericRegEx.test(billerProfile?.creditorTransitNumber)
    ) {
      errors.push(
        t("erps.billermanagement.error.creditTransitNumberNumbericValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorTransitNumber: true };
    }

    if (!billerProfile?.creditorAccountNumber) {
      errors.push(t("erps.billermanagement.error.creditAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (!alphnumericWithHypen.test(billerProfile?.creditorAccountNumber)) {
      errors.push(
        t("erps.billermanagement.error.creditAccountNumberInvalidValue")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (
      billerProfile?.creditorAccountNumber &&
      billerProfile?.creditorAccountNumber.length > 12
    ) {
      errors.push(
        t("erps.billermanagement.error.creditAccountNotGreaterThan12")
      );
      highlightErrorV1 = { ...highlightErrorV1, creditorAccountNumber: true };
    }
    if (fiNumber == 10 && !billerProfile?.debitTransitNumber) {
      errors.push(t("erps.billermanagement.error.debitTransitNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, debitTransitNumber: true };
    }
    if (fiNumber == 10 && !billerProfile?.debitAccountNumber) {
      errors.push(t("erps.billermanagement.error.debitAccountNumberNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
    }
    if (
      fiNumber == 10 &&
      billerProfile?.debitAccountNumber &&
      billerProfile?.debitAccountNumber.length > 12
    ) {
      errors.push(
        t("erps.billermanagement.error.debitAccountNotGreaterThan12")
      );
      highlightErrorV1 = { ...highlightErrorV1, debitAccountNumber: true };
    }
    if (fiNumber == 10 && !billerProfile?.debitFiNumber) {
      errors.push(t("erps.billermanagement.error.debitFiNumberNotBlank.v1"));
      highlightErrorV1 = { ...highlightErrorV1, debitFiNumber: true };
    }

    if (checkValidBillPaymentValue()) {
      errors.push(
        t("erps.billermanagement.error.paymentLengthNotGreaterThan10Digits")
      );
    }
    if (
      !initialDeliveryMethods?.deliveryMethod ||
      (deliveryMethods.length &&
        deliveryMethods.some(({ deliveryMethod }) => !deliveryMethod))
    ) {
      errors.push(t("erps.billermanagement.error.deliveryMethodNotBlank"));
      highlightErrorV1 = { ...highlightErrorV1, deliveryMethod: true };
    }
    if (
      !initialDeliveryMethods?.distributionListCode.trim() ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode }) => !distributionListCode?.trim()
        ))
    ) {
      if (!initialDeliveryMethods?.distributionListCode.trim()) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.destinationNotBlank"));
    } else if (billerProfile?.billerLink) {
      if (
        (initialDeliveryMethods?.distributionListCode.trim() &&
          initialDeliveryMethods?.distributionGroupRcpIndex === "G") ||
        (deliveryMethods.length &&
          deliveryMethods.some(
            ({ distributionListCode, distributionGroupRcpIndex }) =>
              distributionListCode?.trim() && distributionGroupRcpIndex === "G"
          ))
      ) {
        if (
          initialDeliveryMethods?.distributionListCode.trim() &&
          initialDeliveryMethods?.distributionGroupRcpIndex === "G"
        ) {
          highlightErrorV1 = {
            ...highlightErrorV1,
            initialDistributionListCode: true,
          };
        } else {
          highlightErrorV1 = {
            ...highlightErrorV1,
            distributionListCode: true,
          };
        }
        errors.push(
          t("erps.billermanagement.error.memberAsLinkNotEnterdestination")
        );
      }
    }

    if (
      (initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)) ||
      (deliveryMethods.length &&
        deliveryMethods.some(
          ({ distributionListCode, deliveryMethod }) =>
            deliveryMethod === "FAX" &&
            distributionListCode &&
            !numericRegEx10.test(distributionListCode)
        ))
    ) {
      if (
        initialDeliveryMethods.deliveryMethod === "FAX" &&
        initialDeliveryMethods?.distributionListCode &&
        !numericRegEx10.test(initialDeliveryMethods?.distributionListCode)
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionListCode: true,
        };
      } else {
        highlightErrorV1 = { ...highlightErrorV1, distributionListCode: true };
      }
      errors.push(t("erps.billermanagement.error.invaliddestinationBlank"));
    }
    if (
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForInitialMethod ||
      checkPhoneNumberValidationForDeliveryMethod()
        ?.hasValidLengthForOtherMethods
    ) {
      if (
        checkPhoneNumberValidationForDeliveryMethod()
          ?.hasValidLengthForInitialMethod
      ) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      errors.push(
        t(
          "erps.billermanagement.error.distributionTelephoneNumberShouldBe10Digit"
        )
      );
    }
    if (
      checkDistributionLengthValid()?.hasValidDistributionNumber ||
      checkDistributionLengthValid()?.hasValidOtherDistributionNumber
    ) {
      if (checkDistributionLengthValid()?.hasValidDistributionNumber) {
        highlightErrorV1 = {
          ...highlightErrorV1,
          initialDistributionPhoneNumber: true,
        };
      }
      // if (
      //   checkDistributionLengthValid()?.hasValidDistributionNumber ||
      //   checkDistributionLengthValid()?.hasValidOtherDistributionNumber
      // ) {
      //   errors.push(
      //     t("erps.billermanagement.error.distributionTelephoneNumberInvalid")
      //   );
      // }
    }

    if (getRequiredValidationForDeliveryMethod().length) {
      const validationForDeliveryMethod =
        getRequiredValidationForDeliveryMethod();
      setValidationForDelivery(validationForDeliveryMethod);
    }
    if (checkRequiredRecipientCcin()) {
      if (
        billerProfile?.recipientCcin === undefined ||
        billerProfile?.recipientCcin === ""
      ) {
        errors.push(t("erps.billermanagement.error.cooperateCreditorNotBlank"));
        highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
      }
      if (
        billerProfile?.recipientCcin >= 0 &&
        billerProfile?.recipientCcin.toString().length != 8 &&
        numericRegEx.test(billerProfile?.recipientCcin)
      ) {
        errors.push(
          t("erps.billermanagement.error.cooperateNotGreaterThan8Digits")
        );
        highlightErrorV1 = { ...highlightErrorV1, recipientCcin: true };
      }
    }
    if (fiNumber == 10) {
      const minVal = billerProfile?.billerCustomerNumberMinimum;
      const maxVal = billerProfile?.billerCustomerNumberMaximum;
      const accontMask = billerProfile?.billerAccountMask;
      const invalidAccountMaskRegex = /^[^'><$]*$/;
      let minLen = 0;
      let maxLen = 0;
      if (accontMask) {
        const { doubleQuotes, spaces, questionMarks } =
          countCharacters(accontMask);
        const accountMaskLen = accontMask.length;
        minLen = accountMaskLen - doubleQuotes - spaces;
        maxLen = accountMaskLen - doubleQuotes - spaces;

        if (accontMask && accontMask?.includes("?")) {
          minLen = minLen - questionMarks;
        }
      }

      if (!accontMask) {
        errors.push(t("erps.billermanagement.error.accountMaskRequired"));
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerAccountMask: true,
        };
      } else if (
        !invalidAccountMaskRegex.test(accontMask) ||
        !hasEvenNumberOfDoubleQuotes(accontMask)
      ) {
        errors.push(t("erps.billermanagement.error.accountMaskInvalidValue"));
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerAccountMask: true,
        };
      }
      if (minVal === undefined) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMinValueRequired")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMinimum: true,
        };
      } else if (minVal != minLen) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMinValueMatchMask")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMinimum: true,
        };
      }
      if (maxVal === undefined) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMaxValueRequired")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMaximum: true,
        };
      } else if (maxVal != maxLen) {
        errors.push(
          t("erps.billermanagement.error.billerCustomerMaxValueMatchMask")
        );
        highlightErrorV1 = {
          ...highlightErrorV1,
          billerCustomerNumberMaximum: true,
        };
      }
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMinimum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMinimum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMinValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      (parseInt(billerProfile?.billerCustomerNumberMaximum) <= 0 ||
        parseInt(billerProfile?.billerCustomerNumberMaximum) > 30)
    ) {
      errors.push(
        t("erps.billermanagement.error.billerCustomerMaxValueInvalid")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMaximum: true,
      };
    }
    if (
      billerProfile?.billerCustomerNumberMinimum !== undefined &&
      billerProfile?.billerCustomerNumberMaximum !== undefined &&
      parseInt(billerProfile?.billerCustomerNumberMinimum) >
        parseInt(billerProfile?.billerCustomerNumberMaximum)
    ) {
      errors.push(
        t("erps.billermanagement.error.billingAccountMinLessThanMax")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberMinimum: true,
        billerCustomerNumberMaximum: true,
      };
    }
    if (fiNumber != 10) {
      const billerMinField = billerProfile?.billerCustomerNumberMinimum;
      const billerMaxField = billerProfile?.billerCustomerNumberMaximum;
      const min = parseInt(billerMinField);
      const max = parseInt(billerMaxField);
      if ((isNaN(min) && !isNaN(max)) || (!isNaN(min) && isNaN(max))) {
        const hasMinEmpty = isNaN(min) && !isNaN(max);
        const hasMaxEmpty = !isNaN(min) && isNaN(max);

        if (hasMinEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMinValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMinimum: true,
          };
        } else if (hasMaxEmpty) {
          errors.push(
            t("erps.billermanagement.error.billerCustomerMaxValueRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            billerCustomerNumberMaximum: true,
          };
        }
      }
    }

    if (
      billerProfile?.accountValidationValue &&
      billerProfile?.accountValidationValue !== "NONE"
    ) {
      if (
        billerProfile?.accountValidationValue &&
        billerProfile?.accountValidationValue.includes("CDV")
      ) {
        if (!billerProfile?.modChecker) {
          errors.push(t("erps.billermanagement.error.modCheckRequired"));
          highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
        }
        if (billerProfile?.modChecker) {
          if (
            (fiNumber == "99" || fiNumber == "50") &&
            !modCheckBmo.includes(billerProfile?.modChecker)
          ) {
            errors.push(t("erps.billermanagement.error.invalidModCheckBmo"));
            highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
          }
          if (
            fiNumber == "10" &&
            !modCheckCibc.includes(billerProfile?.modChecker)
          ) {
            errors.push(t("erps.billermanagement.error.invalidModCheckCibc"));
            highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
          }
        }

        if (billerProfile?.checkDigitLocation === undefined) {
          errors.push(t("erps.billermanagement.error.digitLocaionRequired"));
          highlightErrorV1 = { ...highlightErrorV1, checkDigitLocation: true };
        }
        if (billerProfile?.validationFromDigit === undefined) {
          errors.push(
            t("erps.billermanagement.error.validatonFromDigitValueRequired")
          );
          highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
        }
        if (billerProfile?.validationToDigit === undefined) {
          errors.push(
            t("erps.billermanagement.error.validatonToDigitValueRequired")
          );
          highlightErrorV1 = { ...highlightErrorV1, validationToDigit: true };
        }
        if (billerProfile?.weighing === undefined) {
          errors.push(t("erps.billermanagement.error.weightingRequired"));
          highlightErrorV1 = { ...highlightErrorV1, weighing: true };
        }
      }
      if (billerProfile?.accountValidationValue.includes("URN")) {
        if (!billerProfile?.uniqueCheckDigitRoutine) {
          errors.push(
            t("erps.billermanagement.error.uniqueCheckDigitRequired")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            uniqueCheckDigitRoutine: true,
          };
        }
        if (
          fiNumber == "10" &&
          billerProfile?.modChecker &&
          !modCheckCibc.includes(billerProfile?.modChecker)
        ) {
          errors.push(t("erps.billermanagement.error.invalidModCheckCibc"));
          highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
        } else if (
          (fiNumber == 99 || fiNumber == 50) &&
          billerProfile?.modChecker &&
          !modCheckBmo.includes(billerProfile?.modChecker)
        ) {
          errors.push(t("erps.billermanagement.error.invalidModCheckBmo"));
          highlightErrorV1 = { ...highlightErrorV1, modChecker: true };
        }
      }

      if (
        billerProfile?.accountValidationValue &&
        (billerProfile?.accountValidationValue === "CDV" ||
          billerProfile?.accountValidationValue === "URN" ||
          billerProfile?.accountValidationValue === "CDV_URN")
      ) {
        if (
          billerProfile?.validationToDigit &&
          billerProfile?.validationFromDigit &&
          billerProfile?.weighing !== undefined &&
          billerProfile?.weighing.length !=
            getWeighingValue(
              parseInt(billerProfile?.validationToDigit),
              parseInt(billerProfile?.validationFromDigit)
            ).length
        ) {
          errors.push(
            t("erps.billermanagement.error.weighingLengthInvalid", {
              length: getWeighingValue(
                parseInt(billerProfile?.validationToDigit),
                parseInt(billerProfile?.validationFromDigit)
              ).length,
            })
          );
          highlightErrorV1 = { ...highlightErrorV1, weighing: true };
        }
      }
    } else {
      highlightErrorV1 = {
        ...highlightErrorV1,
        uniqueCheckDigitRoutine: false,
      };
    }

    if (
      billerProfile?.billerCustomerNumberFormat &&
      !billerCustomerNumberFormatRegEx.test(
        billerProfile?.billerCustomerNumberFormat
      )
    ) {
      errors.push(t("erps.billermanagement.error.accountFormatInvalidValue"));
      highlightErrorV1 = {
        ...highlightErrorV1,
        billerCustomerNumberFormat: true,
      };
    }

    if (parseInt(billerProfile?.checkDigitLocation) <= 0) {
      errors.push(t("erps.billermanagement.error.digitLocationNonZero"));
      highlightErrorV1 = { ...highlightErrorV1, checkDigitLocation: true };
    }
    if (parseInt(billerProfile?.validationFromDigit) <= 0) {
      errors.push(t("erps.billermanagement.error.validationFromNonZero"));
      highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
    }
    if (parseInt(billerProfile?.validationToDigit) <= 0) {
      errors.push(t("erps.billermanagement.error.validationToNonZero"));
      highlightErrorV1 = { ...highlightErrorV1, validationToDigit: true };
    }
    if (
      parseInt(billerProfile?.checkDigitLocation) > 0 &&
      !billerProfile?.accountValidationValue.includes("URN") &&
      parseInt(billerProfile?.checkDigitLocation) >
        parseInt(billerProfile?.billerCustomerNumberMinimum)
    ) {
      errors.push(
        t(
          "erps.billermanagement.error.digitaLocationNotGreaterThanAccountAndNonZero"
        )
      );
      highlightErrorV1 = { ...highlightErrorV1, checkDigitLocation: true };
    }
    if (
      parseInt(billerProfile?.validationFromDigit) > 0 &&
      parseInt(billerProfile?.validationFromDigit) >
        parseInt(billerProfile?.validationToDigit)
    ) {
      errors.push(t("erps.billermanagement.error.validatonToGreaterThanFrom"));
      highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
    }
    if (
      parseInt(billerProfile?.validationFromDigit) > 0 &&
      parseInt(billerProfile?.validationFromDigit) >
        parseInt(billerProfile?.checkDigitLocation)
    ) {
      errors.push(
        t("erps.billermanagement.error.validationFromDigitGreaterThanLocation")
      );
      highlightErrorV1 = { ...highlightErrorV1, validationFromDigit: true };
    }

    if (
      parseInt(billerProfile?.validationToDigit) > 0 &&
      parseInt(billerProfile?.validationToDigit) >
        parseInt(billerProfile?.checkDigitLocation)
    ) {
      errors.push(
        t("erps.billermanagement.error.validationToDigitNotGreaterThanLocation")
      );
      highlightErrorV1 = { ...highlightErrorV1, validationToDigit: true };
    }

    if (
      billerProfile?.uniqueCheckDigitRoutine &&
      !alphaNumericWithSpecialCharRegExV1.test(
        billerProfile?.uniqueCheckDigitRoutine
      )
    ) {
      errors.push(t("erps.billermanagement.error.uniqueRoutineInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, uniqueCheckDigitRoutine: true };
    }

    if (fiNumber != 10 && billerProfile?.prefixText) {
      const prefixText = billerProfile.prefixText;
      const min =
        billerProfile.billerCustomerNumberMinimum !== undefined
          ? +billerProfile.billerCustomerNumberMinimum
          : 0;
      const max =
        billerProfile.billerCustomerNumberMaximum !== undefined
          ? +billerProfile.billerCustomerNumberMaximum
          : 0;
      const prefixErrorMessages = {
        alphanumericWithComma:
          "erps.billermanagement.error.prefixTextAlphanumericWithComma",
        invalidLength: "erps.billermanagement.error.prefixTextinvalid",
        minLengthMustNotZero:
          "erps.billermanagement.error.billerCustomerMinValueMustNot0",
        totalLengthExceedsMax:
          "erps.billermanagement.error.billerCustomerMinMaxOverPrefix",
      };
      const resValues = validateText(
        prefixText,
        min,
        max,
        alphanumericCommaRegEx,
        prefixErrorMessages,
        "billerCustomerNumberMaximum",
        "billerCustomerNumberMinimum",
        "prefixText",
        t
      );
      errors = resValues?.errors ? [...errors, ...resValues?.errors] : errors;
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }

    if (fiNumber != 10 && billerProfile?.suffixText) {
      const suffixText = billerProfile.suffixText;
      const min =
        billerProfile.billerCustomerNumberMinimum !== undefined
          ? +billerProfile.billerCustomerNumberMinimum
          : 0;
      const max =
        billerProfile.billerCustomerNumberMaximum !== undefined
          ? +billerProfile.billerCustomerNumberMaximum
          : 0;
      const suffixErrorMessages = {
        alphanumericWithComma:
          "erps.billermanagement.error.suffixTextAlphanumericWithComma",
        invalidLength: "erps.billermanagement.error.suffixTextinvalid",
        minLengthMustNotZero:
          "erps.billermanagement.error.billerCustomerMinValueMustNot0",
        totalLengthExceedsMax:
          "erps.billermanagement.error.billerCustomerMinMaxOverSuffix",
      };
      const resValues = validateText(
        suffixText,
        min,
        max,
        alphanumericCommaRegEx,
        suffixErrorMessages,
        "billerCustomerNumberMaximum",
        "billerCustomerNumberMinimum",
        "suffixText",
        t,
        errors,
        highlightErrorV1
      );
      errors = [...errors, ...resValues?.errors];
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }

    if (fiNumber == 10 && billerProfile?.suffixText) {
      const suffixText = billerProfile.suffixText;
      const suffixErrorMessages = {
        totalLengthExceedsMax:
          "erps.billermanagement.error.suffixTextNumericWithCommaForCibc",
      };
      const resValues = validateTextForCibc(
        suffixText,
        regexForCibcSuffixPrefix,
        suffixErrorMessages,
        "suffixText",
        t
      );
      errors = resValues?.errors ? [...errors, ...resValues?.errors] : errors;
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }
    if (fiNumber == 10 && billerProfile?.prefixText) {
      const prefixText = billerProfile.prefixText;
      const prefixErrorMessages = {
        totalLengthExceedsMax:
          "erps.billermanagement.error.prefixTextNumericWithCommaForCibc",
      };
      const resValues = validateTextForCibc(
        prefixText,
        regexForCibcSuffixPrefix,
        prefixErrorMessages,
        "prefixText",
        t
      );
      errors = resValues?.errors ? [...errors, ...resValues?.errors] : errors;
      highlightErrorV1 = {
        ...highlightErrorV1,
        ...resValues?.highlightErrorV1,
      };
    }

    if (englishContentLength > MAX_LENGTH) {
      errors.push(
        t("erps.billermanagement.error.englishTextEditor10Character")
      );
      highlightErrorV1 = { ...highlightErrorV1, htmlContentEn: true };
    }

    if (frenchContentLength > MAX_LENGTH) {
      errors.push(t("erps.billermanagement.error.frenchTextEditor10Character"));
      highlightErrorV1 = { ...highlightErrorV1, htmlContentFr: true };
    }
    if (
      fiNumber != 10 &&
      billerProfile?.englishImageUrl &&
      !isValidUrl(billerProfile?.englishImageUrl)
    ) {
      errors.push(t("erps.billermanagement.error.englishImageUrlInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, englishImageUrl: true };
    }
    if (
      billerProfile?.billerComment &&
      billerProfile?.billerComment.length > 160
    ) {
      errors.push(t("erps.billermanagement.error.commentLengthInvalid"));
      highlightErrorV1 = { ...highlightErrorV1, billerComment: true };
    }
    return { errors, highlightErrorV1 };
  };

  const sanitizeBillerData = (jsonObj) => {
    Object.keys(jsonObj).forEach((key) => {
      if (
        jsonObj[key] === undefined ||
        ("string" === typeof jsonObj[key] && jsonObj[key].trim() === "")
      ) {
        delete jsonObj[key];
      }
    });

    return jsonObj;
  };

  const handleSave = () => {
    setSaveLoading(true);
    setShowApiExceptionMessage(false);
    if (showSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (showApiValidationErrorMessage) {
      setShowApiValidationErrorMessage(false);
    }
    const billerProfileSanitizedData = sanitizeBillerData(billerProfile);

    const { errors, highlightErrorV1 } = validateBillerProfileUser(
      billerProfileSanitizedData
    );
    if (errors.length > 0) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      setSaveLoading(false);
      if (errorRef.current) {
        errorRef.current.focus();
      }
    } else if (!(imageError?.English || imageError?.French)) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
      billerProfile.languageCode = i18next.language == "en" ? "E" : "F";
      billerProfile.fiNumber = fiNumber;
      billerProfile.userId = loginUser?.userId;
      billerProfile.createdBy = loginUser?.userId;
      const defaultAlphaValue = fiNumber == "10" ? "M" : "N";
      let reqBody = {
        ...billerProfile,
        billerStatus: "I",
        alphaAllowed: billerProfile?.alphaAllowed || defaultAlphaValue,
        billerProfileExtensions: financialInstitutionTableList,
        distributionList: [initialDeliveryMethods, ...deliveryMethods],
        instructTextEnglish:
          fiNumber == "50" && billerProfile?.instructType === DYNAMIC
            ? getInstructionText(billerProfile, "en")
            : htmlContent["en"],
        instructTextFrench:
          fiNumber == "50" && billerProfile?.instructType === DYNAMIC
            ? getInstructionText(billerProfile, "fr")
            : htmlContent["fr"],
        lineOfbusinessData: lineOfbusinessData,
        billerLiveDate: formatDate(billerProfile.billerLiveDate),
        billerCountry: "CA",
        ...(fiNumber == "50" && { instructType: billerProfile?.instructType }),
        ...(selectedFile?.English && {
          imageENBase64Content: selectedFile?.English?.fileBase64Content,
        }),

        ...(selectedFile?.French && {
          imageFRBase64Content: selectedFile?.French?.fileBase64Content,
        }),
      };
      const nilReportValue = !billerProfile?.nilReportIndex
        ? "N"
        : billerProfile?.nilReportIndex;

      if (nilReportValue) {
        reqBody = { ...reqBody, nilReportIndex: nilReportValue };
      }
      dispatch(
        actions.billerProfileActions.addBillerProfile(
          { ...reqBody },
          {
            t,
            fiNumber,
            setSaveLoading,
            setShowSuccessMessage,
            setShowApiExceptionMessage,
            setLineOfbusinessData,
            setInitialDeliveryMethods,
            setDeliveryMethods,
            setHtmlContent,
            setEditorState,
            setCount,
            EditorState,
            setBillerProfile,
            setFinancialInstitutionTableList,
            financialInstitutionList,
            setPreview,
            setSelectedFile,
            setShowApiValidationErrorMessage,
          }
        )
      );
    }
  };

  return (
    <Box ml={3} role={"main"} aria-labelledby="title">
      {((successMessage && successMessage !== "ERPS0055") ||
        imageSuccessMessage) &&
        showSuccessMessage && (
          <ConfirmationMessage
            message={successMessage || imageSuccessMessage}
            setShowMessage={setShowSuccessMessage}
            inputRef={successRef}
          />
        )}
      <Breadcrumb {...props} componentAccess={componentAccess} />
      <Box className="billerUsers__page__title">
        <Typography variant="h1" id="title">
          {t("erps.billermanagement.button.newbiller", "New Biller")}
        </Typography>
        {isLoading && (
          <Box ml={40}>
            <CircularProgress size={30} />
          </Box>
        )}
      </Box>
      {errorMessageList.length ||
      validationErrors.length ||
      showApiValidationErrorMessage ||
      Object.values(imageError).length ? (
        <Box
          className="manageUsers__errorMsg__container"
          role="alert"
          mt={
            errorMessageList.length ||
            validationErrors.length ||
            showApiValidationErrorMessage ||
            Object.values(imageError).length
              ? 2
              : 0
          }
        >
          {errorMessageList.length ||
          validationErrors.length ||
          showApiValidationErrorMessage ||
          Object.values(imageError).length ? (
            <ErrorMessage
              error={
                showApiValidationErrorMessage
                  ? [
                      ...errorMessageList,
                      ...validationErrors,
                      ...Object.values(imageError),
                      ...getBillerGroupErrorMessage(successMessage),
                    ]
                  : [
                      ...errorMessageList,
                      ...validationErrors,
                      ...Object.values(imageError),
                    ]
              }
              errorRef={errorRef}
            />
          ) : (
            ""
          )}
        </Box>
      ) : (
        ""
      )}
      {(apiExceptionMessage || imageApiExceptionMessage) &&
        showApiExceptionMessage && (
          <Box className="manageUsers__errorMsg__container" role="alert" mt="2">
            <ErrorMessage
              error={apiExceptionMessage || imageApiExceptionMessage}
              errorRef={apiExceptionRef}
            />
          </Box>
        )}
      <Box
        className="manageUsers__page__title"
        role="form"
        aria-labelledby="billerProfile"
      >
        <Typography variant="h2" id="billerProfile">
          {t("erps.billermanagement.page.formtitle", "Biller Profile")}
        </Typography>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerName"
            type="text"
            label={t("erps.billermanagement.label.billerName")}
            className="common__textField__width45"
            size="small"
            onChange={handleBillerProfile("billerName")}
            value={billerProfile?.billerName || ""}
            required="true"
            error={highlightError?.billerName}
            inputProps={{ maxLength: 45 }}
            onBlur={handleBlur("billerName")}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerShortName"
            type="text"
            label={t("erps.billermanagement.label.billerShortName")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerShortName")}
            value={billerProfile?.billerShortName || ""}
            error={highlightError?.billerShortName}
            inputProps={{ maxLength: 20 }}
            required={fiNumber == 10 ? "true" : ""}
            onBlur={handleBlur("billerShortName")}
          />
          {fiNumber == "10" && (
            <ERPSBaseTextField
              id="billerLink"
              type="number"
              label={t("erps.billermanagement.label.billerLink")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("billerLink")}
              value={billerProfile?.billerLink || ""}
              error={highlightError?.billerLink}
              inputProps={{ maxLength: 9 }}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          {fiNumber != "10" && (
            <ERPSBaseTextField
              id="billerDepartment"
              type="text"
              label={t("erps.billermanagement.label.attention", "Attention")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("billerDepartment")}
              value={billerProfile?.billerDepartment || ""}
              error={highlightError?.billerDepartment}
              inputProps={{ maxLength: 35 }}
            />
          )}

          <ERPSBaseTextField
            id="billerAddress"
            type="text"
            label={t("erps.billermanagement.label.address", "Address")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerAddress")}
            value={billerProfile?.billerAddress || ""}
            error={highlightError?.billerAddress}
            inputProps={{ maxLength: 40 }}
          />
          <ERPSBaseTextField
            id="billerCity"
            type="text"
            label={t("erps.billermanagement.label.city", "City")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCity")}
            value={billerProfile?.billerCity || ""}
            required={"true"}
            error={highlightError?.billerCity}
          />
          {fiNumber == "10" && (
            <ERPSBaseTextField
              id="billerSettlementType"
              type="text"
              label={t("erps.billermanagement.label.settlementType")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("billerSettlementType")}
              value={billerProfile?.billerSettlementType || ""}
              error={highlightError?.billerSettlementType}
              isDisabled={true}
            />
          )}
        </Stack>
        <Stack direction="row" spacing={2}>
          {(fiNumber != "10" || selectedFi != "10") && (
            <ERPSBaseTextField
              id="billerCountry"
              type="text"
              label={t("erps.billermanagement.label.country", "Country")}
              className="common__textField__width"
              size="small"
              value={"CA"}
              inputProps={{ maxLength: 2 }}
              isDisabled={true}
            />
          )}
          <ERPSBaseTextField
            id="billerProvince"
            type="text"
            label={t("erps.billermanagement.label.province", "Province")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerProvince")}
            value={billerProfile?.billerProvince || ""}
            select={true}
            items={provinceList}
            SelectProps={{ native: true }}
            InputLabelProps={{ shrink: Boolean(billerProfile?.billerProvince) }}
            error={highlightError?.billerProvince}
            required="true"
          />
          <ERPSBaseTextField
            id="billerPostalCode"
            type="text"
            label={t("erps.billermanagement.label.postalcode", "Postal code")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerPostalCode")}
            value={billerProfile?.billerPostalCode || ""}
            inputProps={{ maxLength: 7 }}
            error={highlightError?.billerPostalCode}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseRadioGroup
            marginTop=".4rem"
            value={billerProfile?.billerLanguageCode || ""}
            label={t("erps.userprofile.language.text", "Language")}
            onChange={handleBillerProfile("billerLanguageCode")}
            items={languageItems}
            required={"true"}
            error={highlightError?.billerLanguageCode}
          />
          <ERPSBaseTextField
            id="groupPriority"
            type="number"
            label={t("erps.billermanagement.label.priority", "Group priority")}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("groupPriority")}
            value={billerProfile?.groupPriority || ""}
            error={highlightError?.groupPriority}
            onInput={(e) => {
              e.target.value = getNumericValue(e.target.value, 0, 2);
            }}
          />
          {fiNumber != "10" && (
            <ERPSBaseTextField
              id="bmoBillerId"
              type="number"
              label={t(
                "erps.billermanagement.label.bmoBillerId",
                "BMO biller ID"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("bmoBillerId")}
              value={billerProfile?.bmoBillerId || ""}
              error={highlightError?.bmoBillerId}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 8);
              }}
            />
          )}
        </Stack>

        {fiNumber == 10 ? (
          <>
            <Stack direction="row" spacing={2}>
              <ERPSBaseTextField
                id="billerStatus"
                type="text"
                label={t("erps.billermanagement.label.status", "Status")}
                className="common__textField__width"
                size="small"
                value={"I"}
                error={highlightError?.billerStatus}
                select={true}
                items={statusList}
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                isDisabled={true}
                onChange={handleBillerProfile("billerStatus")}
              />
              <ERPSBaseDatePicker
                id="billerLiveDate"
                hasMinMaxNotCheck={true}
                label={t("erps.billermanagement.label.liveDate", "Live date")}
                value={null}
                className="app_datepicker_biller_addnew_bankaccount"
                datePickerStyle={"quick-reg-datepicker-v1"}
                isDisabled={true}
                hasRequired={false}
                maxDate={new Date("2100-01-01")}
                minDate={new Date("1900-01-01")}
              />

              <ERPSBaseTextField
                id="remitType"
                type="text"
                label={t(
                  "erps.billermanagement.label.remittanceType",
                  "Remittance type"
                )}
                className="common__textField__width_v6"
                size="small"
                onChange={handleBillerProfile("remitType")}
                value={billerProfile?.remitType || ""}
                inputProps={{ maxLength: 3 }}
              />
            </Stack>
            <Stack direction="row" spacing={2}>
              <ERPSBaseTextField
                id="productType"
                type="text"
                label={t(
                  "erps.billermanagement.label.productType",
                  "Product type"
                )}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("productType")}
                value={billerProfile?.productType}
                error={highlightError?.productType}
                select={true}
                items={productType}
                required={"true"}
                InputLabelProps={{
                  shrink: billerProfile?.productType ? true : false,
                }}
                SelectProps={{ native: true }}
              />
              <ERPSBaseTextField
                id="billerBudgetCategory"
                type="text"
                label={t(
                  "erps.billermanagement.label.budgetCategory",
                  "Budget category"
                )}
                className="common__textField__width"
                size="small"
                onChange={handleBillerProfile("billerBudgetCategory")}
                value={billerProfile?.billerBudgetCategory || ""}
                error={highlightError?.billerBudgetCategory}
                select={true}
                required={"true"}
                items={budgetCategoryCodes}
                SelectProps={{ native: true }}
                InputLabelProps={{
                  shrink: billerProfile?.billerBudgetCategory ? true : false,
                }}
              />
              <ERPSBaseTextField
                id="billerCounty"
                type="text"
                label={t("erps.billermanagement.label.county")}
                className="common__textField__width"
                size="small"
                value={billerProfile.billerCounty}
                onChange={handleBillerProfile("billerCounty")}
                inputProps={{ maxLength: 3 }}
                error={highlightError?.billerCounty}
                required={"true"}
              />
            </Stack>
          </>
        ) : (
          <Stack direction="row" mb={2}>
            <ERPSBaseDatePicker
              label={t("erps.billermanagement.label.liveDate", "Live date")}
              hasMinMaxNotCheck={true}
              value={billerProfile?.billerLiveDate}
              className="common__textField__width"
              onChange={handleBillerProfile("billerLiveDate")}
              required={true}
              maxDate={new Date("2100-01-01")}
              minDate={new Date("1900-01-01")}
            />
          </Stack>
        )}

        <Box
          mt={4}
          mb={-2}
          role={"form"}
          aria-labelledby={"contactInformation"}
        >
          <Typography variant="h3" id={"contactInformation"}>
            {t(
              "erps.billermanagement.button.contactInfo",
              "Contact information"
            )}
          </Typography>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="primaryContactName"
              type="text"
              label={t(
                "erps.billermanagement.label.primarycontact",
                "Primary contact"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("primaryContactName")}
              value={billerProfile?.primaryContactName || ""}
              required={"true"}
              error={highlightError?.primaryContactName}
              inputProps={{ maxLength: 35 }}
            />
            <Stack direction="row" spacing={0}>
              <ERPSBaseTextField
                id="primaryContactPhone"
                type="number"
                label={t(
                  "erps.billermanagement.label.telNum",
                  "Telephone number"
                )}
                className="common__textField__width_v1 addNewBiller_label_fontSize"
                size="small"
                onChange={handleBillerProfile("primaryContactPhone")}
                value={billerProfile?.primaryContactPhone || ""}
                required={"true"}
                error={highlightError?.primaryContactPhone}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 10);
                }}
              />
              <ERPSBaseTextField
                id="primaryContactExtension"
                type="number"
                label={t("erps.billermanagement.label.ext", "Ext.")}
                className="common__textField__width_ext"
                size="small"
                onChange={handleBillerProfile("primaryContactExtension")}
                value={billerProfile?.primaryContactExtension || ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 5);
                }}
              />
            </Stack>
            <ERPSBaseTextField
              id="primaryContactFax"
              type="number"
              label={t("erps.billermanagement.label.faxNumber", "Fax number")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("primaryContactFax")}
              value={billerProfile?.primaryContactFax || ""}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 10);
              }}
            />
          </Stack>
          <Stack direction="row" spacing={2}>
            <ERPSBaseTextField
              id="secondaryContactName"
              type="text"
              label={t(
                "erps.billermanagement.label.secondarycontact",
                "Secondary contact"
              )}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("secondaryContactName")}
              value={billerProfile?.secondaryContactName || ""}
              inputProps={{ maxLength: 35 }}
              error={highlightError?.secondaryContactName}
            />
            <Stack direction="row" spacing={0}>
              <ERPSBaseTextField
                id="secondaryContactPhone"
                type="number"
                label={t(
                  "erps.billermanagement.label.telNum",
                  "Telephone number"
                )}
                className="common__textField__width_v1"
                size="small"
                onChange={handleBillerProfile("secondaryContactPhone")}
                value={billerProfile?.secondaryContactPhone || ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 10);
                }}
                error={highlightError?.secondaryContactPhone}
              />
              <ERPSBaseTextField
                id="secondaryContactExtension"
                type="number"
                label={t("erps.billermanagement.label.ext", "Ext.")}
                className="common__textField__width_ext"
                size="small"
                onChange={handleBillerProfile("secondaryContactExtension")}
                value={billerProfile?.secondaryContactExtension || ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 5);
                }}
              />
            </Stack>
            <ERPSBaseTextField
              id="secondaryContactFax"
              type="number"
              label={t("erps.billermanagement.label.faxNumber", "Fax number")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("secondaryContactFax")}
              value={billerProfile?.secondaryContactFax || ""}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 10);
              }}
            />
          </Stack>
        </Box>
        {fiNumber == "10" && (
          <>
            {" "}
            <Box mt={4}>
              {
                <>
                  <Box mt={4} mb={-2} role={"form"} aria-labelledby={"debit"}>
                    <Typography variant="h3" id={"debit"}>
                      {t("erps.billermanagement.label.debit", "Debit")}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <ERPSBaseTextField
                        id="debitFiNumber"
                        items={billerFinancialInstitutionList}
                        SelectProps={{ native: true }}
                        type="text"
                        select={true}
                        label={t(
                          "erps.billermanagement.label.billerFinancialInstitution",
                          "Biller financial institution"
                        )}
                        className="common__textField__width addNewBiller_label_fontSize"
                        size="small"
                        onChange={handleBillerProfile("debitFiNumber")}
                        value={billerProfile?.debitFiNumber || ""}
                        InputLabelProps={{
                          shrink: Boolean(billerProfile?.debitFiNumber),
                        }}
                        required={"true"}
                        error={highlightError?.debitFiNumber}
                      />
                      <ERPSBaseTextField
                        id="debitTransitNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.transitNumber",
                          "Transit number"
                        )}
                        className="common__textField__width"
                        size="small"
                        required="true"
                        onChange={handleBillerProfile("debitTransitNumber")}
                        value={billerProfile?.debitTransitNumber || ""}
                        error={highlightError?.debitTransitNumber}
                        inputProps={{ maxLength: 5 }}
                      />
                      <ERPSBaseTextField
                        id="debitAccountNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.accountNumber",
                          "Account number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("debitAccountNumber")}
                        value={billerProfile?.debitAccountNumber || ""}
                        required="true"
                        error={highlightError?.debitAccountNumber}
                      />
                    </Stack>
                  </Box>
                  <Box mt={4} mb={-2} role={"form"} aria-labelledby={"credit"}>
                    <Typography variant="h3" id={"credit"}>
                      {t("erps.billermanagement.label.credit", "Credit")}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <ERPSBaseTextField
                        id="creditorFiNumber"
                        items={billerFinancialInstitutionList}
                        SelectProps={{ native: true }}
                        type="text"
                        select={true}
                        label={t(
                          "erps.billermanagement.label.billerFinancialInstitution",
                          "Biller financial institution"
                        )}
                        className="common__textField__width addNewBiller_label_fontSize"
                        size="small"
                        onChange={handleBillerProfile("creditorFiNumber")}
                        value={billerProfile?.creditorFiNumber || ""}
                        InputLabelProps={{
                          shrink: Boolean(billerProfile?.creditorFiNumber),
                        }}
                        required={"true"}
                        error={highlightError?.creditorFiNumber}
                      />
                      <ERPSBaseTextField
                        id="creditorTransitNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.transitNumber",
                          "Transit number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorTransitNumber")}
                        value={billerProfile?.creditorTransitNumber || ""}
                        error={highlightError?.creditorTransitNumber}
                        required="true"
                        inputProps={{ maxLength: 5 }}
                      />
                      <ERPSBaseTextField
                        id="creditorAccountNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.accountNumber",
                          "Account number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorAccountNumber")}
                        value={billerProfile?.creditorAccountNumber || ""}
                        error={highlightError?.creditorAccountNumber}
                        required="true"
                      />
                    </Stack>
                  </Box>
                </>
              }
            </Box>
          </>
        )}
        {fiNumber != "10" && (
          <>
            {" "}
            <Box mt={4} mb={-2}>
              {
                <>
                  {" "}
                  <Box
                    mt={4}
                    mb={-2}
                    role={"form"}
                    aria-labelledby={"bankAccount"}
                  >
                    <Typography variant="h3" id="bankAccount">
                      {t(
                        "erps.billermanagement.label.bankAccount",
                        "Bank account"
                      )}
                    </Typography>
                    <Stack direction="row" spacing={2}>
                      <ERPSBaseTextField
                        id="creditorFiNumber"
                        items={billerFinancialInstitutionList}
                        SelectProps={{ native: true }}
                        type="text"
                        select={true}
                        label={t(
                          "erps.billermanagement.label.billerFinancialInstitution",
                          "Biller financial institution"
                        )}
                        className="common__textField__width addNewBiller_label_fontSize"
                        size="small"
                        onChange={handleBillerProfile("creditorFiNumber")}
                        value={billerProfile?.creditorFiNumber || ""}
                        InputLabelProps={{
                          shrink: Boolean(billerProfile?.creditorFiNumber),
                        }}
                        required={"true"}
                        error={highlightError?.creditorFiNumber}
                      />
                      <ERPSBaseTextField
                        id="creditorTransitNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.transitNumber",
                          "Transit number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorTransitNumber")}
                        value={billerProfile?.creditorTransitNumber || ""}
                        required={"true"}
                        error={highlightError?.creditorTransitNumber}
                        inputProps={{ maxLength: 5 }}
                      />
                      <ERPSBaseTextField
                        id="creditorAccountNumber"
                        type="text"
                        label={t(
                          "erps.billermanagement.label.accountNumber",
                          "Account number"
                        )}
                        className="common__textField__width"
                        size="small"
                        onChange={handleBillerProfile("creditorAccountNumber")}
                        value={billerProfile?.creditorAccountNumber || ""}
                        required={"true"}
                        error={highlightError?.creditorAccountNumber}
                      />
                    </Stack>
                  </Box>
                </>
              }
            </Box>
          </>
        )}
      </Box>
      {fiNumber != "10" && (
        <Box aria-labelledby={"financialInstitution"} mt={8}>
          <Typography variant="h2" id="financialInstitution">
            {t(
              "erps.billermanagement.label.financialInstitution",
              "Financial institutions"
            )}
          </Typography>
          {financialInstitutionTableList &&
            financialInstitutionTableList.length && (
              <ERPSGrid
                data={{
                  rows: financialInstitutionTableList.length
                    ? financialInstitutionTableList
                    : financialInstitutionTableList,
                  count: financialInstitutionTableList
                    ? financialInstitutionTableList.length
                    : "0",

                  columns: getFinancialInstitutionHeadings(
                    true,
                    "",
                    handleSelectionModelChange
                  ),
                }}
                handleCellEditCommit={handleCellEditCommit}
                dataGridWidth="dataGrid__box__container__financial_institution_table"
                hideFooterPagination={true}
                handleSelectionModelChange={handleSelectionModelChange}
                getCellClassName={handleGetCellClassName}
                selectedRows={selectedRow}
                tableLabelText={t(
                  "erps.updatebillerapproval.heading.financialInstitution"
                )}
              />
            )}
        </Box>
      )}
      {fiNumber == "10" && (
        <Box mt={5} role={"form"} aria-labelledby={"vendorAlias"}>
          <Typography variant="h3" id={"vendorAlias"}>
            {t("erps.billermanagement.label.vendorAlias")}
          </Typography>
          <Box mt={2} style={{ paddingBottom: "15px" }}>
            <TextareaAutosize
              aria-label={t("erps.billermanagement.label.vendorAlias")}
              placeholder={t("erps.billermanagement.label.vendorAlias")}
              className={"common__textField__prefix_text_vendorAlias"}
              id="billerAlias"
              onChange={handleBillerProfile("billerAlias")}
              value={billerProfile?.billerAlias || ""}
              error={highlightError?.billerAlias}
              maxLength={700}
            />
          </Box>
        </Box>
      )}
      <Box role={"form"} aria-Labelledby={"paymentAdviceFileDetails"}>
        <div className="manageUsers__page__title">
          <Typography variant="h2" id="paymentAdviceFileDetails">
            {t(
              "erps.billermanagement.label.paymentAdviceFileDetails",
              "Payment advice file details"
            )}
          </Typography>
        </div>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="deliveryMethod"
            type="text"
            label={t(
              "erps.billermanagement.label.deilveryMethod",
              "Delivery method"
            )}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery("deliveryMethod", e.target.value)
            }
            select={true}
            items={deliveryMethodLists}
            value={initialDeliveryMethods?.deliveryMethod}
            error={
              !initialDeliveryMethods.deliveryMethod &&
              highlightError?.deliveryMethod
            }
            required={"true"}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: initialDeliveryMethods?.deliveryMethod ? true : false,
            }}
          />
          <ERPSBaseTextField
            id="distributionFormat"
            type="text"
            label={t("erps.billermanagement.label.format", "Format")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery("distributionFormat", e.target.value)
            }
            select={true}
            value={initialDeliveryMethods?.distributionFormat}
            items={getFormatListItem(initialDeliveryMethods?.deliveryMethod)}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink:
                initialDeliveryMethods?.distributionFormat ||
                initialDeliveryMethods?.deliveryMethod
                  ? true
                  : false,
            }}
          />
          <ERPSBaseTextField
            id="distributionListCode"
            type="text"
            label={t("erps.billermanagement.label.destination", "Destination")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "distributionListCode",
                e.target.value
              )
            }
            value={initialDeliveryMethods.distributionListCode}
            required={"true"}
            error={highlightError?.initialDistributionListCode}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="destinationAttention"
            type="text"
            label={t("erps.billermanagement.label.attention", "Attention")}
            className="common__textField__width"
            size="small"
            onChange={(e) =>
              handleChangeInitialDelivery(
                "destinationAttention",
                e.target.value
              )
            }
            value={initialDeliveryMethods.destinationAttention}
            inputProps={{ maxLength: 30 }}
          />
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id="distributionPhoneNumber"
              type="number"
              label={t(
                "erps.billermanagement.label.telNum",
                "Telephone number"
              )}
              className="common__textField__width_v1"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionPhoneNumber",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionPhoneNumber}
              inputProps={{ maxLength: 10 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 10);
              }}
              error={highlightError?.initialDistributionPhoneNumber}
            />
            <ERPSBaseTextField
              id="distributionExtensionNumber"
              type="number"
              label={t("erps.billermanagement.label.ext", "Ext.")}
              className="common__textField__width_ext"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionExtensionNumber",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionExtensionNumber}
              inputProps={{ maxLength: 5 }}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 5);
              }}
            />
          </Stack>
        </Stack>
        {initialDeliveryMethods.deliveryMethod === "EDI" && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseTextField
              id="distributionEDIMailbox"
              type="text"
              label={t("erps.billermanagement.label.ediMailBox", "EDI mailbox")}
              className="common__textField__width"
              size="small"
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionEDIMailbox",
                  e.target.value
                )
              }
              value={initialDeliveryMethods.distributionEDIMailbox}
              isDisabled={true}
            />
          </Stack>
        )}
        {fiNumber == "10" && (
          <Stack direction="row" spacing={0}>
            <ERPSBaseRadioGroup
              value={initialDeliveryMethods?.distributionGroupRcpIndex}
              label={t("erps.billermanagement.appliesTo.text", "Applies to")}
              onChange={(e) =>
                handleChangeInitialDelivery(
                  "distributionGroupRcpIndex",
                  e.target.value
                )
              }
              items={appliesToItems}
              error={highlightError?.distributionGroupRcpIndex}
            />
          </Stack>
        )}
        {count > 1 && (
          <DeliveryMethodComponent
            deliveryMethods={deliveryMethods}
            handleChangeDeliveryMethods={handleChangeDeliveryMethods}
            getFormatListItem={getFormatListItem}
            deliveryMethodLists={deliveryMethodLists}
            handleRemoveDeliveryMethod={handleRemoveDeliveryMethod}
            handleClickDeliveryMethod={handleClickDeliveryMethod}
            count={count}
            error={reqValidationForDelivery}
            appliesToItems={appliesToItems}
            fiNumber={fiNumber}
          />
        )}
        <Box mt={count === 1 ? 3 : 0}>
          {count === 1 && (
            <Stack direction="row" spacing={0}>
              <ERPSBaseLink
                className="link__blue"
                label={t(
                  "erps.billermanagement.label.addDeilveryMethod",
                  "Add a delivery method"
                )}
                target={""}
                onClick={() => handleClickDeliveryMethod()}
              />
            </Stack>
          )}
          {hasAccess("recipientCcin") && (
            <Stack direction={"row"}>
              <ERPSBaseTextField
                id="recipientCcin"
                type="number"
                label={t(
                  "erps.billermanagement.label.ccin",
                  "Cooperate creditor id number"
                )}
                className="common__textField__width_v4"
                size="small"
                onChange={handleBillerProfile("recipientCcin")}
                value={billerProfile?.recipientCcin || ""}
                error={highlightError?.recipientCcin}
                required={checkRequiredRecipientCcin() ? "true" : ""}
                onInput={(e) => {
                  e.target.value = getNumericValue(e.target.value, 0, 8);
                }}
              />
            </Stack>
          )}
        </Box>

        <Box mt={4}>
          <Typography variant="h3">
            {t(
              "erps.billermanagement.label.deliveryDetails",
              "Delivery details"
            )}
          </Typography>
        </Box>

        <ERPSBaseRadioGroup
          marginTop=".4rem"
          value={billerProfile?.nilReportIndex || "N"}
          label={t("erps.billermanagement.label.nilReports", "Nil reports")}
          id="nilReportIndex"
          onChange={handleBillerProfile("nilReportIndex")}
          items={nilReports}
          required={"true"}
          error={highlightError?.nilReportIndex}
        />

        {fiNumber != 10 && (
          <>
            <Box mt={-2}>
              <ERPSBaseTextField
                id="faxHeaderLine1"
                type="text"
                label={t(
                  "erps.billermanagement.label.faxHeaderLine1",
                  "Fax header line 1"
                )}
                className="common__textField__width_v5"
                size="small"
                onChange={handleBillerProfile("faxHeaderLine1")}
                value={billerProfile?.faxHeaderLine1 || ""}
                inputProps={{ maxLength: 40 }}
              />
            </Box>
            <Box mt={-2}>
              <ERPSBaseTextField
                id="faxHeaderLine2"
                type="text"
                label={t(
                  "erps.billermanagement.label.faxHeaderLine2",
                  "Fax header line 2"
                )}
                className="common__textField__width_v5"
                size="small"
                onChange={handleBillerProfile("faxHeaderLine2")}
                value={billerProfile?.faxHeaderLine2 || ""}
                inputProps={{ maxLength: 40 }}
              />
            </Box>
          </>
        )}
      </Box>
      <Box
        className="manageUsers__page__title"
        role={"form"}
        aria-Labelledby={"billingAccountValidation"}
      >
        <Typography variant="h2" id="billingAccountValidation">
          {t(
            "erps.billermanagement.label.billingAccountValidation",
            "Billing account validations"
          )}
        </Typography>
        <Stack direction="row" spacing={2}>
          {fiNumber == "10" ? (
            <ERPSBaseTextField
              id="billerAccountMask"
              type="text"
              label={t(
                "erps.billermanagement.label.accountMask",
                "Account mask"
              )}
              className="common__textField__width"
              size="small"
              required={"true"}
              onChange={handleBillerProfile("billerAccountMask")}
              value={billerProfile?.billerAccountMask || ""}
              error={highlightError?.billerAccountMask}
            />
          ) : (
            <ERPSBaseTextField
              id="billerCustomerNumberFormat"
              type="text"
              label={t(
                "erps.billermanagement.label.accountFormat",
                "Account format"
              )}
              className="common__textField__width"
              size="small"
              required={fiNumber == "10" ? "true" : ""}
              onChange={handleBillerProfile("billerCustomerNumberFormat")}
              value={billerProfile?.billerCustomerNumberFormat || ""}
              error={highlightError?.billerCustomerNumberFormat}
            />
          )}

          <ERPSBaseTextField
            id="billerCustomerNumberMinimum"
            type="number"
            label={t(
              "erps.billermanagement.label.minimumLength",
              "Minimum length"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberMinimum")}
            value={billerProfile?.billerCustomerNumberMinimum || ""}
            required={fiNumber == "10" ? "true" : false}
            error={highlightError?.billerCustomerNumberMinimum}
          />
          <ERPSBaseTextField
            id="billerCustomerNumberMaximum"
            type="number"
            label={t(
              "erps.billermanagement.label.maximumLength",
              "Maximum length"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("billerCustomerNumberMaximum")}
            value={billerProfile?.billerCustomerNumberMaximum || ""}
            required={fiNumber == "10" ? "true" : false}
            error={highlightError?.billerCustomerNumberMaximum}
          />
        </Stack>
        {fiNumber != "10" && (
          <Box>
            <ERPSBaseCheckBoxGrp
              label={t(
                "erps.billermanagement.label.alphaAllowed",
                "Alpha allowed"
              )}
              onChange={handleBillerProfileCheckbox("alphaAllowed")}
              value={billerProfile?.alphaAllowed === "Y" ? true : false}
              id="alphaAllowed"
            />{" "}
          </Box>
        )}
        <Stack direction="row" spacing={2} mt={-2}>
          <ERPSBaseTextField
            id="accountValidationValue"
            type="text"
            label={t(
              "erps.billermanagement.label.additionalValidation",
              "Additional validation"
            )}
            className="common__textField__width"
            size="small"
            onChange={handleBillerProfile("accountValidationValue")}
            select={true}
            value={billerProfile?.accountValidationValue || ""}
            items={additionalValidationLists}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: billerProfile?.accountValidationValue ? true : false,
            }}
          />
          {billerProfile?.accountValidationValue?.includes("URN") && (
            <ERPSBaseTextField
              id="uniqueCheckDigitRoutine"
              type="text"
              label={t(
                "erps.billermanagement.label.uniqueRoutineName",
                "Unique routine name"
              )}
              className="common__textField__width"
              size="small"
              items={customRoutines}
              onChange={handleBillerProfile("uniqueCheckDigitRoutine")}
              value={billerProfile?.uniqueCheckDigitRoutine || ""}
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              required="true"
              error={highlightError?.uniqueCheckDigitRoutine}
              select={true}
            />
          )}
        </Stack>
        {billerProfile?.accountValidationValue &&
          billerProfile?.accountValidationValue !== "NONE" && (
            <>
              <Stack direction="row" spacing={2} mt={-2}>
                <ERPSBaseTextField
                  id="modChecker"
                  type="text"
                  label={t("erps.billermanagement.label.modCheck", "Mod check")}
                  className="common__textField__width"
                  size="small"
                  onChange={handleBillerProfile("modChecker")}
                  value={billerProfile?.modChecker || ""}
                  required={
                    billerProfile?.accountValidationValue.includes("CDV")
                      ? "true"
                      : ""
                  }
                  error={highlightError?.modChecker}
                />

                <ERPSBaseTextField
                  id="checkDigitLocation"
                  type="number"
                  label={t(
                    "erps.billermanagement.label.digitLocation",
                    "Digit location"
                  )}
                  className="common__textField__width"
                  size="small"
                  onChange={handleBillerProfile("checkDigitLocation")}
                  value={billerProfile?.checkDigitLocation || ""}
                  required={
                    billerProfile?.accountValidationValue.includes("CDV")
                      ? "true"
                      : ""
                  }
                  error={highlightError?.checkDigitLocation}
                />
              </Stack>
              <Stack direction="row" spacing={2}>
                <ERPSBaseTextField
                  id="validationFromDigit"
                  type="number"
                  label={t(
                    "erps.billermanagement.label.validationFromDigit",
                    "Validation from digit"
                  )}
                  className="common__textField__width"
                  size="small"
                  onChange={handleBillerProfile("validationFromDigit")}
                  value={billerProfile?.validationFromDigit || ""}
                  required={
                    billerProfile?.accountValidationValue.includes("CDV")
                      ? "true"
                      : ""
                  }
                  error={highlightError?.validationFromDigit}
                />
                <ERPSBaseTextField
                  id="validationToDigit"
                  type="number"
                  label={t(
                    "erps.billermanagement.label.validationToDigit",
                    "Validation to digit"
                  )}
                  className="common__textField__width"
                  size="small"
                  onChange={handleBillerProfile("validationToDigit")}
                  value={billerProfile?.validationToDigit || ""}
                  required={
                    billerProfile?.accountValidationValue.includes("CDV")
                      ? "true"
                      : ""
                  }
                  error={highlightError?.validationToDigit}
                />
              </Stack>

              <Stack direction="row" spacing={16} mt={2}>
                <Stack direction="column" mt={2}>
                  <ERPSBaseCheckBoxGrp
                    label={t("erps.billermanagement.label.fold", "Fold")}
                    id="fold"
                    value={billerProfile?.fold === "Y" ? true : false}
                    onChange={handleBillerProfileCheckbox("fold")}
                  />
                  <ERPSBaseCheckBoxGrp
                    label={t(
                      "erps.billermanagement.label.subtract",
                      "Subtract"
                    )}
                    id="subtract"
                    value={billerProfile?.subtract === "Y" ? true : false}
                    onChange={handleBillerProfileCheckbox("subtract")}
                    error={highlightError?.subtract}
                  />
                </Stack>
                <ERPSBaseTextField
                  id="weighing"
                  type="text"
                  label={t(
                    "erps.billermanagement.label.weighting",
                    "Weighting"
                  )}
                  className="common__textField__width"
                  size="small"
                  onChange={handleBillerProfile("weighing")}
                  value={billerProfile?.weighing || ""}
                  required={
                    billerProfile?.accountValidationValue.includes("CDV")
                      ? "true"
                      : ""
                  }
                  error={highlightError?.weighing}
                />
              </Stack>
            </>
          )}
        <Box className="manageUsers__page__title">
          <ERPSBaseCheckBoxGrp
            label={t(
              "erps.updatebillerapproval.checkbox.label.prefixCheck",
              "Exclude prefix"
            )}
            id="prefixCheck"
            value={billerProfile?.prefixCheck === "Y" ? true : false}
            onChange={handleBillerProfileCheckbox("prefixCheck")}
          />{" "}
          <Box mt={1}>
            <TextareaAutosize
              aria-label="Prefix text"
              placeholder={t(
                "erps.updatebillerapproval.label.prefixtext",
                "Prefix text"
              )}
              id="prefixText"
              className={
                highlightError?.prefixText
                  ? "common__textField__prefix_text textarea_error_highlight"
                  : "common__textField__prefix_text"
              }
              onChange={handleBillerProfile("prefixText")}
              value={billerProfile?.prefixText || ""}
            />
            <Typography
              variant={"label"}
              sx={{ display: "block", marginLeft: "4px" }}
            >
              {fiNumber == "50"
                ? t("erps.billermanagement.textArea.dndNote.text")
                : t("erps.billermanagement.textArea.note.text")}
            </Typography>
          </Box>
        </Box>
        <Box className="manageUsers__page__title">
          <ERPSBaseCheckBoxGrp
            label={t(
              "erps.updatebillerapproval.checkbox.label.suffixCheck",
              "Exclude suffix"
            )}
            value={billerProfile?.suffixCheck === "Y" ? true : false}
            id="suffixCheck"
            onChange={handleBillerProfileCheckbox("suffixCheck")}
          />{" "}
          <Box mt={1}>
            <TextareaAutosize
              aria-label="Suffix text"
              placeholder={t(
                "erps.updatebillerapproval.label.suffixtext",
                "Suffix text"
              )}
              className={
                highlightError?.suffixText
                  ? "common__textField__prefix_text textarea_error_highlight"
                  : "common__textField__prefix_text"
              }
              id="suffixText"
              onChange={handleBillerProfile("suffixText")}
              value={billerProfile?.suffixText || ""}
            />
            <Typography
              variant={"label"}
              sx={{ display: "block", marginLeft: "4px" }}
            >
              {fiNumber == "50"
                ? t("erps.billermanagement.textArea.dndNote.text")
                : t("erps.billermanagement.textArea.note.text")}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <Box
          className="manageUsers__page__title"
          role={"form"}
          aria-Labelledby={"imageinstructiontext"}
        >
          <Typography variant="h2" id={"imageinstructiontext"}>
            {t(
              "erps.updatebillerapproval.heading.imageinstructiontext",
              "Image and instruction text"
            )}
          </Typography>
        </Box>
        {fiNumber == "50" && (
          <Box>
            <ERPSBaseTextField
              id="instructType"
              type="text"
              label={t("erps.billermanagement.label.instructTextType")}
              className="common__textField__width"
              size="small"
              onChange={handleBillerProfile("instructType")}
              select={true}
              value={billerProfile?.instructType || ""}
              items={instructTypeList}
              SelectProps={{ native: true }}
              InputLabelProps={{
                shrink: true,
              }}
              isDisabled={!billerProfile?.accountValidationValue?.includes("URN")}
            />
          </Box>
        )}
        <Box>
          {fiNumber != 10 ? (
            <ERPSBaseTextField
              id="englishImageUrl"
              type="text"
              label={t(
                "erps.updatebillerapproval.imageinstructiontext.label.englishimageurl",
                "English image url"
              )}
              className="common__textField__image_url"
              size="small"
              onChange={handleBillerProfile("englishImageUrl")}
              value={billerProfile?.englishImageUrl || ""}
              error={highlightError?.englishImageUrl}
              inputProps={{ maxLength: 100 }}
            />
          ) : (
            <>
              {selectedFile?.English && (
                <Box ml={3} mt={2}>
                  {" "}
                  <img src={preview?.englishPreview} height="240" width="550" />
                </Box>
              )}
              <Box display="flex">
                <div>
                  <ERPSBaseTextField
                    id="englishImage"
                    type="text"
                    label={t(
                      "erps.updatebillerapproval.imageinstructiontext.label.englishimage"
                    )}
                    className="common__textField__image"
                    size="small"
                    onChange={handleBillerProfile("englishImage")}
                    value={
                      billerProfile?.englishImage ||
                      selectedFile?.English?.fileName ||
                      ""
                    }
                    error={
                      highlightError?.englishImage || imageError?.["English"]
                    }
                    inputProps={{ maxLength: 100 }}
                    InputLabelProps={{
                      shrink:
                        billerProfile?.englishImage ||
                        selectedFile?.English?.fileName
                          ? true
                          : false,
                    }}
                    isDisabled={true}
                  />
                </div>
                <Box display={"flex"} mt={-2}>
                  <Box>
                    <label htmlFor="icon-button-photo1">
                      <ERPSBaseButton
                        variant="primary"
                        className="button_with_margin48"
                        label={"Upload..."}
                        loading={imageLoading?.["English"]}
                        component={"span"}
                        onClick={(ev) => ev.stopPropagation()}
                      >
                        <input
                          accept="image/*"
                          style={{
                            display: "none",
                          }}
                          id="icon-button-photo1"
                          onChange={(e) => handleFileInput(e, "English")}
                          type="file"
                          key={selectedFile?.English?.fileName || ""}
                        />
                      </ERPSBaseButton>
                    </label>
                  </Box>
                  <Box ml={3}>
                    <ERPSBaseButton
                      variant="primary"
                      className="button_with_margin48"
                      label={"Remove"}
                      //loading={loginPending}
                      component={"span"}
                      onClick={() => handleRemoveImage("English")}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>
        <Box mb={-4} mt={4}>
          <Typography variant="label" id={"english_text_editor"}>
            {t(
              "erps.updatebillerapproval.imageinstructiontext.englishinstructiontext",
              "English instruction text"
            )}
          </Typography>
        </Box>
        <Box>
          <TextEditor
            value={englishValue}
            handleEditorChange={handleEditorChange}
            readOnly={
              fiNumber == "50" && billerProfile?.instructType === DYNAMIC
            }
            langType={"en"}
            id={"english_text_editor"}
          />
        </Box>
        <Box>
          {fiNumber == 10 && (
            <>
              {selectedFile?.French && (
                <Box ml={3} mt={2}>
                  {" "}
                  <img src={preview?.frenchPreview} height="240" width="550" />
                </Box>
              )}
              <Box display="flex">
                <div>
                  <ERPSBaseTextField
                    id="frenchImage"
                    type="text"
                    label={t(
                      "erps.updatebillerapproval.imageinstructiontext.label.frenchimage"
                    )}
                    className="common__textField__image"
                    size="small"
                    onChange={handleBillerProfile("frenchImage")}
                    value={
                      billerProfile?.frenchImage ||
                      selectedFile?.French?.fileName ||
                      ""
                    }
                    error={
                      highlightError?.frenchImage || imageError?.["French"]
                    }
                    inputProps={{ maxLength: 100 }}
                    InputLabelProps={{
                      shrink:
                        billerProfile?.frenchImage ||
                        selectedFile?.French?.fileName
                          ? true
                          : false,
                    }}
                    isDisabled={true}
                  />
                </div>
                <Box display={"flex"} mt={-2}>
                  <Box>
                    <label htmlFor="icon-button-photo2">
                      <ERPSBaseButton
                        variant="primary"
                        className="button_with_margin48"
                        label={"Upload..."}
                        loading={imageLoading?.["French"]}
                        component={"span"}
                        onClick={(ev) => ev.stopPropagation()}
                      >
                        <input
                          accept="image/*"
                          style={{
                            display: "none",
                          }}
                          id="icon-button-photo2"
                          onChange={(e) => handleFileInput(e, "French")}
                          type="file"
                          key={selectedFile?.French?.fileName || ""}
                        />
                      </ERPSBaseButton>
                    </label>
                  </Box>
                  <Box ml={3}>
                    <ERPSBaseButton
                      variant="primary"
                      className="button_with_margin48"
                      label={"Remove"}
                      //loading={loginPending}
                      component={"span"}
                      onClick={() => handleRemoveImage("French")}
                    />
                  </Box>
                </Box>
              </Box>
            </>
          )}
        </Box>

        <Box mb={-4} mt={4}>
          <Typography variant="label" id={"french_text_editor"}>
            {t(
              "erps.updatebillerapproval.imageinstructiontext.frenchinstructiontext",
              "French instruction text"
            )}
          </Typography>
        </Box>
        <Box>
          <TextEditor
            value={frenchValue}
            handleEditorChange={handleEditorChange}
            readOnly={
              fiNumber == "50" && billerProfile?.instructType === DYNAMIC
            }
            langType={"fr"}
            id={"french_text_editor"}
          />
        </Box>
      </Box>
      {fiNumber == "10" && (
        <>
          {" "}
          <Box
            className="manageUsers__page__title"
            role={"form"}
            aria-Labelledby={"lineofbusiness"}
          >
            {
              <>
                {" "}
                <Box className="manageUsers__page__title">
                  <Typography variant="h2" id="lineofbusiness">
                    {t(
                      "erps.billermanagement.label.lineofbusiness",
                      "Line of business settings"
                    )}
                  </Typography>
                </Box>
                <Box mt={3}>
                  <Typography variant="h3">
                    {t("erps.billermanagement.label.image", "Image")}
                  </Typography>
                </Box>
                <Box style={{ width: "500px" }}>
                  <Box
                    mt={3}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography>
                        {t("erps.billermanagement.label.cibc", "CIBC:")}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="isImgCIBCTelephoneBankingIVRChecked"
                            value={
                              lineOfbusinessData?.isImgCIBCTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcBranchBanking",
                              "Branch banking"
                            )}
                            id="isImgCIBCBranchBankingChecked"
                            value={
                              lineOfbusinessData?.isImgCIBCBranchBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCBranchBankingChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="isImgCIBCTelePhoneBankingDeskChecked"
                            value={
                              lineOfbusinessData?.isImgCIBCTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="isImgCIBCOnlineBankingChecked"
                            value={
                              lineOfbusinessData?.isImgCIBCOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgCIBCOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography>
                        {t(
                          "erps.billermanagement.label.pcfinancial",
                          "PC Financial:"
                        )}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="isImgPCFinancialTelephoneBankingIVRChecked"
                            value={
                              lineOfbusinessData?.isImgPCFinancialTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgPCFinancialTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>

                        <Box mt={3}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="isImgPCFinancialTelePhoneBankingDeskChecked"
                            value={
                              lineOfbusinessData?.isImgPCFinancialTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgPCFinancialTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="isImgPCFinancialOnlineBankingChecked"
                            value={
                              lineOfbusinessData?.isImgPCFinancialOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isImgPCFinancialOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
                <Box mt={3}>
                  <Typography variant="h3">
                    {t(
                      "erps.billermanagement.label.instructional",
                      "Instructional"
                    )}
                  </Typography>
                </Box>
                <Box style={{ width: "500px" }}>
                  <Box
                    mt={3}
                    style={{ display: "flex", justifyContent: "space-between" }}
                  >
                    <Box>
                      <Typography>
                        {t("erps.billermanagement.label.cibc", "CIBC:")}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="isInstCIBCTelephoneBankingIVRChecked"
                            value={
                              lineOfbusinessData?.isInstCIBCTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcBranchBanking",
                              "Branch banking"
                            )}
                            id="isInstCIBCBranchBankingChecked"
                            value={
                              lineOfbusinessData?.isInstCIBCBranchBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCBranchBankingChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="isInstCIBCTelePhoneBankingDeskChecked"
                            value={
                              lineOfbusinessData?.isInstCIBCTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="isInstCIBCOnlineBankingChecked"
                            value={
                              lineOfbusinessData?.isInstCIBCOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstCIBCOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                    <Box>
                      <Typography>
                        {t(
                          "erps.billermanagement.label.pcfinancial",
                          "PC Financial:"
                        )}
                      </Typography>
                      <br />
                      <Box mt={-3}>
                        <Box>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingIVR",
                              "Telephone banking IVR"
                            )}
                            id="isInstPCFinancialTelephoneBankingIVRChecked"
                            value={
                              lineOfbusinessData?.isInstPCFinancialTelephoneBankingIVRChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstPCFinancialTelephoneBankingIVRChecked"
                            )}
                          />{" "}
                        </Box>

                        <Box mt={3}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcTelphoneBankingDesk",
                              "Telephone banking (Desktop)"
                            )}
                            id="isInstPCFinancialTelePhoneBankingDeskChecked"
                            value={
                              lineOfbusinessData?.isInstPCFinancialTelePhoneBankingDeskChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstPCFinancialTelePhoneBankingDeskChecked"
                            )}
                          />{" "}
                        </Box>
                        <Box mt={-1}>
                          <ERPSBaseCheckBoxGrp
                            label={t(
                              "erps.billermanagement.label.cibcOnlineBanking",
                              "Online banking"
                            )}
                            id="isInstPCFinancialOnlineBankingChecked"
                            value={
                              lineOfbusinessData?.isInstPCFinancialOnlineBankingChecked ===
                              "Y"
                                ? true
                                : false
                            }
                            onChange={handleLineOfBusiness(
                              "isInstPCFinancialOnlineBankingChecked"
                            )}
                          />{" "}
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </>
            }
          </Box>
        </>
      )}
      <Box className="manageUsers__page__title">
        <Typography variant="h2">
          {t("erps.billermanagement.label.comment", "Comments")}
        </Typography>
      </Box>
      <Box mt={1}>
        <TextareaAutosize
          aria-label="Comments"
          placeholder={t("erps.billermanagement.label.comment", "Comments")}
          className={"common__textField__prefix_text"}
          id="billerComment"
          onChange={handleBillerProfile("billerComment")}
          value={billerProfile?.billerComment || ""}
          error={highlightError?.billerComment}
          maxLength={160}
        />
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.common.button.cancel", "Cancel")}
          actionType={"hasCancelNewBillerAccess"}
          componentAccess={newBillerComponentAccess}
          onClick={handleOpenCancelDialog}
        />
        <ERPSBaseButton
          variant="primary"
          onClick={handleSave}
          className="button_with_margin48"
          label={t("erps.newuser.button.save", "Save")}
          loading={saveLoading}
          actionType={"hasSaveNewBillerAccess"}
          componentAccess={newBillerComponentAccess}
        />
      </Stack>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </Box>
  );
}

export default NewBillerProfileUser;
