import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseDiv from "../custom/inputs/ERPSBaseDiv";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import {
  getFormattedPhoneNumber,
  getNextFilterId,
  requiredFormatDate,
  getLabelName,
} from "../../utils/helper";

export default function NewAndDeleteUserApproval(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [saveDeleteLoading, setSaveDeleteLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [lastRecordDisable, setLastRecordDisable] = useState(false);
  const {
    data,
    setShowSuccessMessage,
    componentAccess,
    userId: loggedInUser,
    userPendingData,
  } = props;
  const userActionError = useSelector(
    (state) => state.userApprovalReducer?.userActionError
  );

  const errorRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [userActionError]);

  const {
    actionName,
    actionCode,
    updatedBy,
    updatedFormattedDate,
    firstName,
    lastName,
    emailAddress,
    phone,
    statusCode,
    languageCode,
    fiDto,
    companyInformation,
    roleName,
    fiNumber,
    userId,
    roleType,
    transactionId,
    displayUpdatedBy,
  } = data;

  const navigateToUserPendingApporvalPage = () => {
    props.history.push("/user-pending-approvals", { componentAccess });
  };

  const hasLastActiveRecord = () => {
    if (userPendingData && userPendingData.length === 1) {
      return true;
    }
    return false;
  };

  const handleDecline = (event) => {
    setDeclineLoading(true);
    if (event) event.preventDefault();
    let createUserApproval = {
      transactionId,
      userId,
      fiNumber,
      actionCode,
    };
    const filteredQueueDataList = userPendingData.filter(
      ({ transactionId: tid }) => transactionId != tid
    );

    const currentIndexVal = userPendingData.findIndex(
      ({ transactionId: tid }) => transactionId == tid
    );
    const slicedArr = userPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();
    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "transactionId"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    dispatch(
      actions.userPendingApprovalsActions.declineUserApprovalData(
        createUserApproval,
        {
          ...props,
          setDeclineLoading,
          setShowSuccessMessage,
          t,
          nextTransactionId,
          queueDataList:
            nextTransactionId === "" ? userPendingData : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
        }
      )
    );
  };

  const handleSubmit = (event) => {
    setSaveDeleteLoading(true);
    if (event) event.preventDefault();
    let createUserApproval = {
      transactionId,
      userId,
      fiNumber,
      actionCode,
    };
    const filteredQueueDataList = userPendingData.filter(
      ({ transactionId: tid }) => transactionId != tid
    );

    const currentIndexVal = userPendingData.findIndex(
      ({ transactionId: tid }) => transactionId == tid
    );
    const slicedArr = userPendingData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();
    const nextTransactionId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      loggedInUser,
      "transactionId"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    if (actionCode === "A") {
      dispatch(
        actions.userPendingApprovalsActions.createUserApprovalData(
          createUserApproval,
          {
            ...props,
            setSaveDeleteLoading,
            setShowSuccessMessage,
            t,
            nextTransactionId,
            queueDataList:
              nextTransactionId === ""
                ? userPendingData
                : filteredQueueDataList,
            componentAccess,
            hasLastRecord,
            setLastRecordDisable,
          }
        )
      );
    } else {
      dispatch(
        actions.userPendingApprovalsActions.deleteUserApprovalData(
          createUserApproval,
          {
            ...props,
            setSaveDeleteLoading,
            setShowSuccessMessage,
            t,
            nextTransactionId,
            queueDataList: filteredQueueDataList,
            componentAccess,
            hasLastRecord,
            setLastRecordDisable,
          }
        )
      );
    }
  };
  const userPendingApprovalChildAccess = componentAccess.find(
    ({ componentType }) => componentType === "UPQFV"
  );
  const userApproverComponentAccess =
    userPendingApprovalChildAccess?.childComponents;

  return (
    <>
      {userActionError && (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          mt={userActionError ? 2 : 0}
        >
          {userActionError && (
            <ErrorMessage error={userActionError} errorRef={errorRef} />
          )}
        </Box>
      )}
      <Box className="manageUsers__page__title">
        <Typography variant="h1">
          {t("erps.manageusers.pendingUser.heading", {
            transactionId: transactionId,
            userId: userId,
          })}
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h2">{`${t(
          "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.subheading"
        )} ${
          actionName === "New"
            ? t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.subheading.newuser"
              )
            : t(
                "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.subheading.deleteduser"
              )
        }`}</Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.label.updateBy"
          )}
          <Typography variant="body2">{displayUpdatedBy}</Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.label.updateOn"
          )}
          <Typography variant="body2">
            {requiredFormatDate(updatedFormattedDate)}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.label.action"
          )}
          <Typography variant="body2">{actionName}</Typography>
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h2">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.subheading.userprofile"
          )}
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h3">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.page.userprofile.subheading.accountSetting"
          )}
        </Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.userId"
          )}
          <Typography variant="body2">{userId}</Typography>
        </Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.financialInstitution"
          )}
          <Typography variant="body2">{fiDto?.fi_name}</Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.roleName"
          )}
          <Typography variant="body2">{roleName}</Typography>
        </Typography>
        <ERPSBaseDiv currentRoleType={roleType} roleType="CA">
          <Typography className="available__documents__value">
            {t(
              "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.subscribingEntityId"
            )}
            <Typography variant="body2">
              {companyInformation?.fiCompanyName}
            </Typography>
          </Typography>
        </ERPSBaseDiv>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.firstName"
          )}
          <Typography variant="body2">{firstName}</Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.lastName"
          )}
          <Typography variant="body2">{lastName}</Typography>
        </Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.language"
          )}
          <Typography variant="body2">
            {languageCode === "F"
              ? t("erps.userprofile.language.french.text")
              : t("erps.userprofile.language.english.text")}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.phoneNumber"
          )}
          <Typography variant="body2">
            {getFormattedPhoneNumber(phone)}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t(
            "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.emailAddress"
          )}
          <Typography variant="body2">{emailAddress}</Typography>
        </Typography>
      </Box>
      {actionName !== "New" && (
        <Box mt={3} sx={{ display: "flex" }}>
          <Typography className="available__documents__value">
            {t(
              "erps.manageusers.userpendingapproval.newanddeleteuserapproval.label.status"
            )}
            <Typography variant="body2">
              {statusCode === "A"
                ? t("erps.manageusers.label.status.active")
                : t("erps.manageusers.label.status.inactive")}
            </Typography>
          </Typography>
        </Box>
      )}
      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.common.button.cancel", "Cancel")}
          onClick={() => navigateToUserPendingApporvalPage()}
          componentAccess={userApproverComponentAccess}
          actionType={"hasUserQueueCancelAccess"}
        />
        <ERPSBaseButton
          componentAccess={userApproverComponentAccess}
          actionType={"hasUserQueueDeclineAccess"}
          functionType="NU"
          variant="secondary"
          onClick={() => handleDecline()}
          loading={declineLoading}
          className="button_with_margin48"
          label={getLabelName({
            key: "decline",
            hasLastActiveRecord,
            lastRecordDisable,
            t,
          })}
          isDisabled={lastRecordDisable}
        />
        <ERPSBaseButton
          functionType="NU"
          variant="primary"
          onClick={() => handleSubmit()}
          loading={saveDeleteLoading}
          className="button_with_margin48"
          label={getLabelName({
            key: "approve",
            hasLastActiveRecord,
            lastRecordDisable,
            t,
          })}
          componentAccess={userApproverComponentAccess}
          actionType={"hasUserQueueApprovalAccess"}
          isDisabled={loggedInUser == data?.updatedBy || lastRecordDisable}
        />
      </Stack>
    </>
  );
}
