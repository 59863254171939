import React, { useState, useEffect } from "react";
import { menuMap } from "./data";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { NavLink } from "react-router-dom";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";


export default function NavBar(props) {
  const user = useSelector((state) => state.loginUserReducer?.user);

  const applicableRoleModules = localStorage.getItem("type") === "rrportal" ? user.rrPortalRoles.modules : user.role.modules;

  const fiReducerList = useSelector((state) => state.fiReducer);
  const fiList = fiReducerList?.financialInstitutionList;
  const fiNumber = fiReducerList?.selectedFi || user?.fiNumber;
  const hasEmailAddress = user?.emailAddress;
  const classicSiteRoleIds = [6, 7, 8, 9];
  const checkClassicSite = (roleId) => classicSiteRoleIds.includes(roleId);
  const checkUserManagementApprovalLinkVisible = () => {
    return fiList?.find(({ fi_number }) => fi_number == fiNumber)
      ?.user_managment_approval_flag;
  };
  const getUpdatedChildModules = (parentType, childType, data) => {
    return data.map(({ componentType, ...rest }) => {
      if (parentType === componentType) {
        const updatedChildComponents = rest?.childComponents.filter(
          ({ componentType: childComponentType }) =>
            childType !== childComponentType
        );
        return {
          ...rest,
          componentType,
          childComponents: updatedChildComponents,
        };
      }
      return { componentType, ...rest };
    });
  };
  const hasNavbarDisable = () => {
    // if (user?.changePasswordIndex == "Y") {
    //   return false;
    // } else {
      return Boolean(hasEmailAddress);
    // }
  };
  const getFilterModules = (roleId, modules) => {
    if (checkClassicSite(roleId)) {
      return modules.filter(({ componentType }) => componentType !== "HO");
    }
    return modules;
  };
  let filteredNavData = [];
  if (user) {
    if (user.role.modules[0].order === undefined) {  // means we haven't assigned menu order yet
      // Sorting modules in the correct order - as listed in data.js
      applicableRoleModules.forEach(role => role.order = menuMap.get(role.componentType)?.order);
      applicableRoleModules.sort( (r1, r2) => (r1.order - r2.order) );
    }

    const roleModules = getFilterModules(user.role.role_id, applicableRoleModules);
    const hasUserManagementApprovalLinkVisible = checkUserManagementApprovalLinkVisible();
    const currentFiNumber = Number(localStorage.getItem("fiNumber"));

    const updatedRolesModule = !hasUserManagementApprovalLinkVisible
        ? getUpdatedChildModules("UM", "UPA", roleModules)
        : roleModules;
    updatedRolesModule.forEach((item) => {
      let filteredFunctionNavData = [];
      let menuGroup = menuMap.get(item.componentType);
      menuGroup?.items?.forEach((navLink) => {
        if (!navLink.fiNumber || navLink.fiNumber.split(",").map(s => Number(s.trim())).includes(currentFiNumber)) {
          if (item.childComponents.length) {
            let userSubMenu = item.childComponents.find(link => navLink.function_type_code === link.componentType);
            if (userSubMenu) {
              filteredFunctionNavData.push({
                    ...navLink,
                    label : navLink.labelOverride?.get(currentFiNumber) || navLink.label,
                    componentAccess: userSubMenu.childComponents,
              });
            }
          } else {
            if (navLink.function_type_code === item.childComponentType)
              filteredFunctionNavData.push({ ...navLink });
          }
        }
      }); // end of  menuGroup.items.forEach(navLink ...)
      if (filteredFunctionNavData && filteredFunctionNavData.length) {
        filteredNavData.push({
              ...menuGroup,
              items: filteredFunctionNavData,
        });
      }
    }); // end of  updatedRolesModule.forEach(item ...)
  }
  return (
    <NavBarView items={filteredNavData} hasEmailAddress={hasNavbarDisable()} />
  );
}
const NavBarView = ({ items, depthStep, depth, hasEmailAddress, ...rest }) => {
  const { t } = useTranslation();
  return (
    <div role="navigation">
      <List disablePadding>
        {items.map((sidebarItem, index) => (
          <SidebarItem
            key={`${t(sidebarItem.name)}${index}`}
            depthStep={depthStep}
            depth={depth}
            {...sidebarItem}
            fontWeight="400"
            fontFamily="Roboto, Regular"
            hasEmailAddress={hasEmailAddress}
            {...rest}
          />
        ))}
      </List>
    </div>
  );
};
const SidebarItem = ({
  label,
  items,
  depthStep = 24,
  depth = 0,
  fontFamily,
  fontWeight,
  url,
  history,
  hasEmailAddress,
  componentAccess,
  ...rest
}) => {
  const [classForBorder, setClassName] = useState(false);
  const { pathname } = useLocation();
  const { t } = useTranslation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <>
      <ListItem
        {...rest}
        style={{ display: "block" }}
        className={
          url &&
          (classForBorder
            ? "navbar__listItem__link_selected"
            : `navbar__listItem__link ${
                !hasEmailAddress ? "disabled-link" : ""
              } `)
        }
        disabled={!hasEmailAddress && !classForBorder}
      >
        {url !== undefined ? (
          <NavLink
            className={(isActive) => {
              if (isActive) {
                setClassName(true);
              }
              return "navbar__listItem__link";
            }}
            style={{
              fontFamily,
              fontWeight,
              fontSize: "14px",
              color: "#2A2C2E",
            }}
            to={{ pathname: url, state: { componentAccess } }}
          >
            <ListItemText style={{ width: "110%" }}>
              <span style={{ fontFamily, fontWeight, fontSize: "14px" }}>
                {t(label)}
              </span>
            </ListItemText>
          </NavLink>
        ) : (
          <ListItemText>
            <span style={{ fontFamily, fontWeight, fontSize: "14px" }}>
              {t(label)}
            </span>
          </ListItemText>
        )}
        {Array.isArray(items) ? (
          <List disablePadding>
            {items.map((subItem) => (
              <SidebarItem
                key={t(subItem.name)}
                url={subItem.url}
                depth={depth + 1}
                depthStep={depthStep}
                {...subItem}
                fontWeight="300"
                fontFamily="Roboto, Light"
                history={history}
                hasEmailAddress={hasEmailAddress}
                {...rest}
                componentAccess={subItem.componentAccess}
              />
            ))}
          </List>
        ) : null}
      </ListItem>
    </>
  );
};
