import React, { useRef, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { t } from "i18next";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import { Box } from "@material-ui/core";
import CommonContainerBox from "../custom/containers/CommonContainerBox";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ErrorMessage from "../custom/containers/ErrorMessage";
import moment from "moment";
import { transactionTypes, Regex, RoleType}  from "../../constants/sharedConstants.js";
import { sanitizeQueryParams, maxCharOccurrence, requiredFieldError, invalidFieldError } from "../../utils/helper";

const ERPSRecallRejectSearch = (props) => {
  const {
    pageType,   // "RPC", "RPM", or "RRH"  (respectively, Recall Payment for Customers, Reject Payment for Mechants, Recall & Reject History)
    pageTitle,
    pageDescription,
    searchValues,
    setSearchValues,
    dates,   // MOVE DEFINITION TO HERE
    loading,
    errorMessage,
    //Events:
    handleReset,
    handleSearch,
    // Extra data for dropdowns in some screens
    financialInstitutionList,
    transactionStatusList,
  } = props;
  if (!pageType || !searchValues|| !setSearchValues || !handleReset || !handleSearch)
    console.log("ERROR:  some important params are empty in ERPSRecallRejectSearch");
  const { t } = useTranslation();

  const user = useSelector((state) => state.loginUserReducer.user);
  
  const [fieldMap, setFieldMap] = useState(() => {
    let roleType = user.role.role_type;
    // Here we defined which fields are displayed for which screen
    // "O" - field is optional, "R" - field is Required
    let fields = [];
    if (pageType === "RPC") {
        fields = [["paymentToDate", "R"], ["transactionToAmount", "R"], ["bmoBillerId", "O"], ["billerCustomerNumber", "R"],
                                          ["verificationNumber", "O"],  ["billerName", "O"],  ["fiCustomerName", "O"]];
    } else if (pageType === "RPM") {
        fields = [["paymentFromDate", "R"], ["fiNumber", "R"],             ["billerCustomerNumber", "O"],
                  ["paymentToDate", "R"],   ["transactionToAmount", "O"],  ["verificationNumber", "O"]];
        if (roleType === RoleType.FI_ADMIN || roleType === RoleType.FI_USER) {
          fields.push(["bmoBillerId", "R"], ["billerName", "R"], ["fiCustomerName", "O"]);
        }
    } else if (pageType === "RRH") {
        fields = [["transactionType", "O"],   ["paymentFromDate", "R"], ["fiNumber", "R"],
                  ["transactionStatus", "R"], ["paymentToDate", "R"],   ["billerCustomerNumber", "O"] ];
        if (roleType === RoleType.FI_ADMIN || roleType === RoleType.FI_USER) {
          fields.push(["bmoBillerId", "O"], ["billerName", "O"], ["fiCustomerName", "O"]);
        }
    }
   /*  Show Everything - only for Developer's debug:
        fields = [["transactionType", "O"], ["paymentFromDate", "R"], ["paymentToDate", "R"], ["transactionStatus", "O"],   ["fiNumber", "O"],
                  ["searchBy", "O"],        ["bmoBillerId", "R"],     ["billerName", "R"],    ["billerCustomerNumber", "R"], ["fiCustomerName", "R"]
                  ,["transactionToAmount", "R"], ["verificationNumber", "R"]
                ];
   */
      else
        setValidationErrors(["Error: Unrecongnized pageType " + pageType]);
    return new Map(fields);
  });

  const [highlightError, setHighlightError] = useState({});
  const [validationErrors, setValidationErrors] = useState([]);
  const errorRef = useRef();
  const [transactionTypeDisplay, setTransactionTypeDisplay] = useState([]);

  useEffect(() => {
    setTransactionTypeDisplay( transactionTypes.map(tt => ({value: tt.value, name: t(tt.name, tt.name)}) ) );
    billerDetails.forEach(bd => bd.name = t(bd.labelKey));

    return () => errorRef.current = null;  // clean up the ref on exit
  }, []);

  useEffect(() => {
    if (errorRef.current)
        errorRef.current.focus();
  }, [validationErrors.length, errorMessage]);

  const handleFieldChange = (fieldName, newValue) => {
    if (loading) {
      setLoadingMsg("The page is loading, please wait");
    } else {
      let validInput = true;
      if (fieldName === "transactionToAmount" || fieldName === "transactionFromAmount") {
        const [wholePart, decimalPart] = String(newValue).split(".") || [String(newValue), null];
        validInput = wholePart.length < 20 && (!decimalPart || decimalPart.length < 3);
      } else if (fieldName === "verificationNumber") {
        if (newValue)
          validInput = Regex.alphaNumericWithHypen.test(newValue);
      }
      if (validInput)
        setSearchValues( {...searchValues, [fieldName]: newValue} ); // invoke setSearchValues(..)  to trigger screen rendering with the new value
    }
  };

  
  const resetClicked = () => {
    setHighlightError({});
    setValidationErrors([]);
    if (handleReset)
        handleReset();
  };

  const searchClicked = () => {
    const groomedValues = { ...sanitizeQueryParams({ ...searchValues }) };
    const { errors, highlightError } = validateSearchValues(groomedValues);
    if (errors.length) {
      setValidationErrors(errors);
      setHighlightError(highlightError);
    } else {
      const {
          transactionType, searchBy, offset, limit,   // Excluding these fields from the mininum field count
          ...rest
      } = groomedValues;
      if (rest && typeof rest === "object" && Object.keys(rest)?.length < 3) {
        setValidationErrors([ t("erps.transactionEnquiry.page.errorMessage.minimumthreefields.required") ]);
      } else {
        setValidationErrors([]);
        setHighlightError({});

        handleSearch(groomedValues)
      }
    }
  };

  const validateSearchValues = (req) => {
    let result = { errors: [], highlightError: {}};

    const date1 = new Date(req.paymentFromDate);
    const date2 = new Date(req.paymentToDate);

    let pfd = fieldMap.get("paymentFromDate");
    if (pfd && isValidValue(req, "paymentFromDate", result, "erps.common.label.paymentStartDate", (v) => (v != "Invalid date"))) {
        if (dates.minStartDateValue && date1.getTime() < dates.minStartDateValue.getTime()) 
            markError(result, "paymentFromDate",
                      t("erps.common.errorMessage.notBeforeMinimalDate", {fieldLabel: t("erps.common.label.paymentStartDate"), minimalDate: dates.minStartDateDisplay}));
        else if (dates.maxDateValue && date1.getTime() > dates.maxDateValue.getTime())
            markError(result, "paymentFromDate",
                      t("erps.common.errorMessage.notAfterMaximalDate", {fieldLabel: t("erps.common.label.paymentStartDate"), maximalDate: dates.maxDateDisplay}));
    }
    let ptd = fieldMap.get("paymentToDate");
    if (fieldMap.get("paymentToDate")) {   // if Payment End Date is displayed
      let paymentToDateLabel = pfd ? "erps.common.label.paymentEndDate" : "erps.common.label.paymentDate";
      if (isValidValue(req, "paymentToDate", result, paymentToDateLabel, (v) => (v != "Invalid date"))) {
        if (dates.minEndDateValue && date2.getTime() < dates.minEndDateValue.getTime())
            markError(result, "paymentToDate",
                      t("erps.common.errorMessage.notBeforeMinimalDate", {fieldLabel: t(paymentToDateLabel), minimalDate: dates.minEndDateDisplay}));
        else if (dates.maxDateValue && date2.getTime() > dates.maxDateValue.getTime())
            markError(result, "paymentToDate",
                      t("erps.common.errorMessage.notAfterMaximalDate", {fieldLabel: t(paymentToDateLabel), maximalDate: dates.maxDateDisplay}));
      }
      if (pfd && ptd) {
          if (date2.getTime() < date1.getTime())
              markError(result, ["paymentFromDate", "paymentToDate"],
                        t("erps.common.errorMessage.dateCompare", {earlierDate: t("erps.common.label.paymentStartDate"), laterDate: t(paymentToDateLabel)}));
          else if (date2.getTime() - date1.getTime() > dates.maxDateIntervalMs)
              markError(result, ["paymentFromDate", "paymentToDate"], t("erps.transactionEnquiry.page.errorMessage.dateRangeForOneMonth"));
      }
    }

    const amounts = [];
    if (fieldMap.get("transactionFromAmount")) {  // if field transactionFromAmount is displayed
      if (isValidValue(req, "transactionFromAmount", result, "erps.common.label.paymentFromAmount")) {
        if (!Regex.decimal2.test(req.transactionFromAmount)) {
            markError(result, "transactionFromAmount", t("erps.common.errorMessage.maxTwoDecimals", {fieldLabel: t("erps.common.label.paymentFromAmount")}));
        } else {
            amounts[0] = parseFloat(req.transactionFromAmount);
            if (amounts[0] < 0)
                markError(result, "transactionFromAmount", t("erps.common.errorMessage.greaterOrEqualZero", {fieldLabel: t("erps.common.label.paymentFromAmount")}));
        }
      }
    }
    if (fieldMap.get("transactionToAmount")) {  // if field transactionToAmount is displayed
      let label = fieldMap.get("transactionFromAmount") ? "erps.common.label.paymentToAmount" : "erps.common.label.paymentAmount";
      if (isValidValue(req, "transactionToAmount", result, label)) {
        if (!Regex.decimal2.test(req.transactionToAmount)) {
            markError(result, "transactionToAmount", t("erps.common.errorMessage.maxTwoDecimals", {fieldLabel: t(label)}));
        } else {
            amounts[1] = parseFloat(req.transactionToAmount);
            if (amounts[1] < 0)
                markError(result, "transactionToAmount", t("erps.common.errorMessage.greaterOrEqualZero"), {fieldLabel: t(label)});
        }
      }
      if (amounts[0] >= 0 && amounts[1] >= 0 && amounts[0] > amounts[1])
        markError(result, ["transactionFromAmount", "transactionToAmount"], t("erps.transactionEnquiry.page.errorMessage.paymentFromLessThanPaymentTo"));
    }

    if (fieldMap.get("bmoBillerId") || fieldMap.get("billerName")) {
      if (req.searchBy === billerDetails[0].value) {  // "billerId"
          isValidValue(req, "bmoBillerId", result, "erps.common.label.billerID", (v) => Regex.alphaNumeric.test(v));
      } else {  // i.e. req.searchBy === "billerName"
          if (isValidValue(req, "billerName", result, null, (v) => Regex.name.test(v))) {
            if ((req.billerName.includes("%") && req.billerName.length < 4) || !Regex.nameWildChar.test(req.billerName) ||
                    (req.billerName.includes("%") && !maxCharOccurrence(req.billerName, "%", 2)) ) {
                markError(result, "billerName", t("erps.transactionEnquiry.page.errorMessage.billerName.minChar"));
            }
          }
      }
    }

    isValidValue(req, "verificationNumber", result, "erps.common.label.confirmationNumber", (v) => Regex.alphaNumericWithHypen.test(v));
    isValidValue(req, "billerCustomerNumber", result, "erps.common.label.customerAccountNumber", (v) => Regex.alphaNumeric.test(v));
    isValidValue(req, "fiCustomerName", result, "erps.common.label.customerName", (v) => Regex.alphaNumericWithMarks.test(v));

    return { ...result };
  };

  function isValidValue(req, fieldName, result, fieldUILabel, validityCheck) {
    const fieldLabel = fieldUILabel || "erps.common.label." + fieldName;
    if (!req[fieldName]) { // if value is empty
      if ("R" === fieldMap.get(fieldName))  // if field is required
        markError(result, fieldName, requiredFieldError(fieldLabel));
      return false; // nothing entered
    }
    if (validityCheck && !validityCheck(req[fieldName])) {  // if validityCheck exists and the input is not valid
      markError(result, fieldName, invalidFieldError(fieldLabel));
      return false; // not valid
    }
    return true;  // a valid value is entered
  }

  function markError(result, fieldName, errorText) {
    if (errorText)
        result.errors.push(errorText);
    if (fieldName) {
        if (Array.isArray(fieldName))
          fieldName.forEach(attr => result.highlightError[attr] = true);
        else
            result.highlightError[fieldName] = true;
    }
  }

  return (
    <Box sx={{ ml: 0 }}>
        {(validationErrors.length || errorMessage) && (
            <Box role="alert" className="manageUsers__errorMsg__container" sx={{ mt: 2 }}>
                <ErrorMessage errorRef={errorRef} error={validationErrors?.length ? validationErrors : errorMessage} />
            </Box>
        )}

        <Box className="manageUsers__page__title">
          {/* For Title update */}
          <CommonContainerBox title={pageTitle} />
          <Typography variant="h1">{pageTitle}</Typography>
        </Box>
        <Box sx={{ mt: 4 }}>
            <Typography variant="h2">{pageDescription}</Typography>
        </Box>
        <Box sx={{ mt: 0 }}>
            <Typography variant="body1">{t("erps.transactionEnquiry.page.wildcardDescription")}</Typography>
        </Box>
        <Box sx={{ mt: 3 }}>
            <Typography variant="h3">{t("erps.transactionEnquiry.page.transactionDetails.page.subheading")}</Typography>
        </Box>

        {fieldMap.get("transactionType") && (
            <Stack sx={{ mt: -1 }} direction="row">
                <Box>
                <ERPSBaseRadioGroup id="transactionType"
                    value={searchValues?.transactionType}
                    name="transactionType"
                    //InputLabelProps={{ shrink: true }}
                    onChange={e => handleFieldChange("transactionType", e.target.value)}
                    items={transactionTypeDisplay}
                    label=""   // seems like it's a mandatory prop
                    //actionType={"hasURRSearchAccess"}   // DO I DEFINE A PERMISSION FOR THIS RADIO BTN ??
                    //className={"transactionEnquiry__radioButton_direction"}  // this removes the top margin
                    marginTop="true"   // this eliminates the default top margin which is quite large
                />
                </Box>
            </Stack>)
        }

        {(pageType !== "RPC") && (
            <Stack sx={{ mt: -2 }} spacing={2} direction="row">
                {fieldMap.get("paymentFromDate") && (
                  <ERPSBaseDatePicker id="paymentFromDate"
                    value={searchValues?.paymentFromDate}
                    label={t("erps.common.label.paymentStartDate")}
                    onChange={e => handleFieldChange("paymentFromDate", e)}
                    className="app_datepicker_biller_transactionEnquiry"
                    hasMargin="true"
                    required={"R" === fieldMap.get("paymentFromDate")}  // must be boolean for ERPSBaseDatePicker
                    datePickerStyle={"quick-reg-datepicker-v1"}
                    maxDate={dates?.maxDateValue}
                    minDate={dates?.minStartDateValue}
                    error={highlightError?.paymentFromDate} />
                )}
                {fieldMap.get("paymentToDate") && (
                  <ERPSBaseDatePicker id="paymentToDate"
                    value={searchValues?.paymentToDate}
                    label={ t(fieldMap.get("paymentFromDate") ? "erps.common.label.paymentEndDate" : "erps.common.label.paymentDate") }
                    onChange={e => handleFieldChange("paymentToDate", e)}
                    className="app_datepicker_biller_transactionEnquiry"
                    hasMargin="true"
                    required={"R" === fieldMap.get("paymentToDate")}  // must be boolean for ERPSBaseDatePicker
                    datePickerStyle={"quick-reg-datepicker-v1"}
                    maxDate={dates?.maxDateValue}
                    minDate={dates?.minEndDateValue}
                    error={highlightError?.paymentToDate} />
                )}
                {fieldMap.get("transactionStatus") && (
                  <ERPSBaseTextField id="transactionStatus"
                    value={searchValues?.transactionStatus}
                    type="text"
                    select={true}
                    label={t("erps.common.label.status", "Status")}
                    className="two_column__textField"
                    onChange={e => handleFieldChange("transactionStatus", e.target.value)}
                    size="small"
                    required={String("R" === fieldMap.get("transactionStatus"))}  // there's never an empty value in the dropdown anyway
                    items={transactionStatusList}
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: searchValues?.transactionStatus ? true : false }} />
                )}

                {(fieldMap.get("fiNumber") && (pageType !== "RRH" || searchValues?.transactionType === "RJ")) && (
                  <ERPSBaseTextField id="fiNumber"
                    value={searchValues?.fiNumber}
                    type="text"
                    select={true}
                    label={t("erps.common.label.financialInstitution", "Financial Institution")}
                    className="three_column__textField"
                    onChange={e => handleFieldChange("fiNumber", e.target.value)}
                    size="small"
                    required={String("R" === fieldMap.get("fiNumber"))}  // there's never an empty value in the dropdown anyway
                    items={financialInstitutionList}
                    SelectProps={{ native: true }}
                    InputLabelProps={{ shrink: searchValues?.fiNumber ? true : false }} />
                )}
            </Stack>
        )}

        {(fieldMap.get("transactionFromAmount") || fieldMap.get("transactionToAmount") || fieldMap.get("verificationNumber")
          || (pageType === "RPC" && fieldMap.get("paymentToDate")) ) && (
            <Stack sx={{ mt: -2 }} direction="row" spacing={2}>
                {pageType === "RPC" && fieldMap.get("paymentToDate") && ( // Only for RPC screen, the Date field is in the same group as Amount and Verification Number
                  <ERPSBaseDatePicker id="paymentToDate"
                    value={searchValues?.paymentToDate}
                    label={t("erps.common.label.paymentDate", "Payment date")}
                    onChange={e => handleFieldChange("paymentToDate", e)}
                    className="app_datepicker_biller_transactionEnquiry"
                    hasMargin="true"
                    required={"R" === fieldMap.get("paymentToDate")}  // must be boolean for ERPSBaseDatePicker
                    datePickerStyle={"quick-reg-datepicker-v1"}
                    maxDate={dates?.maxDateValue}
                    minDate={dates?.minEndDateValue}
                    error={highlightError?.paymentToDate} />
                )}

                {fieldMap.get("transactionFromAmount") && (
                  <ERPSBaseTextField id="transactionFromAmount"
                    value={searchValues?.transactionFromAmount}
                    type="number"
                    allowDecimal={true}
                    label={t("erps.common.label.paymentFromAmount")}
                    className="transactionEnquiry__textField__width"
                    size="small"
                    required={String("R" === fieldMap.get("transactionFromAmount"))}   // must be string "true"
                    onChange={(e) => handleFieldChange("transactionFromAmount", e.target.value) }
                    error={highlightError?.transactionFromAmount} />
                )}
                {fieldMap.get("transactionToAmount") && (
                  <ERPSBaseTextField id="transactionToAmount"
                    value={searchValues?.transactionToAmount}
                    type="number"
                    allowDecimal={true}
                    label={ t(fieldMap.get("transactionFromAmount") ? "erps.common.label.paymentToAmount" : "erps.common.label.paymentAmount") }
                    className="transactionEnquiry__textField__width"
                    size="small"
                    required={String("R" === fieldMap.get("transactionToAmount"))}   // must be string "true"
                    onChange={(e) => handleFieldChange("transactionToAmount", e.target.value) }
                    error={highlightError?.transactionToAmount} />
                )}
                {/*Confirmation number input*/}
                {fieldMap.get("verificationNumber") && (
                  <ERPSBaseTextField id="verificationNumber"
                    value={searchValues?.verificationNumber}
                    allowDecimal={false}
                    label={t("erps.common.label.confirmationNumber")}
                    className="transactionEnquiry__textField__width"
                    size="small"
                    required={String("R" === fieldMap.get("verificationNumber"))}
                    onChange={(e) => handleFieldChange("verificationNumber", e.target.value) }
                    error={highlightError?.verificationNumber} />
                )}
            </Stack>
        )}

        {(fieldMap.get("bmoBillerId") || fieldMap.get("billerName")) ? (
          <>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h3">
                {t("erps.common.label.billerDetails")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 0 }} spacing={2} direction="row">
                <Box sx={{ mt: 0 }}>
                  <ERPSBaseRadioGroup id="searchBy"
                      value={searchValues?.searchBy}
                      name="searchBy"
                      InputLabelProps={{ shrink: true }}
                      label={t("erps.common.label.searchBy")}
                      onChange={e => handleFieldChange("searchBy", e.target.value)}
                      items={billerDetails}
                      error={highlightError?.searchBy}
                      labelPlacement="start"
                      marginTop={true}  // What is this for??
                      className={"transactionEnquiry__radioButton_direction"} />
                </Box>
                <Box>
                  {searchValues?.searchBy === "billerId" && (
                      <ERPSBaseTextField id="bmoBillerId"
                          value={searchValues?.bmoBillerId}
                          required={String("R" === fieldMap.get("bmoBillerId"))}
                          type="text"
                          label={t("erps.common.label.billerID")}
                          className="transactionEnquiry__textField__width"
                          hasMargin="true"
                          size="small"
                          onChange={(e) => handleFieldChange("bmoBillerId", e.target.value)}
                          error={highlightError?.bmoBillerId}
                          inputProps={{ maxLength: 9 }} />
                  )}
                  {searchValues?.searchBy === "billerName" && (
                      <ERPSBaseTextField id="billerName"
                          value={searchValues?.billerName}
                          required={String("R" === fieldMap.get("billerName"))}
                          type="text"
                          label={t("erps.common.label.billerName")}
                          className="transactionEnquiry__textField__width"
                          hasMargin="true"
                          size="small"
                          onChange={(e) => handleFieldChange("billerName", e.target.value)}
                          error={highlightError?.billerName}
                          inputProps={{ maxLength: 45 }} />
                  )}
                </Box>
            </Stack>
          </>
        ) : ("")}

        {(fieldMap.get("billerCustomerNumber") || fieldMap.get("fiCustomerName")) ? (
          <>
            <Box sx={{ mt: 3 }}>
              <Typography variant="h3">
                  {t("erps.common.label.customerDetails")}
              </Typography>
            </Box>
            <Stack sx={{ mt: 2 }} spacing={2} direction="row">
              <ERPSBaseTextField id="billerCustomerNumber"
                  value={searchValues?.billerCustomerNumber}
                  required={String("R" === fieldMap.get("billerCustomerNumber"))}
                  type="text"
                  label={t("erps.common.label.customerAccountNumber")}
                  className="transactionEnquiry__textField__width"
                  hasMargin="true"
                  size="small"
                  onChange={(e) => handleFieldChange("billerCustomerNumber", e.target.value) }
                  error={highlightError?.billerCustomerNumber}
                  inputProps={{ maxLength: 45 }} />

              {(!fieldMap.get("fiCustomerName")) ? ("") : (
                <ERPSBaseTextField id="fiCustomerName"
                  value={searchValues?.fiCustomerName}
                  required={String("R" === fieldMap.get("fiCustomerName"))}
                  type="text"
                  label={t("erps.common.label.customerName")}
                  className="transactionEnquiry__textField__width"
                  hasMargin="true"
                  size="small"
                  onChange={(e) => handleFieldChange("fiCustomerName", e.target.value)}
                  error={highlightError?.fiCustomerName}
                  inputProps={{ maxLength: 30 }} />
              )}
            </Stack>
          </>
        ) : ("")}

        <Stack sx={{ mt: 4 }} spacing={2} direction="row">
          <ERPSBaseButton
              onClick={resetClicked}
              variant="secondary"
              className="button_with_zeromargin"
              label={t("erps.common.button.reset")}
              //componentAccess={componentAccess}
              actionType={"hasTransactionResetBtnAccess"}
          />
          <ERPSBaseButton
              onClick={searchClicked}
              variant="primary"
              className="button_with_zeromargin"
              label={t("erps.common.button.search")}
              loading={loading}
          />
        </Stack>
        <Box sx={{ mt: 3 }}>
            <Typography variant="h2"> {t("erps.manageusers.searchuser.searchresults")} </Typography>
        </Box>
    </Box>
  );
};

export default ERPSRecallRejectSearch;

const addDays = (date, daysToAdd) => {
    let result = date ? new Date(date) : new Date();
    result.setDate(date.getDate() + daysToAdd);
    return result;
};

export const getDateLimits = () => {
    let now = new Date();
    now = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    let minStartDateValue = addDays(now, -90);
    let minEndDateValue = addDays(now, -89);
  return {
      today: now,
      aMonthAgo: addDays(now, -31),
      minStartDateValue: minStartDateValue,
      minEndDateValue: minEndDateValue,
      maxDateValue: now,
      maxDateIntervalMs: 31*24*60*60*1000 + 60*60*1000,  // 31 days + 1hr in milliseconds (+1hr to account for an extra hour summer time switch)
      minStartDateDisplay: moment(minStartDateValue).format("YYYY-MM-DD"),
      minEndDateDisplay: moment(minEndDateValue).format("YYYY-MM-DD"),
      maxDateDisplay: moment(now).format("YYYY-MM-DD")
    };
};

export const billerDetails = [
    { value: "billerId",   labelKey: "erps.common.label.billerID" },
    { value: "billerName", labelKey: "erps.common.label.billerName" }
];

