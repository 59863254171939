import React, { useRef, useEffect } from "react";
import { useReactToPrint } from "react-to-print";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import { Box } from "@material-ui/core";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { useSelector } from "react-redux";
import ERPSTransactionDetails from "../custom/ERPSTransactionDetails";

const RecallRejectDetails = (props) => {
    const {
        location: { state },
    } = props;
    const { t } = useTranslation();
    const transactionListData = useSelector( 
        (state) => {
            switch(props.componentType) {
                case "URR": return state.transactionEnquiryReducer?.upcomingRecallsRejectsData?.data;
                case "RRH": return state.transactionEnquiryReducer?.recallRejectHistoryData?.data;
                default: return null;
            }
         }
    );

    let data = transactionListData ? transactionListData.find(({id}) => id == state.id) : {};
    const lastSlashIndex = props.location.pathname.lastIndexOf("/");
    const backPathname = (lastSlashIndex != -1) ? props.location.pathname.substring(0, lastSlashIndex + 1) : props.location.pathname;

    const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);
    const user = useSelector((state) => state.loginUserReducer.user);
    const roleId = user?.role?.role_id;
    const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;
    let printComponentRef = useRef();

    useEffect(() => {
        return () => { printComponentRef.current = null };  // clean up the ref on exit
    }, []);

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });

    let detailArguments;
    if (data?.transaction_type == "RJ")
        detailArguments = { data: data, rejectReason: data.recall_reject_reason, rejectedBy: data.recalled_rejected_by };
    else if (data?.transaction_type == "RT")
        detailArguments = { data: data, recallReason: data.recall_reject_reason, recalledBy: data.recalled_rejected_by };
    else  // if data.transaction_type is not defined.  This SHOULD NOT HAPPEN, by the way.
        detailArguments = { data: data };

    return (
        <div role="main">
            <Box st={{ ml: 3}}>
                <Breadcrumb {...props} fromDetailPage={true}/>
            </Box>

            <ERPSTransactionDetails {...detailArguments}  ref={printComponentRef}/>

            <Stack direction="row" spacing={2} ml={3}>
                <ERPSBaseButton
                    variant="secondary"
                    className="button_with_margin48"
                    label={t("erps.newuser.button.back", "Back")}
                    onClick={() =>
// SHOULDN'T THIS BE A PARAMETER IF THIS IS RE-USED BY OTHER SCREENS ??
                        //props.history.push("/upcoming-recalls-rejects/", { fromDetailPage: true })
                        props.history.push(backPathname, { fromDetailPage: true })
                    }
                />
                <ERPSBaseButton
                    variant="primary"
                    className="button_with_margin48"
                    label={t("erps.transactionEnquiry.page.transactionDetails.button.print")}
                    onClick={handlePrint}
                />
            </Stack>
        </div>
    );
};

export default withSidebar(RecallRejectDetails);
