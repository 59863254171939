import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box, Grid, Icon, IconButton } from "@material-ui/core";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import { useCustomHook } from "../custom/useCustomHook";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import { getAvailableDocumentsHeading, EMPTY_DOCUMENTS_CRITERIA } from "../../constants/AvailableDocumentsConstant";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";

const AvailableDocuments = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const apiExceptionRef = useRef();
  const billerIdFromPath = props?.billerInfo?.searchedBillerId || props?.location?.state?.searchedBillerId;
  const reqBillerId = props?.billerInfo?.billerId || props?.location?.state?.billerId;
  const billerType = props?.billerInfo?.billerType || props?.location?.state?.billerType;
  const [pageNumber, setPageSize] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(25);
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const [documentData, setDocumentData] = useState(null);
  const [sourceList, setSourceList] = useState([]);
  const [showApiExceptionMessage, setShowApiExceptionMessage] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [filterLoading, setFilterLoading] = useState(false);

  const documentResult = useSelector(
    (state) => state.availableDocumentReducer.availableDocument
  );
  const apiExceptionMessage = useSelector(
    (state) => state.availableDocumentReducer?.downloadDocumentError
  );

  const [validationErrors, setValidationErrors] = useState([]);
  const [highlightError, setHighlightError] = useState({});

  useEffect(() => {
    if (apiExceptionRef.current) {
      apiExceptionRef.current.focus();
    }
  }, [
    apiExceptionMessage && showApiExceptionMessage,
    validationErrors && validationErrors?.length,
  ]);

  useEffect(() => {
    if (loginUser.fiNumber && (loginUser.fiNumber === 2 || loginUser.fiNumber === 10)) {
      setSourceList([
        { value: "", label: "" },
        { value: "Service Fee Invoice", label: t("erps.availabledocuments.sourcelist.servicefeeinvoice") },
        { value: "Bill Payment", label: t("erps.availabledocuments.sourcelist.billpayment") },
        { value: "Recalled and Rejected Payment Report", label: t("erps.availabledocuments.sourcelist.recalledandrejectedpaymentreport") }
      ]);
    } else {
      setSourceList([
        { value: "", label: "" },
        { value: "Service Fee Invoice", label: t("erps.availabledocuments.sourcelist.servicefeeinvoice") },
        { value: "Retail Bill Payment", label: t("erps.availabledocuments.sourcelist.retailbillpayment") },
        { value: "Corporate Bill Payment", label: t("erps.availabledocuments.sourcelist.corporatebillpayment") },
        { value: "Recalled and Rejected Payment Report", label: t("erps.availabledocuments.sourcelist.recalledandrejectedpaymentreport") }
      ]);
    }
  }, [setSourceList]);

  useEffect(() => {
    const searchBillerFilesDto = {
      source: "",
      start_off_set: 0,
      end_off_set: 25,
      start_date: "",
      end_date: "",
      biller_id: props.billerId || reqBillerId,
    };
    dispatch(
      actions.availableDocumentActions.getAvailableDocument(
        searchBillerFilesDto,
        t
      )
    );
  }, [dispatch]);

  useEffect(() => {
    if (documentResult) {
      const data = documentResult.data.map((data) => {
        return { ...data, id: data.file_id };
      });
      setDocumentData(data);
    }
  }, [documentResult]);

  const errorRef = useRef();

  const getUpdatedReportPath = (reportPath) => {
    const pattern = /\\/;
    return pattern.test(reportPath.charAt(0))
      ? reportPath.slice(1)
      : reportPath;
  };

  const fileDownload = (billerFilesDto, fileFormat) => {
    setShowApiExceptionMessage(false);
    const searchBillerFilesDto = {
      source: "",
      start_off_set: 0,
      end_off_set: 25,
      start_date: "",
      end_date: "",
      biller_id: props.billerId || reqBillerId,
    };

    dispatch(
      actions.availableDocumentActions.downloadDocuments(
        {
          ...billerFilesDto,
          file_description: billerFilesDto?.file_type,
          ...(fileFormat === "PDF" && {
            pdf_link: getUpdatedReportPath(billerFilesDto?.pdf_link),
          }),
          ...(fileFormat === "CSV" && {
            csv_link: getUpdatedReportPath(billerFilesDto?.csv_link),
          }),
        },
        fileFormat,
        {
          setShowApiExceptionMessage,
          t,
          searchBillerFilesDto,
          setInitialLoading,
        }
      )
    );
  };
  const todayDate = new Date();
  const minDateValue = new Date(
    todayDate.getFullYear(),
    todayDate.getMonth() - 13,
    todayDate.getDate()
  );
  const maxDateValue = new Date();

  const [values, setValues] = useState({
    billerId: loginUser?.searchBillerFilesDto?.biller_id,
    fiNumber: loginUser?.searchBillerFilesDto?.fi_number,
    billerName: loginUser?.searchBillerFilesDto?.biller_name,
    fiBillerWithBillerId:
      loginUser?.searchBillerFilesDto?.fi_biller_with_biller_id,
  });

  const {
    handleAvailableDocumentsSearch,
    searchCriteriaChangeHandler,
    resetAvailableDocumentsHandler,
    isSearchValid,
    paginateAvailableDocuments,
    searchCriteria,
    hasSearch,
  } = useCustomHook({
    tableResults: documentData,
    tableHeadings: getAvailableDocumentsHeading(fileDownload),
    initialStates: EMPTY_DOCUMENTS_CRITERIA,
    setErrorMessage: setValidationErrors,
    errorMessage: validationErrors,
    propsComp: {
      ...props,
      billerId: props?.billerId || reqBillerId || values?.billerId,
    },
    setHighlightError,
    minDateValue,
    maxDateValue,
    setInitialLoading,
    setLoading: setFilterLoading,
    setPageSize,
    setRowPerPage,
  });

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [!isSearchValid]);

  const searchResultText = () => {
    let textVal;
    let count;
    if (hasSearch) {
      if (documentResult !== null && documentResult !== undefined) {
        textVal = t(
          "erps.availabledocuments.filtereddocumentscodesresults",
          "Filtered documents"
        );
        count = documentResult.count;
      }
    } else {
      if (documentResult !== null && documentResult !== undefined) {
        if (documentResult.count > 0) {
          textVal = t(
            "erps.availabledocuments.availabledocumentscodesresults",
            "All documents"
          );
          count = documentResult.count;
        } else {
          textVal = t(
            "erps.availabledocuments.availabledocumentscodesresults",
            "All documents"
          );
          count = 0;
        }
      } else {
        textVal = t(
          "erps.availabledocuments.label.searchresults",
          "Search Results"
        );
      }
    }
    return { textVal, count };
  };

  const getPageHeading = () => {
    if (billerType) {
      return billerType === "child"
        ? `${t(
            "erps.availabledocuments.text.documentforbiller"
          )}: ${billerIdFromPath}`
        : `${t(
            "erps.availabledocuments.text.documentforparentbiller"
          )}: ${billerIdFromPath}`;
    } else if (props?.billerId) {
      return t("erps.availabledocuments.page.title", "Available documents");
    } else {
      return t("erps.downloaddocuments.page.title");
    }
  };

  return (
    <div role="main">
      <Box ml={3}>
        {(billerType || props?.pageName === "biller-profile") && (
          <Breadcrumb
            {...props}
            updateSetState={props?.updateSetState}
            pathValue={
              billerType
                ? "/downloads-documents/view-documents"
                : "/manage-billers/user-profile"
            }
            componentAccess={props?.parentComponentAccess}
          />
        )}
        {((apiExceptionMessage && showApiExceptionMessage) ||
          Boolean(validationErrors.length)) && (
          <Box className="manageUsers__errorMsg__container" role="alert" mt="2">
            <ErrorMessage
              error={apiExceptionMessage || validationErrors}
              errorRef={apiExceptionRef}
            />
          </Box>
        )}
        <Box mt={4}>
          <Typography variant="h1">{getPageHeading()}</Typography>
        </Box>
        {!props?.billerId && !billerType && (
          <Box className="manageUsers__wrap__box">
            <Typography variant="body1">
              {t(
                "erps.availabledocuments.page.description",
                "Download reports and invoices"
              )}
            </Typography>
          </Box>
        )}
        {!props?.billerId && !billerType && props.pageName !== "biller-profile" && (
            <Box mt={3} sx={{ display: "flex" }}>
              <Typography className="available__documents__value">
                {t("erps.availabledocuments.label.billerId", "Biller ID")}{" "}
                <br />{" "}
                <label className="test-label">
                  {values.fiBillerWithBillerId}
                </label>
              </Typography>
              <Typography className="available__documents__value">
                {t("erps.availabledocuments.label.description", "Biller name")}{" "}
                <br /> <label className="test-label">{values.billerName}</label>
              </Typography>
            </Box>
          )}
        <Box mt={!billerType ? 7 : 4}>
          <Typography variant="h2">
            {t(
              "erps.availabledocuments.filteravailabledocuments.heading",
              "Filter criteria"
            )}
          </Typography>
        </Box>

        {!isSearchValid ? (
          <Box
            className="manageUsers__errorMsg__container"
            mt={!isSearchValid ? 2 : 0}
            role="alert"
          >
            {!isSearchValid && (
              <ErrorMessage
                error={t(
                  "erps.manageusers.searchuser.error.emptyinput",
                  "At least one search criteria must be provided."
                )}
                errorRef={errorRef}
              />
            )}
          </Box>
        ) : (
          ""
        )}
        <Box style={{ width: "650px" }}>
          <Grid container>
            <Grid item sm={4} lg={4}>
              <ERPSBaseTextField
                id="source-container"
                type="text"
                select={true}
                label="Source"
                className="common__textField__width"
                size="small"
                items={sourceList}
                onChange={searchCriteriaChangeHandler("source")}
                value={searchCriteria.source}
                SelectProps={{native: true}}
                InputLabelProps={{shrink: searchCriteria.source ? true : false}}
              />
            </Grid>
            <Grid item sm={4} lg={4}>
              <Box mt={2}>
                <ERPSBaseDatePicker
                  label={t("erps.availabledocuments.page.startdate", "Start date")}
                  onChange={searchCriteriaChangeHandler("startDate")}
                  value={searchCriteria.startDate || null}
                  error={highlightError?.startDate}
                  minDate={minDateValue}
                  maxDate={todayDate}
                  id={"avilable-document-start-date"}
                />
              </Box>
            </Grid>
            <Grid item sm={4} lg={4}>
              <Box mt={2}>
                <ERPSBaseDatePicker
                  label={t("erps.availabledocuments.page.enddate", "End date")}
                  value={searchCriteria.endDate || null}
                  onChange={searchCriteriaChangeHandler("endDate")}
                  error={highlightError?.endDate}
                  minDate={minDateValue}
                  maxDate={todayDate}
                  id={"avilable-document-end-date"}
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box mt={-4} display="flex">
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.common.button.reset", "Reset")}
            onClick={resetAvailableDocumentsHandler}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.common.button.filter", "Filter")}
              onClick={handleAvailableDocumentsSearch}
              loading={filterLoading}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Typography variant="h2"> {searchResultText()?.textVal}</Typography>
        </Box>
        {searchResultText()?.count !== undefined && (
          <Box marginTop={1} marginBottom={1.5} sx={{ display: "flex" }}>
            <Typography variant="body1">
              {searchResultText()?.count}{" "}
              {t("erps.availabledocuments.availabledocuments.records", "records")}
            </Typography>
          </Box>
        )}
        {documentData !== null && documentData !== undefined && documentData.length > 0 && (
            <>
              <Box className="manageUsers_card_visible">
                <CardList
                  data={{
                    rows: documentData,
                    count: documentResult?.count,
                    columns: getAvailableDocumentsHeading(fileDownload),
                  }}
                  onAnyPaginationAction={paginateAvailableDocuments}
                  pageNumber={pageNumber}
                  rowPerPage={rowPerPage}
                />
              </Box>

              <Box className="manageUsers_table_visible">
                <ERPSGrid
                  data={{
                    rows: documentData,
                    count: documentResult?.count,
                    columns: getAvailableDocumentsHeading(fileDownload),
                  }}
                  onAnyPaginationAction={paginateAvailableDocuments}
                  sumOfColumnWidth="true"
                  tableLabelText={t(
                    "erps.downloaddocuments.page.title",
                    "Download documents"
                  )}
                  initialLoading={initialLoading}
                  pageNumber={pageNumber}
                  rowPerPage={rowPerPage}
                />
              </Box>
            </>
          )}
        {(documentResult === null ||
          searchResultText()?.count === undefined) && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {t(
                "erps.availabledocuments.searchuser.notstarted",
                "Search not started"
              )}
            </Typography>
          </Box>
        )}
        {billerType && (
          <ERPSBaseButton
            variant="primary"
            className="button_with_margin48"
            label={t("erps.newuser.button.back")}
            onClick={() => {
              if (props?.checkRedirect) {
                props?.handleCloseViewDocument();
              } else {
                props.history.push("/downloads-documents");
              }
            }}
          />
        )}
      </Box>
    </div>
  );
};

export default withSidebar(AvailableDocuments);
