import React, { useState, useRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../actions";
import ErrorMessage from "../custom/containers/ErrorMessage";
import {
  getNextFilterId,
  requiredFormatDate,
  getLabelName,
} from "../../utils/helper";

export default function NewAndDeletePricePlanApproval(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const pricePlanActionError = useSelector(
    (state) => state.pricePlanQueueReducer?.pricePlanActionError
  );
  const errorRef = useRef();

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [pricePlanActionError]);
  const [saveDeleteLoading, setSaveDeleteLoading] = useState(false);
  const [declineLoading, setDeclineLoading] = useState(false);
  const [lastRecordDisable, setLastRecordDisable] = useState(false);
  const {
    data,
    setShowSuccessMessage,
    componentAccess,
    pricePlanPendingApprovalData,
    userId,
  } = props;

  const {
    actionName,
    baseFee,
    billingModelDescription,
    billingModelNumber,
    billingModelOriginalNumber,
    createdBy,
    createdDate,
    csvFee,
    fiNumber,
    id,
    languageCode,
    maximumDocumentRate,
    minimumDocumentRate,
    notificationFee,
    perDocumentRate,
    updatedBy,
    updatedDate,
    updatedFormattedDate,
    displayUpdatedBy,
  } = data;

  const hasLastActiveRecord = () => {
    if (
      pricePlanPendingApprovalData &&
      pricePlanPendingApprovalData.length === 1
    ) {
      return true;
    }
    return false;
  };

  const navigateToPricePlanPendingApporvalPage = () => {
    props.history.push("/price-plan-pending-approvals", { componentAccess });
  };

  const handleDecline = (event) => {
    setDeclineLoading(true);
    if (event) event.preventDefault();

    const filteredQueueDataList = pricePlanPendingApprovalData.filter(
      ({ billingModelNumber: bmn }) => data?.billingModelNumber != bmn
    );

    const currentIndexVal = pricePlanPendingApprovalData.findIndex(
      ({ billingModelNumber: bmn }) => data?.billingModelNumber == bmn
    );
    const slicedArr = pricePlanPendingApprovalData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();

    const nextPricePlanId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      userId,
      "billingModelNumber"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }
    dispatch(
      actions.pricePlanPendingApprovalsActions.declinePricePlanApprovalData(
        data,
        {
          ...props,
          t,
          setDeclineLoading,
          setShowSuccessMessage,
          nextPricePlanId,
          queueDataList:
            nextPricePlanId === ""
              ? pricePlanPendingApprovalData
              : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
        }
      )
    );
  };

  const handleApprove = (event) => {
    setSaveDeleteLoading(true);
    if (event) event.preventDefault();

    const filteredQueueDataList = pricePlanPendingApprovalData.filter(
      ({ billingModelNumber: bmn }) => data?.billingModelNumber != bmn
    );

    const currentIndexVal = pricePlanPendingApprovalData.findIndex(
      ({ billingModelNumber: bmn }) => data?.billingModelNumber == bmn
    );
    const slicedArr = pricePlanPendingApprovalData.slice(currentIndexVal + 1);
    const requiredArr =
      slicedArr && slicedArr.length ? slicedArr : filteredQueueDataList;
    const hasLastRecord = hasLastActiveRecord();

    const nextPricePlanId = getNextFilterId(
      filteredQueueDataList,
      requiredArr,
      userId,
      "billingModelNumber"
    );
    if (setShowSuccessMessage) {
      setShowSuccessMessage(false);
    }

    dispatch(
      actions.pricePlanPendingApprovalsActions.createPricePlanApprovalData(
        data,
        {
          ...props,
          setSaveDeleteLoading,
          setShowSuccessMessage,
          t,
          pricePlanPendingApprovalData,
          nextPricePlanId,
          queueDataList:
            nextPricePlanId === ""
              ? pricePlanPendingApprovalData
              : filteredQueueDataList,
          componentAccess,
          hasLastRecord,
          setLastRecordDisable,
        }
      )
    );
  };

  const pricePendingApprovalChildAccess = componentAccess.find(
    ({ componentType }) => componentType === "PPAFV"
  );

  function addZeroes(num) {
    if (num) {
      const dec = num.split(".")[1];
      const len = dec && dec.length > 2 ? dec.length : 2;
      return Number(num).toFixed(len);
    }
  }

  const pricePendingApprovalChildComponent =
    pricePendingApprovalChildAccess?.childComponents;
  return (
    <>
      {pricePlanActionError && (
        <Box
          className={"manageUsers__errorMsg__container"}
          role="alert"
          tabIndex={0}
          mt={pricePlanActionError ? 2 : 0}
        >
          {pricePlanActionError && (
            <ErrorMessage error={pricePlanActionError} errorRef={errorRef} />
          )}
        </Box>
      )}
      <Box className="manageUsers__page__title">
        <Typography variant="h1">{`${t(
          "erps.managePricePlan.approval.approvepriceplan"
        )}: ${billingModelDescription}`}</Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h2">{`${t(
          "erps.managePricePlan.approval.approvedetails"
        )}: ${
          actionName === "New"
            ? t("erps.managePricePlan.approval.newpriceplan")
            : t("erps.managePricePlan.approval.updatedpriceplan")
        }`}</Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t("erps.managePricePlan.approval.updatedby")}
          <Typography variant="body2">{displayUpdatedBy}</Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t("erps.managePricePlan.approval.updatedon")}
          <Typography variant="body2">
            {requiredFormatDate(updatedFormattedDate)}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t("erps.managePricePlan.approval.action")}
          <Typography variant="body2">{actionName}</Typography>
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="h2">
          {t("erps.managePricePlan.approval.priceplan")}
        </Typography>
      </Box>
      <Box mt={4}>
        <Typography variant="body2">
          {t("erps.newpriceplan.label.priceplanname")}
        </Typography>
      </Box>
      <Box mt={1}>
        <Typography variant="body2">{billingModelDescription}</Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t("erps.newpriceplan.label.basefee")}
          <Typography variant="body2">
            {addZeroes(baseFee?.toString())}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t("erps.newpriceplan.label.csvfee")}
          <Typography variant="body2">
            {addZeroes(csvFee?.toString())}
          </Typography>
        </Typography>
      </Box>
      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t("erps.newpriceplan.label.notificationfee")}
          <Typography variant="body2">
            {addZeroes(notificationFee?.toString())}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t("erps.newpriceplan.label.perdocumentrate")}
          <Typography variant="body2">
            {addZeroes(perDocumentRate?.toString())}
          </Typography>
        </Typography>
      </Box>

      <Box mt={3} sx={{ display: "flex" }}>
        <Typography className="available__documents__value">
          {t("erps.newpriceplan.label.mindocumentrate")}
          <Typography variant="body2">
            {addZeroes(minimumDocumentRate?.toString())}
          </Typography>
        </Typography>
        <Typography className="available__documents__value">
          {t("erps.newpriceplan.label.maxdocumentrate")}
          <Typography variant="body2">
            {addZeroes(maximumDocumentRate?.toString())}
          </Typography>
        </Typography>
      </Box>
      <Stack direction="row" spacing={2}>
        <ERPSBaseButton
          variant="secondary"
          className="button_with_margin48"
          label={t("erps.common.button.cancel", "Cancel")}
          onClick={() => navigateToPricePlanPendingApporvalPage()}
          componentAccess={pricePendingApprovalChildComponent}
          actionType={"hasPricePlanCancelButton"}
        />
        <ERPSBaseButton
          functionType="PPPA"
          variant="secondary"
          onClick={() => handleDecline()}
          loading={declineLoading}
          className="button_with_margin48"
          label={getLabelName({
            key: "decline",
            hasLastActiveRecord,
            lastRecordDisable,
            t,
          })}
          componentAccess={pricePendingApprovalChildComponent}
          actionType={"hasPricePlanDeclineAccess"}
          isDisabled={lastRecordDisable}
        />
        <ERPSBaseButton
          functionType="PPPA"
          variant="primary"
          onClick={() => handleApprove()}
          loading={saveDeleteLoading}
          className="button_with_margin48"
          label={getLabelName({
            key: "approve",
            hasLastActiveRecord,
            lastRecordDisable,
            t,
          })}
          componentAccess={pricePendingApprovalChildComponent}
          actionType={"hasPricePlanApprovalAccess"}
          isDisabled={userId == data?.updatedBy || lastRecordDisable}
        />
      </Stack>
    </>
  );
}
