
import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { convertDateIntoERPSDateFormat, formatAmount } from "../utils/helper";

export const upcomingRecallsRejectsHeading = (fiMap) => {
  return [
    { field: "id",
      headerName: t("erps.common.label.number.short"),
      width: 50,
      sortable: false,
      renderCell: (params) => (
        <ERPSBaseLink className="link__blue"
          to={ { pathname: `/upcoming-recalls-rejects/recall-reject-details`, state: { id: params.row?.id } }}
          label={ "" + params.row?.seqNo } />
        ),
    },
    { field: "transaction_amount",
      headerName: t("erps.common.label.paymentAmount"),
      width: 160,
      sortable: false,
      renderCell: (params) => { if (params.value)  return formatAmount(params.value); },
    },
    { field: "effective_date",
      headerName: t("erps.common.label.effectiveDate"),
      width: 110,
      sortable: false,
      renderCell: (params) => convertDateIntoERPSDateFormat(params.value),
    },
    { field: "verification_number",
      headerName: t("erps.common.label.confirmationNumber.short"),
      width: 184,
      sortable: false,
    },
    { field: "fi_biller_id",
      headerName: t("erps.common.label.billerID"),
      width: 140,
      sortable: false,
    },
    { field: "biller_customer_number",
      headerName: t("erps.common.label.customerAccountNumber.short"),
      width: 194,
      sortable: false,
    },
    (fiMap ?  // for "RJ" (Reject) show FI column, for "RT" (Recall) show Customer Name column
      { field: "fi_number",
        headerName: t("erps.common.label.fi"),
        width: 230,
        sortable: false,
        renderCell: (params) => fiMap?.get(params.value) || params.value,
      }
    : { field: "fi_customer_name",
        headerName: t("erps.common.label.customerName"),
        width: 230,
        sortable: false,
      }),
    { field: "transaction_status",
      headerName: t("erps.common.label.status"),
      width: 140,
      sortable: false,
      renderCell: (params) => t("erps.common.status.toBeProcessed"),
    },
  ];
};

export const EMPTY_SEARCH_CRITERIA = {
  searchType: null,
  startOffset: 0,
  endOffset: 100,
  limit: 100,
};

