import { fiActionTypes } from "../actions/types/fi.action.types";
import store from "../store/index";
import axios from "axios";
import { getErrorMessageFromException, API_BASE_URL    ,listProps } from "../utils/helper";

export const fiService = {
  getFinancialInstitutionList,
  getHelpDeskPhoneNumber,
  getMasterFiDescriptionList,
  getFinancialInstitutionListByOwningFiNumber,
};

function getFinancialInstitutionList(translation) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(`${API_BASE_URL()}/fi-management/view-financial-institution`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        // For fi_id 99, we set fi_name hard-coded to "Bank of Montreal", because in the DB 
        // the fi_name is 'CANACT' and we don't want to change it in case it's used anywhere else
        // (based on discussion with Arul 2024-11-12)
        if (res.data?.length) {
          res.data.forEach( element => {
            if (element.fi_number == 99)
              element.fi_name = "Bank of Montreal";
          });
        }
        dispatch({
          type: fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError = JSON.parse(JSON.stringify(error));
        dispatch({
          type: fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST_ERROR,
          error:
            getErrorMessageFromException(error, translation) ||
            parsedError.message,
        });
      });
  };
}

function getMasterFiDescriptionList(translation, setIsLoading) {
  return (dispatch) => {
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(`${API_BASE_URL()}/fi-management/view-fi-description`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + token,
        },
      })
      .then((res) => {
        if (setIsLoading) {
          setIsLoading(false);
        }
        dispatch({
          type: fiActionTypes.GET_MASTER_FI_DESCRIPTION_LIST,
          data: res.data,
        });
      })
      .catch((error) => {
        if (setIsLoading) {
          setIsLoading(false);
        }
        const parsedError = JSON.parse(JSON.stringify(error));
        dispatch({
          type: fiActionTypes.GET_MASTER_FI_DESCRIPTION_LIST_ERROR,
          error:
            getErrorMessageFromException(error, translation) ||
            parsedError.message,
        });
      });
  };
}

function getHelpDeskPhoneNumber(fiNumber, roleId, translation) {
  return (dispatch) => {
    const userId = store.getState()?.loginUserReducer.user?.userId;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        `${API_BASE_URL()}/fi-management/help-desk-number?userId=${userId}&&fiNumber=${fiNumber}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: fiActionTypes.FI_HELPDESK_NUMBER_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError = JSON.parse(JSON.stringify(error));
        dispatch({
          type: fiActionTypes.FI_HELPDESK_NUMBER_ERROR,
          error:
            getErrorMessageFromException(error, translation) ||
            parsedError.message,
        });
      });
  };
}

function getFinancialInstitutionListByOwningFiNumber(translation) {
  return (dispatch) => {
    const userId = store.getState()?.loginUserReducer.user?.userId;
    const token = store.getState()?.loginUserReducer.token;
    axios
      .get(
        `${API_BASE_URL()}/fi-management/get-financial-institution-by-owning-fi`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
        }
      )
      .then((res) => {
        dispatch({
          type: fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_SUCCESS,
          data: res.data,
        });
      })
      .catch((error) => {
        const parsedError = JSON.parse(JSON.stringify(error));
        dispatch({
          type: fiActionTypes.GET_FINANCIAL_INSTITUTION_LIST_BY_OWNING_ERROR,
          error:
            getErrorMessageFromException(error, translation) ||
            parsedError.message,
        });
      });
  };
}
