import Typography from "@mui/material/Typography";
import {Box} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import { withSidebar } from "../hoc/withSidebar";
import {useTranslation} from "react-i18next";
import {useDispatch, useSelector} from "react-redux";
import actions from "../../actions";
import ERPSGrid from "../custom/containers/ERPSGrid";
import CardList from "../custom/containers/cardList";
import {rejectTransactionsHeading} from "../../constants/RejectTransactionsConstant";
import moment from "moment";
import { formatDate } from "../../utils/helper";
import { RoleType }  from "../../constants/sharedConstants.js";
import ERPSRecallRejectSearch, { getDateLimits, billerDetails } from "../custom/ERPSRecallRejectSearch";

const RejectTransactions = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        history,
        location: { state, pathname },
    } = props;

    const [errorMessage, setErrorMessage] = useState(null);
    const [loading, setLoading] = useState(false);
    const [pageNumber, setPageNumber] = useState(0);
    const [rowPerPage, setRowPerPage] = useState(100);

    const user = useSelector((state) => state.loginUserReducer.user);
    const roleId = user?.role?.role_id;
    const roleType = user?.role?.role_type;

    const rejectTransactionResponse = useSelector(
        (state) => state.transactionEnquiryReducer
    );

    const dates = getDateLimits();

    const initialSearchValues = {
        paymentFromDate: dates.aMonthAgo,
        paymentToDate: dates.today,
        transactionToAmount: "",
        verificationNumber: "",
        searchBy: billerDetails[0].value,   // "billerId"
        bmoBillerId: (roleType === RoleType.BILLER_ADMIN || roleType === RoleType.BILLER_USER) ? user.billerNonVolatileDto.biller_fi_number : "",
        billerName: "",
        billerCustomerNumber: "",
        fiCustomerName: "",
        fiNumber: "0",
        sortBy: "ED",
      };

    const [searchValues, setSearchValues] = useState(initialSearchValues);

    const [actualCriteria, setActualCriteria] = useState(
        state?.fromDetailPage ? rejectTransactionResponse?.transactionForRejectDetails : { ...initialSearchValues, offset: 0, limit: rowPerPage}
    );

    const [transactionData, setTransactionData] = useState( state?.fromDetailPage && rejectTransactionResponse?.transactionForRejectData.data || null );
    const [financialInstitutionList, setFinancialInstitutionList] = useState([]);
    const [financialInstitutionMap, setFinancialInstitutionMap] = useState(null);

    const financialInstitutionResponse = useSelector( (state) => state.fiReducer?.financialInstitutionList );


    useEffect(() => {
        if (state?.fromDetailPage) {
            // transfer into searchValues the attributes saved in rejectTransactionResponse.transactionForRejectDetails
            Object.keys(searchValues).filter(key => rejectTransactionResponse.transactionForRejectDetails[key])
                  .forEach(key => searchValues[key] = rejectTransactionResponse.transactionForRejectDetails[key]);
            setRowPerPage(actualCriteria.limit);
            setPageNumber(actualCriteria.offset / actualCriteria.limit);
            state.fromDetailPage = null;  // resetting the coming-from-detail-page flag
            if (state.isRejected)
                loadResults(actualCriteria, " (After Reject)");
        } else if (state?.searchedValues) {
            setSearchValues(state?.searchedValues);
        } else {  // reset search resutls previously saved in the reducer
            if (!financialInstitutionResponse || financialInstitutionResponse.length == 0)
                dispatch(actions.fiActions.getFinancialInstitutionList(t));
    
            dispatch({
                type: "GET_TRANSACTION_FOR_REJECT_SUCCESS",
                data: { data: null, transactionForRejectDetails: null },
              });
        }
    }, []);

    useEffect(() => {
        if (financialInstitutionResponse && financialInstitutionResponse.length) {
            const data = financialInstitutionResponse.map(
                ({ fi_name, fi_number }) => ({ value: fi_number, label: `${fi_number} - ${fi_name}` })
            );
            data.unshift({value: "0", label: "All FI's"});
            setFinancialInstitutionList(data);
            setFinancialInstitutionMap(new Map(financialInstitutionResponse.map( ({ fi_number, fi_name }) => [fi_number, fi_name])));
        }
    }, [financialInstitutionResponse]);

    useEffect(() => {
        if (rejectTransactionResponse) {
            const transactionForRejectRes = rejectTransactionResponse?.transactionForRejectData?.data;
            if (transactionForRejectRes?.length) {
                for(let i=0; i < transactionForRejectRes.length; i++)
                    transactionForRejectRes[i].seqNo = i + 1 + actualCriteria.offset;
            }
            setTransactionData(transactionForRejectRes);
        }
    }, [rejectTransactionResponse]);

    const paginate = (values) => {  // 'values' has fields startOffset and endOffset (e.g., for 1st Page: 0,25;  2nd Page: 25,50;  3rd: 50,75...)
        actualCriteria.offset = values.startOffset;
        if (actualCriteria.limit !== (values.endOffset - values.startOffset)) // if the user changed the number of rows per page
          actualCriteria.limit = values.endOffset - values.startOffset;
    
        loadResults(actualCriteria, " (paginate)");
    };

    const loadResults = (queryParams, prompt) => {
        if (errorMessage)
          setErrorMessage(null);
        setLoading(true);

        dispatch(
          actions.transactionEnquiryActions.getTransactionsForReject(
            queryParams,
            { ...props, t, history, setLoading, setErrorMessage}
          )
        );
    };

    const handleSearch = (groomedValues) => {
        if (pageNumber)
            setPageNumber(0);
        if (groomedValues.paymentFromDate)
            groomedValues.paymentFromDate = formatDate(moment(groomedValues.paymentFromDate).format());
        if (groomedValues.paymentToDate)
            groomedValues.paymentToDate = formatDate(moment(groomedValues.paymentToDate).format());
        groomedValues.limit = actualCriteria.limit;
        groomedValues.offset = 0;
        groomedValues.currentFiNumber = user?.fiNumber;
        setActualCriteria({...groomedValues});

        if (roleType === RoleType.FI_ADMIN || roleType === RoleType.FI_USER) {
            if (groomedValues.searchBy === billerDetails[0].value)  // "billerId"
                delete groomedValues["billerName"];  // NOT sending billerName when searching by biller id
            else if (groomedValues.searchBy === billerDetails[1].value)  //  "billerName"
                delete groomedValues["bmoBillerId"]; // NOT sending bmoBillerId when searching by biller Name
        }
    
        loadResults(groomedValues, " (search)");
    };

    const handleReset = () => {
        setSearchValues({...initialSearchValues});
        setActualCriteria({ ...initialSearchValues, offset: 0});
        setPageNumber(0);
        setErrorMessage(null);

        dispatch({
          type: "GET_TRANSACTION_FOR_REJECT_SUCCESS",
          data: { data: null, transactionForRejectDetails: null },
        });
    };

    return (
        <div role="main">
            <Box ml={3}>
                <ERPSRecallRejectSearch 
                    pageType="RPM"
                    pageTitle={t("erps.rejectpaymentmerchants.page.title")}
                    pageDescription={t("erps.transactionEnquiry.page.description")}
                    searchValues={searchValues}
                    setSearchValues={setSearchValues}
                    dates={dates}
                    loading={loading}
                    errorMessage={errorMessage}
                    handleReset={handleReset}
                    handleSearch={handleSearch}
                    financialInstitutionList={financialInstitutionList}
                />

                {transactionData !== null && transactionData !== undefined
                 && Array.isArray(transactionData) && transactionData.length > 0 ? (
                    <>
                        <Box className="manageUsers_table_visible">
                            <ERPSGrid
                                data={{
                                    rows: transactionData,
                                    columns: rejectTransactionsHeading(financialInstitutionMap, roleId, user?.fiNumber),
                                    count: rejectTransactionResponse?.transactionForRejectData?.count || 0
                                }}
                                sumOfColumnWidth="true"
                                initialLoading={loading}
                                pageNumber={pageNumber}
                                setPage={setPageNumber}
                                rowPerPage={rowPerPage}
                                tableLabelText={t("erps.transactionEnquiry.page.title")}
                                hideFooterPagination={false}
                                pageName={"transaction-enquiry"}
                                onAnyPaginationAction={paginate}
                            />
                        </Box>
                        <Box className="manageUsers_card_visible">
                            <CardList
                                data={{
                                    rows: transactionData,
                                    columns: rejectTransactionsHeading(financialInstitutionMap, roleId, user?.fiNumber),
                                    count: rejectTransactionResponse?.transactionForRejectData?.count || 0
                                }}
                                onAnyPaginationAction={paginate}
                                hideFooterPagination={false}
                                pageName={"transaction-enquiry"}
                                pageNumber={pageNumber}
                                setPage={setPageNumber}
                                rowPerPage={rowPerPage}
                            />
                        </Box>
                    </>
                ) : (
                    <Box mt={1}>
                        <Typography variant="body1">
                            { (transactionData?.length === 0)
                                ? (`0 ${t("erps.transactionEnquiry.page.resultfound")}`)
                                : (t("erps.availabledocuments.searchuser.notstarted", "Search not started"))
                            }
                        </Typography>
                    </Box>
                )}
            </Box>
        </div>
    );

};

export default withSidebar(RejectTransactions);