import { useTranslation } from "react-i18next";
import { Box, Grid } from "@material-ui/core";
import ERPSBaseLink from "../custom/inputs/ERPSBaseLink";
import { getHeaderAndFooterLinks } from "../../utils/external-urls";

const Footer = () => {
  const { t, i18n } = useTranslation();
  const fiName = localStorage.getItem("fiName");
  const fiNumber = localStorage.getItem("fiNumber");
  const { legalLink, privacyAndSecurityLink } = getHeaderAndFooterLinks(
    i18n.language
  );
  const domainUrl = window.location.href?.split("/")[2];
  const subDomin = domainUrl?.split(".")?.slice(1)?.join(".");
  const getSpaceChar = () => {
    if (legalLink && privacyAndSecurityLink) {
      return <>&nbsp;|&nbsp;</>;
    }
  };
  const getFiName = () => {
    if (fiName === "bns") {
      return "bns";
    } else if (fiName === "D&D") {
      if (fiNumber == 50 || fiNumber == 88) {
        return "D&D";
      } else if (fiNumber == 16) {
        return "hsbc";
      } else if (fiNumber == 40) {
        return "manulifebank";
      } else if (fiNumber == 37) {
        return "meridiancu";
      } else if (fiNumber == 36) {
        return "motusbank";
      } else if (fiNumber == 26) {
        return "pcbank";
      } else if (fiNumber == 61) {
        return "tangerinebank";
      }
    } else {
      return fiName;
    }
  };
  return (
    <Box
      role="contentinfo"
      mt={10}
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "0px",
      }}
    >
      <Box className="footer__box__container">
        <Box className="footer__box__content">
          <span>
            <center>
              {legalLink && (
                <ERPSBaseLink
                  to={{ pathname: legalLink || "" }}
                  className="link__blue"
                  label={t("erps.footer.privacy.legal.message", "Legal")}
                  target={legalLink ? "_blank" : ""}
                ></ERPSBaseLink>
              )}
              {getSpaceChar()}
              {privacyAndSecurityLink ? (
                <ERPSBaseLink
                  to={{ pathname: privacyAndSecurityLink || "" }}
                  className="link__blue"
                  label={t("erps.footer.privacy.message", "Security")}
                  target={privacyAndSecurityLink ? "_blank" : ""}
                ></ERPSBaseLink>
              ) : (
                ""
              )}
            </center>
            <br />
            {fiNumber == 10 ?
                <span className= "footer__span_content">
                  {t("erps.footer.fihost.cibc.message")}
                  <ERPSBaseLink
                      to={{
                        pathname:
                            "https://dyedurham.ca/solution/managed-payments/" || "",
                      }}
                      className="link__blue"
                      label={subDomin}
                      variant="link"
                      target={"https://dyedurham.ca/solution/managed-payments/"}
                  />
                  {t("erps.footer.fihost.cibc.message.secondPart", {
                    value: getFiName()?.toUpperCase(),
                  })}
                  {t("erps.footer.fihost.cibc.message.thirdPart")}
            <ERPSBaseLink
                to={{
                  pathname:
                      "https://dyedurham.ca/solution/managed-payments/" || "",
                }}
                className="link__blue"
                label={subDomin}
                variant="link"
                target={"https://dyedurham.ca/solution/managed-payments/"}
            />
            {t("erps.footer.fihost.cibc.message.fourthPart")}
                </span>
           :    <span>
                  {t("erps.footer.fihost.message")}
                  <ERPSBaseLink
                          to={{
                            pathname:
                                "https://dyedurham.ca/solution/managed-payments/" || "",
                          }}
                          className="link__blue"
                          label={subDomin}
                          variant="link"
                          target={"https://dyedurham.ca/solution/managed-payments/"}
                      />
                      {t("erps.footer.fihost.message.secondPart", {
                        value: getFiName()?.toUpperCase(),
                      })}
           </span>}
          </span>
        </Box>
              </Box>
    </Box>
  );
};

export default Footer;
