import React, { useEffect, useState, useRef } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box, Grid, Icon } from "@material-ui/core";
import Typography from "@mui/material/Typography";
import { useTranslation } from "react-i18next";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseCheckBoxGrp from "../custom/inputs/ERPSBaseCheckBoxGrp";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import actions from "../../actions";
import { useSelector, useDispatch } from "react-redux";
import warningIcon from "../../assets/images/warning-icon.png";
import userActionTypes from "../../actions/types/user.action.type";
import CustomModelDialog from "../custom/inputs/CustomModelDialog";

const NewUser = (props) => {
  const { t } = useTranslation();
  const pageUrl = props?.location?.pathname;
  const userRoleTypes = useSelector(
    (state) => state.userRoleTypeReducer?.userRoleTypeList
  );
  const subscribingEntitiesList = useSelector(
    (state) => state.subscribingEntitiesReducer?.subscribingEntitiesList
  );
  const successMessage = useSelector(
    (state) => state.newUserReducer?.newUserAdded
  );
  const loginUser = useSelector((state) => state.loginUserReducer?.user);
  const duplicateUserIdError = useSelector(
    (state) => state.newUserReducer?.error
  );
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  const wpBillerAdmin = 3;
  const wpBillerUser = 4;
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  const [error, setError] = useState([]);
  const [subscribeEntityList, setSubscribeEntityList] = useState([]);
  const [userRoleTypeList, setUserRoleTypeList] = useState([]);
  const [loader, setLoader] = useState(false);
  const dispatch = useDispatch();
  const errorRef = useRef(null);
  const successRef = useRef(null);
  const fiNumber = selectedFiNumber || loginUser?.fiNumber;
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [error, duplicateUserIdError]);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && showSuccessMessage]);

  useEffect(() => {
    if (subscribingEntitiesList) {
      let data = [];
      if (loginUser?.fiCompanyNumber?.trim() !== "") {
        subscribingEntitiesList.forEach(
          ({ fiCompanyNumber, fiCompanyName }) => {
            if (fiCompanyNumber === loginUser?.fiCompanyNumber) {
              data.push({
                value: fiCompanyNumber,
                label: fiCompanyName,
              });
            }
          }
        );
      } else {
        data = subscribingEntitiesList.map(
          ({ fiCompanyNumber, fiCompanyName }) => ({
            value: fiCompanyNumber,
            label: fiCompanyName,
          })
        );
        data.unshift({
          value: "",
          label: "",
        });
      }
      setSubscribeEntityList(data);
    }
  }, [subscribingEntitiesList]);

  useEffect(() => {
    if (duplicateUserIdError) {
      dispatch({ type: userActionTypes.NEW_USER_REQUEST });
    }
  }, []);

  useEffect(() => {
    if (userRoleTypes) {
      const data = userRoleTypes.map(({ role_id, role_type, role_name }) => ({
        roleId: role_id,
        value: role_id,
        label: role_name?.toUpperCase(),
      }));
      data.unshift({ roleId: "", value: "", label: "" });
      setUserRoleTypeList(data);
    }
  }, [userRoleTypes]);

  const {
    location: {
      state: { componentAccess },
    },
    history,
  } = props;

  const newUserComponent = componentAccess.filter(
    ({ componentType, childComponents }) => componentType === "NU"
  );

  const newUserComponentAccess = newUserComponent[0].childComponents;

  const languageItems = [
    { name: t("erps.common.label.english"), value: "E" },
    { name: t("erps.common.label.french"), value: "F" },
  ];

  const checkBillerUser = () => {
    return loginUser?.billerNonVolatileDto?.biller_id;
  };

  const [values, setValues] = useState({
    userId: "",
    fiNumber,
    roleId:
      loginUser?.role?.role_id == 9 ? loginUser?.role?.role_id.toString() : "",
    languageCode: "E",
    fiCompanyNumber:
      loginUser?.fiCompanyNumber?.trim() !== ""
        ? loginUser?.fiCompanyNumber
        : "",
    firstName: "",
    lastName: "",
    emailAddress: "",
    phone: "",
    challengeNumber: 0,
    challengeResponse: "",
    changePasswordIndex: "Y",
    logonFailures: 0,
    emailNotification: "N",
    passwordHash: "",
    userIdErrorHighlight: false,
    roleIdErrorHighlight: false,
    subscribingEntityErrorHighlight: false,
    firstNameErrorHighlight: false,
    lastNameErrorHighlight: false,
    emailAddressErrorHighlight: false,
    phoneNumberErrorHighlight: false,
  });

  const isWpBillerAdminChangesRole = (prop) => {
    return (
      prop === "roleId" &&
      pageUrl == "/manage-users/new-user" &&
      checkBillerUser() &&
      loginUser?.role?.role_id == wpBillerAdmin
    );
  };

  const handleChange = (prop) => (event) => {
    if (duplicateUserIdError && prop === "userId") {
      dispatch({ type: userActionTypes.NEW_USER_REQUEST, error: null });
    }
    if (prop === "emailNotification") {
      setValues({ ...values, [prop]: event.target.checked ? "Y" : "N" });
    } else {
      setValues({
        ...values,
        [prop]: event.target.value,
        ...(isWpBillerAdminChangesRole(prop) &&
          loginUser?.billerNonVolatileDto?.email_notification_ok == "N" && {
            emailNotification: "N",
          }),
        ...(isWpBillerAdminChangesRole(prop) &&
          event.target.value == wpBillerAdmin &&
          loginUser?.billerNonVolatileDto?.email_notification_ok == "Y" && {
            emailNotification: "Y",
          }),
        ...(isWpBillerAdminChangesRole(prop) &&
          event.target.value == wpBillerUser &&
          loginUser?.billerNonVolatileDto?.email_notification_ok == "Y" && {
            emailNotification: "N",
          }),
      });
    }
  };

  const addUser = () => {
    dispatch({
      type: userActionTypes.NEW_USER_REQUEST,
      error: null,
      newUserAdded: null,
    });
    const errormsg = validateUser();
    setError(errormsg);

    if (!errormsg.length) {
      setLoader(true);
      setShowSuccessMessage(false);

      const finalObject = createUserFinalObjectForSave();
      const billerId = loginUser?.billerNonVolatileDto?.biller_id;

      dispatch(
        actions.userActions.saveUser(
          { ...finalObject, billerId },
          setValues,
          {
            ...props,
            setLoader,
            loader,
            setShowSuccessMessage,
            t,
          },
          userRoleTypeList,
          subscribingEntitiesList
        )
      );
    }
  };

  function createUserFinalObjectForSave() {
    const finalObject = { ...values };
    userRoleTypeList.forEach((userType) => {
      if (userType.value === values.roleId) {
        finalObject.roleId = userType.roleId;
      }
    });
    finalObject.actionCode = "A";
    finalObject.statusCode = "A";
    finalObject.loggedInUserId = loginUser.userId;
    return finalObject;
  }

  const validateEmail = (email) => {
    var re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const checkUserIdLength = (userId) => {
    if (fiNumber == 2) {
      return userId.length < 6 || userId.length > 8
        ? t("erps.manageusers.length.userid.msg.fispecific.bns")
        : "";
    } else {
      return userId.length < 8 || userId.length > 10
        ? t("erps.manageusers.length.userid.msg.fispecific.others")
        : "";
    }
  };

  function validateUser() {
    let errormsg = [];
    var alphaNumericRegEx = /^[0-9A-Za-z]+$/;
    var alphaNumericRegExWithSpace = /^[a-zA-Z0-9.\-' À-ÿ]+$/;
    var numericRegEx = /^[0-9]+$/;
    values.userIdErrorHighlight = false;
    values.roleIdErrorHighlight = false;
    values.subscribingEntityErrorHighlight = false;
    values.firstNameErrorHighlight = false;
    values.lastNameErrorHighlight = false;
    values.emailAddressErrorHighlight = false;
    values.phoneNumberErrorHighlight = false;

    if (!checkBillerUser()) {
      if (!values.userId) {
        values.userIdErrorHighlight = true;
        errormsg.push(
          t("erps.manageusers.blank.userid.msg", "UserId cannot be blank.")
        );
      } else if (
        values.userId &&
        values.userId.match(alphaNumericRegEx) === null
      ) {
        values.userIdErrorHighlight = true;
        errormsg.push(
          t(
            "erps.manageusers.alphanumeric.userid.msg",
            "UserId must be alpha numeric."
          )
        );
      } else if (values.userId && checkUserIdLength(values.userId)) {
        values.userIdErrorHighlight = true;
        errormsg.push(t(checkUserIdLength(values.userId)));
      }
    }

    if (!values.roleId) {
      values.roleIdErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.roleId.msg", "Please select the Role.")
      );
    }

    if (!values.firstName) {
      values.firstNameErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.firstname.msg", "First name cannot be blank.")
      );
    }
    if (
      values.firstName &&
      (values.firstName.trim().length < 1 ||
        values.firstName.trim().length > 25)
    ) {
      values.firstNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.firstname.msg",
          "First name length is between 1 and 25 characters."
        )
      );
    }
    if (
      values.firstName &&
      values.firstName.match(alphaNumericRegExWithSpace) === null
    ) {
      values.firstNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.firstname.msg",
          "First name must be alpha numeric."
        )
      );
    }

    if (!values.lastName) {
      values.lastNameErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.blank.lastname.msg", "Last name cannot be blank.")
      );
    }
    if (
      values.lastName &&
      (values.lastName.trim().length < 1 || values.lastName.trim().length > 25)
    ) {
      values.lastNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.lastname.msg",
          "Last name length is between 1 and 25 characters."
        )
      );
    }
    if (
      values.lastName &&
      values.lastName.match(alphaNumericRegExWithSpace) === null
    ) {
      values.lastNameErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.alphanumeric.lastname.msg",
          "Last name  must be alpha numeric."
        )
      );
    }
    if (!values.emailAddress) {
      values.emailAddressErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.blank.emailaddress.msg",
          "Email address cannot be blank."
        )
      );
    }
    if (values.emailAddress && values.emailAddress.length > 80) {
      values.emailAddressErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.length.emailaddress.msg",
          "Email address length should not be greater than 60 characters."
        )
      );
    }
    if (values.emailAddress && !validateEmail(values.emailAddress)) {
      values.emailAddressErrorHighlight = true;
      errormsg.push(
        t(
          "erps.manageusers.format.emailaddress.msg",
          "Please enter your e-mail address in this format 'name@example.com'"
        )
      );
    }
    if (values.phone && values.phone.trim().length !== 10) {
      values.phoneNumberErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.length.phone.msg", "Phone length should be 10.")
      );
    }
    if (values.phone && values.phone.match(numericRegEx) === null) {
      values.phoneNumberErrorHighlight = true;
      errormsg.push(
        t("erps.manageusers.numeric.phone.msg", "Phone Must be numeric.")
      );
    }

    if (values.roleId === "9" && !values.fiCompanyNumber) {
      errormsg.push(t("erps.manageusers.subscribingentity.msg"));
    }

    return errormsg;
  }

  const handleOpenCancelDialog = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
  };

  const handleRedirect = () => {
    dispatch({
      type: userActionTypes.RESET_ERROR,
      error: null,
      newUserAdded: null,
    });
    history.push("/manage-users", { componentAccess });
  };

  const getWpBillerId = () => {
    if (fiNumber == 99) {
      return (
        loginUser?.searchBillerFilesDto?.fi_biller_with_biller_id ||
        loginUser?.billerNonVolatileDto?.biller_id
      );
    }
    const externalBillerId =
      loginUser?.billerNonVolatileDto?.biller_fi_number || "";
    const internalBillerId = loginUser?.billerNonVolatileDto?.biller_id || "";
    return externalBillerId
      ? `${externalBillerId}-${internalBillerId}`
      : internalBillerId;
  };

  return (
    <div role="main">
      {successMessage && showSuccessMessage && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={setShowSuccessMessage}
          inputRef={successRef}
        />
      )}
      <Box ml={3}>
        <Breadcrumb {...props} componentAccess={componentAccess} />
        <Box className="manageUsers__page__title">
          <Typography variant="h1">
            {t("erps.newuser.page.title", "Manage users")}
          </Typography>
        </Box>
        <Box mt={4}>
          <Typography variant="h2">
            {t("erps.newuser.account.settings", "Account Settings")}
          </Typography>
        </Box>
        {((error && error?.length > 0) || duplicateUserIdError) && (
          <Box
            className="manageUsers__errorMsg__container"
            mt={error?.length > 0 || duplicateUserIdError ? 5 : 0}
            role={"alert"}
          >
            {((error && error?.length > 0) || duplicateUserIdError) && (
              <Box tabIndex={0} ref={errorRef} style={{ display: "flex" }}>
                <Icon className="manageUsers__error__icon" aria-hidden={false}>
                  <img src={warningIcon} alt="Error notification" />
                </Icon>
                <div style={{ marginTop: "-12px" }}>
                  <ul>
                    {" "}
                    <li
                      style={{
                        color: "#C12334",
                        listStyle: "none",
                        marginLeft: "-20px",
                        marginTop: "20px",
                      }}
                    >
                      {t("erps.common.error.title")}
                    </li>
                    {error.map((err) => (
                      <li style={{ color: "#C12334" }}> {err} </li>
                    ))}
                    {duplicateUserIdError && (
                      <li style={{ color: "#C12334" }}>
                        {" "}
                        {typeof duplicateUserIdError === "object"
                          ? duplicateUserIdError?.message || ""
                          : duplicateUserIdError}{" "}
                      </li>
                    )}
                  </ul>
                </div>
              </Box>
            )}
          </Box>
        )}

        <Box component="form" autoComplete="off">
          {!checkBillerUser() && (
            <Stack direction="row" spacing={3}>
              <ERPSBaseTextField
                id="userId"
                type="text"
                error={values.userIdErrorHighlight}
                label={t("erps.manageusers.label.userid", "User ID")}
                className="three_column__textField"
                onChange={handleChange("userId")}
                value={values.userId}
                size="small"
                required="true"
                actionType="hasUserIDTextField"
                componentAccess={newUserComponentAccess}
              />
            </Stack>
          )}
          {loginUser?.role?.role_id == 3 &&
            pageUrl == "/manage-users/new-user" && (
              <Stack direction="row" spacing={3}>
                <div style={{ width: "210px", padding: "20px 0px 0px 0px" }}>
                  <Typography variant="body1">
                    {t("erps.billermanagement.label.billerId")}
                    <br />
                  </Typography>
                  <label className="test-label">{getWpBillerId()}</label>
                </div>
              </Stack>
            )}
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="role"
              type="text"
              error={values.roleIdErrorHighlight}
              select={true}
              label={t("erps.newuser.label.role", "Role")}
              className="three_column__textField"
              value={values.roleId}
              onChange={handleChange("roleId")}
              size="small"
              items={userRoleTypeList}
              SelectProps={{ native: true }}
              InputLabelProps={{ shrink: values.roleId ? true : false }}
              actionType="hasRoleDropDown"
              componentAccess={newUserComponentAccess}
              required={"true"}
              isDisabled={loginUser?.role?.role_id == 9 ? true : false}
            />
            {values.roleId === "9" && (
              <ERPSBaseTextField
                id="subscribeEntityId"
                select={true}
                type="text"
                label={t(
                  "erps.newuser.label.subscribingentityid",
                  "Subscribing entity id"
                )}
                className="three_column__textField"
                size="small"
                value={values.fiCompanyNumber}
                items={subscribeEntityList}
                onChange={handleChange("fiCompanyNumber")}
                SelectProps={{ native: true }}
                InputLabelProps={{ shrink: true }}
                isDisabled={
                  loginUser?.fiCompanyNumber.trim() !== "" ? true : false
                }
              />
            )}
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="firstname"
              type="text"
              error={values.firstNameErrorHighlight}
              label={t("erps.newuser.label.firstname", "First name")}
              className="three_column__textField"
              size="small"
              value={values.firstName}
              onChange={handleChange("firstName")}
              required="true"
              actionType="hasFNameTextField"
              componentAccess={newUserComponentAccess}
            />
            <ERPSBaseTextField
              id="lastName"
              type="text"
              error={values.lastNameErrorHighlight}
              label={t("erps.newuser.label.lastname", "Last name")}
              className="three_column__textField"
              size="small"
              value={values.lastName}
              onChange={handleChange("lastName")}
              required="true"
              actionType="hasLNameTextField"
              componentAccess={newUserComponentAccess}
            />
          </Stack>
          <Stack direction="row" spacing={3}>
            <ERPSBaseTextField
              id="emailAddress"
              type="text"
              error={values.emailAddressErrorHighlight || duplicateUserIdError === t("erps.manageusers.error.duplicate.emailaddress") }
              label={t("erps.newuser.label.emailaddress", "Email Address")}
              className="three_column__textField"
              size="small"
              value={values.emailAddress}
              onChange={handleChange("emailAddress")}
              actionType="hasEmailTextField"
              required="true"
              componentAccess={newUserComponentAccess}
            />
            <ERPSBaseTextField
              id="phone"
              type="text"
              error={values.phoneNumberErrorHighlight}
              label={t("erps.newuser.label.phonenumber", "Phone number")}
              className="three_column__textField"
              size="small"
              onChange={handleChange("phone")}
              value={values.phone}
              actionType="hasPhoneTextField"
              componentAccess={newUserComponentAccess}
            />
          </Stack>

          <Grid container>
            <Grid item sm={6} lg={6}>
              <ERPSBaseRadioGroup
                name="language"
                value={values.languageCode}
                label={t("erps.common.label.language")}
                onChange={handleChange("languageCode")}
                items={languageItems}
                actionType="hasLangDropDown"
                componentAccess={newUserComponentAccess}
              />
            </Grid>
          </Grid>

          {loginUser?.role?.role_id == 3 &&
            pageUrl == "/manage-users/new-user" && (
              <Stack direction="row" spacing={3} mt={3}>
                <ERPSBaseCheckBoxGrp
                  name="email_notification"
                  value={values?.emailNotification === "Y"}
                  disabled={
                    checkBillerUser() &&
                    loginUser?.billerNonVolatileDto?.email_notification_ok ==
                      "N" &&
                    values.roleId == wpBillerUser
                  }
                  label={t("erps.billerregistration.label.sendNotification")}
                  onChange={handleChange("emailNotification")}
                />
              </Stack>
            )}

          <Stack direction="row" spacing={2}>
            <ERPSBaseButton
              variant="secondary"
              actionType="hasCancelBtn"
              componentAccess={newUserComponentAccess}
              className="button_with_margin48"
              label={t("erps.common.button.cancel", "Cancel")}
              onClick={handleOpenCancelDialog}
            />
            <ERPSBaseButton
              actionType="hasSaveUserAccess"
              functionType="NU"
              variant="primary"
              onClick={addUser}
              className="button_with_margin48"
              label={t("erps.newuser.button.save", "Save")}
              loading={loader}
              componentAccess={newUserComponentAccess}
            />
          </Stack>
        </Box>
      </Box>
      <CustomModelDialog
        handleClose={handleCloseCancelDialog}
        open={openCancelDialog}
        dialogHeaderClassName={"dialogHeaderClassName"}
        dialogActionsClassName={"leftAlignDialogActions"}
        handleRedirect={handleRedirect}
        tanslation={t}
      />
    </div>
  );
};

export default withSidebar(NewUser);
