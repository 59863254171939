import React, { useRef, useEffect, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { withSidebar } from "../hoc/withSidebar";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import { Box } from "@material-ui/core";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Stack from "@mui/material/Stack";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumb from "../Breadcrumb/Breadcrumb";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogHeader from "../custom/inputs/ERPSBaseModelDialog";
import actions from "../../actions";
import moment from "moment";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import ErrorMessage from "../custom/containers/ErrorMessage";
import DialogContent from "@mui/material/DialogContent";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import ERPSTransactionDetails from "../custom/ERPSTransactionDetails";
import { Regex }  from "../../constants/sharedConstants.js";
import { invalidFieldError } from "../../utils/helper";

const RejectPaymentDetails = (props) => {
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const {
        location: { state },
    } = props;

    const [openDialog, setDialogOpen] = useState({ isOpen: false });
    const [isRejected, setIsRejected] = useState(false);
    const [isReasonInvalid, setIsReasonInvalid] = useState(false);

    const transactionForRejectData = useSelector(
        (state) => state.transactionEnquiryReducer?.transactionForRejectData?.data
    );
    const successMessage = useSelector(
        (state) => state.transactionEnquiryReducer?.rejectTransactionData
    );
    const [errorMessage, setErrorMessage] = useState(null);
    const rejectErrorMessage = useSelector(
        (state) => state.transactionEnquiryReducer?.rejectTransactionError
    );

    const user = useSelector((state) => state.loginUserReducer.user);

    const errorRef = useRef();
    const successRef = useRef();
    const printComponentRef = useRef();

    const [rejectReason, setRejectReason] = useState('');

    useEffect(() => {
            localStorage.setItem('rejectReasonValue', rejectReason);

    }, [rejectReason]);


    useEffect(() => {
        dispatch({ type: "UPDATE_TRANSACTION_FOR_REJECT_SUCCESS", data: null })
        const savedValue = localStorage.getItem('rejectReasonValue');
        if (savedValue) {
            setRejectReason(savedValue);
        }
        return onExit;  // clean up the ref on exit
    }, []);

    const onExit = () => {
        errorRef.current = null;
        successRef.current = null;
        printComponentRef.current = null;
    }

    useEffect(() => {
        setErrorMessage(rejectErrorMessage);
        if (errorRef.current) {
            errorRef.current.focus();
        }
    }, [rejectErrorMessage?.length]);

    let data = transactionForRejectData
        ? transactionForRejectData.find(({ payment_id }) => payment_id === state.id)
        : {};

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);
    const [rejectLoading, setRejectLoading] = useState(false);

    useEffect(() => {
        if (successRef.current) {
            successRef.current.focus();
        }
    }, [successMessage && showSuccessMessage]);


    const formatDateTime = (d) => {
        return (d) ? moment(d).format("YYYY-MM-DD hh:mm:ss:SS") : "";
    };

    const handleReject = async  () => {
        const setLoading = setRejectLoading;
        setIsRejected(true);
        setLoading(true);
        if (showSuccessMessage) {
            setShowSuccessMessage(false);
        }
        let reqBodyForReject = {};
        if (data) {
            const {
                id,
                fi_number,
                payment_date,
            } = data;
            reqBodyForReject = {
                correlation_id: id,
                fi_number,
                payment_date,
                recall_reject_reason: rejectReason,
                recalled_rejected_by: user?.userId,
                language_code: user?.languageCode
            };
        }

        dispatch(
            actions.transactionEnquiryActions.rejectTransaction(
                {  reqBodyForReject },
                {
                    setShowSuccessMessage,
                    setLoading,
                    t,
                }
            )
        );
    };

    const handleChange = (event) => {
        setRejectReason(event.target.value);
    };

    const handleConfirmReject = () => {
        if (rejectReason && !Regex.regularText.test(rejectReason)) {
            setErrorMessage(invalidFieldError(t("erps.rejectpaymentmerchants.page.label.reject.reason")));
            setIsReasonInvalid(true);
        } else {
            setErrorMessage(null);
            setIsReasonInvalid(false);
            setDialogOpen({ isOpen: true });
        }
    };
        
    const displayPaymentTime = (paymentTime) => {
        if (paymentTime) {
            const parsedPaymentTime = paymentTime.toString();
            const updatedPaymentTime = parsedPaymentTime.padStart(8, "0");
            return updatedPaymentTime.replace(/(..)(?!$)/g, "$1:");
        }
    };

    const handlePrint = useReactToPrint({
        content: () => printComponentRef.current
    });
    const detailArguments = successMessage ? {data: data, rejectReason: rejectReason, rejectedBy: user?.userId} : {data: data};

    return (
        <div role="main">
            {successMessage && showSuccessMessage && (
                <ConfirmationMessage
                    message={successMessage}
                    setShowMessage={setShowSuccessMessage}
                    inputRef={successRef}
                />
            )}
            {errorMessage && (
                <Box className="manageUsers__errorMsg__container" role="alert" mt={2}>
                    <ErrorMessage error={errorMessage} errorRef={errorRef} />
                </Box>
            )}
            <Box sx={{ ml: 3 }}>
                <Breadcrumb {...props} fromDetailPage={true}/>
            </Box>

            <ERPSTransactionDetails {...detailArguments} ref={printComponentRef}/>

            <Box id={"screenshot-element"} sx={{ ml: 3 }}>
                {!successMessage && (
                <Box>
                    <ERPSBaseTextField
                        id="rejectReason"
                        type="text"
                        label={t("erps.rejectpaymentmerchants.page.label.reject.reason")}
                        className="transactionEnquiry__textField__width"
                        size="small"
                        onChange={handleChange}
                        value={rejectReason}
                        inputProps={{ maxLength: 80 }}
                        error={isReasonInvalid}
                    />
                </Box>
                )}
            </Box>
            <Stack direction="row" spacing={2} ml={3}>
                <ERPSBaseButton
                    variant="secondary"
                    className="button_with_margin48"
                    label={t("erps.newuser.button.back", "Back")}
                    onClick={() =>
                        props.history.push("/reject-payments-merchants/", {
                            fromDetailPage: true,
                            isRejected : isRejected
                        })
                    }
                />

                {!successMessage ? (
                    <ERPSBaseButton
                        variant="primary"
                        className="button_with_margin48"
                        label={t("erps.rejectpaymentmerchants.page.label.confirm.reject")}
                        onClick={handleConfirmReject}
                        loading={rejectLoading}
                        isDisabled={isRejected}
                    />
                ) : (
                    //Logic needs to be added for print button
                    <ERPSBaseButton
                        variant="primary"
                        className="button_with_margin48"
                        label={t("erps.transactionEnquiry.page.transactionDetails.button.print")}
                        onClick={handlePrint}
                        loading={rejectLoading}
                    />
                )}

            </Stack>

            <Dialog
                open={openDialog?.isOpen}
                onClick={() => {
                    setDialogOpen({
                        isOpen: false,
                    });
                }}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                fullWidth={true}
            >
                <DialogHeader
                    id="alert-dialog-title"
                    onClose={() => {
                        setDialogOpen({
                            isOpen: false,
                        });
                    }}
                    className={"box-dialog-header"}
                >
                </DialogHeader>
                <DialogContent
                    id="alert-dialog-description"
                    className={"cancel-dialog-content-size"}
                >
                    {t("erps.rejectpaymentmerchants.page.transactionDetails.dialog.content")}
                </DialogContent>
                <DialogActions className={"box-dialog-buttons"}>
                    <ERPSBaseButton
                        variant="secondary"
                        className="button_with_zeromargin"
                        onClick={() => {
                            setDialogOpen({ isOpen: false });
                        }}
                        label={t("erps.common.button.cancel")}
                    />
                    <ERPSBaseButton
                        variant="primary"
                        className="button_with_zeromargin"
                        onClick={() => handleReject()}
                        label={t("erps.common.button.confirm")}
                    />
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default withSidebar(RejectPaymentDetails);
