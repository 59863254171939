import React, { useState, useEffect, useRef } from "react";
import {
  EMPTY_PRICE_PLAN_CRITERIA,
  getManagePricePlanGridHeadings,
} from "../../constants/ManagePricePlanConstant";
import { Box } from "@material-ui/core";
import { withSidebar } from "../hoc/withSidebar";
import warningIcon from "../../assets/images/warning-icon.png";
import ConfirmationMessage from "../custom/containers/ConfirmationMesssage";
import CommonLayout from "../custom/containers/CommonLayout";
import { useCustomHook } from "../custom/useCustomHook";
import { useTranslation } from "react-i18next";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import ErrorMessage from "../custom/containers/ErrorMessage";
import pricePlanActionType from "../../actions/types/pricePlan.action.type";

const ManagePricePlan = (props) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const errorRef = useRef();
  const [errorMssg, setErrorMssg] = useState(null);
  const pageLimitSize = 100;

  const {
    history,
    location: {
      pathname,
      state: { componentAccess, ...restProps },
    },
  } = props;

  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  const pricePlaneResult = useSelector(
    (state) => state?.pricePlanReducer?.pricePlanList
  );
  const pricePlanSearchCriteria = useSelector(
    (state) => state?.pricePlanReducer?.pricePlanSearchCriteria
  );
  const [pricePlanCriteria, setPricePlanCriteria] = useState(
    EMPTY_PRICE_PLAN_CRITERIA
  );

  const pricePlaneError = useSelector(
    (state) => state?.pricePlanReducer?.error
  );

  const loginUserRoleType = useSelector(
    (state) => state.loginUserReducer?.user?.role.role_type
  );

  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [pricePlaneError]);

  const successMessage = useSelector(
    (state) => state?.pricePlanReducer?.pricePlanUpdated
  );

  const [pricePlanData, setPricePlaneData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [pageNumber, setPageSize] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(pageLimitSize);

  useEffect(() => {
    if (successMessage && !restProps?.fromEditDetailPage) {
      dispatch({
        type: pricePlanActionType.UPDATE_PRICE_PLAN_SUCCESS,
        data: null,
      });
    }
    if (!restProps?.fromDetailPage) {
      dispatch(actions.pricePlanActions.getPricePlanList(t));
    } else if (!pricePlanData && pricePlanSearchCriteria) {
      dispatch(
        actions.pricePlanActions.getFilteredPricePlan({
          ...pricePlanSearchCriteria,
          t,
          setPricePlaneData,
          setPricePlanCriteria,
        })
      );
    }
  }, []);

  useEffect(() => {
    if (pricePlaneResult) {
      const updatedData = pricePlaneResult.data.map(
        ({ billingModelNumber, ...rest }) => ({
          ...rest,
          billingModelNumber,
          id: billingModelNumber,
        })
      );
      setPricePlaneData(updatedData);
    }
  }, [pricePlaneResult]);

  const successRef = useRef(null);

  useEffect(() => {
    if (successRef.current) {
      successRef.current.focus();
    }
  }, [successMessage && successMessage !== "PP_DUP_ERR_MSG"]);
  const handleClose = () => {
    dispatch({
      type: pricePlanActionType.UPDATE_PRICE_PLAN_SUCCESS,
      data: null,
    });
  };

  const {
    handlePricePlanSearch,
    searchCriteriaChangeHandler,
    isSearchValid,
    searchResults,
    resetPricePlanHandler,
    paginatePricePlan,
  } = useCustomHook({
    tableResults: pricePlanData,
    tableHeadings: getManagePricePlanGridHeadings(
      loginUserRoleType,
      componentAccess
    ),
    initialStates: EMPTY_PRICE_PLAN_CRITERIA,
    setLoading,
    t,
    setErrorMssg,
    setManageUserState: setPricePlanCriteria,
    userDetails: pricePlanCriteria,
    pageName: "pricePlan",
    setPricePlaneData,
    setPageSize,
    setRowPerPage,
    errorMessage: errorMssg,
    handleCloseSuccessMessage: handleClose,
    hasSuccessMessageAvailable:
      successMessage && successMessage !== "PP_DUP_ERR_MSG" ? true : false,
    pageLimitSize,
  });

  const navigateToNewUserComponent = () => {
    history.push(pathname + "/new-price-plan", { componentAccess });
  };

  const loginUser = useSelector((state) => state.loginUserReducer?.user);

  const roleType = loginUser.role.role_type;

  return (
    <>
      {successMessage && successMessage !== "PP_DUP_ERR_MSG" && (
        <ConfirmationMessage
          message={successMessage}
          setShowMessage={handleClose}
          inputRef={successRef}
        />
      )}
      <Box
        className={"manageUsers__errorMsg__container"}
        role="alert"
        mt={pricePlaneError ? 2 : 0}
        style={{ marginLeft: "24px" }}
      >
        {" "}
        {pricePlaneError && (
          <ErrorMessage error={pricePlaneError} errorRef={errorRef} />
        )}
      </Box>
      <CommonLayout
        pageTitle={t("erps.managePricePlan.page.title")}
        pageDescription={
          roleType === "PA" || roleType === "WA"
            ? t("erps.managePricePlan.page.full.description")
            : t("erps.managePricePlan.page.description")
        }
        primaryButtonLabel={t("erps.managePricePlan.button.newPricePlan")}
        pageSubHeading={t("erps.managePricePlan.filterpriceplan.heading")}
        pageSubHeadingDescription={t(
          "erps.managePricePlan.filterpriceplan.subheading"
        )}
        errorMssg={
          errorMssg?.length
            ? errorMssg
            : t("erps.managePricePlan.filterpriceplan.error.emptyinput")
        }
        primaryButtonLabelV2={t("erps.common.button.reset")}
        secondaryButtonLabel={t("erps.common.button.filter")}
        searchResultLabel={t(
          "erps.managePricePlan.pricePlanes.pricePlansResults"
        )}
        searchResultFoundLabel={t(
          "erps.managePricePlan.pricePlanes.recordsfound"
        )}
        firstTextInputFieldLabel={t("erps.managePricePlan.label.pricePlanName")}
        firstTextInputFieldId="pricePlanName"
        secondTextInputFieldLabel={t("erps.managePricePlan.label.pricePlanId")}
        secondTextInputFieldId="pricePlanId"
        startSearchHandler={handlePricePlanSearch}
        searchCriteriaChangeHandler={searchCriteriaChangeHandler}
        resetHandler={resetPricePlanHandler}
        navigateToNewUserComponent={navigateToNewUserComponent}
        isSearchValid={isSearchValid}
        warningIcon={warningIcon}
        searchCriteria={pricePlanCriteria}
        searchNotStarted={t("erps.managePricePlan.searchuser.notstarted")}
        searchResults={
          searchResults || {
            rows: pricePlanData ? pricePlanData : [],
            count:
              pricePlaneResult?.data.length <= pageLimitSize
                ? pricePlanData && pricePlanData.length
                : pricePlaneResult?.data.length,
            columns: getManagePricePlanGridHeadings(
              loginUserRoleType,
              componentAccess
            ),
          }
        }
        //budgetData = {budgetData}
        labelLinkValue={["pricePlanId"]}
        pathName={"/manage-price-plan/price-plan-details"}
        isRequired="false"
        history={history}
        dataGridWidth={
          pricePlaneResult?.data.length <= pageLimitSize
            ? "dataGrid__box__container__manage_price_plan"
            : "dataGrid__box__container__manage_price_plan_pagination"
        }
        hideFooterPagination={pricePlaneResult?.data.length <= pageLimitSize}
        loading={loading}
        componentAccess={componentAccess}
        actionType={{
          primaryButtonActionTypeV1: "hasNewPricePlanBtn",
          firstTextInputFieldActionType: "hasPricePlanNameTextField",
          secondTextInputFieldActionType: "hasPricePlanIdTextField",
          primaryButtonActionTypeV2: "hasResetBtn",
          secondaryButtonActionType: "hasSearchPricePlanAccess",
        }}
        isEnable={
          loginUser.role.role_type === "PA" || loginUser.role.role_type === "WA"
        }
        showPaginationBottom={pricePlaneResult?.data.length > pageLimitSize}
        initiateSearch={paginatePricePlan}
        pageNumber={pageNumber}
        rowPerPage={rowPerPage}
      />
    </>
  );
};

export default withSidebar(ManagePricePlan);
