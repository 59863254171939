import React, { useState, useRef, useEffect } from "react";
import { withSidebar } from "../hoc/withSidebar";
import { Box } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import ERPSBaseTextField from "../custom/inputs/ERPSBaseTextField";
import Stack from "@mui/material/Stack";
import ERPSBaseButton from "../custom/inputs/ERPSBaseButton";
import Typography from "@mui/material/Typography";
import ERPSBaseRadioGroup from "../custom/inputs/ERPSBaseRadioGroup";
import ERPSBaseDatePicker from "../custom/inputs/ERPSDatePicker";
import ERPSGrid from "../custom/containers/ERPSGrid";
import { transactionEnquiryHeading, isShowBankCard } from "../../constants/TransactionEnquiryConstant";
import ErrorMessage from "../custom/containers/ErrorMessage";
import actions from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import Alert from "@mui/material/Alert";
import ERPSBaseDiv from "../custom/inputs/ERPSBaseDiv";
import { hasShowComponent } from "../hoc/withBaseValidatorHelper";
import { userActions } from "../../actions/user.actions";
import CardList from "../custom/containers/cardList";
import { getComponentAccess } from "../../utils/helper";
import { getNumericValue } from "./../../helpers/biller";
import CommonContainerBox from "../custom/containers/CommonContainerBox";

const TransactionEnquiry = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const {
    history,
    location: { pathname, state },
  } = props;

  const transactionEnquiryResponse = useSelector(
    (state) => state.transactionEnquiryReducer
  );
  const subscribeEntities = useSelector(
    (state) => state.subscribingEntitiesReducer
  );
  const selectedFiNumber = useSelector((state) => state.fiReducer?.selectedFi);

  const user = useSelector((state) => state.loginUserReducer.user);
  const componentAccess =
    state?.componentAccess ||
    getComponentAccess(
      {
        function_type_code: "RT",
        label_type_code: "TE",
      },
      user
    );
  const roleId = user?.role?.role_id;
  const loggedInBillerId = user?.billerNonVolatileDto?.biller_id;
  const internalBMOUserId = user?.internalBMOUserId;
  const [validationErrors, setValidationErrors] = useState([]);
  const [subscribeEntityList, setSubscribeEntityList] = useState([]);

  const [highlightError, setHighlightError] = useState({});
  const [transactionData, setTransactionData] = useState(null);
  const [initialLoading, setInitialLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [prevBtnLoading, setPrevBtnLoading] = useState(false);
  const [nextBtnLoading, setNextBtnLoading] = useState(false);
  const [dateRange, setDateRange] = useState({});

  const successRef = useRef(null);
  const [pageNumber, setPage] = useState(0);
  const [rowPerPage, setRowPerPage] = useState(100);
  const [loading, setLoading] = useState(false);
  const alertRef = useRef(null);
  const errorRef = useRef();
  useEffect(() => {
    if (errorRef.current) {
      errorRef.current.focus();
    }
  }, [validationErrors.length, errorMessage]);

  useEffect(() => {
    if (alertRef.current && transactionData?.length > rowPerPage) {
      alertRef.current.focus();
    }
  }, [transactionData && transactionData?.length > rowPerPage]);
  function subtractMonths(numOfMonths, date = new Date()) {
    date.setMonth(date.getMonth());
    date.setDate(date.getDate() - 31);
    return date;
  }

  const initialStartDate = subtractMonths(1);
  const initialEndDate = new Date();

  const getMinDateValue = (date, days) => {
    const minDateVal = new Date(
      date.getFullYear(),
      date.getMonth(),
      date.getDate() - days + 1
    );
    return minDateVal;
  };

  const MIN_START_DATE_VALUE = getMinDateValue(initialStartDate, 60);
  const MIN_END_DATE_VALUE = getMinDateValue(initialEndDate, 90);
  const MAX_DATE_VALUE = new Date("2100-01-01");

  const [searchValues, setSearchValue] = useState({
    paymentFromDate: initialStartDate,
    paymentToDate: initialEndDate,
    searchBy: hasShowComponent({
      componentAccess,
      actionType: "hasBillerIdTFAccess",
      user,
    })
      ? "billerId"
      : "",
    startOffset: 0,
    endOffset: rowPerPage,
    sortBy: "BC",
    transactionToAmount: "",
    transactionFromAmount: "",
    subscribeEntityId: "",
    verificationNumber: "",
    billerId: "",
    billerName: "",
    fiCustomerNumber: "",
    fiCustomerName: "",
    billerCustomerNumber: "",
    archive: "n",
    archiveMonth: getMinDateValue(initialEndDate, 90),
    minStartDateValue: MIN_START_DATE_VALUE,
    minEndDateValue: MIN_END_DATE_VALUE,
    maxDateValue: MAX_DATE_VALUE,
  });

  const getPaginationProps = (data) => {
    if (state?.fromDetailPage && data) {
      const { startOffset, endOffset } = data;
      const rowPerPage = endOffset - startOffset;
      const pageNumber = endOffset / (endOffset - startOffset) - 1;
      return { rowPerPage, pageNumber };
    }
    return "";
  };

  useEffect(() => {
    if (transactionEnquiryResponse) {
      const transactionEnquiryRes =
        transactionEnquiryResponse?.transactionEnquiryData?.data;
      if (transactionEnquiryRes && transactionEnquiryRes?.length) {
        const updatedRes = transactionEnquiryRes?.map((d, idx) => ({
          ...d,
          id: idx,
        }));
        setTransactionData(updatedRes);
      } else {
        setTransactionData(transactionEnquiryRes);
      }
    }
    if (
      state?.fromDetailPage &&
      transactionEnquiryResponse?.transactionEnquiryDetails
    ) {
      const data = getPaginationProps(
        transactionEnquiryResponse?.transactionEnquiryDetails
      );
      const page = data?.pageNumber || pageNumber;
      const row = data?.rowPerPage || rowPerPage;
      setPage(page);
      setRowPerPage(row);
      setSearchValue(transactionEnquiryResponse?.transactionEnquiryDetails);
    }
  }, [transactionEnquiryResponse]);

  useEffect(() => {
    if (transactionEnquiryResponse && !state?.fromDetailPage) {
      dispatch({
        type: "GET_TRANSACTION_ENQUIRY_SUCCESS",
        data: { data: null, transactionEnquiryDetails: null },
      });
    }
  }, []);

  useEffect(() => {
    const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;
    if (roleId == 7 || roleId == 8) {
      dispatch(userActions.getSubscribingEntitiesList(fiNumberValue, t));
    }
  }, []);

  useEffect(() => {
    if (subscribeEntities) {
      if (subscribeEntities.subscribingEntitiesList) {
        const data = subscribeEntities.subscribingEntitiesList.map(
          ({ fiCompanyNumber, fiCompanyName }) => ({
            value: fiCompanyNumber,
            label: fiCompanyName,
          })
        );
        data.unshift({
          value: "",
          label: "",
        });
        setSubscribeEntityList(data);
      }
    }
  }, [subscribeEntities]);
  const fiNumberValue = selectedFiNumber ? selectedFiNumber : user?.fiNumber;
  const billerDetails = [
    { name: t("erps.common.label.billerID"),   value: "billerId" },
    { name: t("erps.common.label.billerName"), value: "billerName" },
  ];

  const archivedList = [
    { name: "Yes", value: "y" },
    { name: "No",  value: "n" },
  ];

  const sortDetails = [
    { name: t("erps.transactionEnquiry.componentToPrint.customerDetails.label.bankCard"), value: "BC" },
    { name: t("erps.transactionEnquiry.page.effectivedate"), value: "ED" },
  ];
  const getStartDate = (date) =>
    new Date(date.getFullYear(), date.getMonth(), 1);
  const getEndDate = (date) =>
    new Date(date.getFullYear(), date.getMonth() + 1, 0);
  const handleChange = (key, value) => {
    if (key === "archiveMonth") {
      const updatedDate = new Date(value);
      const dateBeforeThreeMonths = getMinDateValue(initialEndDate, 90);
      const checkSameYearMonth =
        dateBeforeThreeMonths.getFullYear() === updatedDate.getFullYear() &&
        dateBeforeThreeMonths.getMonth() === updatedDate.getMonth();
      let maxDate;
      let minDate;
      if (checkSameYearMonth) {
        maxDate = getMinDateValue(initialStartDate, 61);
        const year = maxDate.getFullYear();
        const month = maxDate.getMonth();
        minDate = new Date(year, month, 1);
      } else {
        maxDate = getEndDate(updatedDate);
        minDate = getStartDate(updatedDate);
      }
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: value,
        paymentFromDate: minDate,
        paymentToDate: maxDate,
        maxDateValue: maxDate,
        minEndDateValue: minDate,
        minStartDateValue: minDate,
      }));
    } else if (key === "archive") {
      if (value === "n") {
        setSearchValue((prevState) => ({
          ...prevState,
          minStartDateValue: MIN_START_DATE_VALUE,
          minEndDateValue: MIN_END_DATE_VALUE,
          maxDateValue: MAX_DATE_VALUE,
          paymentFromDate: initialStartDate,
          paymentToDate: initialEndDate,
          archiveMonth: getMinDateValue(initialEndDate, 90),
          [key]: value,
        }));
      } else {
        const maxDate = getMinDateValue(initialStartDate, 61);
        const year = maxDate.getFullYear();
        const month = maxDate.getMonth();
        const startDate = new Date(year, month, 1);

        setSearchValue((prevState) => ({
          ...prevState,
          paymentFromDate: startDate,
          paymentToDate: maxDate,
          maxDateValue: maxDate,
          minEndDateValue: startDate,
          minStartDateValue: startDate,
          archiveMonth: getMinDateValue(initialEndDate, 90),
          [key]: value,
        }));
      }
    }
    if (key === "searchBy") {
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: value,
        billerName: "",
        billerId: "",
      }));
    }
    if (
      (key === "transactionFromAmount" || key === "transactionToAmount") &&
      value >= 0
    ) {
      const updatedValue = value?.toString();
      const [numericValue, decimalValue] = updatedValue?.split(".") || [
        updatedValue,
        null,
      ];

      if (
        numericValue?.length < 20 &&
        (!decimalValue || decimalValue.length < 3)
      ) {
        setSearchValue((prevState) => ({
          ...prevState,
          [key]: value,
        }));
      }
    } else {
      setSearchValue((prevState) => ({
        ...prevState,
        [key]: value,
      }));
    }
  };

  const handleBlur = (key, value) => {
    setSearchValue((prevState) => ({
      ...prevState,
      [key]: value && typeof value === "string" ? value?.toUpperCase() : value,
    }));
  };

  const getDifferenceInDays = (d1, d2) => {
    let date1 = d1;
    let date2 = d2;
    if (typeof d1 === "string") {
      date1 = new Date(d1);
    }
    if (typeof d2 === "string") {
      date2 = new Date(d2);
    }
    const difference_In_Time = date2.getTime() - date1.getTime();
    const difference_In_Days = Math.floor(
      difference_In_Time / (1000 * 3600 * 24)
    );
    return difference_In_Days;
  };

  const checkBillerInfoRequired = (data) => {
    const {
      paymentFromDate,
      paymentToDate,
      transactionFromAmount: paymentFromAmount,
      transactionToAmount: paymentToAmount,
      verificationNumber: confirmationNumber,
      billerCustomerNumber: customerAccNumber,
      fiCustomerName: customerName,
      fiCustomerNumber: customerBankCardNumber,
    } = data;
    const hasBillerInfoRequired =
      paymentFromDate &&
      paymentToDate &&
      (customerName ||
        paymentFromAmount ||
        paymentToAmount ||
        customerAccNumber ||
        customerBankCardNumber ||
        confirmationNumber);
    return hasBillerInfoRequired;
  };

  const isCharOccurringOnce = (str, char) => {
    let occurrences = str?.split(char)?.length;
    if (typeof occurrences === "number") {
      occurrences -= 1;
      return occurrences === 1;
    }
  };

  const validateSearchValues = (searchReqValues) => {
    const {
      paymentFromDate,
      paymentToDate,
      transactionFromAmount,
      transactionToAmount,
      verificationNumber,
      billerCustomerNumber,
      fiCustomerName,
      fiCustomerNumber,
      billerId,
      billerName,
      searchBy,
      archiveMonth,
      archive,
    } = searchReqValues;
    const parsedPaymentFromAmount = transactionFromAmount
      ? parseFloat(transactionFromAmount)
      : undefined;
    const parsedPaymentToAmount = transactionToAmount
      ? parseFloat(transactionToAmount)
      : undefined;

    let errors = [];
    let highlightErrorV1 = {};
    const date1 = new Date(paymentFromDate);
    const date2 = new Date(paymentToDate);
    const date3 = new Date(archiveMonth);
    const decimalRegEx = /^\d*(\.\d{0,2})*$/;
    const alphaNumericRegEx = /^[0-9A-Za-z\s]+$/;
    const alphaNumericWithHypenRegEx = /^[0-9A-Za-z-%\s]+$/;
    const alphaNumericWildCharRegEx = /^[0-9A-Za-z%\s]+$/;
    const billerNameRegex =
      /^[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+$/;

    const billerNameWildCharRegex =
      /^(?:%?[!@#$%^&*()`~\-_+=\[\]{}\\|;:'",<.>\?éàèùçâêîôûëïüÉÀÈÙÇÂÊÎÔÛËÏÜA-Za-z0-9\/ ]+%?)$/;

    const difference_In_Days = getDifferenceInDays(date1, date2);

    if (archive === "y") {
      if (!archiveMonth) {
        errors.push(
          t("erps.transactionEnquiry.page.errorMessage.archivedDate.invalid")
        );
        highlightErrorV1 = { ...highlightErrorV1, archiveMonth: true };
      } else if (archiveMonth === "Invalid date") {
        errors.push(
          t("erps.transactionEnquiry.page.errorMessage.archivedDate.invalid")
        );
        highlightErrorV1 = { ...highlightErrorV1, archiveMonth: true };
      } else if (archiveMonth && date3) {
        const minDate = new Date(
          new Date().setFullYear(new Date().getFullYear() - 7)
        );
        const maxDate = getMinDateValue(new Date(), 90);
        if (date3.getTime() < minDate.getTime()) {
          errors.push(
            t("erps.common.datepicker.archivedDatenotbeforemaximaldate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            archiveMonth: true,
          };
        } else if (date3.getTime() > maxDate.getTime()) {
          errors.push(
            t("erps.common.datepicker.archiveddatenotaftermaximaldate")
          );
          highlightErrorV1 = {
            ...highlightErrorV1,
            archiveMonth: true,
          };
        }
      }
    }

    if (!paymentFromDate) {
      errors.push(
        t("erps.common.errorMessage.field.required", {fieldLabel: t("erps.common.label.startDate")})
      );
      highlightErrorV1 = { ...highlightErrorV1, paymentFromDate: true };
    }
    if (paymentFromDate == "Invalid date") {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.common.label.startDate")})
      );
    }
    if (!paymentToDate) {
      errors.push(
        t("erps.common.errorMessage.field.required", {fieldLabel: t("erps.common.label.endDate")})
      );
      highlightErrorV1 = { ...highlightErrorV1, paymentToDate: true };
    }
    if (paymentToDate == "Invalid date") {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.common.label.endDate")})
      );
    }
    if (date2.getTime() < date1.getTime()) {
      errors.push(t("erps.common.errorMessage.dateCompare", {earlierDate: t("erps.common.label.startDate"), laterDate: t("erps.common.label.endDate")}));
      highlightErrorV1 = {
        ...highlightErrorV1,
        paymentFromDate: true,
        paymentToDate: true,
      };
    } else if (
      searchValues.minStartDateValue &&
      searchValues.minEndDateValue &&
      searchValues.maxDateValue
    ) {
      const minStartDate = searchValues.minStartDateValue;
      const minEndDate = searchValues.minEndDateValue;
      const maxDate = searchValues.maxDateValue;
      if (paymentFromDate && date1.getTime() < minStartDate.getTime()) {
        errors.push(t("erps.common.errorMessage.notBeforeMinimalDate", {fieldLabel: t("erps.common.label.startDate"), minimalDate: moment(minStartDate).format("YYYY-MM-DD")}));
        highlightErrorV1 = {
          ...highlightErrorV1,
          startDate: true,
        };
      } else if (paymentFromDate && date1.getTime() > maxDate.getTime()) {
        errors.push(t("erps.common.errorMessage.notAfterMaximalDate", {fieldLabel: t("erps.common.label.startDate"), maximalDate: moment(maxDate).format("YYYY-MM-DD")}));
        highlightErrorV1 = {
          ...highlightErrorV1,
          startDate: true,
        };
      }

      if (paymentToDate && date2.getTime() < minEndDate.getTime()) {
        errors.push(t("erps.common.errorMessage.notBeforeMinimalDate", {fieldLabel: t("erps.common.label.endDate"), minimalDate: moment(minEndDate).format("YYYY-MM-DD")}));
        highlightErrorV1 = {
          ...highlightErrorV1,
          endDate: true,
        };
      } else if (paymentToDate && date2.getTime() > maxDate.getTime()) {
        errors.push(t("erps.common.errorMessage.notAfterMaximalDate", {fieldLabel: t("erps.common.label.endDate"), maximalDate: moment(maxDate).format("YYYY-MM-DD")}));
        highlightErrorV1 = {
          ...highlightErrorV1,
          endDate: true,
        };
      }
    }
    if (difference_In_Days > 31) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.dateRangeForOneMonth")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        paymentFromDate: true,
        paymentToDate: true,
      };
    }

    if (parsedPaymentFromAmount !== undefined) {
      if (parsedPaymentFromAmount < 0) {
        errors.push(
          t("erps.common.errorMessage.greaterOrEqualZero", {fieldLabel: t("erps.common.label.paymentFromAmount")})
        );
        highlightErrorV1 = { ...highlightErrorV1, transactionFromAmount: true };
      }

      if (
        parsedPaymentFromAmount > 0 &&
        !decimalRegEx.test(parsedPaymentFromAmount)
      ) {
        errors.push(
          t("erps.common.errorMessage.maxTwoDecimals", {fieldLabel: t("erps.common.label.paymentFromAmount")})
        );
        highlightErrorV1 = { ...highlightErrorV1, transactionFromAmount: true };
      }
    }

    if (parsedPaymentToAmount !== undefined) {
      if (parsedPaymentToAmount < 0) {
        errors.push(
          t("erps.common.errorMessage.greaterOrEqualZero", {fieldLabel: t("erps.common.label.paymentToAmount")})
        );
        highlightErrorV1 = { ...highlightErrorV1, transactionToAmount: true };
      }

      if (
        parsedPaymentToAmount > 0 &&
        !decimalRegEx.test(parsedPaymentToAmount)
      ) {
        errors.push(t("erps.common.errorMessage.maxTwoDecimals", {fieldLabel: t("erps.common.label.paymentToAmount")}));
        highlightErrorV1 = { ...highlightErrorV1, transactionToAmount: true };
      }
    }

    if (
      parsedPaymentFromAmount !== undefined &&
      parsedPaymentToAmount !== undefined &&
      parsedPaymentFromAmount > parsedPaymentToAmount
    ) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.paymentFromLessThanPaymentTo")
      );
      highlightErrorV1 = {
        ...highlightErrorV1,
        transactionToAmount: true,
        transactionFromAmount: true,
      };
    }

    if (
      verificationNumber &&
      !alphaNumericWithHypenRegEx.test(verificationNumber)
    ) {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.common.label.confirmationNumber")})
      );
      highlightErrorV1 = { ...highlightErrorV1, verificationNumber: true };
    }

    if (billerCustomerNumber && !alphaNumericRegEx.test(billerCustomerNumber)) {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.transactionEnquiry.page.label.customerFiNumber")})
      );
      highlightErrorV1 = { ...highlightErrorV1, billerCustomerNumber: true };
    }

    if (fiCustomerName && !alphaNumericWithHypenRegEx.test(fiCustomerName)) {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.transactionEnquiry.page.label.customerFiName")})
      );
      highlightErrorV1 = { ...highlightErrorV1, fiCustomerName: true };
    }

    /* if (
      searchBy === "billerId" &&
      checkBillerInfoRequired(searchReqValues) &&
      !billerId
    ) {
      errors.push(
        t("erps.common.errorMessage.field.required", {fieldLabel: t("erps.transactionEnquiry.page.label.billerId")})
      );
      highlightErrorV1 = { ...highlightErrorV1, billerId: true };
    }

    if (
      searchBy === "billerName" &&
      checkBillerInfoRequired(searchReqValues) &&
      !billerName
    ) {
      errors.push(
        t("erps.common.errorMessage.field.required", {fieldLabel: t("erps.transactionEnquiry.page.label.billerName")})
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    } */

    if (billerId && !alphaNumericRegEx.test(billerId)) {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.transactionEnquiry.page.label.billerId")})
      );
      highlightErrorV1 = { ...highlightErrorV1, billerId: true };
    }

    if (billerName && !billerNameRegex.test(billerName)) {
      errors.push(
        t("erps.common.errorMessage.field.invalid", {fieldLabel: t("erps.transactionEnquiry.page.label.billerName")})
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    } else if (
      billerName &&
      ((billerName.includes("%") && billerName.length < 4) ||
        !billerNameWildCharRegex.test(billerName) ||
        (billerName.includes("%") && !isCharOccurringOnce(billerName, "%")))
    ) {
      errors.push(
        t("erps.transactionEnquiry.page.errorMessage.billerName.minChar")
      );
      highlightErrorV1 = { ...highlightErrorV1, billerName: true };
    }

    return { errors, highlightErrorV1 };
  };

  const sanitizeReqBody = (reqBody) => {
    let updatedReqBody;
    Object.keys(reqBody).forEach((key) => {
      if (
        reqBody[key] === undefined ||
        ("string" === typeof reqBody[key] && reqBody[key].trim() === "")
      ) {
        delete reqBody[key];
      } else {
        if (typeof reqBody[key] === "string") {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key].trim() };
        } else {
          updatedReqBody = { ...updatedReqBody, [key]: reqBody[key] };
        }
      }
    });

    return updatedReqBody;
  };

  const handleSearch = () => {
    const actualCriteria = sanitizeReqBody({ ...searchValues });
    const { errors, highlightErrorV1 } = validateSearchValues(actualCriteria);
    const {
      startOffset,
      sortBy,
      searchBy,
      endOffset,
      archive,
      archiveMonth,
      minStartDateValue,
      minEndDateValue,
      maxDateValue,
      daysCount,
      count,
      ...rest
    } = actualCriteria;

    if (errors.length) {
      setValidationErrors(errors);
      setHighlightError(highlightErrorV1);
    } else if (
      rest &&
      typeof rest === "object" &&
      Object.keys(rest)?.length < 3
    ) {
      setValidationErrors([
        t(
          t(
            "erps.transactionEnquiry.page.errorMessage.minimumthreefields.required"
          )
        ),
      ]);
    } else {
      setValidationErrors([]);
      setHighlightError({});
      setLoading(true);
      if (pageNumber) {
        setPage(0);
      }
      const difference_In_Days = getDifferenceInDays(
        actualCriteria?.paymentFromDate,
        new Date(actualCriteria?.paymentToDate)
      );
      if (errorMessage) {
        setErrorMessage(null);
      }

      dispatch(
        actions.transactionEnquiryActions.getTransactionEnquiry(
          {
            ...actualCriteria,
            billerId:
              actualCriteria?.billerId ||
              searchValues?.billerId ||
              internalBMOUserId?.slice(0, -2) ||
              loggedInBillerId,
            count: 0,
            daysCount: difference_In_Days,
            startOffset: 0,
            endOffset: rowPerPage,
          },
          { ...props, t, history, setLoading, fiNumberValue, setErrorMessage }
        )
      );
    }
  };

  const paginateTransactionEnquiry = (values) => {
    let setBtnLoading;
    const actualCriteria = sanitizeReqBody({ ...values });
    if (actualCriteria.startOffset < actualCriteria.endOffset) {
      const difference_In_Days = getDifferenceInDays(
        searchValues?.paymentFromDate,
        new Date(searchValues?.paymentToDate)
      );
      if (actualCriteria?.actionType === "prev") {
        setBtnLoading = setPrevBtnLoading;
        setBtnLoading(true);
      } else if (actualCriteria?.actionType === "next") {
        setBtnLoading = setNextBtnLoading;
        setBtnLoading(true);
      }
      setInitialLoading(true);
      const searchRequest = {
        ...searchValues,
        startOffset: actualCriteria.startOffset,
        endOffset: actualCriteria.endOffset,
        count: 0,
        daysCount: difference_In_Days,
        billerId: searchValues?.billerId || loggedInBillerId,
      };

      if (errorMessage) {
        setErrorMessage(null);
      }

      dispatch(
        actions.transactionEnquiryActions.getTransactionEnquiry(searchRequest, {
          ...props,
          t,
          fiNumberValue,
          setErrorMessage,
          setLoading: setInitialLoading,
          setBtnLoading,
        })
      );
    }
  };

  const handleReset = () => {
    setHighlightError({});
    setSearchValue({
      paymentFromDate: initialStartDate,
      paymentToDate: initialEndDate,
      searchBy: hasShowComponent({
        componentAccess,
        actionType: "hasBillerIdTFAccess",
        user,
      })
        ? "billerId"
        : "",
      startOffset: 0,
      endOffset: rowPerPage,
      sortBy: "BC",
      transactionToAmount: "",
      transactionFromAmount: "",
      subscribeEntityId: "",
      verificationNumber: "",
      billerId: "",
      billerName: "",
      fiCustomerNumber: "",
      fiCustomerName: "",
      billerCustomerNumber: "",
      archive: "n",
      minStartDateValue: MIN_START_DATE_VALUE,
      minEndDateValue: MIN_END_DATE_VALUE,
      maxDateValue: MAX_DATE_VALUE,
    });
    setValidationErrors([]);
    setPage(0);
    setErrorMessage(null);
    dispatch({
      type: "GET_TRANSACTION_ENQUIRY_SUCCESS",
      data: { data: null, transactionEnquiryDetails: null },
    });
  };

  const hasBillerInfoRequired = (searchValue) => {
    const updatedSearchValues = sanitizeReqBody({ ...searchValues });
    return (
      updatedSearchValues?.searchBy === searchValue &&
      checkBillerInfoRequired({ ...updatedSearchValues })
    );
  };

  return (
    <div role="main">
      <Box ml={3}>
        {validationErrors.length || errorMessage ? (
          <Box
            className="manageUsers__errorMsg__container"
            role="alert"
            mt={validationErrors.length || errorMessage ? 2 : 0}
          >
            {validationErrors.length || errorMessage ? (
              <ErrorMessage
                error={
                  validationErrors?.length ? validationErrors : errorMessage
                }
                errorRef={errorRef}
              />
            ) : (
              ""
            )}
          </Box>
        ) : (
          ""
        )}

        <Box className="manageUsers__page__title">
          {/* For Title update */}
          <CommonContainerBox title={t("erps.transactionEnquiry.page.title")} />
          <Typography variant="h1">
            {t("erps.transactionEnquiry.page.title")}
          </Typography>
        </Box>
        <Box mt={6}>
          <Typography variant="h2">
            {t("erps.transactionEnquiry.page.description")}
          </Typography>
        </Box>
        <ERPSBaseDiv
          actionType={"hasBillerNameTFAccess"}
          componentAccess={componentAccess}
        >
          <Box mt={1}>
            <Typography variant="body1">
              {t("erps.transactionEnquiry.page.wildcardDescription")}
            </Typography>
          </Box>
        </ERPSBaseDiv>
        {roleId == 5 && (
          <>
            <Stack mt={2} direction="row" spacing={2}>
              <Box>
                <ERPSBaseRadioGroup
                  name="archive"
                  marginTop={"true"}
                  InputLabelProps={{ shrink: true }}
                  value={searchValues?.archive}
                  label={"Archived"}
                  onChange={(e) => handleChange("archive", e.target.value)}
                  items={archivedList}
                  error={highlightError?.archive}
                  labelPlacement="start"
                  className={"transactionEnquiry__radioButton_direction"}
                  // actionType={"hasBillerIdTFAccess"}
                  // componentAccess={componentAccess}
                />
              </Box>
            </Stack>
            {searchValues.archive == "y" && (
              <Stack mt={2} direction="row" spacing={2}>
                <Box>
                  <ERPSBaseDatePicker
                    label={"Month"}
                    value={searchValues.archiveMonth}
                    required="true"
                    onChange={(e) => handleChange("archiveMonth", e)}
                    format={"YYYY-MM"}
                    views={["year", "month"]}
                    // error={getErrorFields().includes("periodValue")}
                    className="statistics__datepicker"
                    minDate={
                      new Date(
                        new Date().setFullYear(new Date().getFullYear() - 7)
                      )
                    }
                    maxDate={getMinDateValue(new Date(), 90)}
                    animateYearScrolling={false}
                  />
                </Box>
              </Stack>
            )}
          </>
        )}
        <Box mt={2}>
          <Typography variant="h3">
            {t(
              "erps.transactionEnquiry.page.transactionDetails.page.subheading"
            )}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseDatePicker
            label={t("erps.common.label.startDate", "Start date")}
            onChange={(e) => handleChange("paymentFromDate", e)}
            value={searchValues?.paymentFromDate}
            className="app_datepicker_biller_transactionEnquiry"
            datePickerStyle={"quick-reg-datepicker-v1"}
            maxDate={searchValues.maxDateValue}
            minDate={searchValues.minStartDateValue}
            error={highlightError?.paymentFromDate}
            required={true}
            id="transaction_enquiry_startDate"
          />
          <ERPSBaseDatePicker
            label={t("erps.common.label.endDate", "End date")}
            onChange={(e) => handleChange("paymentToDate", e)}
            value={searchValues?.paymentToDate}
            className="app_datepicker_biller_transactionEnquiry"
            datePickerStyle={"quick-reg-datepicker-v1"}
            maxDate={searchValues.maxDateValue}
            minDate={searchValues.minEndDateValue}
            error={highlightError?.paymentToDate}
            required={true}
            id="transaction_enquiry_endDate"
          />
          <ERPSBaseTextField
            id="subscribeEntityId"
            type="text"
            select={true}
            label={t(
              "erps.newuser.label.subscribingentityid",
              "Subscribing entity id"
            )}
            className="transactionEnquiry__textField__width"
            size="small"
            items={subscribeEntityList}
            onChange={(e) => handleChange("subscribeEntityId", e.target.value)}
            value={searchValues?.subscribeEntityId}
            SelectProps={{ native: true }}
            InputLabelProps={{
              shrink: searchValues?.subscribeEntityId ? true : false,
            }}
            error={highlightError?.subscribeEntityId}
            actionType={"hasSubscribingEntityIdTFAccess"}
            componentAccess={componentAccess}
          />
        </Stack>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="transactionFromAmount"
            type="number"
            allowDecimal={true}
            label={t("erps.common.label.paymentFromAmount")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) =>
              handleChange("transactionFromAmount", e.target.value)
            }
            value={searchValues?.transactionFromAmount}
            error={highlightError?.transactionFromAmount}
            actionType={"hasPaymentFromDateTFAccess"}
            componentAccess={componentAccess}
          />
          <ERPSBaseTextField
            id="transactionToAmount"
            type="number"
            allowDecimal={true}
            label={t("erps.common.label.paymentToAmount")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) =>
              handleChange("transactionToAmount", e.target.value)
            }
            value={searchValues?.transactionToAmount}
            error={highlightError?.transactionToAmount}
            actionType={"hasPaymentToDateTFAccess"}
            componentAccess={componentAccess}
          />
          <ERPSBaseTextField
            id="verificationNumber"
            type="text"
            label={t(
              "erps.common.label.confirmationNumber"
            )}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) => handleChange("verificationNumber", e.target.value)}
            value={searchValues?.verificationNumber}
            error={highlightError?.verificationNumber}
          />
        </Stack>
        <ERPSBaseDiv
          actionType={"hasBillerIdTFAccess"}
          componentAccess={componentAccess}
        >
          <Box mt={4}>
            <Typography variant="h3">
              {t(
                "erps.common.label.billerDetails"
              )}
            </Typography>
          </Box>
          <Stack direction="row" spacing={2}>
            <Box mt={2}>
              <ERPSBaseRadioGroup
                name="searchBy"
                marginTop={"true"}
                InputLabelProps={{ shrink: true }}
                value={searchValues?.searchBy}
                label={t("erps.transactionEnquiry.page.label.searchBy")}
                onChange={(e) => handleChange("searchBy", e.target.value)}
                items={billerDetails}
                error={highlightError?.searchBy}
                labelPlacement="start"
                className={"transactionEnquiry__radioButton_direction"}
                actionType={"hasBillerIdTFAccess"}
                componentAccess={componentAccess}
              />
            </Box>
            <Box style={{ marginTop: "-30px" }}>
              {searchValues?.searchBy === "billerId" && (
                <ERPSBaseTextField
                  id="billerId"
                  type="text"
                  label={t("erps.transactionEnquiry.page.label.billerId")}
                  className="transactionEnquiry__textField__width"
                  size="small"
                  onChange={(e) => handleChange("billerId", e.target.value)}
                  value={searchValues?.billerId}
                  error={highlightError?.billerId}
                  actionType={"hasBillerIdTFAccess"}
                  componentAccess={componentAccess}
                  required={
                    hasBillerInfoRequired(searchValues?.searchBy)
                      ? "true"
                      : "false"
                  }
                  inputProps={{ maxLength: 9 }}
                />
              )}
              {searchValues?.searchBy === "billerName" && (
                <ERPSBaseTextField
                  id="billerName"
                  type="text"
                  label={t("erps.transactionEnquiry.page.label.billerName")}
                  className="transactionEnquiry__textField__width"
                  size="small"
                  onChange={(e) => handleChange("billerName", e.target.value)}
                  value={searchValues?.billerName}
                  error={highlightError?.billerName}
                  onBlur={(e) => handleBlur("billerName", e.target.value)}
                  actionType={"hasBillerNameTFAccess"}
                  componentAccess={componentAccess}
                  required={
                    hasBillerInfoRequired(searchValues?.searchBy)
                      ? "true"
                      : "false"
                  }
                  inputProps={{ maxLength: 45 }}
                />
              )}
            </Box>
          </Stack>
        </ERPSBaseDiv>
        <Box mt={4}>
          <Typography variant="h3">
            {t("erps.common.label.customerDetails")}
          </Typography>
        </Box>
        <Stack direction="row" spacing={2}>
          <ERPSBaseTextField
            id="billerCustomerNumber"
            type="text"
            label={t("erps.transactionEnquiry.page.label.customerFiNumber")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) =>
              handleChange("billerCustomerNumber", e.target.value)
            }
            value={searchValues?.billerCustomerNumber}
            error={highlightError?.billerCustomerNumber}
            actionType={"hasCustomerActNumTFAccess"}
            componentAccess={componentAccess}
            inputProps={{ maxLength: 45 }}
          />
          <ERPSBaseTextField
            id="fiCustomerName"
            type="text"
            label={t("erps.transactionEnquiry.page.label.customerFiName")}
            className="transactionEnquiry__textField__width"
            size="small"
            onChange={(e) => handleChange("fiCustomerName", e.target.value)}
            value={searchValues?.fiCustomerName}
            error={highlightError?.fiCustomerName}
            actionType={"hasCustomerNameTFAccess"}
            componentAccess={componentAccess}
            inputProps={{ maxLength: 30 }}
          />
          {isShowBankCard(fiNumberValue, roleId) && (
            <ERPSBaseTextField
              id="fiCustomerNumber"
              type="number"
              label={t("erps.transactionEnquiry.page.label.customerBankCardName")}
              className="transactionEnquiry__textField__width"
              size="small"
              onChange={(e) => handleChange("fiCustomerNumber", e.target.value)}
              value={searchValues?.fiCustomerNumber}
              error={highlightError?.fiCustomerNumber}
              actionType={"hasCustomerBankCardNumTFAccess"}
              componentAccess={componentAccess}
              onInput={(e) => {
                e.target.value = getNumericValue(e.target.value, 0, 16);
              }}
            />
          )}
        </Stack>

        <Stack direction="row" spacing={2} mt={1}>
          <Box mt={2}>
            <ERPSBaseDiv
              actionType={"hasSortOrderAccess"}
              componentAccess={componentAccess}
            >
              <ERPSBaseRadioGroup
                marginTop={"true"}
                name="sortBy"
                InputLabelProps={{ shrink: true }}
                value={searchValues?.sortBy || "BC"}
                label={t("erps.transactionEnquiry.page.label.sortBy")}
                onChange={(e) => handleChange("sortBy", e.target.value)}
                items={sortDetails}
                labelPlacement="start"
                className={"transactionEnquiry__radioButton_direction"}
                actionType={"hasSortOrderAccess"}
                componentAccess={componentAccess}
              />
            </ERPSBaseDiv>
          </Box>
        </Stack>
        {transactionData && transactionData?.length > rowPerPage ? (
          <Stack
            sx={{ width: "100%" }}
            spacing={2}
            mt={4}
            tabIndex={0}
            ref={alertRef}
          >
            <Alert severity="info">
              {t("erps.transactionenquiry.info.message", {matchCount: rowPerPage})}
            </Alert>
          </Stack>
        ) : (
          ""
        )}
        <Box display="flex">
          <ERPSBaseButton
            variant="secondary"
            className="button_with_margin48"
            label={t("erps.common.button.reset")}
            onClick={handleReset}
            componentAccess={componentAccess}
            actionType={"hasTransactionResetBtnAccess"}
          />
          <Box pl={2}>
            <ERPSBaseButton
              variant="primary"
              className="button_with_margin48"
              label={t("erps.common.button.search")}
              onClick={handleSearch}
              componentAccess={componentAccess}
              actionType={"hasTransactionSearchAccess"}
              loading={loading}
            />
          </Box>
        </Box>
        <Box mt={6}>
          <Typography variant="h2">
            {" "}
            {t("erps.manageusers.searchuser.searchresults")}
          </Typography>
        </Box>
        {transactionData !== null &&
        transactionData !== undefined &&
        transactionData.length > 0 ? (
          <>
            <Box className="manageUsers_table_visible">
              <ERPSGrid
                data={{
                  rows: transactionData,
                  columns: transactionEnquiryHeading(
                    componentAccess,
                    fiNumberValue,
                    roleId,
                    user?.fiNumber
                  ),
                }}
                onAnyPaginationAction={paginateTransactionEnquiry}
                sumOfColumnWidth="true"
                initialLoading={initialLoading}
                pageNumber={pageNumber}
                setPage={setPage}
                rowPerPage={rowPerPage}
                tableLabelText={t("erps.transactionEnquiry.page.title")}
                hideFooterPagination={true}
                pageName={"transaction-enquiry"}
                prevBtnLoading={prevBtnLoading}
                nextBtnLoading={nextBtnLoading}
              />
            </Box>
            <Box className="manageUsers_card_visible">
              <CardList
                data={{
                  rows: transactionData,
                  columns: transactionEnquiryHeading(
                    componentAccess,
                    fiNumberValue,
                    roleId,
                    user?.fiNumber
                  ),
                }}
                onAnyPaginationAction={paginateTransactionEnquiry}
                hideFooterPagination={true}
                pageName={"transaction-enquiry"}
                pageNumber={pageNumber}
                setPage={setPage}
                rowPerPage={rowPerPage}
                prevBtnLoading={prevBtnLoading}
                nextBtnLoading={nextBtnLoading}
              />
            </Box>
          </>
        ) : (
          ""
        )}
        {(transactionData === null || transactionData === undefined) && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {t(
                "erps.availabledocuments.searchuser.notstarted",
                "Search not started"
              )}
            </Typography>
          </Box>
        )}
        {Array.isArray(transactionData) && transactionData?.length === 0 && (
          <Box mt={1}>
            <Typography variant="body1">
              {" "}
              {`0 ${t("erps.transactionEnquiry.page.resultfound")}`}
            </Typography>
          </Box>
        )}
      </Box>
    </div>
  );
};

export default withSidebar(TransactionEnquiry);
