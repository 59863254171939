import React, { useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/system";
import CustomPagination from "../../Pagination/CustomPagination";
import ERPSBaseButton from "../inputs/ERPSBaseButton";

const ERPSGrid = (props) => {
  const {
    data,
    history,
    pathName,
    onAnyPaginationAction,
    hideFooterPagination,
    dataGridWidth,     // this is the name of css class
    gridBoxWidth,      // a number is used as grid with is pixels; otherwise any css width (e.g. "1000px" or "95%").  Ignored if sumOfColumnWidth is true.
    sumOfColumnWidth,  // if true, the Grid width will be calculated as the sum of column widths.
    initialLoading = false,
    userRole,
    componentAccess,
    handleSelectionModelChange,
    checkboxSelection,
    handleCellEditCommit,
    selectedRows,
    isRowSelectable,
    initialStates,
    getCellClassName,
    autoRowHeight,
    tableLabelText,
    pageName,
    nextBtnLoading,
    prevBtnLoading,
  } = props;
  const { columns, rows, count } = data;

  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(
    hideFooterPagination ? count : props?.rowPerPage || 25
  );
  const [loading, setLoading] = React.useState(false);
  useEffect(() => {
    if (props?.pageNumber !== undefined) {
      setPage(props?.pageNumber);
    }
  }, [props?.pageNumber]);

  useEffect(() => {
    if (props?.rowPerPage) {
      setRowsPerPage(props?.rowPerPage);
    }
  }, [props?.rowPerPage]);

  const clickableColumns = ["", "billerId"];
  const clickableColumnsToCheckWithRole = ["", ""];
  const handleOnCellClick = (params) => {
    const { field, value } = params;
    const hasCellClickable =
      userRole !== "CE" && clickableColumnsToCheckWithRole.includes(field);
    let userComponentChildAccess = {};
    if (field === "userId") {
      userComponentChildAccess = componentAccess.find(
        ({ componentType }) => componentType === "MUGFV"
      );
    }

    if (clickableColumns.includes(field) || hasCellClickable) {
      history.push(pathName, {
        id: value,
        componentAccess: userComponentChildAccess?.childComponents,
      });
    }
  };
  const handleChangePage = (event, newPage, actionType) => {
    setLoading(true);
    onAnyPaginationAction({
      ...data,
      startOffset: newPage * rowsPerPage,
      endOffset: newPage * rowsPerPage + rowsPerPage,
      actionType,
    });
    setPage(newPage);
    if (props?.setPage) {
      props?.setPage(newPage);
    }
    setLoading(false);
  };

  const handleChangeRowsPerPage = (event) => {
    const newRowsPerPage = event.target.value;
    setRowsPerPage(newRowsPerPage);
    onAnyPaginationAction({
      ...data,
      startOffset: 0,
      endOffset: newRowsPerPage,
    });
    setPage(0);
  };

  const getRowHeight = () => {
    return (autoRowHeight == true) ? "auto" : null;
  };

  let boxProperties = {};
  if (dataGridWidth) {
    boxProperties.className = dataGridWidth;
  }
  if (sumOfColumnWidth === true || sumOfColumnWidth === "true") {
      // 50 is minimum column width (even if the setting is less).  2 pixels added for table outside border.
      boxProperties.sx = { width: columns.map(c => Math.max(50, c.width)).reduce((a,b) => a+b, 2) + "px" }; 
  } else if (gridBoxWidth) {
    let boxWidth = (/^\d+$/.test(gridBoxWidth)) /* if integer value */ ? gridBoxWidth + "px" : gridBoxWidth;
    boxProperties.sx = { width: boxWidth};
  }

  return (
    <Box {...boxProperties}>
      <DataGrid
        onSelectionModelChange={handleSelectionModelChange}
        disableSelectionOnClick
        loading={initialLoading || loading}
        onCellClick={handleOnCellClick}
        rowHeight={65}
        getRowHeight={getRowHeight}
        checkboxSelection={checkboxSelection}
        selectionModel={selectedRows}
        onSelectionChange={() => {}}
        autoHeight
        hideFooterPagination={hideFooterPagination}
        density="compact"
        hideFooter={hideFooterPagination}
        aria-label={tableLabelText}
        disableColumnMenu
        hideFooterSelectedRowCount
        rows={rows}
        columns={columns}
        pageSize={rowsPerPage}
        showPaginationBottom={!hideFooterPagination}
        onCellEditCommit={handleCellEditCommit}
        isRowSelectable={isRowSelectable}
        initialState={initialStates}
        getCellClassName={getCellClassName}
        components={{
          Pagination: () => {
            return (
              <CustomPagination
                page={page}
                rowsPerPage={rowsPerPage}
                count={count}
                handleChangePage={handleChangePage}
                handleChangeRowsPerPage={handleChangeRowsPerPage}
              />
            );
          },
        }}
      />
      {pageName === "transaction-enquiry" && hideFooterPagination && (
        <Box display="flex" justifyContent={"end"} mr={20}>
          {page !== 0 ? (
            <ERPSBaseButton
              variant="secondary"
              className="button_with_margin48"
              label={"Previous"}
              onClick={(e) => handleChangePage(e, page - 1, "prev")}
              loading={prevBtnLoading}
            />
          ) : (
            ""
          )}
          {!(rows.length <= 100) ? (
            <Box pl={2}>
              <ERPSBaseButton
                variant="primary"
                className="button_with_margin48"
                label={"Next"}
                onClick={(e) => handleChangePage(e, page + 1, "next")}
                loading={nextBtnLoading}
              />
            </Box>
          ) : (
            ""
          )}
        </Box>
      )}
    </Box>
  );
};

export default ERPSGrid;
