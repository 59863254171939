import ERPSBaseLink from "../components/custom/inputs/ERPSBaseLink";
import { t } from "i18next";
import { convertDateIntoERPSDateFormat, formatAmount } from "../utils/helper";

export const rejectTransactionsHeading = (financialInstitutionMap, roleId, loggedInUserFi) => {
    return[
            {
                field: "seqNo",
                headerName: t("erps.common.label.number.short"),
                width: 50,
                sortable: false,
            },
            {
                field: "transaction_amount",
                headerName: t("erps.common.label.paymentAmount"),
                width: 160,
                sortable: false,
                renderCell: (params) => { if (params.value)  return formatAmount(params.value); },
            },
            {
                field: "effective_date",
                headerName: t("erps.common.label.effectiveDate"),
                width: 110,
                sortable: false,
                renderCell: (params) => convertDateIntoERPSDateFormat(params.value),
            },
            {
                field: "verification_number",
                headerName: t("erps.common.label.confirmationNumber.short"),
                width: 184,
                sortable: false,
            },
            {
                field:"fi_biller_id",
                headerName: t("erps.common.label.billerID"),
                width: 140,
                sortable: false,
            },
            {
                field: "fi_number",
                headerName: t("erps.common.label.fi"),
                width: 200,
                sortable: false,
                renderCell: (params) => financialInstitutionMap?.get(params.value) || params.value,
            },
            {
                field: "biller_customer_number",
                headerName: t("erps.common.label.customerAccountNumber.short"),
                width: 210,
                sortable: false,
            },
            {
                field: "fi_customer_name",
                headerName: t("erps.common.label.customerName"),
                width: 220,
                sortable: false,
            },
            {
                field: "reject",
                headerName: t("erps.common.button.reject"),
                width: 70,
                sortable: false,
                renderCell: (params) => (
                    <ERPSBaseLink
                        to={{
                            pathname: `/reject-payments-merchants/transaction-details`,
                            state: { id: params.row?.payment_id},
                        }}
                        className="link__blue"
                        label={t("erps.common.button.reject")}
                    />
                ),
            },
        ];
};



