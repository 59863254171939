import transactionEnquiryTypes from "../actions/types/transactionEnquiry.action.type";

export const transactionEnquiryInitialState = {
  error: null,
  transactionEnquiryData: null,
  transactionEnquiryDetails: null,
  count: 0,
  retraceRecallData: null,
  retraceRecallError: null,
  transactionForRejectData: null,
  transactionForRejectDetails: null,
  rejectTransactionData: null,
  rejectTransactionError: null,
  pendingApprovalData: null,
  pendingApprovalError: null,
  upcomingRecallsRejectsData: null,
  upcomingRecallsRejectsDetails: null,
  recallPendingApprovalsData: null,
  recallRejectHistoryData: null,
  recallRejectHistoryDetails: null
};

export default function transactionEnquiryReducer(
  state = transactionEnquiryInitialState,
  action
) {
  switch (action.type) {
    case transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_SUCCESS:
      return {
        transactionEnquiryData: action.data.data,
        error: null,
        transactionEnquiryDetails: action.data.transactionEnquiryDetails,
        count: action.data.count,
      };
    case transactionEnquiryTypes.GET_TRANSACTION_ENQUIRY_ERROR:
      return { transactionEnquiryData: null, count: 0, error: action.error };
    case transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_SUCCESS:
      return {
        ...state,
        retraceRecallData: action.data,
        retraceRecallError: null,
      };
    case transactionEnquiryTypes.UPDATE_RECALL_RETRACE_INFO_FAILURE:
      return {
        ...state,
        retraceRecallError: action.error,
        retraceRecallData: null,
      };
    case transactionEnquiryTypes.GET_TRANSACTION_FOR_REJECT_SUCCESS:
      return {
        transactionForRejectData: action.data.data,
        error: null,
        transactionForRejectDetails: action.data.transactionForRejectDetails,
        count: action.data.requestId,
      };
    case transactionEnquiryTypes.GET_TRANSACTION_FOR_REJECT_FAILURE:
      return{ transactionForRejectData: null, count: 0, error: action.error };
    case transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_REJECT_SUCCESS:
      return {
        ...state,
        rejectTransactionData: action.data,
        rejectTransactionError: null,
      };
    case transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_REJECT_FAILURE:
      return {
        ...state,
        rejectTransactionError: action.error,
        rejectTransactionData: null};

    case transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_SUCCESS:
      return {
        ...state,
        pendingApprovalData: action.data,
        pendingApprovalError: null };

    case transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_PENDING_APPROVAL_FAILURE:
      return {
        ...state,
        pendingApprovalError: action.error,
        pendingApprovalData: null };

    case "GET_URR_SUCCESS":
      return {
        upcomingRecallsRejectsData: action.data.data,
        error: null,
        upcomingRecallsRejectsDetails: action.data.upcomingRecallsRejectsDetails,
      };
    case "GET_URR_ERROR":
      return { upcomingRecallsRejectsData: null, error: action.error };

    case "GET_RECALL_PENDING_APPROVALS_SUCCESS":
      return {
        recallPendingApprovalsData: action.data.data,
        error: null,
        recallPendingApprovalsDetails: action.data.recallPendingApprovalsDetails,
      };
    case "GET_RECALL_PENDING_APPROVALS_ERROR":
      return { recallPendingApprovalsData: null, error: action.error };

    case transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_RECALL_SUCCESS:
      return {
        ...state,
        recallTransactionData: action.data,
        recallTransactionError: null,
      };
    case transactionEnquiryTypes.UPDATE_TRANSACTION_FOR_RECALL_FAILURE:
      return {
        ...state,
        recallTransactionError: action.error,
        recallTransactionData: null};

    case "GET_RECALL_REJECT_HISTORY_SUCCESS":
      return {
        recallRejectHistoryData: action.data.data,
        error: null,
        recallRejectHistoryDetails: action.data.recallRejectHistoryDetails,
      };
    case "GET_RECALL_REJECT_HISTORY_ERROR":
      return { recallRejectHistoryData: null, error: action.error };
    
    default:
      return state;
  }
}
