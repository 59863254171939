import { loginService } from "./login.service";
import { budgetCategoryService } from "./budgetCategory.service";
import { batchHolidayService } from "./batchHoliday.service";
import { userService } from "./user.service";
import { userQueueService } from "./user.queue.service";
import { passwordService } from "./password.service";
import { userPendingApprovalService } from "./userPendingApproval.service";
import { billerPendingApprovalService } from "./billerPendingApproval.service";
import { pricePlanPendingApprovalService } from "./pricePlanPendingApproval.service";
import { pricePlaneService } from "./pricePlan.service";
import { billerProfileService } from "./billerProfile.service";
import { availableDocumentService } from "./availableDocument.service";
import { fiService } from "./fi.services";
import { appConfigService } from "./app.config.service";
import { transactionEnquiryService } from "./transactionEnquiry.service";
import { upcomingRecallsRejectsService } from "./upcomingRecallsRejects.service.js";
import { relatedBillerService } from "./relatedBiller.service";
import { marketManagementSevice } from "./marketingManagement.service";
import { loginMessageSevice } from "./loginMessage.service";
import { viewStatisticsService } from "./viewStatistics.service";
import { synchronizationService } from "./synchronization.service";
import { faxManagementService } from "./faxManagement.service";
import { recallPaymentCustomerService } from "./recallPaymentCustomer.service";

const services = {
  loginService,
  budgetCategoryService,
  batchHolidayService,
  userService,
  passwordService,
  userQueueService,
  userPendingApprovalService,
  pricePlaneService,
  billerProfileService,
  availableDocumentService,
  pricePlanPendingApprovalService,
  fiService,
  appConfigService,
  billerPendingApprovalService,
  transactionEnquiryService,
  upcomingRecallsRejectsService,
  relatedBillerService,
  marketManagementSevice,
  loginMessageSevice,
  viewStatisticsService,
  synchronizationService,
  faxManagementService,
  recallPaymentCustomerService,
};

export default services;
